import { type Filter } from 'constants/types'
import { isValidFilterTuple } from 'components/Filters/NumberFilter/useNumberFilter'
import { type BaseFilterProps } from 'components/Filters/types'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import {
  type NormalizedMetrics,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { useGetAtomValue } from 'hooks/useGetAtomValue'
import { getFilterNumericValue } from 'shared/utils/analyticsConfig'
import { filtersStateAtom, useSetFiltersState } from './useFiltersState'

type AddNewFilterArgs = Parameters<BaseFilterProps['addFilter']>[0]

export const addNewFilter = ({
  filterId,
  filterIndex,
  value,
  selectedFilterGroup,
  filters,
  normalizedMetrics,
}: AddNewFilterArgs & {
  filters: Record<string, Filter[]>
  normalizedMetrics: NormalizedMetrics
}) => {
  const newFilters = { ...filters }
  const { format } = normalizedMetrics[filterId] ?? {}

  const isUpdate = Boolean(newFilters[filterId]?.[filterIndex])

  const formatNumber = (v: string | number) =>
    format
      ? String(getFilterNumericValue(v.toString(), format, selectedFilterGroup))
      : v.toString()

  // Ensure newFilters[filterId] is an array
  newFilters[filterId] = [...(newFilters[filterId] || [])]

  let newValue: string[] | string

  if (isValidFilterTuple(value, selectedFilterGroup)) {
    newValue = value.map(formatNumber)

    const newFilter = {
      comparisonId: selectedFilterGroup,
      value: newValue[0],
      maxValue: newValue[1],
    }

    if (isUpdate) {
      newFilters[filterId][filterIndex] = newFilter
    } else {
      newFilters[filterId].push(newFilter)
    }
  } else {
    newValue = Array.isArray(value)
      ? value.map(formatNumber)
      : formatNumber(value)

    const newFilter = {
      comparisonId: selectedFilterGroup,
      value: newValue,
    }

    if (isUpdate) {
      newFilters[filterId][filterIndex] = newFilter
    } else {
      newFilters[filterId].push(newFilter)
    }
  }

  return { newFilters, newValue }
}

export const useAddFilter = () => {
  const setFilters = useSetFiltersState()
  const normalizedMetrics = useNormalizedMetrics()
  const [trackEvent] = useTrackEvent()
  const getCurrentFilters = useGetAtomValue(filtersStateAtom)

  return (args: AddNewFilterArgs) => {
    const filters = getCurrentFilters()

    const { newFilters, newValue } = addNewFilter({
      ...args,
      filters,
      normalizedMetrics,
    })

    trackEvent({
      eventName: 'Report Filter Added',
      eventProperties: {
        filterType: args.selectedFilterGroup,
        key: args.filterId,
        value: Array.isArray(newValue) ? newValue.slice(0, 10) : newValue,
      },
    })

    setFilters(newFilters)
  }
}
