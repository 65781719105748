import { DYNAMIC_DATE_ID, getDatePreset } from 'constants/getDatePresets'
import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { type ExperimentConfig } from 'features/geoLift/graphql/fragments'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useState } from 'react'

export const CampaignDetails: React.FC<{
  config: ExperimentConfig
}> = ({ config }) => {
  const [showMore, setShowMore] = useState(false)

  const now = new Date()
  const defaultPeriod = getDatePreset(DYNAMIC_DATE_ID.LAST_4_WEEKS)

  let startDate = String(defaultPeriod.value[0]?.toISOString())
  let endDate = String(defaultPeriod.value[1]?.toISOString())

  const configStartDate = new Date(config.startDate)
  const configEndDate = new Date(config.endDate)

  if (now > configStartDate) {
    startDate = String(configStartDate.toISOString())
    endDate = String(configEndDate.toISOString())
  }

  const { statistics, query } = useStatistics({
    variables: {
      statisticsParams: {
        frontendIds: [config.frontendId],
        dimensions: ['campaign'],
        metrics: ['cost:total'],
        startDate: startDate,
        endDate: endDate,
        filter: {
          channel: [
            {
              value: [config.channel],
              comparisonId: 'isAnyOf',
            },
          ],
          country: [
            {
              value: [config.country],
              comparisonId: 'isAnyOf',
            },
          ],
          funnelCampaign: [
            {
              value: config.funnelCampaigns,
              comparisonId: 'isAnyOf',
            },
          ],
          campaign: [
            {
              value: config.excludeCampaigns,
              comparisonId: 'isNoneOf',
            },
          ],
          'cost:total': [
            {
              value: 1.0,
              comparisonId: 'gt',
            },
          ],
        },
      },
    },
  })

  if (query.loading)
    return (
      <Flex justifyContent="flex-end">
        <Skeleton h={4} w={24} />
      </Flex>
    )

  if (query.error || !statistics.length)
    return (
      <Typography
        fontSize="sm"
        fontWeight={400}
        lineHeight={5}
        color="gray.800"
        textAlign="right"
      >
        -
      </Typography>
    )

  const campaigns = statistics?.map((item) => item.campaign.formattedValue)

  if (!campaigns?.length) return null

  const campaignLimit = 3

  return (
    <Box minW={0}>
      {campaigns
        .slice(0, showMore ? undefined : campaignLimit)
        .map((campaign) => (
          <Tooltip key={campaign} label={campaign} placement="left" size="sm">
            <Typography
              fontSize="sm"
              fontWeight={400}
              lineHeight={5}
              color="gray.800"
              textAlign="right"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {campaign}
            </Typography>
          </Tooltip>
        ))}

      {campaigns.length > campaignLimit && (
        <Button
          float="right"
          size="sm"
          variant="link"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'View less' : 'View more'}
        </Button>
      )}
    </Box>
  )
}
