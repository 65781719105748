import { AdvancedMultiSelectFilter } from 'components/Filters/AdvancedMultiSelectFilter'
import { NumberFilter } from 'components/Filters/NumberFilter/NumberFilter'
import { SimpleMultiSelectFilter } from 'components/Filters/SimpleMultiSelectFilter'
import { type BaseFilterProps } from 'components/Filters/types'
import {
  useNormalizedDimensions,
  type NormalizedDimensions,
} from 'graphql/statistics/useDimensions'
import { type FC } from 'react'

type PopupType = 'simpleMultiselect' | 'advancedMultiselect' | 'numeric'

const getFilterPopupType = (id: string, dimensions: NormalizedDimensions) => {
  const isDimension = dimensions[id]

  return !isDimension
    ? 'numeric'
    : (popupTypeMappings[id] ?? 'simpleMultiselect')
}

const popupTypeMappings: Record<string, PopupType> = {
  campaign: 'advancedMultiselect',
  ad: 'advancedMultiselect',
  adGroup: 'advancedMultiselect',
  adSet: 'advancedMultiselect',
  keyword: 'advancedMultiselect',
  voucher: 'advancedMultiselect',
  landingPage: 'advancedMultiselect',
  product: 'advancedMultiselect',
  productName: 'advancedMultiselect',
  variant: 'advancedMultiselect',
  brand: 'advancedMultiselect',
  category: 'advancedMultiselect',
  subCategory: 'advancedMultiselect',
  collection: 'advancedMultiselect',
  material: 'advancedMultiselect',
  customAttribute1: 'advancedMultiselect',
  customAttribute2: 'advancedMultiselect',
  customAttribute3: 'advancedMultiselect',
  customAttribute4: 'advancedMultiselect',
  customAttribute5: 'advancedMultiselect',
  customerId: 'advancedMultiselect',
}

const emptyArray: string[] = []

interface FilterPopupPickerProps extends BaseFilterProps {}

export const FilterPopupPicker: FC<FilterPopupPickerProps> = (props) => {
  const normalizedDimensions = useNormalizedDimensions()

  const { value: selectedFilterValue, maxValue: selectedFilterValueMax } =
    props.filters?.[props.filterId]?.[props.filterIndex] ?? {}
  const selectedOptions = !selectedFilterValue
    ? emptyArray
    : Array.isArray(selectedFilterValue)
      ? selectedFilterValue
      : [selectedFilterValue]

  const popup: PopupType = getFilterPopupType(
    props.filterId,
    normalizedDimensions,
  )

  if (popup === 'simpleMultiselect')
    return (
      <SimpleMultiSelectFilter {...props} selectedOptions={selectedOptions} />
    )

  if (popup === 'advancedMultiselect')
    return (
      <AdvancedMultiSelectFilter
        {...props}
        selectedOptions={selectedFilterValue}
      />
    )

  const numberFilterValue =
    !Array.isArray(selectedFilterValue) && selectedFilterValueMax
      ? [selectedFilterValue, selectedFilterValueMax]
      : selectedFilterValue

  return <NumberFilter {...props} values={numberFilterValue} />
}
