import { Box, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { EmptyState } from 'features/segmentations/shared/EmptyState'
import { useSegmentationCustomerSets } from '../graphql/useSegmentationCustomerSets'
import { AddCustomerSetModal } from './AddCustomerSetModal'
import { CustomerSetRow } from './CustomerSetRow'

export const CustomerSetsList: React.FC = () => {
  const { customerSets, query } = useSegmentationCustomerSets()

  if (query.loading) {
    return (
      <Table mt={6}>
        <Tbody>
          <SkeletonTableRows columns={3} rows={5} />
        </Tbody>
      </Table>
    )
  }

  if (customerSets.length === 0) {
    return (
      <EmptyState
        imageSrc="/images/Layers.svg"
        title="Customer set"
        description="A customer set defines the rules for how your customers should automatically be assigned to different customer sets. You can create multiple sets to use for different customer sets."
        modalButton={
          <AddCustomerSetModal
            modalButton={<Button size="sm">Create new customer set</Button>}
          />
        }
      />
    )
  }

  return (
    <Table mt={6}>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Last updated</Th>
          <Th>
            <Box srOnly>Actions</Box>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {customerSets.map((set) => (
          <CustomerSetRow key={set.id} set={set} />
        ))}
      </Tbody>
    </Table>
  )
}
