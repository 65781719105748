import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography/Typography'
import { useDeleteDashboard } from 'features/dashboards/graphql/useDeleteDashboard'
import { useSetDashboardState } from 'features/dashboards/hooks/useDashboardState'
import { DASHBOARD_PAGES } from 'features/dashboards/routePages'
import { useNavigate, useMatch } from 'react-router'

interface DeleteDashboardModalProps {
  isOpen: boolean
  onClose: () => void
  dashboardId: string | undefined
}

export const DeleteDashboardModal = ({
  isOpen,
  onClose,
  dashboardId,
}: DeleteDashboardModalProps) => {
  const [deleteDashboard, deleteDashboardState] = useDeleteDashboard()
  const isDashboardDetailsPage = useMatch(
    DASHBOARD_PAGES.DASHBOARD_BY_ID(dashboardId ?? ''),
  )
  const navigate = useNavigate()
  const toast = useToast()

  const setDashboardState = useSetDashboardState()

  const onDeleteDashboard = async () => {
    if (!dashboardId) return

    try {
      const response = await deleteDashboard({ id: dashboardId })

      if (response.data?.deleteDashboard) {
        onClose()
        if (isDashboardDetailsPage) {
          setDashboardState(undefined)
          navigate(DASHBOARD_PAGES.BASE)
        }
      } else {
        toast({
          status: 'error',
          description: 'Could not delete dashboard',
        })
      }
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not delete dashboard',
      })
      captureException(`Delete dashboard: ${error}`)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete dashboard</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Typography>
            The dashboard will be permanently deleted including all widgets it
            contains.
          </Typography>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="grey" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={onDeleteDashboard}
            isLoading={deleteDashboardState.loading}
          >
            Delete dashboard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
