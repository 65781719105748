import { Flex, MenuButton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useDashboardState } from 'features/dashboards/hooks/useDashboardState'
import { AddWidgetButton } from './AddWidgetButton/AddWidgetButton'
import { DashboardContextualMenu } from './DashboardContextualMenu/DashboardContextualMenu'
import { DashboardHeaderContainer } from './DashboardHeaderContainer'
import { DashboardNameIconForm } from './DashboardNameIconForm'
import { SaveDashboardButton } from './SaveDashboardButton'

export const DashboardHeader = () => {
  const dashboard = useDashboardState()

  return (
    <DashboardHeaderContainer>
      <Flex alignItems="center" gap={1}>
        <DashboardNameIconForm />
        <DashboardContextualMenu dashboard={dashboard} />
      </Flex>
      <Flex gap={2}>
        <AddWidgetButton
          customMenuButton={
            <MenuButton
              as={Button}
              leadingIcon={{ name: 'PlusIcon' }}
              variant="outline"
              colorScheme="gray"
              size="sm"
              w="fit-content"
            >
              Add widget
            </MenuButton>
          }
        />
        <SaveDashboardButton />
      </Flex>
    </DashboardHeaderContainer>
  )
}
