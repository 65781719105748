import { Box, Divider, Flex, useDisclosure } from '@chakra-ui/react'
import { widgetConfig } from 'features/dashboards/consts'
import { selectedWidgetTypeAtom } from 'features/dashboards/hooks/useWidgetsState'
import { WidgetType } from 'features/dashboards/types'
import AttributionButton from 'features/reports/components/ReportPanel/ReportDetailsPanel/AttributionSection/AttributionButton'
import { AttributionModal } from 'features/reports/components/ReportPanel/ReportDetailsPanel/AttributionSection/AttributionModal'
import { DimensionsSection } from 'features/reports/components/ReportPanel/ReportDetailsPanel/DimensionsSection'
import { MetricsSection } from 'features/reports/components/ReportPanel/ReportDetailsPanel/MetricsSection/MetricsSection'
import { useAtomValue } from 'jotai'
import { WidgetDatePickerContainer } from '../WidgetFilters/WidgetDatePickerContainer'
import { WidgetFiltersContainer } from '../WidgetFilters/WidgetFiltersContainer'
import { LastUpdateSection } from './LastUpdateSection'
import { WidgetComparisonSettings } from './WidgetComparisonSettings'

export const WidgetDataSettings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const widgetType = useAtomValue(selectedWidgetTypeAtom)
  const { requiresDimensions } = widgetType ? widgetConfig[widgetType] : {}

  return (
    <>
      <Flex p={4} gap={4} direction="column">
        {requiresDimensions && <DimensionsSection />}
        <MetricsSection allowInvalidMetricCombinations={!requiresDimensions} />
        <AttributionButton onOpen={onOpen} />
        <AttributionModal
          isOpen={isOpen}
          onClose={onClose}
          allowInvalidMetricCombinations={!requiresDimensions}
        />
      </Flex>
      <Divider />
      {widgetType === WidgetType.TABLE && (
        <>
          <Flex p={4} gap={4} direction="column">
            <WidgetComparisonSettings />
          </Flex>
          <Divider />
        </>
      )}
      <Flex p={4} gap={4} direction="column">
        <WidgetFiltersContainer />
        <WidgetDatePickerContainer />
      </Flex>
      <Divider />

      <Box p={4}>
        <LastUpdateSection />
      </Box>
    </>
  )
}
