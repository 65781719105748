import { type Filter } from 'constants/types'
import {
  type NormalizedDimensions,
  type Dimension,
} from 'graphql/statistics/useDimensions'
import {
  type NormalizedMetrics,
  type Metric,
} from 'graphql/statistics/useMetrics'
import { getValidDimensions, getValidMetrics } from './analyticsConfig'

interface GetValidFiltersParams {
  filters: Record<string, Filter[]>
  normalizedDimensions: NormalizedDimensions
  normalizedMetrics: NormalizedMetrics
  dimensions: Dimension[]
  metrics: Metric[]
}

export const getValidFilters = ({
  filters,
  normalizedDimensions,
  normalizedMetrics,
  dimensions,
  metrics,
}: GetValidFiltersParams) => {
  return Object.entries(filters).reduce(
    (acc, [key, filter]) => {
      const dimension = normalizedDimensions[key]

      if (dimension) {
        const validDimensions = getValidDimensions([dimension], metrics)

        if (validDimensions.length === 0) {
          return acc
        }

        acc[key] = filter
      }
      const metric = normalizedMetrics[key]

      if (!metric || !metrics.includes(metric)) {
        return acc
      }

      const validMetrics = getValidMetrics([key], normalizedMetrics, dimensions)

      if (validMetrics.length === 0) {
        return acc
      }

      acc[key] = filter

      return acc
    },
    {} as Record<string, Filter[]>,
  )
}
