import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { useSegmentationCustomerSets } from 'features/segmentations/views/CustomerSetsView/graphql/useSegmentationCustomerSets'
import { useSegmentationProductSets } from 'features/segmentations/views/ProductSetsView/graphql/useSegmentationSets'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useState } from 'react'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import { ExportCustomerSetModal } from './ExportCustomerSetModal'
import { ExportProductSetModal } from './ExportProductSetModal'
import { ExportToCSVButton } from './ExportToCSVButton'
import { isProductRelatedDimension } from './utils'

export const ExportMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const [selectedSegmentation, setSelectedSegmentation] = useState<
    'product' | 'customer' | null
  >(null)
  const { dimensions, dimensionKeys } = useDimensionsState()

  const { enableSegmentationProductSet, enableSegmentationCustomerSet } =
    useFeatureFlags()
  const { productSets, query: productSetsQuery } = useSegmentationProductSets()
  const { customerSets, query: customerSetsQuery } =
    useSegmentationCustomerSets()

  const productRelatedDimensionSelected = dimensions.some(
    isProductRelatedDimension,
  )
  const customerIdSelected = dimensionKeys.find((id) => id === 'customerId')

  const isProductSetReady =
    productRelatedDimensionSelected &&
    !productSetsQuery.loading &&
    productSets.length > 0

  const isCustomerSetReady =
    customerIdSelected && !customerSetsQuery.loading && customerSets.length > 0

  const productSetTooltipLabel = !productRelatedDimensionSelected
    ? 'Select product related dimension to enable this option.'
    : productSets.length === 0
      ? 'Create at least one product set to enable this option.'
      : ''

  const customerSetTooltipLabel = !customerIdSelected
    ? 'Select customer id dimension to enable this option.'
    : customerSets.length === 0
      ? 'Create at least one customer set to enable this option.'
      : ''

  if (!(enableSegmentationProductSet || enableSegmentationCustomerSet)) {
    return <ExportToCSVButton type="button" />
  }

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy size="sm">
      <MenuButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onOpen()
        }}
        as={Button}
        size="sm"
        variant="outline"
        colorScheme="gray"
        trailingIcon={{
          name: isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon',
          size: 'small',
        }}
      >
        Export
      </MenuButton>
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList>
          <ExportToCSVButton type="menu" />
          {(enableSegmentationProductSet || enableSegmentationCustomerSet) && (
            <MenuDivider />
          )}
          <MenuGroup title="Export to segmentations">
            {enableSegmentationProductSet && (
              <Tooltip
                placement="bottom"
                label={productSetTooltipLabel}
                isDisabled={isProductSetReady}
              >
                <Box>
                  <MenuItem
                    onClick={() => {
                      setSelectedSegmentation('product')
                      onModalOpen()
                    }}
                    isDisabled={!isProductSetReady}
                  >
                    <Flex width="100%" alignItems="center" gap={2}>
                      <Icon name="CubeIcon" size="small" />
                      Product set
                    </Flex>
                  </MenuItem>
                </Box>
              </Tooltip>
            )}
            {enableSegmentationCustomerSet && (
              <Tooltip
                placement="bottom"
                label={customerSetTooltipLabel}
                isDisabled={isCustomerSetReady}
              >
                <Box>
                  <MenuItem
                    onClick={() => {
                      setSelectedSegmentation('customer')
                      onModalOpen()
                    }}
                    isDisabled={!isCustomerSetReady}
                  >
                    <Flex width="100%" alignItems="center" gap={2}>
                      <Icon name="CustomersIcon" size="small" />
                      Customer set
                    </Flex>
                  </MenuItem>
                </Box>
              </Tooltip>
            )}
          </MenuGroup>
        </MenuList>
      </Portal>
      {isProductSetReady && selectedSegmentation === 'product' && (
        <ExportProductSetModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          productSets={productSets}
        />
      )}
      {isCustomerSetReady && selectedSegmentation === 'customer' && (
        <ExportCustomerSetModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          customerSets={customerSets}
        />
      )}
    </Menu>
  )
}
