import { useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { useMemo } from 'react'
import { widgetAnalyticsConfigAtom } from '../atoms/dashboardViewState'

const focusWidgetCompareUnitStateAtom = atomFamily(
  (widgetId: string | undefined) =>
    focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
      optic.optional().prop('compareUnit'),
    ),
)

export const useWidgetCompareUnitState = (widgetId: string | undefined) => {
  const atom = useMemo(
    () => focusWidgetCompareUnitStateAtom(widgetId),
    [widgetId],
  )

  return useAtomValue(atom)
}

export const useSetWidgetCompareUnitState = (widgetId: string | undefined) => {
  const atom = useMemo(
    () => focusWidgetCompareUnitStateAtom(widgetId),
    [widgetId],
  )

  return useSetAtom(atom)
}
