import { graphql } from 'generated/graphql'

export const AnalyticsConfigFragment = graphql(/* GraphQL */ `
  fragment AnalyticsConfigFields on AnalyticsConfig {
    id
    chart
    compareDynamicDate
    compareStartDate
    compareEndDate
    compareUnit
    dimensions
    metrics
    startDate
    endDate
    dynamicDate
    filters
    tableState
    __typename
  }
`)

export const DashboardWidgetFieldsFragment = graphql(/* GraphQL */ `
  fragment DashboardWidgetFields on DashboardWidget {
    __typename
    id
    name
    type
    analyticsConfig {
      ...AnalyticsConfigFields
    }
  }
`)

export const DashboardOwnerFragment = graphql(/* GraphQL */ `
  fragment DashboardOwnerFields on User {
    id
    externalId
  }
`)

export const DashboardFieldsFragment = graphql(/* GraphQL */ `
  fragment DashboardFields on Dashboard {
    __typename
    id
    name
    createdAt
    updatedAt
    iconName
    iconColor
    visibility {
      __typename
      ...TeamFields
      ...MerchantFields
    }
    widgets {
      ...DashboardWidgetFields
    }
    startDate
    endDate
    dynamicDate
    compareStartDate
    compareEndDate
    compareDynamicDate
    filters
    layout
  }
`)

export const DashboardWithOwnerFragment = graphql(/* GraphQL */ `
  fragment DashboardWithOwnerFields on Dashboard {
    ...DashboardFields
    owner {
      ...DashboardOwnerFields
    }
  }
`)
