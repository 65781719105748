import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useSetReportStateAtom } from 'features/reports/hooks/useReportState'

import { REPORT_PAGES } from 'features/reports/routePages'
import { useDeleteReport } from 'graphql/reports/useDeleteReport'
import { type FC } from 'react'
import { useMatch, useNavigate } from 'react-router'
import { useSetAnalyticsConfigAtom } from 'shared/hooks/useAnalyticsConfigState'

interface DeleteReportModalProps {
  isOpen: boolean
  onClose: () => void
  reportId: string | undefined
}

export const DeleteReportModal: FC<DeleteReportModalProps> = ({
  isOpen,
  onClose,
  reportId,
}) => {
  const toast = useToast()
  const [deleteReportMutation, deleteReportState] = useDeleteReport()
  const navigate = useNavigate()
  const isReportDetailsPage = useMatch(
    REPORT_PAGES.REPORT_BY_ID(reportId ?? ''),
  )
  const setReportAtom = useSetReportStateAtom()
  const setAnalyticsConfigAtom = useSetAnalyticsConfigAtom()

  const onDeleteReport = async () => {
    if (!reportId) return

    try {
      const response = await deleteReportMutation({
        id: reportId,
      })

      if (response.data?.deleteReport) {
        onClose()
        if (isReportDetailsPage) {
          setReportAtom(undefined)
          setAnalyticsConfigAtom(undefined)
          navigate(REPORT_PAGES.BASE)
        }
      } else {
        toast({ status: 'error', description: 'Could not delete report' })
      }
    } catch (error) {
      toast({ status: 'error', description: 'Could not delete report' })
      captureException(`Delete report: ${error}`)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Typography>
            The report will be deleted. This can not be undone.
          </Typography>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="grey" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={onDeleteReport}
            isLoading={deleteReportState.loading}
          >
            Delete report
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
