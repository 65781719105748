import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  GridItem,
  SimpleGrid,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { SingleDatepicker } from 'components/Datepicker/SingleDatepicker/SingleDatepicker'
import { Dropdown } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Label } from 'components/Label/Label'
import { RadioCard } from 'components/RadioCard/RadioCard'
import { Typography } from 'components/Typography'
import { addDays } from 'date-fns'
import { ExperimentConfigPurpose } from 'features/geoLift/graphql/fragments'
import { useChannels } from 'graphql/useChannels'
import { useCountries } from 'graphql/useCountries'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { type FieldError, useForm } from 'react-hook-form'
import { z } from 'zod'

import { ExcludeCampaignsDropdown } from './ExcludeCampaignsDropdown'
import { FunnelDropdown } from './FunnelDropdown'

interface ExperimentConfigDrawerProps {
  isOpen: boolean
  onClose: () => void
  defaultValues: ValidationSchema
  onSubmit: (args: ValidationSchema) => void
  isLoading: boolean
  mode: 'create' | 'edit'
}

const validationSchema = z.object({
  name: z.string().min(1),
  purpose: z.enum([
    ExperimentConfigPurpose.LIFT,
    ExperimentConfigPurpose.NEW_CHANNEL,
  ] as const),
  frontendId: z.string().min(1, { message: 'Please select a storefront' }),
  country: z.string().min(1, { message: 'Please select a country' }),
  channel: z.string().min(1, { message: 'Please select a channel' }),
  funnelCampaigns: z
    .string()
    .array()
    .min(1, { message: 'Please select a funnel' }),
  excludeCampaigns: z.string().array(),
  expectedRoas: z.coerce
    .number()
    .min(100, { message: 'Must be greater or equal to 100%' })
    .max(1000, { message: 'Must be less or equal to 1000%' }),
  startDate: z.date({ message: 'Please select a start date' }),
  treatmentPeriod: z
    .number({
      message: 'Please select a test period',
    })
    .min(1, { message: 'Please select a test period' }),
  postTreatmentPeriod: z
    .number({
      message: 'Please select a post treatment period',
    })
    .min(1, { message: 'Please select a post treatment period' }),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const mmmChannels = ['google', 'facebook', 'tiktok']

export const ExperimentConfigDrawer: React.FC<ExperimentConfigDrawerProps> = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit,
  isLoading,
  mode,
}) => {
  const { sites = [] } = useMerchantInfo()
  const siteOptions = useMemo(
    () =>
      (sites ?? []).map((site) => ({ id: site.frontendId, name: site.name })),
    [sites],
  )
  const { countries, query: countryQuery } = useCountries()
  const { channels, query: channelsQuery } = useChannels()

  const methods = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues,
  })

  const {
    watch,
    setValue,
    formState: { errors },
  } = methods

  const [
    frontendId,
    purpose,
    country,
    channel,
    funnelCampaigns,
    excludeCampaigns,
    startDate,
    treatmentPeriod,
    postTreatmentPeriod,
  ] = watch([
    'frontendId',
    'purpose',
    'country',
    'channel',
    'funnelCampaigns',
    'excludeCampaigns',
    'startDate',
    'treatmentPeriod',
    'postTreatmentPeriod',
  ])

  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerOverlay />
      <Form<ValidationSchema> methods={methods} onSubmit={onSubmit}>
        <DrawerContent position="relative" overflowY="auto">
          <DrawerHeader>
            {mode === 'create'
              ? 'Add new experiment'
              : 'Edit experiment parameters'}
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody>
            <Typography
              fontSize="medium"
              color="color.900"
              lineHeight={6}
              mb={2}
            >
              Experiment details
            </Typography>

            <Typography fontSize="sm" color="color.800" lineHeight={5}>
              Specify the details of the experiment. Based on what you select we
              will suggest statistically optimised experiment designs.
            </Typography>

            <SimpleGrid columns={2} spacingY={6} spacingX={4} mt={6}>
              <GridItem colSpan={2}>
                <Label label="Type of test" />

                <SimpleGrid columns={2} spacingX={4}>
                  <RadioCard
                    label="Lift test"
                    description="A test where the treatment group has ads paused or reduced (holdout treatment) to measure the true incremental impact of your marketing efforts."
                    selected={purpose === ExperimentConfigPurpose.LIFT}
                    onChange={() =>
                      setValue('purpose', ExperimentConfigPurpose.LIFT)
                    }
                  />

                  <RadioCard
                    label="New channel test"
                    description="A test where the treatment group receives ads in a new channel to measure its incremental contribution to sales or profit."
                    selected={purpose === ExperimentConfigPurpose.NEW_CHANNEL}
                    onChange={() =>
                      setValue('purpose', ExperimentConfigPurpose.NEW_CHANNEL)
                    }
                  />
                </SimpleGrid>
              </GridItem>

              <GridItem colSpan={2}>
                <Divider />
              </GridItem>

              <Input
                name="name"
                label="Experiment name"
                placeholder="E.g Facebook lower US"
                type="text"
                error={errors.name}
              />

              <Dropdown
                options={siteOptions}
                value={frontendId}
                label="Storefront"
                error={errors.frontendId}
                placeholder="Select storefront"
                callback={(option) =>
                  setValue('frontendId', option.id.toString(), {
                    shouldValidate: true,
                  })
                }
              />

              <ComboBox
                selectionMode={SELECTION_MODE.SINGLE}
                options={countries}
                selected={country}
                label="Country"
                isLoading={countryQuery.loading}
                error={errors.country}
                placeholder="Select Country"
                setSelected={(option) =>
                  setValue('country', option, { shouldValidate: true })
                }
              />

              <GridItem colSpan={2}>
                <Divider />
              </GridItem>

              <ComboBox
                selectionMode={SELECTION_MODE.SINGLE}
                options={channels.filter((c) => mmmChannels.includes(c.id))}
                selected={channel}
                label="Channel"
                isLoading={channelsQuery.loading}
                error={errors.channel}
                placeholder="Select Channel"
                setSelected={(option) =>
                  setValue('channel', option, { shouldValidate: true })
                }
              />

              <FunnelDropdown
                channel={channel}
                funnelCampaigns={funnelCampaigns}
                error={errors.funnelCampaigns as FieldError}
                onChange={(option) =>
                  setValue('funnelCampaigns', option, { shouldValidate: true })
                }
              />
              <ExcludeCampaignsDropdown
                frontendId={frontendId}
                channel={channel}
                country={country}
                funnelCampaigns={funnelCampaigns}
                excludeCampaigns={excludeCampaigns}
                onChange={(option) =>
                  setValue('excludeCampaigns', option, { shouldValidate: true })
                }
              />
              <Input
                name="expectedRoas"
                label={'Expected ROAS'}
                placeholder={'Expected ROAS'}
                type="number"
                labelTooltip="Expected average return on ad spend for the selected channel and funnels. Used to estimate the cost and incrementality of the experiment."
                helperText="%"
                min="0"
                error={errors.expectedRoas}
              />
              <GridItem colSpan={2}>
                <Divider />
              </GridItem>

              <SingleDatepicker
                selectedDate={startDate}
                placement="bottom"
                setSelectedDate={(date) => {
                  setValue('startDate', date as Date, { shouldValidate: true })
                }}
                minDate={addDays(new Date(), 1)}
                placeholder="DD MMM, YYYY"
                label="Start date"
                error={errors.startDate}
              />

              <Dropdown
                options={[
                  { id: 28, name: '4 Weeks' },
                  { id: 42, name: '6 Weeks' },
                  { id: 56, name: '8 Weeks' },
                  { id: 70, name: '10 Weeks' },
                  { id: 84, name: '12 Weeks' },
                ]}
                value={treatmentPeriod}
                label="Test period"
                error={errors.treatmentPeriod}
                placeholder="Select test period"
                callback={(option) =>
                  setValue('treatmentPeriod', option.id, {
                    shouldValidate: true,
                  })
                }
              />
              <Dropdown
                options={[
                  { id: 14, name: '2 Weeks' },
                  { id: 28, name: '4 Weeks' },
                  { id: 42, name: '6 Weeks' },
                ]}
                value={postTreatmentPeriod}
                label="Post treatment period"
                error={errors.postTreatmentPeriod}
                placeholder="Select post treatment period"
                callback={(option) =>
                  setValue('postTreatmentPeriod', option.id, {
                    shouldValidate: true,
                  })
                }
              />
            </SimpleGrid>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" isLoading={isLoading}>
              {mode === 'create' ? 'Create experiment' : 'Update'}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Form>
    </Drawer>
  )
}
