import { Box, Flex } from '@chakra-ui/react'
import { WidgetStatus } from 'features/dashboards/atoms/dashboardViewState'
import { useWidgetPanelOpenAtom } from 'features/dashboards/atoms/widgetPanel'
import { useSelectWidget } from 'features/dashboards/hooks/useSelectWidget'
import {
  selectedWidgetIdAtom,
  useWidgetStatus,
  useWidgetType,
} from 'features/dashboards/hooks/useWidgetsState'
import { WidgetType } from 'features/dashboards/types'
import { useAtomValue } from 'jotai'
import { useLayoutEffect, useRef, useState } from 'react'
import { WidgetContextualMenu } from '../WidgetContextualMenu/WidgetContextualMenu'
import { ChartWidgetContent } from './ChartWidgetContent'
import { KpiWidgetContent } from './KpiWidgetContent'
import { TableWidgetContent } from './TableWidgetContent'
import { WidgetCardDateLabel } from './WidgetCardDateLabel'
import { WidgetCardTitle } from './WidgetCardTitle'

const getBorderProps = (
  widgetStatus: WidgetStatus | undefined,
  isSelected: boolean,
  isHovered: boolean,
) => {
  if (widgetStatus === WidgetStatus.Error) {
    return {
      borderColor: 'red.400',
      borderWidth: isSelected ? '2px' : '1px',
    }
  }
  if (isSelected) {
    return {
      borderColor: 'blue.400',
      borderWidth: '2px',
    }
  }

  return {
    borderColor: isHovered ? 'gray.400' : 'gray.200',
    borderWidth: '1px',
  }
}

interface WidgetCardProps {
  widgetId: string
  height: number
  rowId: string | undefined
}

const WidgetCard = ({ widgetId, height, rowId }: WidgetCardProps) => {
  const widgetType = useWidgetType(widgetId)
  const ref = useRef<HTMLDivElement>(null)
  const [titleHeight, setTitleHeight] = useState(0)
  const [isHovered, setIsHovered] = useState(false)
  const widgetPanelOpen = useWidgetPanelOpenAtom()
  const selectedWidgetId = useAtomValue(selectedWidgetIdAtom)
  const selectWidget = useSelectWidget()

  const widgetStatus = useWidgetStatus(widgetId)
  const isSelected = widgetId === selectedWidgetId && widgetPanelOpen
  const borderProps = getBorderProps(widgetStatus, isSelected, isHovered)

  useLayoutEffect(() => {
    if (ref.current) {
      setTitleHeight(ref.current.clientHeight)
    }
  }, [])

  return (
    <Flex
      onClick={() => selectWidget(widgetId)}
      bg="background"
      h="100%"
      maxW="full"
      flexDirection="column"
      borderStyle="solid"
      overflow="auto"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...borderProps}
    >
      <Box px={4} pt={4} ref={ref}>
        <WidgetCardTitle widgetId={widgetId} />
        <WidgetCardDateLabel widgetId={widgetId} />
      </Box>
      <Box flex={1}>
        {widgetType === WidgetType.CHART ? (
          <ChartWidgetContent
            widgetId={widgetId}
            chartContainerHeight={height - titleHeight - 34}
          />
        ) : null}
        {widgetType === WidgetType.KPI ? (
          <KpiWidgetContent widgetId={widgetId} />
        ) : null}
        {widgetType === WidgetType.TABLE ? (
          <TableWidgetContent widgetId={widgetId} />
        ) : null}
      </Box>

      <Box position="absolute" top={3} right={4}>
        <WidgetContextualMenu
          widgetId={widgetId}
          rowId={rowId}
          isVisible={isHovered}
        />
      </Box>
    </Flex>
  )
}

export default WidgetCard
