import { useToast } from '@chakra-ui/react'
import { useReportsAnalytics } from 'features/reports/hooks/useReportsAnalytics'
import { type SegmentationCustomerSet } from 'features/segmentations/graphql/fragments'
import { useCustomerSetById } from 'features/segmentations/graphql/useCustomerSetById'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import { useSetCustomerRules } from 'features/segmentations/views/CustomerRulesView/graphql/useSetSegmentationCustomerRules'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { uniq } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import { ExportSetModal } from './ExportSetModal'

export const ExportCustomerSetModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  customerSets: SegmentationCustomerSet[]
}> = ({ isOpen, onClose, customerSets }) => {
  const { data } = useReportsAnalytics({ fetchPolicy: 'cache-only' })
  const { dimensions } = useDimensionsState()
  const normalizedDimensions = useNormalizedDimensions()
  const [selectedDimension, setSelectedDimension] = useState(
    normalizedDimensions['customerId'],
  )
  const [uniqueCustomerIds, setUniqueCustomerIds] = useState<string[]>([])
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([])
  const [selectedCustomerSet, setSelectedCustomerSet] = useState(
    customerSets.length > 0
      ? customerSets[0]
      : ({ id: '' } as SegmentationCustomerSet),
  )
  const [setCustomerRules, setCustomerRulesState] = useSetCustomerRules()
  const { customerSet, query: customerSetQuery } = useCustomerSetById(
    selectedCustomerSet.id,
  )

  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueIds = uniq(
        data
          .map((row) => row[selectedDimension.id]?.value)
          .filter(Boolean) as string[],
      )

      setSelectedCustomerIds(uniqueIds)
      setUniqueCustomerIds(uniqueIds)
    }
  }, [data, selectedDimension.id])

  const onSubmit = () => {
    if (!customerSet) return

    const newOverrideRule = {
      filters: {
        [selectedDimension.id]: [
          { comparisonId: 'isAnyOf', value: selectedCustomerIds },
        ],
      },
      description: null,
      labelId: null,
      expiresAt: null,
    }

    setCustomerRules({
      variables: {
        params: {
          rules: [
            newOverrideRule,
            ...customerSet.rules.map((rule) => ({
              description: rule.description,
              filters: rule.filters,
              labelId: rule.label?.id,
              expiresAt: rule.expiresAt,
            })),
          ],
          setId: customerSet.id,
        },
      },
      optimisticResponse: {
        setSegmentationCustomerRules: {
          ...customerSet,
          rules: [
            {
              id: 'optimistic',
              __typename: 'SegmentationCustomerRule' as const,
              label: null,
              ...newOverrideRule,
            },
            ...customerSet.rules,
          ],
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __typename: 'SegmentationCustomerSet',
            id: customerSet.id,
          }),
          fields: { rules: () => data?.setSegmentationCustomerRules.rules },
        })
      },
      onCompleted: () => {
        navigate(SEGMENTATIONS_PAGES.CUSTOMER_RULES_BY_ID(customerSet.id))
      },
      onError: () => {
        toast({ status: 'error', description: 'Failed to create rule' })
      },
    })
  }

  return (
    <ExportSetModal
      type="Customer"
      enableDimensionSelector={false}
      filterLabel="Customer"
      filterIcon="CustomersIcon"
      isLoading={customerSetQuery.loading || setCustomerRulesState.loading}
      isDisabled={selectedCustomerIds.length === 0 || !customerSet}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      selectedValues={selectedCustomerIds}
      setSelectedValues={setSelectedCustomerIds}
      uniqueValues={uniqueCustomerIds}
      sets={customerSets}
      selectedSet={selectedCustomerSet}
      setSelectedSet={(set) =>
        setSelectedCustomerSet(set as SegmentationCustomerSet)
      }
      dimensions={dimensions}
      selectedDimension={selectedDimension}
      setSelectedDimension={setSelectedDimension}
    />
  )
}
