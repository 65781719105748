import { MenuButton, Box } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { AddWidgetButton } from '../DashboardHeader/AddWidgetButton/AddWidgetButton'

interface EmptyRowButtonProps {
  rowIndex: number
}

export const EmptyRowButton = ({ rowIndex }: EmptyRowButtonProps) => {
  return (
    <Box py={4}>
      <AddWidgetButton
        customMenuButton={
          <MenuButton
            as={ButtonIcon}
            name="PlusIcon"
            size="sm"
            w="full"
            maxW="full"
            colorScheme="grey"
            opacity={0}
            _hover={{ opacity: 1 }}
            transition="opacity 0.1s ease-in-out"
          />
        }
        options={{ rowIndex }}
      />
    </Box>
  )
}
