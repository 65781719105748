import { Box, Divider, Flex } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useWidgetPanelSetAtom } from 'features/dashboards/atoms/widgetPanel'
import { widgetConfig } from 'features/dashboards/consts'
import { WidgetType } from 'features/dashboards/types'
import { WidgetDataSettings } from './WidgetDataSettings'

export const KpiWidgetPanelContent = () => {
  const setWidgetPanelOpen = useWidgetPanelSetAtom()

  return (
    <Box
      flexShrink={0}
      w="300px"
      borderLeft="solid 1px"
      borderLeftColor="gray.200"
      h="full"
      overflowY="auto"
    >
      <Flex flexDirection="column" p={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Typography fontSize="md" color="gray.800">
            {widgetConfig[WidgetType.KPI].name}
          </Typography>
          <ButtonIcon
            name="CloseIcon"
            title="Close"
            size="sm"
            variant="ghost"
            onClick={() => setWidgetPanelOpen(false)}
          />
        </Flex>
      </Flex>
      <Divider />
      <WidgetDataSettings />
    </Box>
  )
}
