import { Flex, MenuItem } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { Icon } from 'components/Icon/Icon'
import { format } from 'date-fns'
import { useReportsAnalytics } from 'features/reports/hooks/useReportsAnalytics'
import { reportIdAtom } from 'features/reports/hooks/useReportState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { CSVLink } from 'react-csv'
import { useAnalyticsTableState } from 'shared/hooks/useAnalyticsTableState'
import { useDateState } from 'shared/hooks/useDateState'
import { getCsvData, getCsvHeaders } from './utils'

export interface CsvHeader {
  label: string
  key: string
  groupKey: string
  isCompare?: boolean
}

interface CsvData {
  data: Record<string, number | string>[]
  headers: CsvHeader[]
}

const emptyData: CsvData = {
  data: [],
  headers: [],
}

interface DownloadCSVButtonProps {
  type: 'button' | 'menu'
}

export const ExportToCSVButton = ({ type }: DownloadCSVButtonProps) => {
  const selectedReportId = useAtomValue(reportIdAtom)
  const state = useAnalyticsTableState()
  const [trackEvent] = useTrackEvent()
  const tableColumnIds = state.map((column) => column.id)
  const { dateRange, compareDateRange } = useDateState()
  const isCompare = getIsPopulatedDateRange(compareDateRange)
  const normalizedMetrics = useNormalizedMetrics()
  const normalizedDimensions = useNormalizedDimensions()
  const normalizedAttributionModels = useNormalizedAttributionModels()
  const { data } = useReportsAnalytics({ fetchPolicy: 'cache-only' })
  const [isPending, startTransition] = useTransition()
  const csvLinkRef = useRef<CSVLink>(null)

  const [csvData, setCsvData] = useState<CsvData>(emptyData)
  const [isLoading, setIsLoading] = useState(false)

  const isDisabled = tableColumnIds?.length === 0 || data?.length === 0

  const fileName = useMemo(() => {
    const startDate = dateRange[0]
    const endDate = dateRange[1]
    const formattedStartDate = startDate ? format(startDate, 'yyMMdd') : ''
    const formattedEndDate = endDate ? format(endDate, 'yyMMdd') : ''

    return formattedStartDate + '-' + formattedEndDate + '_dema_data'
  }, [dateRange])

  const fetchCsvData = () => {
    if (isDisabled || isLoading) {
      return
    }

    startTransition(() => {
      setIsLoading(true)

      const headers = getCsvHeaders(
        tableColumnIds,
        normalizedMetrics,
        normalizedDimensions,
        normalizedAttributionModels,
        isCompare,
      )
      const fetchedData = getCsvData(data, normalizedMetrics)

      setCsvData({ data: fetchedData, headers })
    })
    trackEvent({
      eventName: 'Report Exported',
      eventProperties: {
        reportId: selectedReportId ?? '',
      },
    })
  }

  useEffect(() => {
    if (csvLinkRef.current && !isPending && csvData.data.length > 0) {
      // @ts-expect-error type is not well defined
      csvLinkRef.current.link.click()
      setIsLoading(false)
    }
  }, [isPending, csvData])

  const CSVLinkComponent = ({
    text,
    style,
  }: {
    text: string
    style?: React.CSSProperties
  }) => {
    return (
      <>
        <Flex
          alignItems="center"
          gap={2}
          style={{ ...style }}
          onClick={fetchCsvData}
        >
          <Icon name="DownloadIcon" size="small" />
          {text}
        </Flex>
        <CSVLink
          {...csvData}
          // @ts-expect-error type is not well defined
          ref={csvLinkRef}
          filename={fileName}
        />
      </>
    )
  }

  if (type === 'button') {
    return (
      <Button
        size="sm"
        variant="outline"
        colorScheme="gray"
        isDisabled={isDisabled}
      >
        <CSVLinkComponent text="Export" />
      </Button>
    )
  } else if (type === 'menu') {
    return (
      <MenuItem isDisabled={isDisabled} p={0}>
        <CSVLinkComponent
          text="Export to CSV"
          style={{ width: '100%', height: '100%', padding: '6px 16px' }}
        />
      </MenuItem>
    )
  }
}
