import { atom, useAtomValue } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { useMemo } from 'react'
import { DASHBOARD_LAYOUT_GRID_COLUMNS, widgetConfig } from '../consts'
import { type DashboardLayoutCell, type DashboardLayoutRow } from '../types'
import { dashboardLayoutAtom } from './useDashboardState'
import { widgetTypeByWidgetIdAtom } from './useWidgetsState'

export interface DashboardLayoutCellState extends DashboardLayoutCell {
  minWidth: number
  minHeight: number
}
export interface DashboardLayoutRowState extends DashboardLayoutRow {
  rowId: string
  minHeight: number
  cells: DashboardLayoutCellState[]
  availableWidth: number
  rowIndex: number
}

export type DashboardLayoutState = {
  rows: Partial<Record<string, DashboardLayoutRowState>>
  order: string[]
}

export const dashboardLayoutRowStateAtom = atomFamily(
  (rowId: string | undefined) => {
    return atom<DashboardLayoutRowState | undefined>((get) => {
      if (!rowId) return

      const layout = get(dashboardLayoutAtom)
      const row = layout.rows[rowId]

      if (!row) return

      const cells = row?.cells
        .map((cell) => {
          const widgetType = get(widgetTypeByWidgetIdAtom(cell.widgetId))

          if (!widgetType) return null

          const { minWidth, minHeight } = widgetConfig[widgetType] || {}

          return {
            ...cell,
            minWidth,
            minHeight,
          }
        })
        .filter((cell): cell is DashboardLayoutCellState => cell !== null)

      const minHeight = cells?.reduce((maxHeight, cell) => {
        if (!cell) return maxHeight

        return Math.max(maxHeight, cell.minHeight)
      }, 0)

      const minWidth = cells?.reduce((sum, cell) => {
        if (!cell) return sum

        return sum + cell.minWidth
      }, 0)

      const rowIndex = layout.order.indexOf(rowId)

      const availableWidth = DASHBOARD_LAYOUT_GRID_COLUMNS - (minWidth ?? 0)

      return {
        rowId,
        rowIndex,
        cells,
        minHeight,
        availableWidth,
        height: row?.height,
      }
    })
  },
)

export const useDashboardLayoutRowState = (rowId: string | undefined) => {
  const rowAtom = useMemo(() => dashboardLayoutRowStateAtom(rowId), [rowId])

  return useAtomValue(rowAtom)
}

// State for drag and drop
export enum WidgetDropPosition {
  Before = 'before',
  After = 'after',
}

export const overItemStateAtom = atom<{
  id: string
  containerId: string
  dropPosition?: WidgetDropPosition
} | null>(null)

export const activeItemStateAtom = atom<{
  id: string
  containerId: string
} | null>(null)
