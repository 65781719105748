import {
  DEFAULT_ATTRIBUTION_ID,
  useNormalizedAttributionModels,
} from 'graphql/statistics/useAttributionModels'
import { isAttributedMetric } from 'graphql/statistics/useMetrics'
import { useMemo } from 'react'
import { useMetricsState } from 'shared/hooks/useMetricsState'
import { ReportDetailRow } from '../ReportDetailsRow/ReportDetailsRow'

const infoDetails = {
  tooltipText:
    "An attribution model defines how credit is assigned to different touchpoints such as channels and ads in the user's path to completing an important action such as placing an order.",
  link: 'https://dema.ai',
}

type AttributionButtonProps = {
  onOpen: () => void
  allowInvalidMetricCombinations?: boolean
}

const AttributionButton = ({
  onOpen,
  allowInvalidMetricCombinations,
}: AttributionButtonProps) => {
  const normalizedAttributionModels = useNormalizedAttributionModels()
  const { metrics } = useMetricsState(allowInvalidMetricCombinations)

  const selectedAttributions = useMemo(
    () => metrics.filter(isAttributedMetric),
    [metrics],
  )

  const activeAttributions = useMemo(() => {
    const labelSet = new Set()
    const labels = selectedAttributions.reduce<string[]>(
      (acc, { attributionId }) => {
        const label =
          normalizedAttributionModels[attributionId ?? DEFAULT_ATTRIBUTION_ID]
            ?.label ?? ''

        if (label && !labelSet.has(label)) {
          acc.push(label)
          labelSet.add(label)
        }

        return acc
      },
      [],
    )

    if (labels.length > 0) {
      return labels.sort()
    }

    return []
  }, [selectedAttributions, normalizedAttributionModels])

  return (
    <>
      <ReportDetailRow
        title="Attribution"
        label={
          activeAttributions.length > 0
            ? activeAttributions.join(', ')
            : 'No attribution'
        }
        iconName="ChartPieIcon"
        infoDetails={infoDetails}
        onClick={onOpen}
      />
    </>
  )
}

export default AttributionButton
