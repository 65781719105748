import { demaReports } from 'features/reports/demaReports'
import { type AnalyticsConfig } from 'graphql/reports/types'
import { atom, type SetStateAction } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { type OptionalKey } from 'types/optionalKey'

export type AnalyticsConfigState = OptionalKey<
  AnalyticsConfig,
  'id' | '__typename'
>

const initialAnalyticsConfig: AnalyticsConfigState | undefined = undefined

const baseAnalyticsConfigAtom = atom<AnalyticsConfigState | undefined>(
  initialAnalyticsConfig,
)

const defaultAnalyticsConfig: AnalyticsConfigState = {
  ...demaReports[0].analyticsConfig,
}

export const analyticsConfigAtom = atom(
  (get) => {
    const analyticsConfig = get(baseAnalyticsConfigAtom)

    return analyticsConfig ?? defaultAnalyticsConfig
  },
  (_, set, config: SetStateAction<AnalyticsConfigState | undefined>) => {
    return set(baseAnalyticsConfigAtom, config)
  },
)

export const dimensionsAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('dimensions'),
)

export const metricsAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('metrics'),
)

export const filtersAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('filters'),
)

export const chartAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('chart'),
)

export const dateStateAtom = atom((get) => {
  const config = get(analyticsConfigAtom)

  return {
    dateRange:
      config?.startDate && config?.endDate
        ? [new Date(config.startDate), new Date(config.endDate)]
        : [],
    compareDateRange:
      config?.compareStartDate && config?.compareEndDate
        ? [new Date(config.compareStartDate), new Date(config.compareEndDate)]
        : [null, null],
    dynamicDate: config?.dynamicDate,
    compareDynamicDate: config?.compareDynamicDate,
  }
})

export const analyticsConfigIdAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('id'),
)

export const newAnalyticsConfigAtom = atom<AnalyticsConfigState>({
  ...defaultAnalyticsConfig,
  dimensions: [],
  metrics: [],
})

export const scopedAnalyticsAtoms = [
  analyticsConfigAtom,
  dimensionsAtom,
  metricsAtom,
  filtersAtom,
  chartAtom,
  dateStateAtom,
  analyticsConfigIdAtom,
] as const
