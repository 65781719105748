import { config } from 'config'
import { Routes as Auth } from 'features/auth'
import { routes as dashboardsRoutes } from 'features/dashboards/Routes'
import { routes as geoLiftRoutes } from 'features/geoLift/Routes'
import { routes as optimizationsRoutes } from 'features/optimizations/Routes'
import { routes as reportsRoutes } from 'features/reports/Routes'
import { routes as segmentationsRoutes } from 'features/segmentations/Routes'
import { routes as settingsRoutes, teamRoutes } from 'features/settings/Routes'
import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { AppWrapper } from './AppWrapper'
import { AuthenticatedOutlet } from './AuthenticatedOutlet'
import { NotFound } from './NotFound'
import { UnexpectedError } from './UnexpectedError'

export const router = createBrowserRouter(
  [
    { path: 'auth/*', Component: Auth, errorElement: <UnexpectedError /> },
    {
      path: '*',
      Component: AuthenticatedOutlet,
      errorElement: <UnexpectedError />,
      children: [
        {
          Component: AppWrapper,
          children: [
            {
              index: true,
              Component: lazy(() => import('features/dashboard/Dashboard')),
            },
            {
              path: 'optimizations',
              Component: lazy(
                () => import('features/optimizations/Optimizations'),
              ),
              children: optimizationsRoutes,
            },
            {
              path: 'segmentations',
              Component: lazy(
                () => import('features/segmentations/Segmentations'),
              ),
              children: segmentationsRoutes,
            },
            {
              path: 'geo-lift',
              Component: lazy(() => import('features/geoLift/GeoLift')),
              children: geoLiftRoutes,
            },
            {
              path: 'dashboards',
              Component: lazy(() => import('features/dashboards/Dashboards')),
              children: dashboardsRoutes,
            },
            {
              path: 'reports',
              Component: lazy(() => import('features/reports/Reports')),
              children: reportsRoutes,
            },
            {
              path: 'settings',
              Component: lazy(() => import('features/settings/Settings')),
              children: [...settingsRoutes, ...teamRoutes],
            },
            { path: '*', Component: NotFound },
          ],
        },
      ],
    },
  ],
  { basename: config.APP_BASENAME },
)
