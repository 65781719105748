import { Box, Divider, Flex } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { ToggleWithLabels } from 'components/ToggleWithLabels/ToggleWithLabels'
import { Typography } from 'components/Typography'
import { useWidgetPanelSetAtom } from 'features/dashboards/atoms/widgetPanel'
import { useState } from 'react'
import { WidgetChartSettings } from './WidgetChartSettings'
import { WidgetDataSettings } from './WidgetDataSettings'

enum WidgetDetailsPanelSections {
  DATA = 'data',
  CHART = 'chart',
}

const sections = [
  { id: WidgetDetailsPanelSections.DATA, label: 'Widget data' },
  { id: WidgetDetailsPanelSections.CHART, label: 'Chart settings' },
]

export const ChartWidgetPanelContent = () => {
  const [selectedSection, setSelectedSection] = useState(
    WidgetDetailsPanelSections.DATA,
  )
  const setWidgetPanelOpen = useWidgetPanelSetAtom()

  return (
    <Box
      flexShrink={0}
      w="300px"
      borderLeft="solid 1px"
      borderLeftColor="gray.200"
      h="full"
      overflowY="auto"
    >
      <Flex flexDirection="column">
        <Box p={4}>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography fontSize="md" color="gray.800" lineHeight={6}>
              Chart
            </Typography>
            <ButtonIcon
              name="CloseIcon"
              title="Close"
              size="sm"
              variant="ghost"
              onClick={() => setWidgetPanelOpen(false)}
            />
          </Flex>
        </Box>

        <Box px={4} pb={4}>
          <ToggleWithLabels
            items={sections}
            setSelected={setSelectedSection}
            selected={selectedSection}
            w="full"
            buttonProps={{
              flex: 1,
            }}
          />
        </Box>

        <Divider />

        <Flex flexDirection="column">
          {selectedSection === WidgetDetailsPanelSections.DATA && (
            <WidgetDataSettings />
          )}
          {selectedSection === WidgetDetailsPanelSections.CHART && (
            <WidgetChartSettings />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
