import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import { useCallback } from 'react'
import { useSetAnalyticsConfigAtom } from 'shared/hooks/useAnalyticsConfigState'
import { widgetAtomFamily } from '../atoms/dashboardViewState'
import { useWidgetPanelSetAtom } from '../atoms/widgetPanel'
import { useSetSelectedWidgetState } from './useWidgetsState'

export const useSelectWidget = () => {
  const setSelectedWidgetAtom = useSetSelectedWidgetState()
  const setAnalyticsConfigAtom = useSetAnalyticsConfigAtom()
  const setWidgetPanelOpenAtom = useWidgetPanelSetAtom()

  const getWidgetById = useGetAtomFamilyValue(widgetAtomFamily)

  return useCallback(
    (widgetId: string) => {
      if (!widgetId) {
        return
      }

      const widget = getWidgetById(widgetId)

      setSelectedWidgetAtom(widget)
      setAnalyticsConfigAtom(widget?.analyticsConfig)
      setWidgetPanelOpenAtom(true)
    },
    [
      getWidgetById,
      setSelectedWidgetAtom,
      setAnalyticsConfigAtom,
      setWidgetPanelOpenAtom,
    ],
  )
}
