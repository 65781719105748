import { Portal } from '@chakra-ui/react'
import { DragOverlay } from '@dnd-kit/core'
import {
  activeItemStateAtom,
  useDashboardLayoutRowState,
} from 'features/dashboards/hooks/useDashboardLayoutState'
import { useAtomValue } from 'jotai'
import { SortableItem } from '../DashboardLayout/SortableItem'
import WidgetCard from '../WidgetCard/WidgetCard'

export const WidgetCardDragOverlay = () => {
  const activeItemState = useAtomValue(activeItemStateAtom)
  const activeRowState = useDashboardLayoutRowState(
    activeItemState?.containerId,
  )

  if (!activeItemState?.id) return null

  return (
    <Portal>
      <DragOverlay>
        <SortableItem id={activeItemState.id} isGhost>
          <WidgetCard
            widgetId={activeItemState.id}
            rowId={activeRowState?.rowId}
            height={activeRowState?.height ?? 0}
          />
        </SortableItem>
      </DragOverlay>
    </Portal>
  )
}
