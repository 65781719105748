import { Flex } from '@chakra-ui/react'
import {
  useSensors,
  DndContext,
  useSensor,
  MouseSensor,
  TouchSensor,
  MeasuringStrategy,
} from '@dnd-kit/core'
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { useDashboardLayoutRearrange } from 'features/dashboards/hooks/useDashboardLayoutRearrange'

import { dashboardLayoutAtom } from 'features/dashboards/hooks/useDashboardState'
import { generateNewRowId } from 'features/dashboards/utils'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { EmptyDashboard } from '../EmptyDashboard'
import { WidgetCardDragOverlay } from '../WidgetCard/WidgetCardDragOverlay'
import { DashboardRow } from './DashboardRow/DashboardRow'
import { EmptyRowButton } from './EmptyRowButton'

export const DashboardLayout = () => {
  const { order } = useAtomValue(dashboardLayoutAtom)

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor),
    // Disable for now, as it's not working well when mixed with the widget name form
    // useSensor(KeyboardSensor),
  )

  const {
    onDragEnd,
    onDragStart,
    onDragMove,
    onDragCancel,
    collisionDetectionStrategy,
  } = useDashboardLayoutRearrange()

  const newRowDroppableIds = useMemo(() => {
    return order.map((_, index) => generateNewRowId(index + 1)) // offset by 1 since dropbbale containers are created after each row
  }, [order])

  const sortableContextItems = [...order, ...newRowDroppableIds]

  if (order.length === 0) return <EmptyDashboard />

  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragCancel={onDragCancel}
      onDragEnd={onDragEnd}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      collisionDetection={collisionDetectionStrategy}
    >
      <Flex
        h="100%"
        px={4}
        py={4}
        width="100%"
        flexDirection="column"
        gap={4}
        boxSizing="border-box"
      >
        <SortableContext
          items={sortableContextItems}
          strategy={verticalListSortingStrategy}
        >
          {order.map((rowId, index) => (
            <DashboardRow
              key={rowId}
              id={rowId}
              newRowId={newRowDroppableIds[index]}
              rowIndex={index}
            />
          ))}
        </SortableContext>
        <EmptyRowButton rowIndex={order.length} />
      </Flex>
      <WidgetCardDragOverlay />
    </DndContext>
  )
}
