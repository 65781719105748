import { Grid, Box, Divider, SimpleGrid, Skeleton } from '@chakra-ui/react'

import { Alert } from 'components/Alert/Alert'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { RadioCard } from 'components/RadioCard/RadioCard'
import { Typography } from 'components/Typography'
import {
  type Experiment,
  ExperimentConfigStatus,
} from 'features/geoLift/graphql/fragments'
import { type ExperimentSetupFieldsFragment } from 'generated/graphql/graphql'
import { UnexpectedError } from 'shared/UnexpectedError'
import { SETUP_VARIANT, type EXPERIMENT_METRIC } from '../consts'
import { CausalEffectChart } from './CausalEffectChart'
import { ExperimentStatistics } from './ExperimentStatistics'
import { GeographicalTargeting } from './GeographicalTargeting'
import { KPIChart } from './KPIChart'
import { MapChart } from './MapChart'
import { PercentLiftChart } from './PercentLiftChart'
import { ReturnLiftChart } from './ReturnLiftChart'
import { ROASComparisonChart } from './ROASComparisonChart'

export const ExperimentViewContent = ({
  experiment,
  isLoading,
  isError,
  selectedSetup,
  selectedMetric,
  setSelectedMetric,
  setupVariant,
  setSetupVariant,
}: {
  experiment: Experiment | null
  isLoading: boolean
  isError: boolean
  selectedSetup: ExperimentSetupFieldsFragment | undefined
  selectedMetric: EXPERIMENT_METRIC
  setSelectedMetric: (metric: EXPERIMENT_METRIC) => void
  setupVariant: SETUP_VARIANT
  setSetupVariant: (variant: SETUP_VARIANT) => void
}) => {
  const hasSetup = !!experiment?.setup

  const inferenceMetric = experiment?.inference?.metrics.find(
    (metric) => metric.id === selectedMetric,
  )

  return (
    <Box h={0} minH="full" w={0} minW="full" overflowY="auto">
      {isLoading ? (
        <Box py={4} px={6}>
          <Skeleton h={52} w="full" mb={3} />
          <Skeleton h={52} w="full" mb={3} />
          <Skeleton h={52} w="full" />
        </Box>
      ) : isError || !experiment?.suggestions ? (
        <UnexpectedError />
      ) : (
        <>
          {experiment.config.statusSuggestions ===
          ExperimentConfigStatus.RUNNING ? (
            <Box px={6} py={4}>
              <Box maxW={400}>
                <Typography
                  fontSize="sm"
                  fontWeight={500}
                  lineHeight={5}
                  color="gray.800"
                  mb={8}
                >
                  Experiment design
                </Typography>

                <ProgressBar
                  title="Configuring experiment"
                  description="You can continue using the platform as normal during the configuration."
                  progress={null}
                />
              </Box>
            </Box>
          ) : experiment?.config.statusSuggestions ===
            ExperimentConfigStatus.FAILED ? (
            <Box px={6} py={4}>
              <Box maxW={400}>
                <Typography
                  fontSize="sm"
                  fontWeight={500}
                  lineHeight={5}
                  color="gray.800"
                  mb={8}
                >
                  Experiment design
                </Typography>

                <ProgressBar
                  title="Configuring experiment"
                  description="Unable to create experiment setups. Try changing some experiment parameters."
                  progress={100}
                  status="error"
                />
              </Box>
            </Box>
          ) : (
            <>
              {selectedSetup?.isValidExperiment === false &&
                experiment.status === 'draft' && (
                  <Box px={6} py={4}>
                    <Alert
                      status="error"
                      content="We were unable to create a valid experiment setup. The estimated spend for the selected channel and selected test period is not high enough. Please select another channel and/or a longer test period."
                      closable={false}
                    />
                  </Box>
                )}

              {new Date(experiment.config.endDate) < new Date() && (
                <Box px={6} py={4}>
                  <Alert
                    status="info"
                    content="The test period has ended and you can find the initial results from the experiment below. When the post-treatment period ends the results will be automatically updated. You can also update the results at any time."
                    closable={false}
                  />
                </Box>
              )}

              {experiment.status === 'running' && (
                <Box px={6} py={4}>
                  <Alert
                    status="info"
                    content="The experiment is currently running. When the test period is completed you will get the initial results from the experiment and after the post-treatment period you will get the final results."
                    closable={false}
                  />
                </Box>
              )}

              {experiment?.config.statusInference ===
                ExperimentConfigStatus.RUNNING && (
                <Box px={6} py={4} bg="gray.100">
                  <Box maxWidth={440}>
                    <ProgressBar
                      title="Fetching experiment results"
                      progress={null}
                    />
                  </Box>
                </Box>
              )}

              {!hasSetup && (
                <>
                  <Box px={6} py={4}>
                    <Typography
                      fontSize="sm"
                      fontWeight={500}
                      lineHeight={5}
                      color="gray.800"
                      mb={4}
                    >
                      Suggested experiment setup
                    </Typography>

                    <SimpleGrid columns={2} gap={4}>
                      <RadioCard
                        label="Cost"
                        description="Experiment setup optimized to minimize test cost."
                        selected={setupVariant === SETUP_VARIANT.COST}
                        onChange={() => setSetupVariant(SETUP_VARIANT.COST)}
                      />

                      <RadioCard
                        label="Statistical significance"
                        description="Experiment setup optimized for statistical significance."
                        selected={
                          setupVariant ===
                          SETUP_VARIANT.STATISTICAL_SIGNIFICANCE
                        }
                        onChange={() =>
                          setSetupVariant(
                            SETUP_VARIANT.STATISTICAL_SIGNIFICANCE,
                          )
                        }
                      />
                    </SimpleGrid>
                  </Box>
                  <Divider />
                </>
              )}

              {selectedSetup && (
                <ExperimentStatistics
                  setup={selectedSetup}
                  summary={inferenceMetric?.summary}
                  selectedMetric={selectedMetric}
                  setSelectedMetric={setSelectedMetric}
                />
              )}

              {inferenceMetric && (
                <>
                  <Divider />
                  <ROASComparisonChart
                    inference={inferenceMetric}
                    selectedMetric={selectedMetric}
                  />
                  <Divider />
                  <Box px={6} py={4}>
                    <Typography
                      fontSize="sm"
                      fontWeight={500}
                      lineHeight={5}
                      color="gray.800"
                    >
                      Lift analysis
                    </Typography>
                    <SimpleGrid columns={[1, null, 2]} rowGap={4}>
                      <KPIChart
                        inference={inferenceMetric}
                        selectedMetric={selectedMetric}
                        startDate={experiment.config.startDate}
                        endDate={experiment.config.endDate}
                        treatmentPeriod={experiment.config.treatmentPeriod}
                        postTreatmentPeriod={
                          experiment.config.postTreatmentPeriod
                        }
                      />
                      <PercentLiftChart
                        pctLift={inferenceMetric.pctLift}
                        selectedMetric={selectedMetric}
                        startDate={experiment.config.startDate}
                        endDate={experiment.config.endDate}
                        treatmentPeriod={experiment.config.treatmentPeriod}
                        postTreatmentPeriod={
                          experiment.config.postTreatmentPeriod
                        }
                      />
                      <CausalEffectChart
                        cumulativeLift={inferenceMetric.cumulativeLift}
                        selectedMetric={selectedMetric}
                        startDate={experiment.config.startDate}
                        endDate={experiment.config.endDate}
                        treatmentPeriod={experiment.config.treatmentPeriod}
                        postTreatmentPeriod={
                          experiment.config.postTreatmentPeriod
                        }
                      />
                      <ReturnLiftChart
                        returnLift={inferenceMetric.returnLift}
                        selectedMetric={selectedMetric}
                        startDate={experiment.config.startDate}
                        endDate={experiment.config.endDate}
                        treatmentPeriod={experiment.config.treatmentPeriod}
                        postTreatmentPeriod={
                          experiment.config.postTreatmentPeriod
                        }
                      />
                    </SimpleGrid>
                  </Box>
                  <Divider />
                </>
              )}
              {selectedSetup && (
                <>
                  <Divider />
                  <Grid templateColumns="300px 1fr" gap={6}>
                    <GeographicalTargeting
                      setup={selectedSetup}
                      experimentName={experiment.config.name}
                    />
                    <MapChart
                      zoneType={experiment.config.zoneType}
                      country={experiment.config.country}
                      treatmentGroup={selectedSetup.treatmentGroup}
                      controlGroup={
                        inferenceMetric?.syntheticControlGroup ||
                        selectedSetup.controlGroup
                      }
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}
