import { type DateRange, type Filter } from 'constants/types'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { type BaseFilterProps } from 'components/Filters/types'
import {
  useNormalizedAttributionModels,
  type AttributionId,
} from 'graphql/statistics/useAttributionModels'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import {
  useNormalizedMetrics,
  type AttributedMetric,
} from 'graphql/statistics/useMetrics'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { type FC } from 'react'
import { getMetricLabelWithAttribution } from 'shared/utils/analyticsConfig'
import { FilterPopupPicker } from '../FilterPopupPicker'
import { PopupButton } from './PopupButton'

interface SelectedFilterChipProps
  extends Pick<BaseFilterProps, 'addFilter' | 'removeFilter' | 'filters'> {
  filterId: string
  filter: Filter
  metricShouldShowAttribution: boolean
  filterIndex: number
  dateRange: DateRange
}

export const SelectedFilterChip: FC<SelectedFilterChipProps> = ({
  filterId,
  filterIndex,
  filter,
  filters,
  metricShouldShowAttribution,
  addFilter,
  dateRange,
  removeFilter,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { uniqueDimensionValues, query } = useUniqueDimensionValues({
    dimensionId: filterId,
    dateRange,
    skipStaticValues: true,
  })

  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()
  const normalizedAttributionModels = useNormalizedAttributionModels()
  const metric = normalizedMetrics[filterId]

  const metricLabelWithAttribution = getMetricLabelWithAttribution(
    metric,
    normalizedAttributionModels?.[
      ((metric as AttributedMetric)?.attributionId ?? '') as AttributionId
    ]?.label,
  )

  const label =
    normalizedDimensions[filterId]?.label ||
    (metricShouldShowAttribution ? metricLabelWithAttribution : metric.label) ||
    ''

  return (
    <Flex alignItems="stretch" maxW="100%" alignContent="center">
      <FilterPopupPicker
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        options={uniqueDimensionValues}
        isLoading={query.loading}
        filterId={filterId}
        filterIndex={filterIndex}
        filterLabel={label}
        addFilter={addFilter}
        removeFilter={removeFilter}
        filters={filters}
        defaultSelectedFilter={filter.comparisonId}
        popupButton={
          <PopupButton
            options={uniqueDimensionValues}
            isLoading={query.loading}
            label={label}
            filter={filter}
            metric={metric}
          />
        }
      />
      <ButtonIcon
        name="CloseIcon"
        title="Remove filter"
        size="sm"
        maxH="unset"
        h="unset"
        onClick={() => {
          removeFilter({ filterId, filterIndex })
        }}
      />
    </Flex>
  )
}
