import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { useSetReportStateAtom } from 'features/reports/hooks/useReportState'
import { useCallback } from 'react'
import { type AnalyticsConfigState } from 'shared/atoms/analyticsConfigState'
import { useSetAnalyticsConfigAtom } from 'shared/hooks/useAnalyticsConfigState'

export const useSelectReport = () => {
  const setReportAtom = useSetReportStateAtom()
  const setAnalyticsConfigAtom = useSetAnalyticsConfigAtom()

  return useCallback(
    (report: ReportState, analyticsConfig: AnalyticsConfigState) => {
      if (!report || !report.id) {
        return
      }

      setReportAtom(report)
      setAnalyticsConfigAtom(analyticsConfig)
    },
    [setReportAtom, setAnalyticsConfigAtom],
  )
}
