import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  SimpleGrid,
  ModalFooter,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { RadioCard } from 'components/RadioCard/RadioCard'
import { Typography } from 'components/Typography'
import {
  type DashboardWidgetState,
  newWidgetAtom,
} from 'features/dashboards/atoms/dashboardViewState'
import { useAddWidgetToDashboard } from 'features/dashboards/hooks/useAddWidgetToDashboard'
import { WidgetType } from 'features/dashboards/types'
import { generateWidgetId } from 'features/dashboards/utils'
import { useReportById } from 'graphql/reports/useReportById'
import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import { useState } from 'react'
import { v7 as uuidv7 } from 'uuid'

const options = [
  {
    label: 'Chart',
    icon: 'ChartBarIcon',
    value: WidgetType.CHART,
  },
  {
    label: 'Table',
    icon: 'TableIcon',
    value: WidgetType.TABLE,
  },
] as const

interface AddWidgetFromReportModalProps {
  isOpen: boolean
  onClose: () => void
  selectedReportId: string | null
}

export const AddWidgetFromReportModal = ({
  isOpen,
  onClose,
  selectedReportId,
}: AddWidgetFromReportModalProps) => {
  const [selectedWidgetType, setSelectedWidgetType] = useState<WidgetType>()

  const { report, query } = useReportById(selectedReportId)
  const { addWidgetToDashboard } = useAddWidgetToDashboard()

  const getNewWidgetState = useGetAtomFamilyValue(newWidgetAtom)

  const addWidgetFromReport = (widgetType: WidgetType | undefined) => {
    if (query.loading || !widgetType) return

    const newWidgetState = getNewWidgetState(widgetType)

    const newWidget: DashboardWidgetState = {
      ...newWidgetState,
      name: report?.name ?? newWidgetState.name,
      id: generateWidgetId(),
      type: widgetType,
      ...(report?.analyticsConfig && {
        analyticsConfig: {
          ...report.analyticsConfig,
          id: uuidv7(),
        },
      }),
    }

    addWidgetToDashboard(newWidget)

    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add widget from report</ModalHeader>
        <ModalBody>
          <Flex flexDirection="column" gap={4}>
            <Typography>Choose which widget to add from the report</Typography>
            <SimpleGrid columns={2} spacingX={2}>
              {options.map((option) => (
                <RadioCard
                  key={option.value}
                  label={option.label}
                  icon={{ name: option.icon, size: 'small' }}
                  selected={selectedWidgetType === option.value}
                  onChange={() => setSelectedWidgetType(option.value)}
                />
              ))}
            </SimpleGrid>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="grey" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => addWidgetFromReport(selectedWidgetType)}
            isDisabled={!selectedWidgetType}
          >
            Add widget
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
