import { useMultiSelectLabel } from 'components/Filters/useMultiSelectLabel'
import {
  DimensionMetricSelectorDrawerOpenState,
  useDimensionMetricSelectorDrawerSetAtom,
} from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import {
  getMetricKeyWithoutAttribution,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { useMemo } from 'react'
import { useMetricsState } from 'shared/hooks/useMetricsState'
import { ReportDetailRow } from '../ReportDetailsRow/ReportDetailsRow'

const infoDetails = {
  tooltipText:
    'A metric is a quantitative measurement expressed by a numeric value. It is typically either a currency value or a percentage value. How the value is calculated depends on the specific metric.',
  link: 'https://dema.ai', // TODO change the links once it has been decided by where it should go
}

export const MetricsSection = ({
  allowInvalidMetricCombinations,
}: {
  allowInvalidMetricCombinations?: boolean
}) => {
  const normalizedMetrics = useNormalizedMetrics()
  const { uniqueMetrics } = useMetricsState(allowInvalidMetricCombinations)
  const uniqueMetricKeys = useMemo(
    () => uniqueMetrics.map(getMetricKeyWithoutAttribution),
    [uniqueMetrics],
  )

  const label = useMultiSelectLabel({
    selectedIds: uniqueMetricKeys,
    normalizedOptions: normalizedMetrics,
    defaultEmptyLabel: 'Select metrics',
  })
  const setDrawerOpenState = useDimensionMetricSelectorDrawerSetAtom()

  return (
    <ReportDetailRow
      title="Metrics"
      label={label}
      iconName="BarChartIcon"
      infoDetails={infoDetails}
      onClick={() =>
        setDrawerOpenState(DimensionMetricSelectorDrawerOpenState.METRIC)
      }
    />
  )
}
