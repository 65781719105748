import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const SET_SEGMENTATION_CUSTOMER_RULES_MUTATION = graphql(/* GraphQL */ `
  mutation SetSegmentationCustomerRules(
    $params: SetSegmentationCustomerRulesInput!
  ) {
    setSegmentationCustomerRules(params: $params) {
      ...SegmentationCustomerSetFields
      rules {
        ...SegmentationCustomerRuleFields
      }
    }
  }
`)

export const useSetCustomerRules = () => {
  return useMutation(SET_SEGMENTATION_CUSTOMER_RULES_MUTATION)
}
