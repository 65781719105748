import {
  type AccessorKeyColumnDef,
  createColumnHelper,
} from '@tanstack/react-table'
import { Typography } from 'components/Typography'
import { useWidgetDateState } from 'features/dashboards/hooks/useWidgetDateState'
import { type CompareUnit, type TableState } from 'graphql/reports/types'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { type NormalizedDimensions } from 'graphql/statistics/useDimensions'
import { type NormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMemo } from 'react'
import { calcDifference } from 'utils/calcDifference'
import { stringToNumber } from 'utils/stringToNumber'

interface UseWidgetAnalyticsTableColumnsProps {
  normalizedDimensions: NormalizedDimensions
  normalizedMetrics: NormalizedMetrics
  tableState: TableState
  compareUnit?: CompareUnit
  widgetId: string
}

const columnHelper = createColumnHelper<NormalizedStatistic>()

export const useWidgetAnalyticsTableColumns = ({
  normalizedDimensions,
  normalizedMetrics,
  tableState,
  compareUnit,
  widgetId,
}: UseWidgetAnalyticsTableColumnsProps) => {
  const {
    widgetDateState: { isCompare },
  } = useWidgetDateState(widgetId)

  const columns = useMemo(
    () =>
      tableState
        .map((column) => {
          const dimensionRow = normalizedDimensions[column.id]

          if (dimensionRow) {
            return columnHelper.accessor(dimensionRow.id, {
              header: dimensionRow.label,
              cell: ({ row }) => {
                const dimension = row.original[dimensionRow.id]

                return <>{dimension.formattedValue}</>
              },
            })
          }

          const metric = normalizedMetrics[column.id]

          if (metric) {
            return columnHelper.accessor(metric.key, {
              header: metric.label,
              cell: ({ row }) => {
                const { formattedValue, formattedDifference, color } =
                  calcDifference({
                    value: stringToNumber(row.original[metric.key]?.value),
                    compareValue: stringToNumber(
                      row.original[metric.key]?.comparisonValue,
                    ),
                    format: metric.format,
                    displayFormat: compareUnit ?? 'value',
                  })

                return (
                  <>
                    <Typography fontSize="xs">
                      {formattedValue ?? '-'}
                    </Typography>
                    {isCompare && (
                      <Typography fontSize="xs" color={color}>
                        {formattedDifference ?? 'N/A'}
                      </Typography>
                    )}
                  </>
                )
              },
            })
          }

          return null
        })
        .filter(
          (column): column is AccessorKeyColumnDef<NormalizedStatistic> =>
            column !== null,
        ),
    [
      compareUnit,
      isCompare,
      normalizedDimensions,
      normalizedMetrics,
      tableState,
    ],
  )

  return columns
}
