import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const DELETE_SEGMENTATION_CUSTOMER_SET_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteSegmentationCustomerSet($id: ID!) {
    deleteSegmentationCustomerSet(id: $id) {
      id
      __typename
    }
  }
`)

export const useDeleteSegmentationCustomerSet = () => {
  const [deleteSegmentationCustomerSet] = useMutation(
    DELETE_SEGMENTATION_CUSTOMER_SET_MUTATION,
  )

  return deleteSegmentationCustomerSet
}
