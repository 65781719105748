import { Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { EmptyState } from 'features/segmentations/shared/EmptyState'
import { useSegmentationCustomerLabels } from 'features/segmentations/views/CustomerLabelsView/graphql/useCustomerLabels'
import type React from 'react'
import { AddCustomerLabelModal } from './AddCustomerLabelModal'
import { DeleteCustomerLabelModal } from './DeleteCustomerLabelModal'
import { EditCustomerLabelModal } from './EditCustomerLabelModal'

export const CustomerLabelsList: React.FC = () => {
  const { segmentationCustomerLabels, query } = useSegmentationCustomerLabels()

  if (query.loading) {
    return (
      <Table>
        <Tbody>
          <SkeletonTableRows rows={5} columns={1} />
        </Tbody>
      </Table>
    )
  }

  if (!segmentationCustomerLabels || segmentationCustomerLabels.length === 0) {
    return (
      <EmptyState
        imageSrc="/images/Cubes.svg"
        title="Customer labels"
        description="Customer labels are used to assign your customers to different categories."
        modalButton={
          <AddCustomerLabelModal
            modalButton={<Button size="sm">New customer label</Button>}
          />
        }
      />
    )
  }

  return (
    <Table>
      <Tbody>
        {segmentationCustomerLabels.map((label) => (
          <Tr display="flex" key={label.id}>
            <Td px={4} flexGrow={1} alignContent="center">
              <Flex alignItems="center" gap={2}>
                <Icon
                  name={label.iconName}
                  size="small"
                  color={label.iconColor}
                />
                <Typography
                  fontSize="xs"
                  fontWeight={500}
                  color="gray.800"
                  lineHeight={4}
                >
                  {label.name}
                </Typography>
              </Flex>
            </Td>
            <Td px={4}>
              <EditCustomerLabelModal currentLabel={label} />
              <DeleteCustomerLabelModal label={label} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
