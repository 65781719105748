import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client'
import { createFragmentRegistry } from '@apollo/client/cache'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { config } from 'config'
import { DashboardWithOwnerFragment } from 'features/dashboards/graphql/fragments'
import { IntegrationFieldsFragment } from 'features/settings/graphql/fragments'
import { Integrations_MerchantSiteFragment } from 'features/settings/views/IntegrationsView/AccountsTable/AccountsTable'
import { ChannelMappings_MerchantSiteFragment } from 'features/settings/views/MappingsView/ExistingMappings/ExistingMappings'
import { MappableSessions_MerchantSiteFragment } from 'features/settings/views/MappingsView/UnhandledMappings/UnhandledMappings'
import { MarketingCostManualInputs_MerchantSiteFragment } from 'features/settings/views/MarketingCostsView/MarketingCostTable/MarketingCostTable'
import { TaxonomyInputFieldsFragment } from 'features/settings/views/shared/ManualInput/graphql/fragments'
import { ReportWithOwnerFragment } from 'graphql/reports/fragments'
import {
  Teams_TeamFieldsFragment,
  Teams_TeamMemberFieldsFragment,
} from 'graphql/teams/fragments'
import { errorLink } from './errorLink'
import { sentryLink } from './sentryLink'

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
  credentials: 'same-origin',
})

const authLink = setContext(async (_, { headers }) => {
  const token = await window.Clerk?.session?.getToken()

  return {
    headers: {
      ...headers,
      'Dema-Client-Version': config.APP_VERSION,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const retryLink = new RetryLink()

let apolloClient: ApolloClient<NormalizedCacheObject>

export const createApolloClient = () => {
  apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(
        ReportWithOwnerFragment,
        IntegrationFieldsFragment,
        TaxonomyInputFieldsFragment,
        ChannelMappings_MerchantSiteFragment,
        MappableSessions_MerchantSiteFragment,
        Integrations_MerchantSiteFragment,
        MarketingCostManualInputs_MerchantSiteFragment,
        Teams_TeamFieldsFragment,
        Teams_TeamMemberFieldsFragment,
        DashboardWithOwnerFragment,
      ),
      typePolicies: {
        MerchantSite: {
          keyFields: ['frontendId'],
        },
        Metric: {
          keyFields: ['groupId', 'id'],
        },
      },
    }),
    link: from([authLink, errorLink, retryLink, sentryLink, httpLink]),
    name: 'Dema app',
    version: config.APP_VERSION,
  })

  // Displays error and dev messages
  if (['test', 'development'].includes(config.APP_ENV)) {
    loadDevMessages()
    loadErrorMessages()
  }

  return apolloClient
}

export const getApolloClient = () => apolloClient
