import { Flex, Th } from '@chakra-ui/react'
import { flexRender, type Header } from '@tanstack/react-table'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import {
  useSetWidgetTableState,
  useWidgetTableState,
} from 'features/dashboards/hooks/useWidgetTableState'
import { type ExtendedSortDirection } from 'graphql/reports/types'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useCallback, useMemo } from 'react'

const getHeaderIcon = (
  sortDirection: ExtendedSortDirection | undefined,
): IconName | undefined => {
  if (sortDirection) {
    if (['asc', 'ascCompare'].includes(sortDirection)) {
      return 'ArrowUpIcon'
    }

    if (['desc', 'descCompare'].includes(sortDirection)) {
      return 'ArrowDownIcon'
    }
  }

  return undefined
}

interface WidgetAnalyticsTableHeaderProps {
  header: Header<NormalizedStatistic, unknown>
  widgetId: string
  sortValue?: ExtendedSortDirection
  onHeaderClick?: (
    header: Header<NormalizedStatistic, unknown>,
    sortValue: ExtendedSortDirection,
  ) => void
  index: number
}

export const WidgetAnalyticsTableHeader = ({
  header,
  widgetId,
  index,
}: WidgetAnalyticsTableHeaderProps) => {
  const tableState = useWidgetTableState(widgetId)
  const { setTableSorting } = useSetWidgetTableState(widgetId)

  const sortValue = useMemo(() => {
    const sort = tableState.find(({ id }) => id === header.id)?.sort

    return sort
  }, [tableState, header.id])

  const headerName = flexRender(
    header.column.columnDef.header,
    header.getContext(),
  )
  const headerIcon = getHeaderIcon(sortValue)
  const textAlign = index > 0 ? 'end' : 'start'

  const setSortOrder = useCallback(
    (sort: ExtendedSortDirection) => {
      setTableSorting(header.id, sort)
    },
    [header.id, setTableSorting],
  )

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()

      const nextSort = header.column.getNextSortingOrder()

      if (!nextSort) {
        setSortOrder(null)
        header.column.toggleSorting()

        return
      }

      setSortOrder(nextSort)
      header.column.toggleSorting(nextSort === 'desc')
    },
    [header.column, setSortOrder],
  )

  return (
    <Th
      key={header.id}
      py={3}
      px={4}
      bg="gray.100"
      borderColor="gray.200"
      onClick={onClick}
      textAlign={textAlign}
    >
      <Flex justifyContent={textAlign}>
        {typeof headerName === 'string' && (
          <Typography fontWeight="600">{headerName}</Typography>
        )}
        {headerIcon && (
          <Icon
            visibility={sortValue ? 'visible' : 'hidden'}
            name={headerIcon}
            size="small"
          />
        )}
      </Flex>
    </Th>
  )
}
