import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { useToast } from '@chakra-ui/react'
import {
  getChartOptions,
  getGroupedSeriesData,
} from 'features/reports/utils/chart/common'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type Highcharts from 'highcharts'
import { useEffect, useState } from 'react'
import { staticChartOptions } from 'utils/chart/constants'
import { useWidgetChartState } from './useWidgetChartState'
import { useWidgetDateState } from './useWidgetDateState'

export const useChartWidgetOptions = ({
  widgetId,
  data,
  isLoading,
}: {
  widgetId?: string
  data: NormalizedStatistic[]
  isLoading: boolean
}) => {
  const { currency, timezone } = useMerchantInfo()
  const { resolvedDateState: dateState } = useWidgetDateState(widgetId)
  const {
    xAxis,
    color: chartGroup,
    series,
    chartSorting,
  } = useWidgetChartState(widgetId)

  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()

  const [options, setOptions] = useState<Highcharts.Options>(
    staticChartOptions as Highcharts.Options,
  )
  const toast = useToast()

  useEffect(() => {
    if (isLoading) {
      return
    }

    const groupedData = getGroupedSeriesData({
      data,
      groupBy: chartGroup ?? null,
      series,
      xAxis,
      timezone,
    })

    const options = getChartOptions({
      currency,
      groupBy: chartGroup ?? null,
      series,
      groupedData,
      normalizedDimensions,
      normalizedMetrics,
      xAxis,
      dateState,
      data,
      timezone,
      compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
      chartSorting,
    })

    setOptions(options)
  }, [
    currency,
    data,
    chartGroup,
    isLoading,
    normalizedDimensions,
    normalizedMetrics,
    xAxis,
    timezone,
    toast,
    series,
    dateState,
    chartSorting,
  ])

  return options
}
