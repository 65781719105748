import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({
  thumb: {
    backgroundSize: '14px',
    backgroundPosition: 'center',
    _checked: {
      backgroundImage: `url(images/icons/switchThumbCheck.svg)`,
    },
  },
  track: {
    bg: 'gray.300',
    _hover: { bg: 'gray.500', _disabled: { bg: 'gray.200' } },
    _disabled: { bg: 'gray.200' },
    _checked: {
      bg: 'primary.600',
      color: 'primary.600',
      _hover: { bg: 'primary.600' },
      _disabled: {
        bg: 'primary.200',
      },
    },
  },
})

const topBottom = definePartsStyle(() => ({
  thumb: {
    backgroundImage: `url(images/icons/switchThumbDown.svg)`,
    _checked: {
      backgroundImage: `url(images/icons/switchThumbUp.svg)`,
    },
  },
  track: {
    bg: 'red.500',
    _hover: { bg: 'red.500' },
    _disabled: {
      bg: 'red.500',
      opacity: 0.4,
    },
    _checked: {
      bg: 'green.600',
      _hover: { bg: 'green.600' },
      _disabled: {
        bg: 'green.600',
        opacity: 0.4,
      },
    },
  },
}))

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { topBottom },
})
