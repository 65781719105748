import { MenuItem, useDisclosure, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationCustomerSet } from 'features/segmentations/graphql/fragments'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type FC } from 'react'
import { useEditSegmentationCustomerSet } from '../../graphql/useEditSegmentationCustomerSet'
import {
  SegmentationCustomerSetPicker,
  type UpdatedSegmentationCustomerSet,
} from '../CustomerSetPicker'

interface Props {
  currentSegmentationCustomerSet: SegmentationCustomerSet | undefined
}

export const EditCustomerSetModal: FC<Props> = ({
  currentSegmentationCustomerSet,
}) => {
  const editSegmentationCustomerSet = useEditSegmentationCustomerSet()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onEditSet = (customerSet: UpdatedSegmentationCustomerSet) => {
    if (!customerSet.id) return

    editSegmentationCustomerSet({
      variables: {
        id: customerSet.id,
        params: {
          name: customerSet.name,
          iconColor: customerSet.iconColor,
          iconName: customerSet.iconName,
        },
      },
      optimisticResponse: {
        editSegmentationCustomerSet: {
          id: customerSet.id,
          name: customerSet.name,
          dynamicDate: customerSet.dynamicDate,
          iconColor: customerSet.iconColor,
          iconName: customerSet.iconName,
          updatedAt: new Date().toISOString(),
          __typename: 'SegmentationCustomerSet',
        },
      },
      onCompleted: ({ editSegmentationCustomerSet }) => {
        trackEvent({
          eventName: 'Segmentations Customer Set Edited',
          eventProperties: {
            setId: editSegmentationCustomerSet.id,
            setName: editSegmentationCustomerSet.name,
            iconName: editSegmentationCustomerSet.iconName,
            iconColor: editSegmentationCustomerSet.iconColor,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not edit segmentation set. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <SegmentationCustomerSetPicker
      disclosure={{ isOpen, onOpen, onClose }}
      currentCustomerSet={currentSegmentationCustomerSet}
      modalButton={
        <MenuItem
          icon={<Icon name="EditIcon" size="small" />}
          closeOnSelect={true}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          Edit customer set
        </MenuItem>
      }
      isEditMode
      onApply={onEditSet}
    />
  )
}
