import { useStatistics } from 'graphql/statistics/useStatistics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { toISODateRange } from 'utils/dateRange'
import { WidgetStatus } from '../atoms/dashboardViewState'
import { useWidgetFiltersState } from './filters/useWidgetFiltersState'
import { useWidgetDateState } from './useWidgetDateState'
import { useWidgetDimensionsState } from './useWidgetDimensionsState'
import { useWidgetMetricsState } from './useWidgetMetricState'
import { useSetWidgetStatus } from './useWidgetsState'

export const useKpiWidgetAnalytics = (widgetId?: string) => {
  const { timezone, frontendIds = [], currency } = useMerchantInfo()

  const { aggregatedFilters } = useWidgetFiltersState(widgetId)
  const { dimensionKeys: dimensions } = useWidgetDimensionsState(widgetId)
  const { metricKeys: metrics } = useWidgetMetricsState(widgetId)
  const {
    resolvedDateState: { dateRange, compareDateRange },
  } = useWidgetDateState(widgetId)

  const [startDate, endDate] = toISODateRange(dateRange, timezone)
  const [compareStartDate, compareEndDate] = toISODateRange(
    compareDateRange,
    timezone,
  )
  const setWidgetStatus = useSetWidgetStatus(widgetId)

  const shouldFetch =
    Boolean(timezone) &&
    Boolean(currency) &&
    Boolean(startDate) &&
    Boolean(endDate) &&
    metrics.length > 0 &&
    frontendIds.length > 0

  const { query, statistics } = useStatistics({
    skip: !shouldFetch,
    variables: {
      statisticsParams: {
        frontendIds,
        startDate: startDate as string,
        endDate: endDate as string,
        metrics,
        dimensions,
        filter: aggregatedFilters,
        compareStartDate: compareStartDate,
        compareEndDate: compareEndDate,
      },
    },
    onCompleted: (data) => {
      const isEmptyData = data.viewer?.merchant.statistics.length === 0

      setWidgetStatus(isEmptyData ? WidgetStatus.Error : WidgetStatus.Ready)
    },
    onError: () => {
      setWidgetStatus(WidgetStatus.Error)
    },
  })

  return {
    statistics,
    loading: query.loading,
  }
}
