import {
  useCanEditResource,
  useCanEditResourceOwner,
  type UseCanEditResourceParams,
} from 'shared/hooks/useCanEditResource'

export const useCanEditReport = ({
  visibility,
  owner,
}: UseCanEditResourceParams | undefined = {}) => {
  return useCanEditResource({ visibility, owner })
}

export const useCanEditReportOwner = ({
  visibility,
  owner,
}: UseCanEditResourceParams | undefined = {}) => {
  return useCanEditResourceOwner({ visibility, owner })
}
