import { useLazyQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'

export const CUSTOMER_SET_DOWNLOAD_URL_QUERY = graphql(/* GraphQL */ `
  query CustomerSetDownloadUrl($id: ID!) {
    viewer {
      id
      merchant {
        id
        segmentationCustomerSet(id: $id) {
          downloadUrl
        }
      }
    }
  }
`)

export const useCustomerSetDownloadUrl = (customerSetId: string) => {
  const [fetchDownloadUrl, { data, loading, error }] = useLazyQuery(
    CUSTOMER_SET_DOWNLOAD_URL_QUERY,
    { variables: { id: customerSetId } },
  )

  return {
    fetchDownloadUrl,
    downloadUrl: data?.viewer?.merchant?.segmentationCustomerSet?.downloadUrl,
    loading,
    error,
  }
}
