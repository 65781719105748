import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import Input from 'components/Input/Input'

import { ColorPicker } from 'components/Pickers/ColorPicker'
import { GenericPickerModal } from 'components/Pickers/GenericPickerModal'
import { IconPicker } from 'components/Pickers/IconPicker'
import { Typography } from 'components/Typography'

import {
  type CreateSegmentationCustomerSetInput,
  type SegmentationCustomerSetFieldsFragment,
} from 'generated/graphql/graphql'
import {
  useDefaultDisclosure,
  type DefaultDisclosure,
} from 'hooks/useDefaultDisclosure'
import { useRef, useState, type FC, type ReactElement } from 'react'
import {
  CUSTOMER_SET_DEFAULT_DATE_RANGE,
  SEGMENTATION_SET_COLORS,
  SEGMENTATION_SET_ICON_NAMES,
} from '../../../consts'

export type UpdatedSegmentationCustomerSet =
  CreateSegmentationCustomerSetInput & {
    id?: string
  }

const defaultSegmentationCustomerSet: UpdatedSegmentationCustomerSet = {
  iconColor: SEGMENTATION_SET_COLORS[0],
  name: '',
  iconName: SEGMENTATION_SET_ICON_NAMES[0],
  dynamicDate: CUSTOMER_SET_DEFAULT_DATE_RANGE,
}

type SegmentationCustomerSetPickerProps = {
  currentCustomerSet: SegmentationCustomerSetFieldsFragment | undefined
  modalButton: ReactElement
  isEditMode?: boolean
  onApply: (customerSet: UpdatedSegmentationCustomerSet) => void
  disclosure?: DefaultDisclosure
  isLoading?: boolean
  closeOnApply?: boolean
}

export const SegmentationCustomerSetPicker: FC<
  SegmentationCustomerSetPickerProps
> = ({
  currentCustomerSet,
  modalButton,
  isEditMode,
  onApply,
  disclosure,
  isLoading,
  closeOnApply = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [customerSet, setCustomerSet] =
    useState<UpdatedSegmentationCustomerSet>(
      currentCustomerSet ?? defaultSegmentationCustomerSet,
    )
  const { isOpen, onOpen, onClose } = useDefaultDisclosure(disclosure)

  return (
    <GenericPickerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      modalButton={modalButton}
      setToDefaultState={() => {
        if (!currentCustomerSet) {
          setCustomerSet(defaultSegmentationCustomerSet)
        }
      }}
      initialFocusRef={inputRef}
    >
      <ModalHeader>
        {isEditMode ? 'Edit customer set' : 'Add customer set'}
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Typography mb={4}>
          Choose a color and an icon for the customer set.
        </Typography>

        <Box mb={6}>
          <ColorPicker
            colors={SEGMENTATION_SET_COLORS}
            selectedColor={customerSet.iconColor}
            setSelectedColor={(iconColor) =>
              setCustomerSet((segmentationCustomerSet) => ({
                ...segmentationCustomerSet,
                iconColor,
              }))
            }
          />
        </Box>

        <Box mb={8}>
          <IconPicker
            icons={SEGMENTATION_SET_ICON_NAMES}
            iconColor={customerSet.iconColor}
            selectedIcon={customerSet.iconName}
            setSelectedIcon={(iconName) =>
              setCustomerSet((segmentationCustomerSet) => ({
                ...segmentationCustomerSet,
                iconName,
              }))
            }
          />
        </Box>
        <Box>
          <Input
            ref={inputRef}
            label="Customer set name"
            title="Customer set name"
            placeholder=""
            value={customerSet.name}
            maxLength={50}
            onChange={(e) =>
              setCustomerSet((segmentationCustomerSet) => ({
                ...segmentationCustomerSet,
                name: e.target.value,
              }))
            }
          />
        </Box>
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={
            !customerSet.name || !customerSet.iconColor || !customerSet.iconName
          }
          variant="solid"
          type="submit"
          colorScheme="primary"
          isLoading={isLoading}
          onClick={() => {
            onApply(customerSet)
            if (closeOnApply) {
              onClose()
            }
          }}
        >
          {isEditMode ? 'Save' : 'Add customer set'}
        </Button>
      </ModalFooter>
    </GenericPickerModal>
  )
}
