import {
  type DropdownOption,
  type ComboBoxSingleProps,
  type DropdownGroup,
} from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { useNormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import {
  type Metric,
  useNormalizedMetrics,
  type AttributedMetric,
} from 'graphql/statistics/useMetrics'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'
import {
  getMetricLabelWithAttribution,
  getValidDimensions,
} from 'shared/utils/analyticsConfig'

type DimensionMetricAutocompleteProps = Omit<
  ComboBoxSingleProps,
  'setSelected' | 'selectionMode' | 'options'
> & {
  validDimensionIds?: string[]
  validMetricIds?: string[]
  metrics: Metric[]
  setSelected: (item: DropdownOption) => void
  showMetricsFirst?: boolean
  skipDimensionGroupValidation?: boolean
}

export const DimensionMetricAutocomplete = ({
  validDimensionIds,
  validMetricIds,
  setSelected,
  metrics,
  showMetricsFirst = false,
  skipDimensionGroupValidation = false,
  ...rest
}: DimensionMetricAutocompleteProps) => {
  const normalizedDimensions = useNormalizedDimensions()
  const dimensions =
    validDimensionIds?.map((id) => normalizedDimensions[id]).filter(Boolean) ||
    Object.values(normalizedDimensions)

  const normalizedMetrics = useNormalizedMetrics()
  const validMetrics =
    validMetricIds?.map((id) => normalizedMetrics[id]).filter(Boolean) ||
    metrics

  const normalizedAttributionModels = useNormalizedAttributionModels()

  const groups: DropdownGroup[] = useMemo(() => {
    const metricGroups = groupBy(validMetrics, (metric) => metric.groupKey)

    const dimensionGroup: DropdownGroup = {
      name: 'Dimensions',
      items: (skipDimensionGroupValidation
        ? dimensions
        : getValidDimensions(dimensions, validMetrics)
      ).map((dim) => ({
        ...dim,
        name: dim.label,
      })),
    }

    const metricGroup: DropdownGroup = {
      name: 'Metrics',
      items: validMetrics.map((metric) => ({
        ...metric,
        id: metric.key,
        name:
          metricGroups[metric.groupKey]?.length > 1
            ? getMetricLabelWithAttribution(
                metric,
                normalizedAttributionModels[
                  (metric as AttributedMetric).attributionId ?? ''
                ]?.label,
              )
            : metric.label,
      })),
    }

    return showMetricsFirst
      ? [metricGroup, dimensionGroup]
      : [dimensionGroup, metricGroup]
  }, [
    dimensions,
    validMetrics,
    normalizedAttributionModels,
    showMetricsFirst,
    skipDimensionGroupValidation,
  ])

  return (
    <ComboBox
      {...rest}
      selectionMode={SELECTION_MODE.SINGLE}
      options={groups}
      setSelected={(id) => {
        const item = groups
          .flatMap((group) => group.items)
          .find((item) => item.id === id)

        if (item) {
          setSelected(item)
        }
      }}
    />
  )
}
