import { useToast } from '@chakra-ui/react'
import { SEGMENTATION_CUSTOMER_LABELS_QUERY } from 'features/segmentations/views/CustomerLabelsView/graphql/useCustomerLabels'
import { type CreateSegmentationCustomerLabelInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { set } from 'lodash-es'
import cloneDeep from 'lodash-es/cloneDeep'
import { type ReactElement } from 'react'
import { type SegmentationCustomerLabel } from '../graphql/fragments'
import { useCreateCustomerLabel } from '../graphql/useCreateCustomerLabel'
import { CustomerLabelPicker } from './CustomerLabelPicker'

interface AddCustomerLabelModalProps {
  modalButton: ReactElement
  onCompleted?: (label: SegmentationCustomerLabel) => void
}

export const AddCustomerLabelModal: React.FC<AddCustomerLabelModalProps> = ({
  modalButton,
  onCompleted,
}) => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const createCustomerLabel = useCreateCustomerLabel()

  const onApply = (label: CreateSegmentationCustomerLabelInput) => {
    if (!label.name || !label.iconName || !label.iconColor) return

    createCustomerLabel({
      variables: {
        params: {
          name: label.name,
          iconName: label.iconName,
          iconColor: label.iconColor,
        },
      },
      optimisticResponse: {
        createSegmentationCustomerLabel: {
          name: label.name,
          iconName: label.iconName,
          iconColor: label.iconColor,
          id: 'optimistic',
          __typename: 'SegmentationCustomerLabel',
        },
      },
      update: (cache, { data }) => {
        if (!data) return
        cache.updateQuery(
          { query: SEGMENTATION_CUSTOMER_LABELS_QUERY },
          (queryData) => {
            if (!queryData?.viewer?.merchant.segmentationCustomerLabels)
              return queryData

            const queryDataClone = cloneDeep(queryData)

            set(queryDataClone, 'viewer.merchant.segmentationCustomerLabels', [
              ...queryData.viewer.merchant.segmentationCustomerLabels,
              data.createSegmentationCustomerLabel,
            ])

            return queryDataClone
          },
        )
      },
      onCompleted: ({ createSegmentationCustomerLabel }) => {
        trackEvent({
          eventName: 'Segmentations Customer Label Created',
          eventProperties: {
            name: createSegmentationCustomerLabel.name,
            iconName: createSegmentationCustomerLabel.iconName,
            iconColor: createSegmentationCustomerLabel.iconColor,
          },
        })

        onCompleted?.(
          createSegmentationCustomerLabel as SegmentationCustomerLabel,
        )
      },
      onError: () => {
        toast({
          description: 'Could not add customer label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <CustomerLabelPicker
      currentLabel={undefined}
      modalButton={modalButton}
      onApply={onApply}
    />
  )
}
