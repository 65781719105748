import { Divider, Flex, Grid } from '@chakra-ui/react'
import { ToggleWithLabels } from 'components/ToggleWithLabels/ToggleWithLabels'
import {
  DimensionMetricSelectorDrawerOpenState,
  useDimensionMetricSelectorDrawerOpenAtom,
  useDimensionMetricSelectorHoveredItemSetAtom,
} from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import { DimensionSelectorSection } from './DimensionSelectorSection'
import { MetricSelectorSection } from './MetricSelectorSection'

const sections = [
  {
    id: DimensionMetricSelectorDrawerOpenState.DIMENSION,
    label: 'Dimensions',
  },
  { id: DimensionMetricSelectorDrawerOpenState.METRIC, label: 'Metrics' },
]

interface DimensionMetricSelectorSectionProps {
  isToggleDisabled?: boolean
  allowInvalidMetricCombinations?: boolean
}

export const DimensionMetricSelectorSection = ({
  isToggleDisabled,
  allowInvalidMetricCombinations,
}: DimensionMetricSelectorSectionProps) => {
  const [openState, setOpenState] = useDimensionMetricSelectorDrawerOpenAtom()
  const setHoveredItem = useDimensionMetricSelectorHoveredItemSetAtom()

  return (
    <Grid gridTemplateRows="auto auto 1fr">
      <Flex px={4} py={2} justifyContent="flex-end">
        <ToggleWithLabels
          items={sections}
          setSelected={(id) => {
            setOpenState(id)
            setHoveredItem(null)
          }}
          selected={openState}
          isDisabled={isToggleDisabled}
        />
      </Flex>
      <Divider />
      {openState === DimensionMetricSelectorDrawerOpenState.DIMENSION && (
        <DimensionSelectorSection />
      )}
      {openState === DimensionMetricSelectorDrawerOpenState.METRIC && (
        <MetricSelectorSection
          allowInvalidMetricCombinations={allowInvalidMetricCombinations}
        />
      )}
    </Grid>
  )
}
