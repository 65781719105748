import { useQuery } from '@apollo/client'
import { type SegmentationCustomerSet } from 'features/segmentations/graphql/fragments'
import { graphql } from 'generated/graphql'

export const SEGMENTATION_CUSTOMER_SETS_QUERY = graphql(/* GraphQL */ `
  query SegmentationCustomerSets {
    viewer {
      id
      merchant {
        id
        segmentationCustomerSets {
          ...SegmentationCustomerSetFields
        }
      }
    }
  }
`)

export const useSegmentationCustomerSets = () => {
  const { data, ...query } = useQuery(SEGMENTATION_CUSTOMER_SETS_QUERY)

  return {
    query,
    customerSets: (data?.viewer?.merchant.segmentationCustomerSets ??
      []) as SegmentationCustomerSet[],
  }
}
