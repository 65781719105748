import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { Dropdown, type DropdownOption } from 'components/Dropdown'
import { type ChartSorting, type ChartSerie } from 'graphql/reports/types'
import { type Metric } from 'graphql/statistics/useMetrics'
import uniqBy from 'lodash-es/uniqBy'
import { useMemo, type FC } from 'react'
import { useDateState } from 'shared/hooks/useDateState'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { compareColor, isTimeUnit, timeDimensions } from 'utils/chart/constants'
import { type UpdateChartOptions } from './ChartSettingsPanel'
import { ChartSortingOption } from './ChartSortingOption'

type ChartOptionsProps = {
  onChange: (args: UpdateChartOptions) => void
  clearGColor: () => void
  uniqueMetrics: Metric[]
  series: ChartSerie[]
  chartGroup: string | null
  xAxis: string
  chartSorting: ChartSorting
}

export const ChartOptions: FC<ChartOptionsProps> = ({
  onChange,
  clearGColor,
  chartGroup,
  series,
  xAxis,
  uniqueMetrics,
  chartSorting,
}) => {
  const { dimensions } = useDimensionsState()
  const { compareDateRange } = useDateState()

  const isMultiMetric = series.length > 1

  const isScatter = series[0].type === CHART_TYPE_ID.SCATTER
  const allowXAxisMetrics = !isMultiMetric && isScatter

  const isPie = series[0].type === CHART_TYPE_ID.PIE
  const groupingDisabled = !isMultiMetric && isPie

  const uniqueMetricOptions = useMemo(
    () =>
      uniqueMetrics.map((metric) => ({
        id: metric.key,
        name: metric?.label ?? '',
      })),
    [uniqueMetrics],
  )

  const validXAxisOptions: DropdownOption[] = useMemo(() => {
    if (allowXAxisMetrics) {
      return uniqueMetricOptions
    }
    const options: DropdownOption[] = [...timeDimensions]

    return uniqBy(
      options.concat(
        dimensions.map((dimension) => {
          return {
            id: dimension.id,
            name: dimension?.label ?? '',
          }
        }),
      ),
      'id',
    )
  }, [dimensions, uniqueMetricOptions, allowXAxisMetrics])

  const validColors: DropdownOption[] = useMemo(() => {
    const arr: DropdownOption[] =
      getIsPopulatedDateRange(compareDateRange) && !allowXAxisMetrics // don't show group date for scatter
        ? [compareColor]
        : []

    return arr.concat(
      dimensions.reduce<DropdownOption[]>((acc, dimension) => {
        if (!isMultiMetric && dimension.id !== xAxis) {
          acc.push({
            id: dimension.id,
            name: dimension?.label ?? '',
          })
        }

        return acc
      }, []),
    )
  }, [compareDateRange, allowXAxisMetrics, dimensions, isMultiMetric, xAxis])

  const selectedXAxis = useMemo(() => {
    return validXAxisOptions.find((dimension) => xAxis === dimension.id)
  }, [xAxis, validXAxisOptions])
  const selectedGColor = useMemo(() => {
    return validColors.find((color) => chartGroup === color.id)
  }, [chartGroup, validColors])

  const isTimeXAxis = isTimeUnit(String(selectedXAxis?.id))

  const showSortDropdown = !isTimeXAxis && !isPie && !isScatter

  return (
    <Flex direction="column" gap={4}>
      <Dropdown
        value={selectedXAxis?.id}
        callback={(newXAxis) => onChange({ newXAxis })}
        containerProps={{ flex: 1 }}
        options={validXAxisOptions}
        label="X Axis"
        size="sm"
      />
      {showSortDropdown && selectedXAxis && (
        <ChartSortingOption
          onChange={onChange}
          series={series}
          chartSorting={chartSorting}
          selectedXAxis={selectedXAxis}
        />
      )}
      {!groupingDisabled && (
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          gap={2}
          flex={1}
        >
          <Dropdown
            value={selectedGColor?.id}
            callback={(newGColor) => onChange({ newGColor })}
            options={validColors}
            label="Grouped by"
            size="sm"
            containerProps={{ flexGrow: 1 }}
          />
          {chartGroup && (
            <Button
              size="sm"
              variant="outline"
              colorScheme="gray"
              onClick={clearGColor}
            >
              Clear
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  )
}
