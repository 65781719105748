import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type SegmentationCustomerLabel } from '../../../graphql/fragments'

export const SEGMENTATION_CUSTOMER_LABELS_QUERY = graphql(/* GraphQL */ `
  query SegmentationCustomerLabels {
    viewer {
      id
      merchant {
        id
        segmentationCustomerLabels {
          ...SegmentationCustomerLabelFields
        }
      }
    }
  }
`)

export const useSegmentationCustomerLabels = () => {
  const { data, ...query } = useQuery(SEGMENTATION_CUSTOMER_LABELS_QUERY)

  return {
    query,
    segmentationCustomerLabels: (data?.viewer?.merchant
      .segmentationCustomerLabels ?? []) as SegmentationCustomerLabel[],
  }
}
