import { useGetAtomValue } from 'hooks/useGetAtomValue'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
import {
  dashboardStateAtom,
  type DashboardWidgetState,
} from '../atoms/dashboardViewState'
import { type DashboardLayout } from '../types'
import { useDashboardLayoutManager } from './useDashboardLayoutManager'
import { dashboardLayoutAtom } from './useDashboardState'
import { useSelectWidget } from './useSelectWidget'
import { useWidgetOperations } from './useWidgetsState'

export type AddWidgetToDashboardOptions = {
  selectWidget?: boolean
  rowId?: string
  rowIndex?: number
}

export const useAddWidgetToDashboard = () => {
  const { addWidget } = useWidgetOperations()
  const setDashboardLayout = useSetAtom(dashboardLayoutAtom)
  const handleSelectWidget = useSelectWidget()
  const layoutManager = useDashboardLayoutManager()

  const getDashboardState = useGetAtomValue(dashboardStateAtom)

  const addWidgetToDashboard = useCallback(
    async (
      widget: DashboardWidgetState,
      options?: AddWidgetToDashboardOptions,
    ) => {
      const { selectWidget = true, rowId, rowIndex } = options ?? {}
      const dashboardState = getDashboardState()

      if (!dashboardState.id) return

      addWidget(widget)

      let updatedLayout: DashboardLayout | undefined

      if (rowId) {
        updatedLayout = layoutManager.moveWidgetToExistingRow({
          widget,
          targetRowId: rowId,
          targetCellIndex: 0,
        })
      } else {
        const { layout } = layoutManager.moveWidgetToNewRow({
          widget,
          rowIndex,
        })

        updatedLayout = layout
      }

      setDashboardLayout(updatedLayout)

      if (selectWidget) {
        handleSelectWidget(widget.id)
      }
    },
    [
      addWidget,
      getDashboardState,
      handleSelectWidget,
      layoutManager,
      setDashboardLayout,
    ],
  )

  return { addWidgetToDashboard }
}
