import { Box } from '@chakra-ui/react'
import { addDays } from 'date-fns'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { TooltipSectionLabel } from 'features/dashboard/components/ChartTooltip/shared/TooltipSectionLabel'
import { getGridLineOptions } from 'features/reports/utils/chart/common'
import { type InferenceGraphPointFieldsFragment } from 'generated/graphql/graphql'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import type React from 'react'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { CHART_PRIMARY_COLOR, staticChartOptions } from 'utils/chart/constants'
import { formatMetric } from 'utils/numberFormats'
import { experimentViewMetrics, type EXPERIMENT_METRIC } from '../consts'
import { MARGIN_DAYS } from './KPIChart'

interface CausalEffectChartProps {
  cumulativeLift: InferenceGraphPointFieldsFragment[]
  selectedMetric: EXPERIMENT_METRIC
  startDate: string
  endDate: string
  treatmentPeriod: number
  postTreatmentPeriod: number
}

export const CausalEffectChart: React.FC<CausalEffectChartProps> = ({
  cumulativeLift,
  selectedMetric,
  startDate,
  endDate,
  postTreatmentPeriod,
}) => {
  const { currency } = useMerchantInfo()

  const metric =
    experimentViewMetrics.find((metric) => metric.id === selectedMetric) ??
    experimentViewMetrics[0]

  const postTreatmentEndDate = addDays(new Date(endDate), postTreatmentPeriod)

  const treatmentData = cumulativeLift.map((point) => ({
    x: new Date(point.date).getTime(),
    y: point.value,
    lowerBound: point.lowerBound === null ? undefined : point.lowerBound,
    upperBound: point.upperBound === null ? undefined : point.upperBound,
  }))

  const gridLineOptions = getGridLineOptions(CHART_TYPE_ID.LINE)

  const options: Highcharts.Options = {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      zooming: { type: 'xy' },
      type: CHART_TYPE_ID.LINE,
      height: 250,
    },
    xAxis: {
      ...staticChartOptions.xAxis,
      type: 'datetime',
      max: addDays(postTreatmentEndDate, MARGIN_DAYS).getTime(),
      plotBands: [
        {
          from: new Date(startDate).getTime(),
          to: new Date(endDate).getTime(),
          color: colorTheme.gray[100],
        },
        {
          from: new Date(endDate).getTime(),
          to: postTreatmentEndDate.getTime(),
          color: colorTheme.gray[50],
        },
      ],
      plotLines: [
        {
          value: new Date(startDate).getTime(),
          color: colorTheme.gray[300],
          dashStyle: 'ShortDash',
          width: 1,
        },
        {
          value: new Date(endDate).getTime(),
          color: colorTheme.gray[300],
          dashStyle: 'ShortDash',
          width: 1,
        },
      ],
      crosshair: {
        color: colorTheme.grey[300],
        dashStyle: 'ShortDot',
      },
      ...gridLineOptions.xAxis,
    },
    yAxis: {
      ...staticChartOptions.yAxis,
      title: {
        ...staticChartOptions.yAxis?.title,
        text: `Cumulative causal effect`,
      },
      plotLines: [
        {
          value: 0,
          color: colorTheme.gray[300],
          dashStyle: 'ShortDot',
          width: 1,
        },
      ],
      ...gridLineOptions.yAxis,
    },
    series: [
      {
        type: 'arearange',
        name: 'Confidence interval',
        data: treatmentData.map(({ x, lowerBound, upperBound }) => ({
          x,
          low: lowerBound,
          high: upperBound,
        })),
        color: colorTheme.gray[400],
        fillColor: colorTheme.gray[200],
        lineWidth: 1,
        dashStyle: 'Dot',
        // enableMouseTracking: false, // Disable tooltip for the confidence interval
        showInLegend: false,
      },
      {
        type: 'line',
        name: `Cumulative causal effect on ${metric.name.toLowerCase()}`,
        data: treatmentData,
        color: CHART_PRIMARY_COLOR,
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      ...staticChartOptions.plotOptions,
      series: {
        ...staticChartOptions.plotOptions.series,
        stickyTracking: true,
      },
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      shared: true,
      useHTML: true,
      formatter: function () {
        if (!this.x) return ''

        const date = new Date(this.x)
        const formattedDate = date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })

        const [areaRangePoint, valuePoint] = this.points ?? []

        const hasAreaRangePoint = Boolean(
          areaRangePoint &&
            areaRangePoint.point.high &&
            areaRangePoint.point.low,
        )

        const formattedHighValue = areaRangePoint.point.high
          ? formatMetric(metric.format, areaRangePoint.point.high, currency)
          : 'N/A'

        const formattedLowValue = areaRangePoint.point.low
          ? formatMetric(metric.format, areaRangePoint.point.low, currency)
          : 'N/A'

        const value = valuePoint?.y
          ? formatMetric(metric.format, valuePoint.y, currency)
          : '0'

        const element = (
          <div>
            <TooltipSectionLabel label={formattedDate} />

            {hasAreaRangePoint && (
              <TooltipMetricRow
                iconColor={areaRangePoint.color?.toString()}
                metricName="Upper"
                value={formattedHighValue}
              />
            )}

            <TooltipMetricRow
              iconColor={valuePoint.color?.toString()}
              metricName={metric.name}
              value={value}
            />

            {hasAreaRangePoint && (
              <TooltipMetricRow
                iconColor={areaRangePoint.color?.toString()}
                metricName="Lower"
                value={formattedLowValue}
              />
            )}
          </div>
        )

        return renderToString(element)
      },
    },
  }

  return (
    <Box position="relative">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  )
}
