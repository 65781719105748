import { Box, type BoxProps } from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  WidgetDropPosition,
  overItemStateAtom,
} from 'features/dashboards/hooks/useDashboardLayoutState'
import { useAtomValue } from 'jotai'

interface SortableItemProps extends BoxProps {
  id: string
  isGhost?: boolean
  children: React.ReactNode
}

export const SortableItem = ({
  id,
  children,
  isGhost = false,
  ...props
}: SortableItemProps) => {
  const over = useAtomValue(overItemStateAtom)
  const dropPosition =
    !isGhost && over?.id === id ? over.dropPosition : undefined

  const {
    setNodeRef,
    isDragging,
    isSorting,
    attributes,
    listeners,
    transform,
    transition,
  } = useSortable({
    id,
  })

  const style = {
    transform: isSorting ? undefined : CSS.Translate.toString(transform),
    transition,
    opacity: isGhost ? 0.5 : 1,
  }

  return (
    <Box
      ref={setNodeRef}
      height="100%"
      style={style}
      position="relative"
      overflow="visible"
      {...attributes}
      {...listeners}
      {...props}
      _after={{
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '2px',
        opacity: dropPosition ? 1 : 0,
        bg: 'blue.500',
        left: dropPosition === WidgetDropPosition.Before ? '-9px' : undefined,
        right: dropPosition === WidgetDropPosition.After ? '-9px' : undefined,
      }}
    >
      {!isDragging ? (
        children
      ) : (
        <Box w="100%" h="100%" backgroundColor="gray.100" />
      )}
    </Box>
  )
}
