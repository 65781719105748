import { Flex, SimpleGrid } from '@chakra-ui/react'
import { InfoTooltip } from 'components/Tooltip/InfoTooltip'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { useRef } from 'react'

export const DetailRow: React.FC<{
  label: string
  value: string | React.ReactElement
  tooltip?: string
}> = ({ label, value, tooltip }) => {
  const labelRef = useRef<HTMLDivElement>(null)
  const isOverflowing = useIsOverflowing(labelRef)

  return (
    <SimpleGrid
      gridTemplateColumns="auto 1fr"
      gap={2}
      py={2.5}
      justifyContent="space-between"
      borderBottom="solid 1px"
      borderBottomColor="gray.200"
      _last={{
        borderBottom: 'none',
      }}
    >
      <Flex alignItems="center" gap={1} minW={0}>
        <Tooltip isDisabled={!isOverflowing} label={label}>
          <Typography
            fontSize="xs"
            fontWeight={500}
            lineHeight={5}
            color="gray.600"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            ref={labelRef}
          >
            {label}
          </Typography>
        </Tooltip>
        {tooltip && (
          <InfoTooltip
            tooltipText={tooltip}
            iconProps={{ size: 'small', color: 'gray.600' }}
          />
        )}
      </Flex>
      {typeof value === 'string' ? (
        <Flex alignItems="center" justifyContent="end">
          <Typography
            fontSize="sm"
            fontWeight={500}
            lineHeight={4}
            color="gray.800"
            textAlign="right"
            whiteSpace="nowrap"
          >
            {value}
          </Typography>
        </Flex>
      ) : (
        value
      )}
    </SimpleGrid>
  )
}
