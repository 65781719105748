import { type Filter } from 'constants/types'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useGetAtomValue } from 'hooks/useGetAtomValue'
import { filtersStateAtom, useSetFiltersState } from './useFiltersState'

export const removeFilter = ({
  filterId,
  filterIndex,
  filters,
}: {
  filterId: string
  filterIndex: number
  filters: Record<string, Filter[]>
}) => {
  const newFilters = {
    ...filters,
  }

  const currentFilter = newFilters[filterId] || []
  const newFilter = currentFilter.filter((_, index) => index !== filterIndex)

  if (newFilter.length > 0) {
    newFilters[filterId] = newFilter
  } else {
    delete newFilters[filterId]
  }

  return newFilters
}

export const useRemoveFilter = () => {
  const setFilters = useSetFiltersState()
  const [trackEvent] = useTrackEvent()
  const getCurrentFilters = useGetAtomValue(filtersStateAtom)

  return ({
    filterId,
    filterIndex,
  }: {
    filterId: string
    filterIndex: number
  }) => {
    const filters = getCurrentFilters()

    const newFilters = removeFilter({ filterId, filterIndex, filters })

    trackEvent({
      eventName: 'Report Filter Removed',
      eventProperties: {
        key: filterId,
        filterIndex,
      },
    })

    setFilters(newFilters)
  }
}
