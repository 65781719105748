import { useUserReports } from 'graphql/reports/useUserReports'
import {
  useNewAnalyticsConfigAtom,
  useSetAnalyticsConfigAtom,
} from 'shared/hooks/useAnalyticsConfigState'
import {
  useSetReportStateAtom,
  useNewReportState,
} from '../hooks/useReportState'
import { REPORT_PAGE_TITLES } from '../routePages'
import { ReportListTable } from './shared/ReportListTable'

export const AllReportsView = () => {
  const { userReports, query } = useUserReports()
  const setReportState = useSetReportStateAtom()
  const setAnalyticsConfigState = useSetAnalyticsConfigAtom()
  const newReportState = useNewReportState()
  const newAnalyticsConfig = useNewAnalyticsConfigAtom()

  return (
    <ReportListTable
      pageTitle={REPORT_PAGE_TITLES.ALL}
      reports={userReports}
      isLoading={query.loading}
      addReportCallback={() => {
        setReportState(newReportState)
        setAnalyticsConfigState(newAnalyticsConfig)
      }}
    />
  )
}
