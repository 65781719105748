import { Box } from '@chakra-ui/react'
import type React from 'react'
import { CustomerSetsHeader } from './components/CustomerSetsHeader'
import { CustomerSetsList } from './components/CustomerSetsList'

export const CustomerSetsView: React.FC = () => {
  return (
    <Box>
      <CustomerSetsHeader />
      <CustomerSetsList />
    </Box>
  )
}
