import {
  Box,
  Flex,
  type FlexProps,
  Grid,
  Image,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { Button } from 'components/buttons'
import { Icon, type IconName, type IconProps } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import {
  DimensionMetricSelectorDrawerOpenState,
  useDimensionMetricSelectorDrawerOpenAtom,
  useDimensionMetricSelectorHoveredItemAtom,
  useDraftDimensions,
  useDraftMetrics,
} from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import {
  type Dimension,
  useNormalizedDimensions,
} from 'graphql/statistics/useDimensions'
import {
  getMetricKeyWithoutAttribution,
  isAttributedMetric,
  type Metric,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { type ReactNode } from 'react'
import { Link } from 'react-router-dom'
import {
  getValidMetricProcessors,
  isValidMetric,
} from 'shared/utils/analyticsConfig'

const emptyStateDetails = {
  [DimensionMetricSelectorDrawerOpenState.METRIC]: {
    title: 'Metric definitions',
    description:
      'Hover on a metric to see more details and information about that metric.',
  },
  [DimensionMetricSelectorDrawerOpenState.DIMENSION]: {
    title: 'Dimension definitions',
    description:
      'Hover on a dimension to see more details and information about that dimension.',
  },
  [DimensionMetricSelectorDrawerOpenState.HIDDEN]: {
    title: 'Definitions',
    description: 'Hover on a metric or dimension to see more details.',
  },
} as const

type HoveredItemDetails =
  | ((Dimension | Metric) & { format?: string }) // Adding format here is a workaround to make the type checker happy
  | undefined

const getFormatIconName = (format: string | undefined): IconName => {
  switch (format) {
    case METRIC_FORMAT.CURRENCY:
      return 'DollarSignIcon'
    case METRIC_FORMAT.PERCENT:
    case METRIC_FORMAT.PERCENT_INT:
      return 'Percent2Icon'
    case METRIC_FORMAT.DECIMAL:
    case METRIC_FORMAT.ABBREVIATED_INT:
    case METRIC_FORMAT.ABBREVIATED_DECIMAL:
    case METRIC_FORMAT.INTEGER:
      return 'HashtagIcon'
    case METRIC_FORMAT.SECONDS:
      return 'ClockIcon'
    default:
      return 'TextIcon'
  }
}

export const ExtendedTooltipSection = ({
  allowInvalidMetricCombinations = false,
}: {
  allowInvalidMetricCombinations?: boolean
}) => {
  const [openState] = useDimensionMetricSelectorDrawerOpenAtom()
  const [hoveredItem] = useDimensionMetricSelectorHoveredItemAtom()
  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()
  const [draftMetrics] = useDraftMetrics(allowInvalidMetricCombinations)
  const [draftDimensions] = useDraftDimensions()

  const getMetricsToBeDeselected = () => {
    if (!hoveredItem || !normalizedDimensions[hoveredItem]) return []

    const dimension = normalizedDimensions[hoveredItem]
    const validMetricProcessors = getValidMetricProcessors([dimension])
    const validMetricIds = Object.values(normalizedMetrics)
      .filter((metric) => !isAttributedMetric(metric))
      .filter((metric) => isValidMetric(metric, validMetricProcessors))
      .map(getMetricKeyWithoutAttribution)

    return draftMetrics.filter((metric) => !validMetricIds.includes(metric))
  }
  const metricsToBeDeselected = getMetricsToBeDeselected()
  const getDimensionsMakingMetricInvalid = () => {
    if (!hoveredItem || !normalizedMetrics[hoveredItem]) return []

    const metric = normalizedMetrics[hoveredItem]
    const invalidDimensions: string[] = []

    draftDimensions.forEach((dim) => {
      const dimension = normalizedDimensions[dim]
      const validMetricProcessors = getValidMetricProcessors([dimension])

      if (!isValidMetric(metric, validMetricProcessors)) {
        invalidDimensions.push(dimension.id)
      }
    })

    return invalidDimensions
  }
  const dimensionsMakingMetricInvalid = getDimensionsMakingMetricInvalid()

  const hoveredItemDetails: HoveredItemDetails = hoveredItem
    ? normalizedDimensions[hoveredItem] || normalizedMetrics[hoveredItem]
    : undefined

  return (
    <Box bg="grey.100" py={6} px={4}>
      {hoveredItemDetails ? (
        <Grid gridTemplateRows="auto 1fr auto" gap={6} h="full">
          <Grid gridTemplateRows="auto auto" gap={2}>
            <Typography fontWeight={500} lineHeight={6} fontSize="md">
              {hoveredItemDetails?.label}
            </Typography>
            <Badge
              p={1}
              icon={getFormatIconName(hoveredItemDetails.format)}
              as="div"
              display="flex"
              alignItems="center"
              width="fit-content"
            >
              {hoveredItemDetails.format ?? 'Text'}
            </Badge>
          </Grid>
          <Box h={0} minH="full">
            <Flex flexDir="column" gap={4} overflowY="auto" h="full">
              {metricsToBeDeselected.length > 0 && (
                <InfoBox
                  title="Incompatible metrics"
                  body={
                    <>
                      This dimension is not compatible with the following
                      selected metrics:
                      <UnorderedList listStylePosition="inside" my={2}>
                        {metricsToBeDeselected.map((metric) => (
                          <ListItem
                            key={metric}
                            color="gray.700"
                            lineHeight={4}
                            fontSize="xs"
                            fontWeight={500}
                          >
                            {normalizedMetrics[metric].label}
                          </ListItem>
                        ))}
                      </UnorderedList>
                      If you select this dimension, these metrics will be
                      deselected.
                    </>
                  }
                  icon="ExclamationTriangleIcon"
                  iconProps={{ color: 'orange.600' }}
                  bg="orange.50"
                />
              )}
              {dimensionsMakingMetricInvalid.length > 0 && (
                <InfoBox
                  title="Unavailable metric"
                  body={
                    <>
                      This metric is currently disabled because it is not
                      possible to combine with the following selected
                      dimensions:
                      <UnorderedList listStylePosition="inside" my={2}>
                        {dimensionsMakingMetricInvalid.map((dim) => (
                          <ListItem
                            key={dim}
                            color="gray.700"
                            lineHeight={4}
                            fontSize="xs"
                            fontWeight={500}
                          >
                            {normalizedDimensions[dim].label}
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </>
                  }
                  icon="ExclamationTriangleIcon"
                  iconProps={{ color: 'orange.600' }}
                  bg="orange.50"
                />
              )}
              {draftDimensions.length === 0 &&
                hoveredItem &&
                normalizedMetrics[hoveredItem] &&
                !allowInvalidMetricCombinations && (
                  <InfoBox
                    title="Unavailable metric"
                    body="This metric is disabled because you don't have any dimensions selected. Please select a dimension before selecting metrics."
                    icon="ExclamationTriangleIcon"
                    iconProps={{ color: 'orange.600' }}
                    bg="orange.50"
                  />
                )}
              <InfoBox
                title="Description"
                body={hoveredItemDetails.description}
              />
            </Flex>
          </Box>
          <InfoBox
            body={
              <Button
                as={Link}
                variant="link"
                trailingIcon={{ name: 'LinkExternalIcon' }}
                to="https://docs.dema.ai/guides/fundamentals/metrics"
                target="_blank"
                rel="noreferrer noopener"
              >
                View in Knowledge base
              </Button>
            }
          />
        </Grid>
      ) : (
        <Flex alignItems="center" h="full">
          <EmptyState
            imageSrc="/images/Cubes.svg"
            {...emptyStateDetails[openState]}
          />
        </Flex>
      )}
    </Box>
  )
}

interface InfoBoxProps extends FlexProps {
  title?: string
  body: ReactNode
  icon?: IconName
  iconProps?: Omit<IconProps, 'name'>
}

const InfoBox = ({ title, body, icon, iconProps, ...rest }: InfoBoxProps) => {
  return (
    <Flex gap={3} bg="white" p={4} {...rest}>
      {icon && <Icon name={icon} size="medium" {...iconProps} />}
      <Flex flexDir="column" gap={2}>
        {title && (
          <Typography
            fontWeight={600}
            lineHeight={4}
            fontSize="xs"
            color="gray.700"
          >
            {title}
          </Typography>
        )}
        <Typography as="div" lineHeight={4} fontSize="xs" color="gray.700">
          {body}
        </Typography>
      </Flex>
    </Flex>
  )
}

const EmptyState = ({
  imageSrc,
  title,
  description,
}: {
  imageSrc: string
  title: string
  description: string
}) => {
  return (
    <Box px={2}>
      <Image src={imageSrc} alt={title} boxSize={120} mb={6} />
      <Typography color="gray.900" fontSize="sm" fontWeight={500} mb={1}>
        {title}
      </Typography>
      <Typography fontSize="xs" lineHeight={4}>
        {description}
      </Typography>
    </Box>
  )
}
