import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { Box } from '@chakra-ui/react'
import {
  type ToggleWithIconsItem,
  ToggleWithIcons,
} from 'components/ToggleWithIcons/ToggleWithIcons'
import { type CompareUnit } from 'graphql/reports/types'
import {
  useCompareUnitState,
  useSetCompareUnitState,
} from 'shared/hooks/useCompareUnitState'
import { useDateState } from 'shared/hooks/useDateState'
import { ReportDetailsHeader } from './ReportDetailsRow/ReportDetailsHeader'

const items: ToggleWithIconsItem<CompareUnit>[] = [
  {
    id: DISPLAY_FORMAT.PERCENTAGE_DIFF,
    title: 'Percentage difference',
    iconName: 'Percent1Icon',
  },
  {
    id: DISPLAY_FORMAT.ABSOLUTE_DIFF,
    title: 'Absolute difference',
    iconName: 'HashtagIcon',
  },
]

export const CompareUnitSection = () => {
  const setCompareUnit = useSetCompareUnitState()
  const compareUnit = useCompareUnitState()
  const { isCompare } = useDateState()

  return (
    <Box>
      <ReportDetailsHeader title="Comparison unit" />

      <ToggleWithIcons
        items={items}
        selected={compareUnit}
        setSelected={setCompareUnit}
        isDisabled={!isCompare}
      />
    </Box>
  )
}
