import { Box, Flex, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { AddWidgetButton } from './DashboardHeader/AddWidgetButton/AddWidgetButton'

export const EmptyDashboard = () => {
  return (
    <Flex
      w="280px"
      h="100%"
      mx="auto"
      flexDir="column"
      gap={6}
      maxW="380px"
      justifyContent="center"
    >
      <Image
        src="/images/EmptyDashboardWidgets.svg"
        alt="Empty dashboard widgets"
        boxSize={120}
      />
      <Box>
        <Typography fontSize="md" fontWeight={500} mb={2}>
          Widgets
        </Typography>
        <Typography>
          You haven&apos;t added any widget yet. You can either add a widget
          from an existing report or build it from scratch. existing report or
          build it from scratch.
        </Typography>
      </Box>
      <AddWidgetButton />
    </Flex>
  )
}
