import { DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import type { DateRange } from 'constants/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { toZonedTime } from 'date-fns-tz'
import { dashboardStateAtom } from 'features/dashboards/atoms/dashboardViewState'
import { merchantInfoAtom } from 'graphql/useMerchantInfo'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { type DateState } from 'shared/hooks/useDateState'
import {
  getPresetFromUrlCompareDynamicDate,
  getPresetFromUrlDynamicDate,
} from 'shared/utils/analyticsConfig'

const DEFAULT_DYNAMIC_DATE = DYNAMIC_DATE_ID.CUSTOM_RANGE

export const focusStartDateStateAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('startDate'),
)

export const focusEndDateStateAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('endDate'),
)

export const focusDynamicDateStateAtom = focusAtom(
  dashboardStateAtom,
  (optic) => optic.prop('dynamicDate'),
)

export const focusCompareStartDateStateAtom = focusAtom(
  dashboardStateAtom,
  (optic) => optic.prop('compareStartDate'),
)

export const focusCompareEndDateStateAtom = focusAtom(
  dashboardStateAtom,
  (optic) => optic.prop('compareEndDate'),
)

export const focusCompareDynamicDateStateAtom = focusAtom(
  dashboardStateAtom,
  (optic) => optic.prop('compareDynamicDate'),
)

export const dashboardDateStateAtom = atom(
  (get) => {
    const { timezone } = get(merchantInfoAtom)
    const startDate = get(focusStartDateStateAtom)
    const endDate = get(focusEndDateStateAtom)
    const dynamicDate = get(focusDynamicDateStateAtom)
    const compareStartDate = get(focusCompareStartDateStateAtom)
    const compareEndDate = get(focusCompareEndDateStateAtom)
    const compareDynamicDate = get(focusCompareDynamicDateStateAtom)

    const dynamicDatePreset = getPresetFromUrlDynamicDate(
      dynamicDate ?? DEFAULT_DYNAMIC_DATE,
    )
    const validDateRange: DateRange = [
      dynamicDatePreset?.value[0] ?? toZonedTime(startDate, timezone),
      dynamicDatePreset?.value[1] ?? toZonedTime(endDate, timezone),
    ]

    const compareDynamicDatePreset = getPresetFromUrlCompareDynamicDate(
      compareDynamicDate ?? DEFAULT_DYNAMIC_DATE,
      validDateRange,
    )
    const validCompareDateRange: DateRange = [
      compareDynamicDatePreset?.value[0] ??
        (compareStartDate ? toZonedTime(compareStartDate, timezone) : null),
      compareDynamicDatePreset?.value[1] ??
        (compareEndDate ? toZonedTime(compareEndDate, timezone) : null),
    ]

    const isCompare = !!validCompareDateRange[0] && !!validCompareDateRange[1]

    return {
      dateRange: validDateRange,
      dynamicDate: dynamicDatePreset?.id,
      compareDateRange: validCompareDateRange,
      compareDynamicDate: compareDynamicDatePreset?.id,
      isCompare,
    }
  },
  (
    _,
    set,
    {
      dateRange,
      compareDateRange,
      compareDynamicDate,
      dynamicDate,
    }: Omit<DateState, 'isCompare'>,
  ) => {
    const [compareStartDate, compareEndDate] = compareDateRange

    if (getIsPopulatedDateRange(dateRange)) {
      set(focusStartDateStateAtom, dateRange[0].toISOString())
      set(focusEndDateStateAtom, dateRange[1].toISOString())
    }

    set(
      focusCompareStartDateStateAtom,
      compareStartDate ? compareStartDate.toISOString() : null,
    )
    set(
      focusCompareEndDateStateAtom,
      compareEndDate ? compareEndDate.toISOString() : null,
    )
    set(focusDynamicDateStateAtom, dynamicDate)
    set(
      focusCompareDynamicDateStateAtom,
      (compareDynamicDate as string) ?? null,
    )
  },
)

export const useDashboardDateState = () => useAtomValue(dashboardDateStateAtom)

export const useSetDashboardDateState = () => useSetAtom(dashboardDateStateAtom)
