import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type GetSegmentationCustomerChartMetricsInput } from 'generated/graphql/graphql'

const CUSTOMER_CHART_METRICS_QUERY = graphql(/* GraphQL */ `
  query CustomerChartMetrics(
    $params: GetSegmentationCustomerChartMetricsInput!
  ) {
    viewer {
      id
      merchant {
        id
        segmentationCustomerChartMetrics(params: $params)
      }
    }
  }
`)

export const useCustomerChartMetrics = ({
  params,
}: {
  params: GetSegmentationCustomerChartMetricsInput
}) => {
  const { data, ...query } = useQuery(CUSTOMER_CHART_METRICS_QUERY, {
    variables: { params },
  })

  return {
    segmentationCustomerChartMetrics: data?.viewer?.merchant
      .segmentationCustomerChartMetrics as Record<
      string,
      Record<string, number>
    >,
    ...query,
  }
}
