import { Box } from '@chakra-ui/react'
import { AnalyticsChartContainer } from 'features/reports/components/AnalyticsChart/AnalyticsChartContainer'
import { AnalyticsTable } from 'features/reports/components/AnalyticsTable/AnalyticsTable'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import { useMetricsState } from 'shared/hooks/useMetricsState'

export const DataSection = () => {
  const { dimensions } = useDimensionsState()
  const { metrics } = useMetricsState()
  const isValidReport = dimensions.length > 0 && metrics.length > 0

  if (!isValidReport) {
    return (
      <Box pt="80px" px={10}>
        <EmptyData label="You haven't added any data yet" />
      </Box>
    )
  }

  return (
    <>
      <AnalyticsChartContainer />
      <AnalyticsTable />
    </>
  )
}
