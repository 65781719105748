import {
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  type ColumnSort,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { WidgetStatus } from 'features/dashboards/atoms/dashboardViewState'
import { useTableWidgetAnalytics } from 'features/dashboards/hooks/useTableWidgetAnalytics'
import { useWidgetCompareUnitState } from 'features/dashboards/hooks/useWidgetCompareUnitState'
import { useWidgetStatus } from 'features/dashboards/hooks/useWidgetsState'
import { useWidgetTableState } from 'features/dashboards/hooks/useWidgetTableState'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useState } from 'react'
import { EmptyWidgetContent } from '../WidgetCard/EmptyWidgetContent'
import { ErrorWidgetContent } from '../WidgetCard/ErrorWidgetContent'
import { useWidgetAnalyticsTableColumns } from './useWidgetAnalyticsTableColumns'
import { WidgetAnalyticsTableHeader } from './WidgetAnalyticsTableHeader'

export const WidgetAnalyticsTable = ({ widgetId }: { widgetId: string }) => {
  const widgetStatus = useWidgetStatus(widgetId)
  const { data, loading: isLoading } = useTableWidgetAnalytics(widgetId)
  const compareUnit = useWidgetCompareUnitState(widgetId)

  const tableState = useWidgetTableState(widgetId)
  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()

  const [sorting, setSorting] = useState<ColumnSort[]>(() => {
    const sorting = tableState.filter(({ sort }) => sort)

    return sorting.map(({ id, sort }) => ({
      id,
      desc: sort === 'desc',
    }))
  })

  const columns = useWidgetAnalyticsTableColumns({
    normalizedDimensions,
    normalizedMetrics,
    tableState,
    compareUnit,
    widgetId,
  })

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  if (widgetStatus === WidgetStatus.Empty) {
    return <EmptyWidgetContent widgetId={widgetId} />
  }

  if (widgetStatus === WidgetStatus.Error) {
    return <ErrorWidgetContent />
  }

  return (
    <TableContainer height="100%">
      <Table>
        <Thead bg="gray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <WidgetAnalyticsTableHeader
                  key={header.id}
                  header={header}
                  widgetId={widgetId}
                  index={index}
                />
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody w="100%">
          {isLoading ? (
            <LoadingWidgetAnalyticsTable columns={columns.length} />
          ) : (
            table.getRowModel().rows.map((row) => (
              <Tr key={row.id} h="40px">
                {row.getVisibleCells().map((cell, index) => (
                  <Td
                    key={cell.id}
                    textAlign={index > 0 ? 'right' : 'left'}
                    borderColor="gray.200"
                    py={1}
                    px={4}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const LoadingWidgetAnalyticsTable = ({
  columns,
  rows = 6,
}: {
  columns: number
  rows?: number
}) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, index) => (
        <Tr key={index} h="40px">
          {Array.from({ length: columns }).map((_, index) => (
            <Td key={index} borderColor="gray.200" py={1} px={4}>
              <Flex justifyContent={index > 0 ? 'flex-end' : 'flex-start'}>
                <Skeleton h={2} w="64px" />
              </Flex>
            </Td>
          ))}
        </Tr>
      ))}
    </>
  )
}
