import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { type FC, type ReactElement } from 'react'
import { useAddFilter } from 'shared/hooks/filters/useAddFilter'
import { useFiltersState } from 'shared/hooks/filters/useFiltersState'
import { useRemoveFilter } from 'shared/hooks/filters/useRemoveFilter'
import { useDateState } from 'shared/hooks/useDateState'
import { FilterPopupPicker } from '../FilterPopupPicker'
interface TableFilterProps {
  filterId: string
  filterLabel: string
  setPopupOpen: (isOpen: boolean) => void
  isPopupOpen: boolean
  children: ReactElement
}

const TableFilter: FC<TableFilterProps> = ({
  filterId,
  filterLabel,
  isPopupOpen,
  setPopupOpen,
  children,
}) => {
  const { dateRange } = useDateState()
  const { uniqueDimensionValues, query } = useUniqueDimensionValues({
    dimensionId: filterId,
    dateRange,
    skipDynamicValues: !isPopupOpen, // Only dispatch request when opening popup
    skipStaticValues: true,
  })
  const filters = useFiltersState()
  const addFilter = useAddFilter()
  const removeFilter = useRemoveFilter()

  return (
    <FilterPopupPicker
      filterId={filterId}
      filterIndex={filters[filterId]?.length ?? 0} // The new filter index will be the current filter length for that key
      filterLabel={filterLabel}
      isOpen={isPopupOpen}
      addFilter={addFilter}
      removeFilter={removeFilter}
      filters={filters}
      onOpen={() => setPopupOpen(true)}
      onClose={() => setPopupOpen(false)}
      options={uniqueDimensionValues}
      isLoading={query.loading}
      popupButton={children}
    />
  )
}

export default TableFilter
