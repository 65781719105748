import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { useChannels } from 'graphql/useChannels'
import { useCountries } from 'graphql/useCountries'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import {
  type ExperimentOptionFields,
  getExperimentOptionDisplayName,
  getExperimentOptionSortingFn,
} from './consts'

export const useUniqueFilterOptions = (
  experiments: ExperimentSummary[],
  filterOption: ExperimentOptionFields,
) => {
  const { normalizedSites } = useMerchantInfo()
  const { normalizedChannels } = useChannels()
  const { normalizedCountries } = useCountries()

  return useMemo(() => {
    return uniqBy(experiments, filterOption)
      .map((experiment) => ({
        id: experiment[filterOption],
        name: getExperimentOptionDisplayName(
          filterOption,
          experiment,
          normalizedSites,
          normalizedCountries,
          normalizedChannels,
        ),
      }))
      .sort(getExperimentOptionSortingFn(filterOption))
  }, [
    experiments,
    filterOption,
    normalizedSites,
    normalizedCountries,
    normalizedChannels,
  ])
}
