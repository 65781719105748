import { Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { EmptyState } from 'features/segmentations/shared/EmptyState'
import { useProductLabels } from 'features/segmentations/views/ProductLabelsView/graphql/useProductLabels'
import type React from 'react'
import { AddProductLabelModal } from './AddProductLabelModal'
import { DeleteProductLabelModal } from './DeleteProductLabelModal'
import { EditProductLabelModal } from './EditProductLabelModal'

export const ProductLabelsList: React.FC = () => {
  const { productLabels, query } = useProductLabels()

  if (query.loading) {
    return (
      <Table>
        <Tbody>
          <SkeletonTableRows rows={5} columns={1} />
        </Tbody>
      </Table>
    )
  }

  if (!productLabels || productLabels.length === 0) {
    return (
      <EmptyState
        imageSrc="/images/Cubes.svg"
        title="Product labels"
        description="Product labels are used to assign your products to different categories such as for example Fastmovers, Slowmovers and Standstills."
        modalButton={
          <AddProductLabelModal
            modalButton={<Button size="sm">New product label</Button>}
          />
        }
      />
    )
  }

  return (
    <Table>
      <Tbody>
        {productLabels.map((label) => (
          <Tr display="flex" key={label.id}>
            <Td px={4} flexGrow={1} alignContent="center">
              <Flex alignItems="center" gap={2}>
                <Icon
                  name={label.iconName}
                  size="small"
                  color={label.iconColor}
                />
                <Typography
                  fontSize="xs"
                  fontWeight={500}
                  color="gray.800"
                  lineHeight={4}
                >
                  {label.name}
                </Typography>
              </Flex>
            </Td>
            <Td px={4}>
              <EditProductLabelModal currentLabel={label} />
              <DeleteProductLabelModal label={label} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
