import { Box } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { type PropsWithChildren } from 'react'
import { calcPercentageDiff } from 'utils/calcPercentageDiff'
import { formatMetric } from 'utils/numberFormats'

interface ProfitDescriptionProps {
  selectedChannel: string
  isSingleChannel?: boolean
  showValuesPerDay: boolean
  optimalSpend: number
  optimalProfit: number
  actualSpend: number
  actualProfit: number
  saturationSpend: number
  saturationProfit: number
}

export const ProfitDescription: React.FC<ProfitDescriptionProps> = ({
  selectedChannel,
  optimalSpend,
  optimalProfit,
  actualSpend,
  actualProfit,
  showValuesPerDay,
  saturationProfit,
  saturationSpend,
  isSingleChannel,
}) => {
  const { currency } = useMerchantInfo()
  const dailyText = showValuesPerDay ? 'daily' : ''
  const hasActualData = isFinite(actualProfit) // This only happens in historical view
  const spendPercentIncrease =
    calcPercentageDiff(optimalSpend, actualSpend) / 100
  const estimatedProfit = optimalProfit - actualProfit
  const isPositiveEstimatedProfit = estimatedProfit >= 0
  const spendToSaturation = saturationSpend - actualSpend
  const profitToSaturation = saturationProfit - actualProfit

  return (
    <Box p={4} bg="gray.50" maxW="284px" flexShrink={0} w="full">
      <DescriptionTitle />
      <Typography fontSize="sm" color="gray.800" lineHeight={5}>
        <ProposalDescription
          hasActualData={hasActualData}
          dailyText={dailyText}
          selectedChannel={selectedChannel}
          optimalSpend={optimalSpend}
          currency={currency}
        />
        {isFinite(spendPercentIncrease) ? (
          <PercentageIncreaseDescription
            spendPercentIncrease={spendPercentIncrease}
            currency={currency}
            dailyText={dailyText}
          />
        ) : null}
        .
        <br />
        <br />
        <AllocationIntroDescription dailyText={dailyText} />
        {hasActualData ? (
          <>
            <HistoricalEstimatedProfitDescription
              selectedChannel={selectedChannel}
              currency={currency}
              estimatedProfit={estimatedProfit}
            />
            {!isPositiveEstimatedProfit
              ? negativeProfitReallocationDescription
              : null}
          </>
        ) : (
          <FutureOptimalProfitDescription
            selectedChannel={selectedChannel}
            optimalProfit={optimalProfit}
            currency={currency}
          />
        )}
        {hasActualData && isSingleChannel ? (
          <>
            <br />
            <br />
            <HistoricalSingleChannelMaximizeProfit
              selectedChannel={selectedChannel}
              spendToSaturation={spendToSaturation}
              profitToSaturation={profitToSaturation}
              currency={currency}
              dailyText={dailyText}
            />
          </>
        ) : null}
      </Typography>
    </Box>
  )
}

const ProposalDescription = ({
  hasActualData,
  dailyText,
  selectedChannel,
  optimalSpend,
  currency,
}: {
  hasActualData: boolean
  dailyText: string
  selectedChannel: string
  optimalSpend: number
  currency: string | undefined
}) => {
  return (
    <>
      {hasActualData ? `Given your current budget, we ` : 'We '}
      propose the {dailyText} Marketing spend for{' '}
      <BoldText>{selectedChannel}</BoldText> during this period to be{' '}
      <ValueBadge value={optimalSpend} currency={currency} isBlue />
    </>
  )
}

const DescriptionTitle = () => {
  return (
    <Typography fontSize="lg" color="gray.800" mb={4}>
      Increase your profit
    </Typography>
  )
}

const PercentageIncreaseDescription = ({
  spendPercentIncrease,
  currency,
  dailyText,
}: {
  spendPercentIncrease: number
  currency: string | undefined
  dailyText: string
}) => {
  return (
    <>
      , {spendPercentIncrease > 0 ? 'an increase' : 'a decrease'} from your
      actual {dailyText} spend with{' '}
      <BoldText>
        {formatMetric(METRIC_FORMAT.PERCENT, spendPercentIncrease, currency)}
      </BoldText>
    </>
  )
}

const HistoricalEstimatedProfitDescription = ({
  estimatedProfit,
  currency,
  selectedChannel,
}: {
  estimatedProfit: number
  currency: string | undefined
  selectedChannel: string
}) => {
  return (
    <>
      {estimatedProfit >= 0 ? 'increase' : 'decrease'} with{' '}
      <ValueBadge value={estimatedProfit} currency={currency} /> for{' '}
      <BoldText>{selectedChannel}</BoldText>.
    </>
  )
}

const AllocationIntroDescription = ({ dailyText }: { dailyText: string }) =>
  `Making the suggested allocations in Marketing spend, your ${dailyText} Net gross profit 3 is estimated to `

const FutureOptimalProfitDescription = ({
  optimalProfit,
  currency,
  selectedChannel,
}: {
  optimalProfit: number
  currency: string | undefined
  selectedChannel: string
}) => {
  return (
    <>
      be <ValueBadge value={optimalProfit} currency={currency} /> for{' '}
      <BoldText>{selectedChannel}</BoldText>.
    </>
  )
}

const negativeProfitReallocationDescription =
  ' However, reallocating the reduced spend to other channels may increase your overall marketing performance.'

const HistoricalSingleChannelMaximizeProfit = ({
  selectedChannel,
  spendToSaturation,
  profitToSaturation,
  currency,
  dailyText,
}: {
  selectedChannel: string
  spendToSaturation: number
  profitToSaturation: number
  currency: string | undefined
  dailyText: string
}) => {
  return (
    <>
      Disregarding the current budget, to reach the maximum profit for{' '}
      <BoldText>{selectedChannel}</BoldText> you need to{' '}
      {spendToSaturation > 0 ? 'increase' : 'decrease'} your {dailyText} spend
      by{' '}
      <BoldText>
        {formatMetric(METRIC_FORMAT.CURRENCY, spendToSaturation, currency)}
      </BoldText>
      . We estimate your {dailyText} Net gross profit 3 will{' '}
      {profitToSaturation > 0 ? 'increase' : 'decrease'} by{' '}
      <BoldText>
        {formatMetric(METRIC_FORMAT.CURRENCY, profitToSaturation, currency)}
      </BoldText>{' '}
      when making this allocation.
    </>
  )
}

interface ValueBadgeProps {
  value: number
  currency: string | undefined
  isBlue?: boolean
}

const ValueBadge = ({ value, currency, isBlue }: ValueBadgeProps) => {
  return (
    <Typography
      as="span"
      bg={isBlue ? 'primary.100' : value >= 0 ? 'green.100' : 'red.100'}
      color={isBlue ? 'primary.500' : value >= 0 ? 'green.700' : 'red.700'}
      px={1}
      fontWeight={600}
      whiteSpace="nowrap"
    >
      {formatMetric(METRIC_FORMAT.CURRENCY, value, currency)}
    </Typography>
  )
}

const BoldText = ({ children }: PropsWithChildren) => (
  <Typography as="span" fontWeight={600} whiteSpace="nowrap">
    {children}
  </Typography>
)
