import { useAtomValue } from 'jotai'
import { Resizable } from 're-resizable'
import { type ReactNode } from 'react'
import { addWidgetMenuOpenAtom } from '../../DashboardHeader/AddWidgetButton/AddWidgetButton'
import { RowDivider } from './RowDivider'

interface ResizableRowProps {
  minHeight: number
  height: number
  isNewRowActive: boolean
  isResizingRow: boolean
  children: ReactNode
  onResizeStart: () => void
  onResizeStop: (height: number) => void
  onNewRowActiveChange: (isActive: boolean) => void
}

export const ResizableRow = ({
  minHeight,
  height,
  isNewRowActive,
  isResizingRow,
  children,
  onResizeStart,
  onResizeStop,
  onNewRowActiveChange,
}: ResizableRowProps) => {
  const isAddWidgetMenuOpen = useAtomValue(addWidgetMenuOpenAtom)

  return (
    <Resizable
      minHeight={minHeight}
      size={{ height }}
      enable={{ bottom: true }}
      handleComponent={{
        bottom: (
          <RowDivider
            isActive={isNewRowActive || isResizingRow}
            onMouseEnter={() => {
              if (!isAddWidgetMenuOpen) {
                onNewRowActiveChange(true)
              }
            }}
            onMouseLeave={() => {
              if (!isAddWidgetMenuOpen) {
                onNewRowActiveChange(false)
              }
            }}
          />
        ),
      }}
      handleStyles={{
        bottom: {
          position: 'static',
          height: 16,
        },
      }}
      onResizeStart={onResizeStart}
      onResizeStop={(_, __, ___, data) => {
        onResizeStop(height + data.height)
      }}
    >
      {children}
    </Resizable>
  )
}
