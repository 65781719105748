import { type ApolloCache, useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type DashboardWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { getIsMerchantVisibility, mapToVisibility } from 'graphql/shared/utils'
import { cloneDeep, omit, set } from 'lodash-es'
import { v7 as uuidv7 } from 'uuid'
import {
  type DashboardWidgetState,
  type DashboardState,
} from '../atoms/dashboardViewState'
import { MERCHANT_DASHBOARDS_QUERY } from './useMerchantDashboards'
import { transformDashboard } from './utils'

const getValidWidgets = (widgets: DashboardWidgetState[] | undefined) => {
  return widgets?.map((widget) => ({
    ...omit(widget, '__typename'),
    analyticsConfig: {
      ...omit(widget.analyticsConfig, '__typename'),
      id: widget.analyticsConfig?.id ?? uuidv7(),
    },
  }))
}

const EDIT_DASHBOARD_MUTATION = graphql(/* GraphQL */ `
  mutation EditDashboardMutation(
    $dashboardId: ID!
    $payload: UpdateDashboardInput!
  ) {
    updateDashboard(id: $dashboardId, payload: $payload) {
      ...DashboardWithOwnerFields
    }
  }
`)

type EditDashboardParams = {
  id: string
  dashboard: Omit<Partial<DashboardState>, 'owner' | 'widgets'> & {
    owner?: Partial<DashboardState['owner']>
    widgets?: DashboardWidgetState[]
  }
}

export const useEditDashboard = (): [typeof editDashboard, typeof state] => {
  const [mutation, state] = useMutation(EDIT_DASHBOARD_MUTATION)

  const editDashboard = async ({ id, dashboard }: EditDashboardParams) => {
    const fragmentData = {
      ...dashboard,
      widgets: dashboard.widgets?.map((widget) => ({
        __typename: 'DashboardWidget',
        ...widget,
      })),
      owner: dashboard.owner
        ? {
            __typename: 'User',
            id: dashboard.owner.id ?? 'optimistic',
            externalId: dashboard.owner.externalId,
          }
        : undefined,
      updatedAt: new Date().toISOString(),
    } as unknown as DashboardWithOwnerFieldsFragment

    const editDashboardMutation = await mutation({
      variables: {
        dashboardId: id,
        payload: {
          name: dashboard.name,
          iconName: dashboard.iconName,
          iconColor: dashboard.iconColor,
          widgets: getValidWidgets(dashboard.widgets),
          dynamicDate: dashboard.dynamicDate,
          compareDynamicDate: dashboard.compareDynamicDate,
          compareStartDate: dashboard.compareStartDate,
          compareEndDate: dashboard.compareEndDate,
          startDate: dashboard.startDate,
          endDate: dashboard.endDate,
          filters: dashboard.filters,
          layout: dashboard.layout,
          teams: mapToVisibility(dashboard.visibility),
          ownerClerkUserId: dashboard.owner?.externalId,
        },
      },
      optimisticResponse: {
        updateDashboard: fragmentData,
      },
      update: (cache, { data }) => {
        if (!data?.updateDashboard) return

        updateUserDashboardsQuery({
          cache,
          dashboard: data.updateDashboard,
        })
      },
    })

    const result = editDashboardMutation.data?.updateDashboard

    if (!result) throw new Error(editDashboardMutation.errors?.[0].message)

    return transformDashboard(result)
  }

  return [editDashboard, state]
}

const updateUserDashboardsQuery = ({
  cache,
  dashboard,
}: {
  cache: ApolloCache<unknown>
  dashboard: DashboardWithOwnerFieldsFragment
}) => {
  const isOrganizationDashboard = getIsMerchantVisibility(
    dashboard.visibility[0],
  )

  cache.updateQuery({ query: MERCHANT_DASHBOARDS_QUERY }, (queryData) => {
    if (!queryData?.viewer) return queryData
    const { dashboards } = queryData.viewer.merchant
    const newDashboards = dashboards.filter((item) => item.id !== dashboard.id)
    const queryDataClone = cloneDeep(queryData)

    if (isOrganizationDashboard) {
      newDashboards.push(dashboard)
      set(queryDataClone, 'viewer.dashboards', newDashboards)
    } else {
      set(queryDataClone, 'viewer.dashboards', newDashboards)
    }

    return queryDataClone
  })
}
