import { type DropdownGroup } from 'components/Dropdown'
import { type Dimension } from 'graphql/statistics/useDimensions'
import {
  isAttributedMetric,
  useNormalizedMetrics,
  type Metric,
} from 'graphql/statistics/useMetrics'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import {
  getValidMetricProcessors,
  isValidMetric,
} from 'shared/utils/analyticsConfig'

export const useMetricGroups = ({
  dimensions,
  allowInvalidMetricCombinations = false,
  filteredMetrics,
  showDeselect,
}: {
  dimensions?: Dimension[]
  allowInvalidMetricCombinations?: boolean
  filteredMetrics?: Metric[]
  showDeselect?: boolean
}) => {
  const { dimensions: selectedDimensions } = useDimensionsState()
  const normalizedMetrics = useNormalizedMetrics()

  const populatedDimensions = dimensions ? dimensions : selectedDimensions

  const groups = useMemo(() => {
    const validMetricProcessors = getValidMetricProcessors(populatedDimensions)
    const allMetrics = filteredMetrics ?? Object.values(normalizedMetrics)
    // Group only base metrics
    const groupedMetrics = groupBy(
      allMetrics.filter((metric) => !isAttributedMetric(metric)),
      'groupId',
    )

    return Object.values(groupedMetrics).map((group) => ({
      name: group[0].groupLabel,
      showDeselect: !!showDeselect,
      items: group
        .filter((met): met is Metric => 'groupId' in met)
        .map((metric) => {
          let isDisabled = false

          if (!allowInvalidMetricCombinations && validMetricProcessors) {
            isDisabled = !isValidMetric(metric, validMetricProcessors)
          }

          return {
            ...metric,
            name: metric.label,
            id: metric.groupKey,
            metricId: metric.id,
            disabled: isDisabled,
          }
        }),
    })) satisfies DropdownGroup[]
  }, [
    populatedDimensions,
    filteredMetrics,
    normalizedMetrics,
    allowInvalidMetricCombinations,
    showDeselect,
  ])

  return groups
}
