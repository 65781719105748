import { Box } from '@chakra-ui/react'
import { type UniqueIdentifier } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { dashboardLayoutRowStateAtom } from 'features/dashboards/hooks/useDashboardLayoutState'
import {
  getCellWidthInColumns,
  getRelativeCellWidth,
} from 'features/dashboards/utils'
import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import { Fragment, useRef, useEffect } from 'react'
import {
  PanelGroup,
  PanelResizeHandle,
  type ImperativePanelGroupHandle,
} from 'react-resizable-panels'
import { SortableWidget } from '../SortableWidget'

interface ResizableRowWidgetCellsProps {
  cells: Array<{
    widgetId: string
    width: number
    minWidth: number
  }>
  rowId: string
  height: number
  onCellWidthsChange: (rowId: string, widths: number[]) => void
  activeCellIndex: number | undefined
  setActiveCellIndex: (index: number | undefined) => void
}

export const ResizableRowWidgetCells = ({
  cells,
  rowId,
  height,
  onCellWidthsChange,
  activeCellIndex,
  setActiveCellIndex,
}: ResizableRowWidgetCellsProps) => {
  const panelGroupRef = useRef<ImperativePanelGroupHandle>(null)
  const layoutMapRef = useRef<Map<UniqueIdentifier, number>>(new Map())
  const getRowState = useGetAtomFamilyValue(dashboardLayoutRowStateAtom)

  const handlePanelLayoutChange = (sizes: number[]) => {
    const layoutMap = layoutMapRef.current

    cells.forEach((cell, index) => {
      if (!cell?.widgetId) return

      const rawWidth = sizes[index]
      const width = getCellWidthInColumns(rawWidth)

      layoutMap.set(cell.widgetId, width)
    })
  }

  useEffect(() => {
    // Sync the layout when the cells state changes
    const layoutMap = layoutMapRef.current
    const panelGroup = panelGroupRef.current

    if (!panelGroup) return

    const sizes = cells.map((cell) => cell?.width)
    const relativeSizes = sizes.map(getRelativeCellWidth)

    panelGroup.setLayout(relativeSizes)

    cells.forEach((cell, index) => {
      layoutMap.set(cell?.widgetId, sizes[index])
    })
  }, [cells])

  useEffect(() => {
    // Sync the layout when the user is resizing the row manually
    const layoutMap = layoutMapRef.current

    if (layoutMap.size === 0 || !!activeCellIndex) return

    const panelGroup = panelGroupRef.current
    const rowState = getRowState(rowId)

    if (panelGroup && rowState) {
      const columnSizes = rowState.cells.map(
        (cell) => layoutMap.get(cell?.widgetId as string)!,
      )
      const relativeSizes = columnSizes.map(getRelativeCellWidth)

      panelGroup.setLayout(relativeSizes)
      onCellWidthsChange(rowId, columnSizes)
    }
  }, [activeCellIndex, rowId, onCellWidthsChange, getRowState])

  return (
    <SortableContext items={cells.map((cell) => cell?.widgetId)}>
      <PanelGroup
        ref={panelGroupRef}
        direction="horizontal"
        onLayout={handlePanelLayoutChange}
        style={{
          overflow: 'initial',
          boxSizing: 'border-box',
        }}
      >
        {cells.map((cell, index) => (
          <Fragment key={cell?.widgetId}>
            <SortableWidget
              widgetId={cell?.widgetId}
              width={cell?.width}
              minWidth={cell?.minWidth}
              height={height}
              index={index}
              rowId={rowId}
            />

            {index < cells.length - 1 && (
              <PanelResizeHandle
                onDragging={(isDragging) => {
                  setActiveCellIndex(isDragging ? index : undefined)
                }}
                style={{ display: 'flex' }}
              >
                <Box
                  px={'7px'}
                  opacity={activeCellIndex === index ? 1 : 0}
                  _hover={{ opacity: 1 }}
                  transition="opacity 0.1s ease-in-out"
                >
                  <Box height="100%" width="2px" backgroundColor="blue.500" />
                </Box>
              </PanelResizeHandle>
            )}
          </Fragment>
        ))}
      </PanelGroup>
    </SortableContext>
  )
}
