import { useAnalyticsConfigById } from 'graphql/reports/useAnalyticsConfigById'
import { ANALYTICS_CONFIG_SEARCH_PARAM_KEY } from 'graphql/reports/useCreateAnalyticsConfig'
import { useReportById } from 'graphql/reports/useReportById'
import { useCallback, useEffect } from 'react'
import { useNavigationType, useParams, NavigationType } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
  useNewAnalyticsConfigAtom,
  useSetAnalyticsConfigAtom,
} from 'shared/hooks/useAnalyticsConfigState'
import { ReportViewStatus } from '../atoms/reportViewStateAtom'
import { demaReports } from '../demaReports'
import { useIsNewReportFlow } from '../hooks/useIsNewReportFlow'
import {
  useNewReportState,
  useSetReportStateAtom,
} from '../hooks/useReportState'
import { useReportStatus, useSetReportStatus } from '../hooks/useReportStatus'

/**
 * `ReportStateSync` creates a unidirectional sync from the backend to the store.
 * This component fetches data from the backend and overrides the store with the fetched data.
 * The sync happens when starting the application, refreshing or when navigating with browser navigation. Once the state is in sync, all the changes are made on the state locally.
 * If reportId is a dema report, it sets that report to the state in the store. This is needed when refreshing the page.
 * If user is on new report page, and there is no report state set, it sets the report state to the new report state. This is needed when refreshing the page.
 * Having a special component to sync the state makes sure that only the necessary components are rendered. This is important for performance reasons.
 */
export const ReportStateSync = () => {
  const { id: reportId } = useParams()
  const [searchParams] = useSearchParams()
  const analyticsConfigId = searchParams.get(ANALYTICS_CONFIG_SEARCH_PARAM_KEY)

  const {
    report,
    query: { loading: isReportQueryLoading },
  } = useReportById(reportId)
  const {
    analyticsConfig,
    query: { loading: isAnalyticsQueryLoading },
  } = useAnalyticsConfigById(analyticsConfigId)

  const setReportState = useSetReportStateAtom()
  const setAnalyticsConfigState = useSetAnalyticsConfigAtom()
  const reportStatus = useReportStatus()
  const setReportStatus = useSetReportStatus()
  const isDemaReport = demaReports.find((report) => report.id === reportId)
  const isNewReportFlow = useIsNewReportFlow()
  const newReportState = useNewReportState()
  const newAnalyticsConfigState = useNewAnalyticsConfigAtom()
  const navigationType = useNavigationType()

  const updateReportState = useCallback(() => {
    if (isDemaReport) {
      setReportState(isDemaReport)
      setAnalyticsConfigState(isDemaReport.analyticsConfig)

      return
    }
    if (isNewReportFlow) {
      setReportState((prev) => {
        // The previous state will be undefined when refreshing the page. In that case, we default to the new state.
        // When navigating inside the app, the state will have been optimistically set with specific values so we don't override it.
        return prev ?? newReportState
      })
      setAnalyticsConfigState((prev) => prev ?? newAnalyticsConfigState)

      return
    }
    if (!isReportQueryLoading && !isAnalyticsQueryLoading) {
      setReportState(report)
      setAnalyticsConfigState(analyticsConfig ?? report?.analyticsConfig)
    }
  }, [
    isReportQueryLoading,
    report,
    setReportState,
    isDemaReport,
    isNewReportFlow,
    newReportState,
    setAnalyticsConfigState,
    newAnalyticsConfigState,
    analyticsConfig,
    isAnalyticsQueryLoading,
  ])

  // Sync report and analytics config state
  useEffect(() => {
    if (reportStatus === ReportViewStatus.InSync) {
      return
    }
    updateReportState()
  }, [reportStatus, updateReportState])

  // Sets status
  useEffect(() => {
    if (!isReportQueryLoading && !isAnalyticsQueryLoading) {
      const hasNotFoundReport = reportId && !report
      const hasNotFoundAnalytics = analyticsConfigId && !analyticsConfig
      const missingAnalyticsConfigId = !analyticsConfigId

      if (
        !isDemaReport &&
        ((hasNotFoundReport && hasNotFoundAnalytics) ||
          (missingAnalyticsConfigId && hasNotFoundReport))
      ) {
        setReportStatus(ReportViewStatus.NotFound)

        return
      }

      setReportStatus(ReportViewStatus.InSync)
    }
  }, [
    analyticsConfigId,
    reportId,
    report,
    analyticsConfig,
    isReportQueryLoading,
    isAnalyticsQueryLoading,
    setReportStatus,
    isDemaReport,
  ])

  // Handles browser navigation after state has been synced. This is needed to update the state when users navigate back or forward (POP action)
  // POP is the default initial state. That is why we make sure that the app is InSync before triggering this effect.
  useEffect(() => {
    if (
      navigationType === NavigationType.Pop &&
      reportStatus === ReportViewStatus.InSync
    ) {
      updateReportState()
    }
  }, [navigationType, reportStatus, updateReportState])

  return null
}
