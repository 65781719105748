import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import {
  getMetricKeyWithoutAttribution,
  normalizedMetricsAtom,
} from 'graphql/statistics/useMetrics'
import { atom, type SetStateAction, useAtom, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { dimensionsStateAtom } from 'shared/hooks/useDimensionsState'
import { metricsStateAtom } from 'shared/hooks/useMetricsState'
import {
  getActualDimensions,
  getUniqueMetrics,
  getValidMetrics,
} from 'shared/utils/analyticsConfig'

export enum DimensionMetricSelectorDrawerOpenState {
  DIMENSION = 'dimension',
  METRIC = 'metric',
  HIDDEN = 'hidden',
}

const dimensionMetricSelectorDrawerOpenAtom =
  atom<DimensionMetricSelectorDrawerOpenState>(
    DimensionMetricSelectorDrawerOpenState.HIDDEN,
  )

export const useDimensionMetricSelectorDrawerOpenAtom = () =>
  useAtom(dimensionMetricSelectorDrawerOpenAtom)
export const useDimensionMetricSelectorDrawerSetAtom = () =>
  useSetAtom(dimensionMetricSelectorDrawerOpenAtom)

const dimensionMetricSelectorHoveredItemAtom = atom<string | null>(null)

export const useDimensionMetricSelectorHoveredItemAtom = () =>
  useAtom(dimensionMetricSelectorHoveredItemAtom)
export const useDimensionMetricSelectorHoveredItemSetAtom = () =>
  useSetAtom(dimensionMetricSelectorHoveredItemAtom)

const draftDimensionsAtom = atom<string[] | undefined>(undefined)
const draftMetricsAtom = atom<string[] | undefined>(undefined)

const draftDimensions = atom(
  (get) => {
    const draftDimensions = get(draftDimensionsAtom)
    const { dimensionKeys } = get(dimensionsStateAtom)

    return draftDimensions ?? dimensionKeys
  },
  (_, set, draftDimensions: SetStateAction<string[] | undefined>) => {
    set(draftDimensionsAtom, draftDimensions)
  },
)

const draftMetrics = atomFamily((allowInvalidMetricCombinations?: boolean) =>
  atom(
    (get) => {
      const draftMetrics = get(draftMetricsAtom)
      const { uniqueMetricKeys } = get(
        metricsStateAtom(allowInvalidMetricCombinations),
      )

      const normalizedMetrics = get(normalizedMetricsAtom)
      const dimensionIds = get(draftDimensions)
      const normalizedDimensions = get(normalizedDimensionsAtom)
      const dimensions = getActualDimensions(dimensionIds, normalizedDimensions)
      const metrics = draftMetrics ?? uniqueMetricKeys

      const validMetrics = getValidMetrics(
        metrics,
        normalizedMetrics,
        dimensions,
        allowInvalidMetricCombinations,
      )

      const uniqueMetrics = getUniqueMetrics(validMetrics)

      return uniqueMetrics.map(getMetricKeyWithoutAttribution)
    },
    (_, set, draftMetrics: SetStateAction<string[] | undefined>) => {
      set(draftMetricsAtom, draftMetrics)
    },
  ),
)

export const useDraftDimensions = () => useAtom(draftDimensions)
export const useDraftMetrics = (allowInvalidMetricCombinations?: boolean) =>
  useAtom(draftMetrics(allowInvalidMetricCombinations))
export const useSetDraftDimensions = () => useSetAtom(draftDimensions)
export const useSetDraftMetrics = (allowInvalidMetricCombinations?: boolean) =>
  useSetAtom(draftMetrics(allowInvalidMetricCombinations))
