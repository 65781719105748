import { type DateRange } from 'constants/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { toZonedTime } from 'date-fns-tz'
import { merchantInfoAtom } from 'graphql/useMerchantInfo'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { useMemo } from 'react'
import { type DateState } from 'shared/hooks/useDateState'
import {
  getPresetFromUrlCompareDynamicDate,
  getPresetFromUrlDynamicDate,
} from 'shared/utils/analyticsConfig'
import { widgetAnalyticsConfigAtom } from '../atoms/dashboardViewState'
import { dashboardDateStateAtom } from './filters/useDashboardDateState'

const focusWidgetStartDateStateAtom = atomFamily(
  (widgetId: string | undefined) =>
    focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
      optic.optional().prop('startDate'),
    ),
)

const focusWidgetEndDateStateAtom = atomFamily((widgetId: string | undefined) =>
  focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
    optic.optional().prop('endDate'),
  ),
)

const focusWidgetDynamicDateStateAtom = atomFamily(
  (widgetId: string | undefined) =>
    focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
      optic.optional().prop('dynamicDate'),
    ),
)

const focusWidgetCompareStartDateStateAtom = atomFamily(
  (widgetId: string | undefined) =>
    focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
      optic.optional().prop('compareStartDate'),
    ),
)

const focusWidgetCompareEndDateStateAtom = atomFamily(
  (widgetId: string | undefined) =>
    focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
      optic.optional().prop('compareEndDate'),
    ),
)

const focusWidgetCompareDynamicDateStateAtom = atomFamily(
  (widgetId: string | undefined) =>
    focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
      optic.optional().prop('compareDynamicDate'),
    ),
)

const originalWidgetDateStateAtom = atomFamily((widgetId: string | undefined) =>
  atom((get) => {
    const { timezone } = get(merchantInfoAtom)
    const startDate = get(focusWidgetStartDateStateAtom(widgetId))
    const endDate = get(focusWidgetEndDateStateAtom(widgetId))
    const dynamicDate = get(focusWidgetDynamicDateStateAtom(widgetId))
    const compareStartDate = get(focusWidgetCompareStartDateStateAtom(widgetId))
    const compareEndDate = get(focusWidgetCompareEndDateStateAtom(widgetId))
    const compareDynamicDate = get(
      focusWidgetCompareDynamicDateStateAtom(widgetId),
    )

    const dynamicDatePreset = getPresetFromUrlDynamicDate(dynamicDate)
    const validDateRange: DateRange = [
      dynamicDatePreset?.value[0] ??
        (startDate ? toZonedTime(startDate, timezone) : null),
      dynamicDatePreset?.value[1] ??
        (endDate ? toZonedTime(endDate, timezone) : null),
    ]

    const compareDynamicDatePreset = getPresetFromUrlCompareDynamicDate(
      compareDynamicDate,
      validDateRange,
    )
    const validCompareDateRange: DateRange = [
      compareDynamicDatePreset?.value[0] ??
        (compareStartDate ? toZonedTime(compareStartDate, timezone) : null),
      compareDynamicDatePreset?.value[1] ??
        (compareEndDate ? toZonedTime(compareEndDate, timezone) : null),
    ]

    const isCompare = !!validCompareDateRange[0] && !!validCompareDateRange[1]

    return {
      dateRange: validDateRange,
      dynamicDate: dynamicDatePreset?.id,
      compareDateRange: validCompareDateRange,
      compareDynamicDate: compareDynamicDatePreset?.id,
      isCompare,
    }
  }),
)

export const widgetDateStateAtom = atomFamily((widgetId: string | undefined) =>
  atom(
    (get) => {
      const dashboardDateState = get(dashboardDateStateAtom)
      const widgetDateState = get(originalWidgetDateStateAtom(widgetId))

      const resolvedDateState = {
        dateRange:
          widgetDateState.dateRange[0] !== null ||
          widgetDateState.dateRange[1] !== null
            ? widgetDateState.dateRange
            : dashboardDateState.dateRange,
        dynamicDate:
          widgetDateState.dynamicDate ?? dashboardDateState.dynamicDate,
        compareDateRange:
          widgetDateState.compareDateRange[0] !== null ||
          widgetDateState.compareDateRange[1] !== null
            ? widgetDateState.compareDateRange
            : dashboardDateState.compareDateRange,
        compareDynamicDate:
          widgetDateState.compareDynamicDate ??
          dashboardDateState.compareDynamicDate,
        isCompare: widgetDateState.isCompare ?? dashboardDateState.isCompare,
      }

      return {
        resolvedDateState,
        widgetDateState,
      }
    },
    (
      _,
      set,
      {
        dateRange,
        dynamicDate,
        compareDateRange,
        compareDynamicDate,
      }: Omit<DateState, 'isCompare'>,
    ) => {
      const [compareStartDate, compareEndDate] = compareDateRange

      if (getIsPopulatedDateRange(dateRange)) {
        set(focusWidgetStartDateStateAtom(widgetId), dateRange[0].toISOString())
        set(focusWidgetEndDateStateAtom(widgetId), dateRange[1].toISOString())
      }

      set(
        focusWidgetCompareStartDateStateAtom(widgetId),
        compareStartDate ? compareStartDate.toISOString() : null,
      )
      set(
        focusWidgetCompareEndDateStateAtom(widgetId),
        compareEndDate ? compareEndDate.toISOString() : null,
      )
      set(focusWidgetDynamicDateStateAtom(widgetId), dynamicDate)
      set(
        focusWidgetCompareDynamicDateStateAtom(widgetId),
        (compareDynamicDate as string) ?? null,
      )
    },
  ),
)

export const useWidgetDateState = (widgetId: string | undefined) => {
  const dateStateAtom = useMemo(() => widgetDateStateAtom(widgetId), [widgetId])
  const dateState = useAtomValue(dateStateAtom)

  return dateState
}

export const useSetWidgetDateState = (widgetId: string | undefined) => {
  const dateStateAtom = useMemo(() => widgetDateStateAtom(widgetId), [widgetId])
  const setWidgetDateState = useSetAtom(dateStateAtom)

  return setWidgetDateState
}
