import { type ApolloCache, useMutation } from '@apollo/client'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { graphql } from 'generated/graphql'
import {
  type ReportLabel,
  type ReportWithOwnerFieldsFragment,
  type Team,
} from 'generated/graphql/graphql'
import { getIsMerchantVisibility, mapToVisibility } from 'graphql/shared/utils'
import { MERCHANT_TEAMS_QUERY } from 'graphql/teams/useMerchantTeams'
import { cloneDeep, set } from 'lodash-es'
import { type AnalyticsConfigState } from 'shared/atoms/analyticsConfigState'
import { MERCHANT_REPORTS_QUERY } from './useMerchantReports'
import { REPORT_LABELS_QUERY } from './useReportLabels'
import { transformReport } from './utils'

const EDIT_REPORT_MUTATION = graphql(/* GraphQL */ `
  mutation EditReportMutation($reportId: ID!, $payload: EditReportInput!) {
    editReport(id: $reportId, payload: $payload) {
      ...ReportWithOwnerFields
    }
  }
`)

type EditReportParams = {
  id: string
  report: Omit<Partial<ReportState>, 'owner'> & {
    owner?: Partial<ReportState['owner']>
  }
  analyticsConfig?: Partial<AnalyticsConfigState>
}

export const useEditReport = (): [typeof editReport, typeof state] => {
  const [mutation, state] = useMutation(EDIT_REPORT_MUTATION)

  const editReport = async ({
    id,
    report,
    analyticsConfig,
  }: EditReportParams) => {
    const labelIds = report.labels?.map((label) => label.id)
    const fragmentData = {
      ...report,
      owner: report.owner
        ? {
            __typename: 'User',
            id: report.owner.id ?? 'optimistic',
            externalId: report.owner.externalId,
          }
        : undefined,
      updatedAt: new Date().toISOString(),
    } as unknown as ReportWithOwnerFieldsFragment

    const editReportMutation = await mutation({
      variables: {
        reportId: id,
        payload: {
          name: report.name,
          description: report.description,
          analyticsConfigId: analyticsConfig?.id,
          labels: labelIds,
          teams: mapToVisibility(report.visibility),
          ownerClerkUserId: report.owner?.externalId,
        },
      },
      optimisticResponse: {
        editReport: fragmentData,
      },
      update: (cache, { data }) => {
        if (!data?.editReport) return
        // Update merchant reports
        updateMerchantReportsQuery({ cache, report: data.editReport })
        // Update team reports
        updateTeamReportsQueries({ cache, report: data.editReport })
        // Update report labels
        updateReportLabelsQuery({ cache, report: data.editReport })
      },
    })

    const result = editReportMutation.data?.editReport

    if (!result) throw new Error(editReportMutation.errors?.[0].message)

    return transformReport(result)
  }

  return [editReport, state]
}

const updateMerchantReportsQuery = ({
  cache,
  report,
}: {
  cache: ApolloCache<unknown>
  report: ReportWithOwnerFieldsFragment
}) => {
  const isOrganizationReport = getIsMerchantVisibility(report.visibility[0])

  cache.updateQuery({ query: MERCHANT_REPORTS_QUERY }, (queryData) => {
    if (!queryData?.viewer) return queryData

    const { reports } = queryData.viewer.merchant
    const newReports = reports.filter((item) => item.id !== report.id)
    const clonedQueryData = cloneDeep(queryData)

    if (isOrganizationReport) {
      newReports.push(report)
      set(clonedQueryData, 'viewer.merchant.reports', newReports)
    } else {
      set(clonedQueryData, 'viewer.merchant.reports', newReports)
    }

    return clonedQueryData
  })
}

const updateTeamReportsQueries = ({
  cache,
  report,
}: {
  cache: ApolloCache<unknown>
  report: ReportWithOwnerFieldsFragment
}) => {
  const isOrganizationReport = getIsMerchantVisibility(report.visibility[0])
  const merchantQuery = cache.readQuery({
    query: MERCHANT_TEAMS_QUERY,
  })
  const reportCacheId = cache.identify(report)
  const reportVisibilitySet = new Set(report.visibility.map(({ id }) => id))

  // Go through each team and update the reports
  for (const team of merchantQuery?.viewer?.merchant.teams ?? []) {
    cache.modify<Team>({
      id: cache.identify(team),
      fields: {
        reports(existingReportRefs = [], { toReference }) {
          // If the report is an organization report or does not have the team in the visibility, remove it from the team reports
          if (isOrganizationReport || !reportVisibilitySet.has(team.id)) {
            return existingReportRefs.filter(
              (ref) => toReference(ref)?.__ref !== reportCacheId,
            )
          }

          // If the report has this team in its visibility and it's not already in the team reports, add it
          if (
            !existingReportRefs.some(
              (ref) => toReference(ref)?.__ref === reportCacheId,
            )
          ) {
            const newRef = toReference(reportCacheId ?? '')

            if (newRef) {
              return [...existingReportRefs, newRef]
            }
          }

          return existingReportRefs
        },
      },
    })
  }
}

const updateReportLabelsQuery = ({
  cache,
}: {
  cache: ApolloCache<unknown>
  report: ReportWithOwnerFieldsFragment
}) => {
  const labelsQuery = cache.readQuery({
    query: REPORT_LABELS_QUERY,
  })

  // Calculating the report count for each label and knowing the previous and new labels for the report adds a lot of complexity so it is better to simply remove the report count. The report count will be updated when the report labels are fetched again.
  for (const label of labelsQuery?.viewer?.merchant.reportLabels ?? []) {
    cache.modify<ReportLabel>({
      id: cache.identify(label),
      fields: {
        reportCount(_, { DELETE }) {
          return DELETE
        },
      },
    })
  }
}
