import { useUser } from '@clerk/clerk-react'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { type OwnerFieldsFragment } from 'generated/graphql/graphql'
import { getIsTemplateReport } from 'graphql/reports/utils'
import {
  RESOURCE_VISIBILITY,
  type ResourceVisibility,
  getResourceVisibilityType,
} from 'graphql/shared/utils'
import { useIsAdminUser } from 'hooks/useIsAdminUser'
import { useIsGuestUser } from 'hooks/useIsGuestUser'

export type UseCanEditResourceParams = {
  visibility?: ResourceVisibility[]
  owner?: OwnerFieldsFragment
}

type CanEditResourceParams = {
  visibility?: ResourceVisibility[]
  owner?: OwnerFieldsFragment
  isGuestUser?: boolean
  userId?: string
}

const getResourceAccess = ({
  visibility,
  owner,
  userId,
}: CanEditResourceParams) => {
  if (!visibility || !owner)
    return { isOwner: false, isPrivate: false, isTemplateReport: false }

  const isOwner = owner.id === userId
  const isPrivate =
    getResourceVisibilityType(visibility) === RESOURCE_VISIBILITY.PRIVATE
  const isTemplateReport = getIsTemplateReport(owner)

  return { isOwner, isPrivate, isTemplateReport }
}

export const getCanEditResource = ({
  visibility,
  owner,
  isGuestUser,
  userId,
}: CanEditResourceParams) => {
  const { isOwner, isPrivate, isTemplateReport } = getResourceAccess({
    visibility,
    owner,
    isGuestUser,
    userId,
  })

  // Logic is:
  // 1. If report is template report, no one can edit it
  // 2. If user is owner, they always have edit access no matter what
  // 3. If user is a guest, they never have edit access to other reports
  // 4. If the report is private, only the owner has edit access
  return !isTemplateReport && (isOwner || (!isGuestUser && !isPrivate))
}

export const getCanEditResourceOwner = ({
  visibility,
  owner,
  isAdminUser,
  userId,
  isGuestUser,
}: {
  visibility?: ReportState['visibility']
  owner?: ReportState['owner']
  isAdminUser: boolean
  userId?: string
  isGuestUser?: boolean
}) => {
  const { isPrivate } = getResourceAccess({
    visibility,
    owner,
    userId,
  })

  if (isAdminUser && !isPrivate) return true

  return getCanEditResource({ visibility, owner, isGuestUser, userId })
}

export const useCanEditResource = ({
  visibility,
  owner,
}: UseCanEditResourceParams) => {
  const isGuestUser = useIsGuestUser()
  const { user } = useUser()

  return getCanEditResource({
    visibility,
    owner,
    isGuestUser,
    userId: user?.publicMetadata.dema_id,
  })
}

export const useCanEditResourceOwner = ({
  visibility,
  owner,
}: UseCanEditResourceParams) => {
  const isAdminUser = useIsAdminUser()

  return getCanEditResourceOwner({ visibility, owner, isAdminUser })
}
