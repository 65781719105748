import { Button } from 'components/buttons'
import { SegmentationHeaderWrapper } from 'features/segmentations/shared/SegmentationHeaderWrapper'
import { AddCustomerSetModal } from './AddCustomerSetModal'

export const CustomerSetsHeader: React.FC = () => {
  return (
    <SegmentationHeaderWrapper>
      <SegmentationHeaderWrapper.Title>
        Customer sets
      </SegmentationHeaderWrapper.Title>

      <AddCustomerSetModal
        modalButton={
          <Button
            size="sm"
            leadingIcon={{ name: 'PlusSmallIcon' }}
            colorScheme="gray"
          >
            New customer set
          </Button>
        }
      />
    </SegmentationHeaderWrapper>
  )
}
