import { useMutation, type ApolloCache } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type AnalyticsConfigInput,
  type DashboardWithOwnerFieldsFragment,
} from 'generated/graphql/graphql'
import { getIsMerchantVisibility, mapToVisibility } from 'graphql/shared/utils'
import { cloneDeep, set } from 'lodash-es'
import { useCallback } from 'react'
import { type DashboardWidgetResult, type DashboardResult } from '../types'
import { MERCHANT_DASHBOARDS_QUERY } from './useMerchantDashboards'
import { USER_DASHBOARDS_QUERY } from './useUserDashboards'

const CREATE_DASHBOARD_MUTATION = graphql(/* GraphQL */ `
  mutation CreateDashboardMutation($payload: CreateDashboardInput!) {
    createDashboard(payload: $payload) {
      ...DashboardWithOwnerFields
    }
  }
`)

type CreateDashboardWidgetParams = Pick<
  DashboardWidgetResult,
  'id' | 'name' | 'type'
> & {
  analyticsConfig?: AnalyticsConfigInput
}

export type CreateDashboardParams = {
  dashboard: Pick<
    DashboardResult,
    | 'name'
    | 'iconName'
    | 'iconColor'
    | 'dynamicDate'
    | 'compareDynamicDate'
    | 'compareStartDate'
    | 'compareEndDate'
    | 'startDate'
    | 'endDate'
    | 'filters'
    | 'layout'
    | 'visibility'
  > & {
    widgets?: CreateDashboardWidgetParams[]
  }
}

interface UseCreateDashboardParams {
  skipUpdateUserDashboardsQuery?: boolean
}

export const useCreateDashboard = (
  options?: UseCreateDashboardParams,
): [typeof createDashboard, typeof state] => {
  const [mutation, state] = useMutation(CREATE_DASHBOARD_MUTATION)

  const createDashboard = useCallback(
    async ({ dashboard }: CreateDashboardParams): Promise<DashboardResult> => {
      const createMutation = await mutation({
        variables: {
          payload: {
            name: dashboard.name,
            iconName: dashboard.iconName,
            iconColor: dashboard.iconColor,
            endDate: dashboard.endDate,
            filters: dashboard.filters,
            layout: dashboard.layout,
            startDate: dashboard.startDate,
            dynamicDate: dashboard.dynamicDate,
            compareDynamicDate: dashboard.compareDynamicDate,
            compareStartDate: dashboard.compareStartDate,
            compareEndDate: dashboard.compareEndDate,
            teams: mapToVisibility(dashboard.visibility),
            widgets: dashboard.widgets ?? [],
          },
        },
        optimisticResponse: {
          createDashboard: {
            ...dashboard,
            id: 'optimistic',
            __typename: 'Dashboard',
            updatedAt: new Date().toISOString(),
          } as unknown as DashboardWithOwnerFieldsFragment,
        },
        update: (cache, { data }) => {
          if (!data?.createDashboard) return

          if (!options?.skipUpdateUserDashboardsQuery) {
            updateMerchantDashboardsQuery({
              cache,
              dashboard: data.createDashboard,
            })
            updateUserDashboardsQuery({
              cache,
              dashboard: data.createDashboard,
            })
          }
        },
        onCompleted: ({ createDashboard }) => {
          if (!createDashboard) return

          // @TODO(manuel): add tracking
        },
      })

      const result = createMutation.data?.createDashboard

      if (!result) throw new Error(createMutation.errors?.[0].message)

      return result as unknown as DashboardResult
    },
    [mutation, options?.skipUpdateUserDashboardsQuery],
  )

  return [createDashboard, state]
}

export const updateMerchantDashboardsQuery = ({
  cache,
  dashboard,
}: {
  cache: ApolloCache<unknown>
  dashboard: DashboardWithOwnerFieldsFragment
}) => {
  const isOrganizationDashboard = getIsMerchantVisibility(
    dashboard.visibility[0],
  )

  if (isOrganizationDashboard) {
    cache.updateQuery({ query: MERCHANT_DASHBOARDS_QUERY }, (queryData) => {
      if (!queryData?.viewer) return queryData
      const { dashboards } = queryData.viewer.merchant
      const queryDataClone = cloneDeep(queryData)

      set(queryDataClone, 'viewer.dashboards', [...dashboards, dashboard])

      return queryDataClone
    })
  }
}

export const updateUserDashboardsQuery = ({
  cache,
  dashboard,
}: {
  cache: ApolloCache<unknown>
  dashboard: DashboardWithOwnerFieldsFragment
}) => {
  cache.updateQuery({ query: USER_DASHBOARDS_QUERY }, (queryData) => {
    if (!queryData?.viewer) return queryData

    const queryDataClone = cloneDeep(queryData)

    set(queryDataClone, 'viewer.dashboards', [
      ...queryData.viewer.dashboards,
      dashboard,
    ])

    return queryDataClone
  })
}
