import { useApolloClient } from '@apollo/client'
import { useUser } from '@clerk/clerk-react'
import {
  updateUserDashboardsQuery,
  updateMerchantDashboardsQuery,
  useCreateDashboard,
} from 'features/dashboards/graphql/useCreateDashboard'
import { useNewDashboardState } from 'features/dashboards/hooks/useDashboardState'
import {
  DASHBOARD_PAGE_TITLES,
  DASHBOARD_PAGES,
} from 'features/dashboards/routePages'
import { type DashboardWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useUserDashboards } from '../../graphql/useUserDashboards'
import { DashboardListTable } from './components/DashboardListTable'

export const UserDashboardsView = () => {
  const { dashboards, loading } = useUserDashboards()
  const { user } = useUser()
  const [createDashboard, { loading: isCreatingDashboard }] =
    useCreateDashboard({
      skipUpdateUserDashboardsQuery: true,
    })
  const client = useApolloClient()
  const newDashboardState = useNewDashboardState()
  const navigate = useNavigate()

  const handleCreateDashboard = async () => {
    const createdDashboard = await createDashboard({
      dashboard: {
        ...newDashboardState,
        visibility: newDashboardState.visibility ?? [],
        widgets: [],
      },
    })

    navigate(DASHBOARD_PAGES.DASHBOARD_BY_ID(createdDashboard.id))

    updateUserDashboardsQuery({
      cache: client.cache,
      dashboard: createdDashboard as DashboardWithOwnerFieldsFragment,
    })

    updateMerchantDashboardsQuery({
      cache: client.cache,
      dashboard: createdDashboard as DashboardWithOwnerFieldsFragment,
    })
  }

  const privateDashboards = useMemo(() => {
    return dashboards.filter(
      (dashboard) => dashboard.owner.id === user?.publicMetadata.dema_id,
    )
  }, [dashboards, user?.publicMetadata.dema_id])

  return (
    <DashboardListTable
      pageTitle={DASHBOARD_PAGE_TITLES.MY}
      dashboards={privateDashboards}
      isLoading={loading}
      addDashboardButtonProps={{
        onClick: handleCreateDashboard,
        isLoading: isCreatingDashboard,
      }}
    />
  )
}
