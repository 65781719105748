import { MenuItem, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { type DashboardState } from 'features/dashboards/atoms/dashboardViewState'
import { useCreateDashboard } from 'features/dashboards/graphql/useCreateDashboard'
import { useSetDashboardState } from 'features/dashboards/hooks/useDashboardState'
import { DASHBOARD_PAGES } from 'features/dashboards/routePages'
import { createDashboardCopy } from 'features/dashboards/utils'
import { useNavigate } from 'react-router'

interface DuplicateDashboardMenuItemProps {
  dashboard: DashboardState
}

export const DuplicateDashboardMenuItem = ({
  dashboard,
}: DuplicateDashboardMenuItemProps) => {
  const navigate = useNavigate()
  const [createDashboard, createDashboardState] = useCreateDashboard()
  const setDashboardState = useSetDashboardState()
  const toast = useToast()

  const createDuplicatedDashboard = async () => {
    if (!dashboard.id) return

    try {
      const { layout, widgets } = createDashboardCopy(dashboard)

      const newDashboard = await createDashboard({
        dashboard: {
          ...dashboard,
          name: `Copy of ${dashboard.name}`,
          visibility: dashboard.visibility ?? [],
          layout,
          widgets,
        },
      })

      toast({
        status: 'success',
        description: 'Successfully duplicated dashboard',
      })
      setDashboardState(newDashboard)

      navigate(DASHBOARD_PAGES.DASHBOARD_BY_ID(newDashboard.id))
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not create dashboard',
      })
    }
  }

  return (
    <MenuItem
      onClick={createDuplicatedDashboard}
      disabled={createDashboardState.loading}
      icon={<Icon name="CopyIcon" size="small" />}
    >
      Duplicate
    </MenuItem>
  )
}
