import { useEffect } from 'react'
import { useParams } from 'react-router'
import { DashboardViewStatus } from '../atoms/dashboardViewState'
import { useDashboardById } from '../graphql/useDashboardById'
import {
  useSetDashboardState,
  useSetDashboardStatus,
} from '../hooks/useDashboardState'

/**
 * `DashboardStateSync` uses a subscription to the Apollo cache to keep the dashboard state
 * in sync when changes occur
 */
export const DashboardStateSync = () => {
  const { id: dashboardId } = useParams()

  const {
    dashboard,
    query: { loading: isDashboardQueryLoading },
  } = useDashboardById(dashboardId)

  const setDashboardState = useSetDashboardState()
  const setDashboardStatus = useSetDashboardStatus()

  useEffect(() => {
    if (!isDashboardQueryLoading) {
      const dashboardNotFound = dashboardId && !dashboard

      if (dashboardNotFound) {
        setDashboardStatus(DashboardViewStatus.NotFound)

        return
      }

      setDashboardStatus(DashboardViewStatus.InSync)
      setDashboardState(dashboard)
    }
  }, [
    dashboardId,
    dashboard,
    isDashboardQueryLoading,
    setDashboardStatus,
    setDashboardState,
  ])

  return null
}
