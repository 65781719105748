import { useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { useMemo } from 'react'
import {
  dashboardWidgetsAtom,
  type DashboardWidgetState,
  newWidgetAtom,
  selectedWidgetStateAtom,
  widgetAtomFamily,
  widgetStatusAtomFamily,
} from '../atoms/dashboardViewState'
import { type WidgetType } from '../types'

export const selectedWidgetTypeAtom = focusAtom(
  selectedWidgetStateAtom,
  (optic) => optic.optional().prop('type'),
)

export const selectedWidgetIdAtom = focusAtom(
  selectedWidgetStateAtom,
  (optic) => optic.optional().prop('id'),
)

export const useSelectedWidgetState = () =>
  useAtomValue(selectedWidgetStateAtom)

export const useSetSelectedWidgetState = () =>
  useSetAtom(selectedWidgetStateAtom)

export const useNewWidgetState = (widgetType: WidgetType) => {
  const atom = useMemo(() => newWidgetAtom(widgetType), [widgetType])

  return useAtomValue(atom)
}

export const widgetTypeByWidgetIdAtom = atomFamily((widgetId?: string) =>
  focusAtom(widgetAtomFamily(widgetId), (optic) =>
    optic.optional().prop('type'),
  ),
)

export const widgetNameByWidgetIdAtom = atomFamily((widgetId?: string) =>
  focusAtom(widgetAtomFamily(widgetId), (optic) =>
    optic.optional().prop('name'),
  ),
)

export const useWidgetById = (widgetId?: string) => {
  const widgetAtom = useMemo(() => widgetAtomFamily(widgetId), [widgetId])

  return useAtomValue(widgetAtom)
}

export const useSetWidgetById = (widgetId?: string) => {
  const widgetAtom = useMemo(() => widgetAtomFamily(widgetId), [widgetId])

  return useSetAtom(widgetAtom)
}

export const useWidgetType = (widgetId?: string) => {
  const widgetTypeAtom = useMemo(
    () => widgetTypeByWidgetIdAtom(widgetId),
    [widgetId],
  )

  return useAtomValue(widgetTypeAtom)
}

export const useWidgetStatus = (widgetId: string | undefined) => {
  const widgetStatusAtom = useMemo(
    () => widgetStatusAtomFamily(widgetId),
    [widgetId],
  )

  return useAtomValue(widgetStatusAtom)
}

export const useSetWidgetStatus = (widgetId: string | undefined) => {
  const widgetStatusAtom = useMemo(
    () => widgetStatusAtomFamily(widgetId),
    [widgetId],
  )

  return useSetAtom(widgetStatusAtom)
}
export const useWidgetOperations = () => {
  const setDashboardWidgets = useSetAtom(dashboardWidgetsAtom)

  const addWidget = (widget: DashboardWidgetState) => {
    setDashboardWidgets((prev) => [...prev, widget])
  }

  const deleteWidget = (widgetId: string) => {
    setDashboardWidgets((prev) =>
      prev.filter((widget) => widget.id !== widgetId),
    )
  }

  return { addWidget, deleteWidget }
}
