import {
  Box,
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Image,
  Th,
  Thead,
  Tr,
  useBreakpoint,
} from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  type ColumnSort,
} from '@tanstack/react-table'
import { Button } from 'components/buttons'
import { type ButtonProps } from 'components/buttons/Button'
import { Icon } from 'components/Icon/Icon'
import { Input } from 'components/Input'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { DashboardHeaderContainer } from 'features/dashboards/components/DashboardHeader/DashboardHeaderContainer'
import { DASHBOARD_PAGES } from 'features/dashboards/routePages'
import { type DashboardResult } from 'features/dashboards/types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { VISIBILITY_ICON_BG_VARIABLE } from 'shared/ResourceVisibility/OverlappingIcons'
import { colorTheme } from 'ui/theme/colors'
import { useDashboardListColumns } from '../hooks/useDashboardListColumns'

const EmptyTable = () => {
  return (
    <Tr>
      <Td border="unset">
        <Flex
          flexDir="column"
          gap={6}
          maxW="380px"
          justifyContent="center"
          mx="auto"
        >
          <Image
            src="/images/EmptyDashboards.svg"
            alt="No dashboards"
            boxSize={120}
          />
          <Box>
            <Typography fontSize="md" fontWeight={500} mb={2}>
              Dashboards
            </Typography>
            <Typography>
              Dashboards let you visualize your key data in one view. They are
              comprised of different widgets such as charts, KPI:s and tables.
            </Typography>
          </Box>
        </Flex>
      </Td>
    </Tr>
  )
}

interface DashboardListTableProps {
  pageTitle?: string
  dashboards: DashboardResult[]
  isLoading?: boolean
  addDashboardButtonProps?: ButtonProps
}

export const DashboardListTable = ({
  pageTitle = 'Dashboards',
  dashboards,
  isLoading: isLoadingProp,
  addDashboardButtonProps,
}: DashboardListTableProps) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([])

  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })

  const columns = useDashboardListColumns()

  const table = useReactTable({
    data: dashboards,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const isLoading = isLoadingProp || memberships?.isLoading

  const { rows } = table.getRowModel()
  const isEmpty = rows.length === 0

  const [isLargerThanLg] = useBreakpoint('lg')

  return (
    <Box w="full">
      <Table h={!isLoading && isEmpty ? 'full' : 'auto'}>
        <Thead zIndex={1} position="sticky" top={0} bg="white">
          <Tr>
            <Th border="unset" p={0} colSpan={columns.length}>
              <DashboardHeaderContainer
                flexWrap={!isLargerThanLg ? 'wrap' : 'nowrap'}
                borderBottom={0}
              >
                <Flex alignItems="center" gap={6}>
                  <Typography fontSize="md" fontWeight={600}>
                    {pageTitle}
                  </Typography>
                </Flex>
                <Flex alignItems="center" gap={2}>
                  {/* @TODO(manuel): Implement search functionality */}
                  <Input
                    size="sm"
                    leadingIcon={{
                      name: 'MagnifyingGlassIcon',
                    }}
                    placeholder="Search dashboards"
                  />
                  <Button {...addDashboardButtonProps} size="sm">
                    Add dashboard
                  </Button>
                </Flex>
              </DashboardHeaderContainer>
              {!isEmpty && <Divider />}
            </Th>
          </Tr>
          {(isLoading || !isEmpty) &&
            table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSorted = header.column.getIsSorted()
                  const headerName = flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )

                  return (
                    <Th
                      key={header.id}
                      textAlign="left"
                      bg="unset"
                      border="unset"
                      textTransform="unset"
                      letterSpacing="unset"
                      pb={2}
                      pt={6}
                      pl={4}
                      _hover={{ cursor: 'pointer' }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex alignItems="center" gap={2}>
                        {typeof headerName === 'string' && (
                          <Typography fontWeight="500">{headerName}</Typography>
                        )}
                        <Icon
                          visibility={isSorted ? 'visible' : 'hidden'}
                          name={
                            isSorted === 'asc' ? 'ArrowUpIcon' : 'ArrowDownIcon'
                          }
                          size="small"
                        />
                      </Flex>
                    </Th>
                  )
                })}
              </Tr>
            ))}
          <Tr>
            <Th border="unset" px={0} py={0} colSpan={columns.length}>
              <Divider />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <SkeletonTableRows columns={columns.length} rows={5} />
          ) : isEmpty ? (
            <EmptyTable />
          ) : (
            rows.map((row) => (
              <Tr
                key={row.id}
                as={Link}
                to={DASHBOARD_PAGES.DASHBOARD_BY_ID(row.original.id)}
                display="table-row"
                verticalAlign="inheirt"
                height={16}
                _hover={{
                  bg: 'grey.50',
                  cursor: 'pointer',
                  [VISIBILITY_ICON_BG_VARIABLE]: colorTheme.grey[50],
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    maxW="300px"
                    _last={{ pr: 8, w: '0px' }}
                    verticalAlign="middle"
                    fontSize="sm"
                    lineHeight={5}
                    py={3}
                    px={4}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
