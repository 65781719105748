import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { toISODateRange } from 'utils/dateRange'
import { WidgetStatus } from '../atoms/dashboardViewState'
import { useWidgetFiltersState } from './filters/useWidgetFiltersState'
import { useWidgetDateState } from './useWidgetDateState'
import { useWidgetDimensionsState } from './useWidgetDimensionsState'
import { useWidgetMetricsState } from './useWidgetMetricState'
import { useSetWidgetStatus } from './useWidgetsState'
import { useWidgetTableState } from './useWidgetTableState'

type UseTableWidgetAnalyticsResponse = {
  data: NormalizedStatistic[]
  loading: boolean
}

export const useTableWidgetAnalytics = (
  widgetId: string | undefined,
  options?: Parameters<typeof useStatistics>[0],
): UseTableWidgetAnalyticsResponse => {
  const { timezone, currency, frontendIds = [] } = useMerchantInfo()
  const {
    resolvedDateState: { dateRange, compareDateRange },
  } = useWidgetDateState(widgetId)
  const [startDate, endDate] = toISODateRange(dateRange, timezone)
  const [compareStartDate, compareEndDate] = toISODateRange(
    compareDateRange,
    timezone,
  )
  const { aggregatedFilters: filters } = useWidgetFiltersState(widgetId)
  const { metricKeys: metrics } = useWidgetMetricsState(widgetId)
  const { dimensionKeys: dimensions } = useWidgetDimensionsState(widgetId)
  const tableState = useWidgetTableState(widgetId)
  const setWidgetStatus = useSetWidgetStatus(widgetId)

  const sortParams = useMemo(() => {
    const columnToSort = tableState.find((column) => column.sort)

    if (!columnToSort) {
      return undefined
    }

    const sortOn = columnToSort.id
    const sortDirection = columnToSort.sort

    return {
      sortOn,
      sortDirection,
    }
  }, [tableState])

  const shouldFetch =
    Boolean(timezone) &&
    Boolean(currency) &&
    Boolean(startDate) &&
    Boolean(endDate) &&
    frontendIds.length > 0 &&
    dimensions.length > 0 &&
    metrics.length > 0

  const { query: dataQuery, statistics: data } = useStatistics({
    ...options,
    skip: !shouldFetch,
    variables: {
      statisticsParams: {
        frontendIds,
        startDate: startDate as string,
        endDate: endDate as string,
        compareStartDate: compareStartDate as string,
        compareEndDate: compareEndDate as string,
        dimensions,
        metrics,
        filter: filters,
        limit: 20,
        ...(sortParams && {
          sortOn: {
            metric: sortParams.sortOn,
            order: sortParams.sortDirection,
            strategy: 'value',
          },
        }),
      },
    },
    onCompleted: (data) => {
      const isEmptyData = data.viewer?.merchant.statistics.length === 0

      setWidgetStatus(isEmptyData ? WidgetStatus.Error : WidgetStatus.Ready)
    },
    onError: () => {
      setWidgetStatus(WidgetStatus.Error)
    },
  })

  return {
    data,
    loading: dataQuery.loading,
  }
}
