import { type DropdownOption } from 'components/Dropdown/types'
import {
  addDays,
  differenceInDays,
  differenceInWeeks,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  sub,
  subDays,
  subYears,
  subWeeks,
  add,
  lastDayOfMonth,
} from 'date-fns'
import type { DateRange } from './types'

export interface DatePreset<
  T extends DYNAMIC_DATE_ID | COMPARE_DYNAMIC_DATE_ID =
    | DYNAMIC_DATE_ID
    | COMPARE_DYNAMIC_DATE_ID,
> extends DropdownOption {
  id: T
  name: string
  value: DateRange
  hasPrediction?: boolean
}

const getPrecedingPeriodMatching = (
  startDate: Date | null,
  endDate: Date | null,
): DateRange => {
  if (!startDate || !endDate) return [null, null]

  const difference = differenceInWeeks(endDate, startDate) + 1

  return [
    startOfDay(subWeeks(startDate, difference)),
    endOfDay(subWeeks(endDate, difference)),
  ]
}

const getPrecedingPeriod = (
  startDate: Date | null,
  endDate: Date | null,
): DateRange => {
  if (!startDate || !endDate) return [null, null]

  const difference = differenceInDays(endDate, startDate) + 1

  return [
    startOfDay(subDays(startDate, difference)),
    endOfDay(subDays(startDate, 1)),
  ]
}

const getPrecedingMonth = (
  startDate: Date | null,
  endDate: Date | null,
): DateRange => {
  if (!startDate || !endDate) return [null, null]

  return [
    startOfDay(sub(startDate, { months: 1 })),
    endOfDay(sub(endDate, { months: 1 })),
  ]
}

const getPrecedingYear = (
  startDate: Date | null,
  endDate: Date | null,
): DateRange => {
  if (!startDate || !endDate) return [null, null]

  return [
    startOfDay(sub(startDate, { years: 1 })),
    endOfDay(sub(endDate, { years: 1 })),
  ]
}

const getLastFridayOfNovember = (year: number) => {
  const lastDayOfNovember = lastDayOfMonth(new Date(year, 10))
  const dayOfWeek = lastDayOfNovember.getDay()
  const daysToSubtract = (dayOfWeek - 5 + 7) % 7

  return startOfDay(subDays(lastDayOfNovember, daysToSubtract))
}
const getLastBlackFriday = (
  startDate: Date | null,
  endDate: Date | null,
): DateRange => {
  if (!startDate || !endDate) return [null, null]

  const lastFridayOfNovember = getLastFridayOfNovember(startDate.getFullYear())
  const daysFromStartToFriday = differenceInDays(
    lastFridayOfNovember,
    startDate,
  )

  const lastFridayOfNovemberPreviousYear = getLastFridayOfNovember(
    startDate.getFullYear() - 1,
  )
  const compareStartDate = subDays(
    lastFridayOfNovemberPreviousYear,
    daysFromStartToFriday,
  )
  const dayDifference = differenceInDays(endDate, startDate)

  return [
    startOfDay(compareStartDate),
    endOfDay(addDays(compareStartDate, dayDifference)),
  ]
}

export const getPrecedingYearMatchingWeekdays = (
  startDate: Date | null,
  endDate: Date | null,
): DateRange => {
  if (!startDate || !endDate) return [null, null]
  const adjustedStartDate = subYears(new Date(startDate), 1)

  const difference = differenceInDays(endDate, startDate)

  for (let i = 1; i <= 2; i++) {
    const tempDate = new Date(adjustedStartDate)

    tempDate.setDate(tempDate.getDate() + i)
    if (tempDate.getDay() === startDate.getDay()) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() + i)
      break
    }
  }
  const adjustedEndDate = addDays(adjustedStartDate, difference)

  return [startOfDay(adjustedStartDate), endOfDay(adjustedEndDate)]
}

export enum DYNAMIC_DATE_ID {
  'CUSTOM_RANGE' = 'custom-range',
  'TODAY' = 'today',
  'YESTERDAY' = 'yesterday',
  'LAST_7_DAYS' = 'last-7-days',
  'LAST_14_DAYS' = 'last-14-days',
  'LAST_28_DAYS' = 'last-28-days',
  'LAST_WEEK' = 'last-week',
  'LAST_2_WEEKS' = 'last-2-weeks',
  'LAST_4_WEEKS' = 'last-4-weeks',
  'THIS_WEEK' = 'this-week',
  'THIS_WEEK_TO_DATE' = 'this-week-to-date',
  'THIS_MONTH' = 'this-month',
  'THIS_MONTH_TO_DATE' = 'this-month-to-date',
  'LAST_MONTH' = 'last-month',
  'LAST_90_DAYS' = 'last-90-days',
  'THIS_YEAR_TO_DATE' = 'this-year',
  'LAST_YEAR' = 'last-year',
  'LAST_12_MONTHS' = 'last-12-months',
  'NEXT_WEEK' = 'next-week',
  'NEXT_4_WEEKS' = 'next-4-weeks',
}

const getDatePresetsMap = (
  now: Date,
): Record<DYNAMIC_DATE_ID, DatePreset<DYNAMIC_DATE_ID>> => {
  return {
    [DYNAMIC_DATE_ID.CUSTOM_RANGE]: {
      id: DYNAMIC_DATE_ID.CUSTOM_RANGE,
      name: 'Custom range',
      value: [null, null],
    },
    [DYNAMIC_DATE_ID.TODAY]: {
      id: DYNAMIC_DATE_ID.TODAY,
      name: 'Today',
      value: [startOfDay(now), endOfDay(now)],
      hasPrediction: true,
    },
    [DYNAMIC_DATE_ID.YESTERDAY]: {
      id: DYNAMIC_DATE_ID.YESTERDAY,
      name: 'Yesterday',
      value: [
        startOfDay(sub(now, { days: 1 })),
        endOfDay(sub(now, { days: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.LAST_7_DAYS]: {
      id: DYNAMIC_DATE_ID.LAST_7_DAYS,
      name: 'Last 7 days',
      value: [
        startOfDay(sub(now, { days: 7 })),
        endOfDay(sub(now, { days: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.LAST_14_DAYS]: {
      id: DYNAMIC_DATE_ID.LAST_14_DAYS,
      name: 'Last 14 days',
      value: [
        startOfDay(sub(now, { days: 14 })),
        endOfDay(sub(now, { days: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.LAST_28_DAYS]: {
      id: DYNAMIC_DATE_ID.LAST_28_DAYS,
      name: 'Last 28 days',
      value: [
        startOfDay(sub(now, { days: 28 })),
        endOfDay(sub(now, { days: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.THIS_WEEK]: {
      id: DYNAMIC_DATE_ID.THIS_WEEK,
      name: 'This week',
      value: [startOfISOWeek(now), endOfISOWeek(now)],
      hasPrediction: true,
    },
    [DYNAMIC_DATE_ID.THIS_WEEK_TO_DATE]: {
      id: DYNAMIC_DATE_ID.THIS_WEEK_TO_DATE,
      name: 'This week to date',
      value: [startOfISOWeek(now), endOfDay(now)],
    },
    [DYNAMIC_DATE_ID.LAST_WEEK]: {
      id: DYNAMIC_DATE_ID.LAST_WEEK,
      name: 'Last week',
      value: [
        startOfISOWeek(sub(now, { weeks: 1 })),
        endOfISOWeek(sub(now, { weeks: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.LAST_2_WEEKS]: {
      id: DYNAMIC_DATE_ID.LAST_2_WEEKS,
      name: 'Last 2 weeks',
      value: [
        startOfISOWeek(sub(now, { weeks: 2 })),
        endOfISOWeek(sub(now, { weeks: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.LAST_4_WEEKS]: {
      id: DYNAMIC_DATE_ID.LAST_4_WEEKS,
      name: 'Last 4 weeks',
      value: [
        startOfISOWeek(sub(now, { weeks: 4 })),
        endOfISOWeek(sub(now, { weeks: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.THIS_MONTH]: {
      id: DYNAMIC_DATE_ID.THIS_MONTH,
      name: 'This month',
      value: [startOfMonth(now), endOfMonth(now)],
      hasPrediction: true,
    },
    [DYNAMIC_DATE_ID.THIS_MONTH_TO_DATE]: {
      id: DYNAMIC_DATE_ID.THIS_MONTH_TO_DATE,
      name: 'This month to date',
      value: [startOfMonth(now), endOfDay(now)],
    },
    [DYNAMIC_DATE_ID.LAST_MONTH]: {
      id: DYNAMIC_DATE_ID.LAST_MONTH,
      name: 'Last month',
      value: [
        startOfMonth(sub(now, { months: 1 })),
        endOfMonth(sub(now, { months: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.LAST_90_DAYS]: {
      id: DYNAMIC_DATE_ID.LAST_90_DAYS,
      name: 'Last 90 days',
      value: [
        startOfDay(sub(now, { days: 90 })),
        endOfDay(sub(now, { days: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.THIS_YEAR_TO_DATE]: {
      id: DYNAMIC_DATE_ID.THIS_YEAR_TO_DATE,
      name: 'This year to date',
      value: [startOfYear(now), endOfDay(now)],
    },
    [DYNAMIC_DATE_ID.LAST_YEAR]: {
      name: 'Last year',
      id: DYNAMIC_DATE_ID.LAST_YEAR,
      value: [
        startOfYear(sub(now, { years: 1 })),
        endOfYear(sub(now, { years: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.LAST_12_MONTHS]: {
      name: 'Last 12 months',
      id: DYNAMIC_DATE_ID.LAST_12_MONTHS,
      value: [
        startOfDay(sub(now, { months: 12, days: 1 })),
        endOfDay(sub(now, { days: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.NEXT_WEEK]: {
      id: DYNAMIC_DATE_ID.NEXT_WEEK,
      name: 'Next week',
      value: [
        startOfISOWeek(add(now, { weeks: 1 })),
        endOfISOWeek(add(now, { weeks: 1 })),
      ],
    },
    [DYNAMIC_DATE_ID.NEXT_4_WEEKS]: {
      id: DYNAMIC_DATE_ID.NEXT_4_WEEKS,
      name: 'Next 4 weeks',
      value: [
        startOfISOWeek(add(now, { weeks: 1 })),
        endOfISOWeek(add(now, { weeks: 4 })),
      ],
    },
  }
}

export const getDatePreset = (presetId: DYNAMIC_DATE_ID) => {
  const now = new Date()

  return getDatePresetsMap(now)[presetId]
}

export const getDatePresets = (
  dynamicDates: DYNAMIC_DATE_ID[],
): DatePreset<DYNAMIC_DATE_ID>[] => {
  const now = new Date()
  const datePresetsMap = getDatePresetsMap(now)
  const dates: DatePreset<DYNAMIC_DATE_ID>[] = []

  for (const dynamicDate of dynamicDates) {
    dates.push(datePresetsMap[dynamicDate])
  }

  return dates
}

export enum COMPARE_DYNAMIC_DATE_ID {
  'CUSTOM_RANGE' = 'custom-range',
  'PRECEDING_PERIOD_MATCHING' = 'preceding-period-matching',
  'PRECEDING_PERIOD' = 'preceding-period',
  'PRECEDING_MONTH' = 'preceding-month',
  'PRECEDING_YEAR' = 'preceding-year',
  'PRECEDING_YEAR_MATCHING_WEEKDAYS' = 'preceding-year-matching-weekdays',
  'LAST_BLACK_FRIDAY' = 'last-black-friday',
}

const getCompareDatePresetsMap = (
  startDate: Date | null,
  endDate: Date | null,
): Record<COMPARE_DYNAMIC_DATE_ID, DatePreset<COMPARE_DYNAMIC_DATE_ID>> => {
  return {
    [COMPARE_DYNAMIC_DATE_ID.CUSTOM_RANGE]: {
      id: COMPARE_DYNAMIC_DATE_ID.CUSTOM_RANGE,
      name: 'Custom range',
      value: [null, null],
    },
    [COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD]: {
      id: COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD,
      name: 'Preceding period',
      value: getPrecedingPeriod(startDate, endDate),
    },
    [COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING]: {
      id: COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING,
      name: 'Preceding period (matching weekdays)',
      value: getPrecedingPeriodMatching(startDate, endDate),
    },
    [COMPARE_DYNAMIC_DATE_ID.PRECEDING_MONTH]: {
      id: COMPARE_DYNAMIC_DATE_ID.PRECEDING_MONTH,
      name: 'Preceding month (same dates)',
      value: getPrecedingMonth(startDate, endDate),
    },
    [COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR]: {
      id: COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR,
      name: 'Preceding year (same dates)',
      value: getPrecedingYear(startDate, endDate),
    },
    [COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS]: {
      id: COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
      name: 'Preceding year (matching weekdays)',
      value: getPrecedingYearMatchingWeekdays(startDate, endDate),
    },
    [COMPARE_DYNAMIC_DATE_ID.LAST_BLACK_FRIDAY]: {
      id: COMPARE_DYNAMIC_DATE_ID.LAST_BLACK_FRIDAY,
      name: 'Preceding year (matching black week)',
      value: getLastBlackFriday(startDate, endDate),
    },
  }
}

export const getCompareDatePreset = (
  presetId: COMPARE_DYNAMIC_DATE_ID,
  startDate: Date | null,
  endDate: Date | null,
) => {
  return getCompareDatePresetsMap(startDate, endDate)[presetId]
}

export const getCompareDatePresets = (
  compareDynamicDates: COMPARE_DYNAMIC_DATE_ID[],
  startDate: Date | null,
  endDate: Date | null,
) => {
  const compareDatePresetMap = getCompareDatePresetsMap(startDate, endDate)
  const dates: DatePreset<COMPARE_DYNAMIC_DATE_ID>[] = []

  for (const compareDynamicDate of compareDynamicDates) {
    dates.push(compareDatePresetMap[compareDynamicDate])
  }

  return dates
}
