import { getDatePreset } from 'constants/getDatePresets'
import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import {
  useSetWidgetDateState,
  useWidgetDateState,
} from 'features/dashboards/hooks/useWidgetDateState'
import { selectedWidgetIdAtom } from 'features/dashboards/hooks/useWidgetsState'
import { ReportDetailsHeader } from 'features/reports/components/ReportPanel/ReportDetailsPanel/ReportDetailsRow/ReportDetailsHeader'
import { useAtomValue } from 'jotai'
import { DateRangePicker } from 'shared/DateRangePicker'

export const WidgetDatePickerContainer = () => {
  const widgetId = useAtomValue(selectedWidgetIdAtom)
  const { resolvedDateState, widgetDateState } = useWidgetDateState(widgetId)
  const setDateState = useSetWidgetDateState(widgetId)

  const dateLabel = widgetDateState.dynamicDate
    ? getDatePreset(widgetDateState.dynamicDate)?.name
    : undefined

  return (
    <Flex flexDirection="column" gap={2}>
      <ReportDetailsHeader title="Date range (Override)" />
      <DateRangePicker
        dateState={resolvedDateState}
        onDateChange={setDateState}
        triggerButton={
          <Button
            size="sm"
            colorScheme="gray"
            leadingIcon={{ name: 'CalendarBlankIcon' }}
          >
            {dateLabel || 'Add date range'}
          </Button>
        }
      />
    </Flex>
  )
}
