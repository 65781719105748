import { Flex, Skeleton } from '@chakra-ui/react'
import { getWeek } from 'date-fns'
import { WidgetStatus } from 'features/dashboards/atoms/dashboardViewState'
import { useChartWidgetAnalytics } from 'features/dashboards/hooks/useChartWidgetAnalytics'
import { useChartWidgetOptions } from 'features/dashboards/hooks/useChartWidgetOptions'
import { useWidgetChartState } from 'features/dashboards/hooks/useWidgetChartState'
import { useWidgetMetricsState } from 'features/dashboards/hooks/useWidgetMetricState'
import { useWidgetStatus } from 'features/dashboards/hooks/useWidgetsState'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCBoost from 'highcharts/modules/boost'
import HCExporting from 'highcharts/modules/exporting'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useRef } from 'react'
import { EmptyWidgetContent } from './EmptyWidgetContent'
import { ErrorWidgetContent } from './ErrorWidgetContent'

HCExporting(Highcharts)
HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HCBoost(Highcharts)

Highcharts.dateFormats.W = function (timestamp) {
  return String(
    getWeek(new Date(timestamp), {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    }),
  )
}

Highcharts.setOptions({
  time: {
    useUTC: false,
  },
})

interface WidgetAnalyticsChartProps {
  widgetId: string
  containerHeight: number
}

export const WidgetAnalyticsChart = ({
  widgetId,
  containerHeight,
}: WidgetAnalyticsChartProps) => {
  const widgetStatus = useWidgetStatus(widgetId)
  const chartRef = useRef<HighchartsReact.RefObject>(null)

  const { series } = useWidgetChartState(widgetId)
  const { chartData: data, loading } = useChartWidgetAnalytics(widgetId)
  const { metrics: selectedMetrics } = useWidgetMetricsState(widgetId)
  const isLoading = selectedMetrics.length !== 0 && loading
  const options = useChartWidgetOptions({
    widgetId,
    data,
    isLoading,
  })

  useEffect(() => {
    chartRef.current?.chart.zoomOut()
  }, [series])

  if (isLoading) {
    return <LoadingWidgetAnalyticsChart />
  }

  if (widgetStatus === WidgetStatus.Empty) {
    return <EmptyWidgetContent widgetId={widgetId} />
  }

  if (widgetStatus === WidgetStatus.Error) {
    return <ErrorWidgetContent />
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
      containerProps={{ style: { width: '100%', height: containerHeight } }}
    />
  )
}

const LoadingWidgetAnalyticsChart = () => {
  return (
    <Flex direction="column" height="100%" gap={4}>
      <Skeleton h={4} w="full" flexGrow={1} />
      <Skeleton h={4} w="full" />
    </Flex>
  )
}
