import {
  defineStyle,
  defineStyleConfig,
  type SystemStyleObject,
  type StyleFunctionProps,
} from '@chakra-ui/react'

export const sizes = {
  xs: defineStyle({
    borderRadius: 0,
    fontSize: 'sm',
    lineHeight: 1,
    px: 2,
    minH: 6,
  }),
  sm: defineStyle({
    borderRadius: 0,
    fontSize: 'sm',
    lineHeight: 1,
    px: 2,
    minH: 8,
  }),
  md: defineStyle({
    borderRadius: 0,
    fontSize: 'sm',
    lineHeight: 1,
    px: 4,
    minH: 10,
  }),
  lg: defineStyle({
    borderRadius: 0,
    fontSize: 'sm',
    lineHeight: 1,
    px: 4,
    minH: 12,
  }),
}

type ButtonSize = keyof typeof sizes

// TODO rethink 'readOnly' state from a UX perspective
// Currently only used in Settings - Mapping - Existing mappings menu.
const _readOnly: SystemStyleObject = {
  cursor: 'default',
  userSelect: 'text',
}

export const variants = {
  solid: defineStyle((props) => {
    const { colorScheme } = props

    const _disabled = {
      bg: 'grey.300',
      color: 'grey.500',
    }

    switch (colorScheme) {
      case 'primary':
        return {
          bg: 'primary.500',
          color: 'white',
          _hover: {
            bg: 'primary.600',
            color: 'white',
            _disabled,
          },
          _active: {
            bg: 'primary.700',
            color: 'white',
            _disabled,
          },
          _disabled,
          _readOnly: {
            ..._readOnly,
            bg: 'primary.100',
            color: 'primary.600',
          },
        }

      case 'gray':
      case 'grey':
        return {
          bg: 'grey.100',
          color: 'grey.800',
          _hover: {
            bg: 'grey.200',
            color: 'grey.900',
            _disabled,
          },
          _active: {
            bg: 'grey.300',
            color: 'grey.950',
            _disabled,
          },
          _disabled,
          _readOnly: { ..._readOnly, bg: 'grey.200', color: 'grey.800' },
        }

      case 'red':
        return {
          bg: 'red.500',
          color: 'white',
          _hover: {
            bg: 'red.600',
            color: 'white',
            _disabled,
          },
          _active: {
            bg: 'red.700',
            color: 'white',
            _disabled,
          },
          _disabled,
        }
    }

    return {}
  }),
  outline: defineStyle((props) => {
    const { colorScheme } = props

    const _disabled: SystemStyleObject = {
      bg: 'transparent',
      borderColor: 'grey.300',
      color: 'grey.300',
      cursor: 'default',
    }

    const baseStyle: SystemStyleObject = {
      bg: 'transparent',
      border: 'solid 1px',
      _disabled,
    }

    const _invalid: SystemStyleObject = {
      borderColor: 'red.300',
    }

    switch (colorScheme) {
      case 'primary':
        return {
          ...baseStyle,
          color: 'primary.500',
          borderColor: 'primary.500',
          _hover: {
            bg: 'primary.600',
            borderColor: 'primary.600',
            color: 'white',
            _disabled,
          },
          _active: {
            bg: 'primary.700',
            borderColor: 'primary.700',
            color: 'white',
            _disabled,
          },
          _readOnly,
          _invalid,
        }

      case 'grey':
      case 'gray':
        return {
          ...baseStyle,
          color: 'grey.800',
          borderColor: 'grey.400',
          _hover: {
            bg: 'grey.100',
            borderColor: 'grey.400',
            color: 'grey.800',
            _disabled,
          },
          _active: {
            bg: 'grey.200',
            borderColor: 'grey.400',
            color: 'grey.900',
            _disabled,
          },
          _readOnly,
          _invalid,
        }

      case 'red':
        return {
          ...baseStyle,
          color: 'red.500',
          borderColor: 'red.500',
          _hover: {
            bg: 'red.600',
            borderColor: 'red.600',
            color: 'white',
            _disabled,
          },
          _active: {
            bg: 'red.700',
            borderColor: 'red.700',
            color: 'white',
            _disabled,
          },
          _readOnly,
          _invalid,
        }
    }

    return {}
  }),
  ghost: defineStyle((props) => {
    const { colorScheme } = props

    const _disabled: SystemStyleObject = {
      bg: 'transparent',
      color: 'grey.300',
      cursor: 'default',
    }

    const baseStyle: SystemStyleObject = {
      bg: 'transparent',
      _disabled,
    }

    switch (colorScheme) {
      case 'primary':
        return {
          ...baseStyle,
          color: 'primary.500',
          _hover: {
            bg: 'grey.100',
            color: 'primary.600',
            _disabled,
          },
          _active: {
            bg: 'grey.200',
            color: 'primary.600',
            _disabled,
          },
          _readOnly,
        }

      case 'grey':
      case 'gray':
        return {
          ...baseStyle,
          color: 'grey.800',
          _hover: {
            bg: 'grey.100',
            color: 'grey.900',
            _disabled,
          },
          _active: {
            bg: 'grey.200',
            color: 'grey.900',
            _disabled,
          },
          _readOnly,
        }
    }

    return {}
  }),
  link: defineStyle((props) => {
    const { colorScheme, size } = props as StyleFunctionProps & {
      size: ButtonSize
    }

    const _disabled = {
      bg: 'transparent',
      color: 'grey.300',
    }

    const fontStyle: Record<ButtonSize, object> = {
      xs: defineStyle({ fontSize: '10px', minH: '14px' }),
      sm: defineStyle({ fontSize: '12px', minH: 4 }),
      md: defineStyle({ fontSize: '14px', minH: 5 }),
      lg: defineStyle({ fontSize: '16px', minH: 6 }),
    }

    const baseStyle = {
      ...fontStyle[size],
      bg: 'transparent',
      textUnderlineOffset: '2px',
      _disabled,
    }

    switch (colorScheme) {
      case 'primary':
        return {
          ...baseStyle,
          color: 'primary.500',
          ':hover, :focus, :active': {
            textDecoration: 'underline',
            textDecorationColor: 'primary.600',
            color: 'primary.600',
            _disabled,
          },
        }

      case 'red':
        return {
          ...baseStyle,
          color: 'red.500',
          ':hover, :focus, :active': {
            textDecoration: 'underline',
            textDecorationColor: 'red.600',
            color: 'red.600',
            _disabled,
          },
        }
    }

    return {}
  }),
}

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
    lineHeight: 'unset',
    _disabled: {
      opacity: 1,
    },
    transition: 'none', // remove base chakra transition
  },
  sizes,
  variants,
})
