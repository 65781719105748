import { useToast } from '@chakra-ui/react'
import { useReportsAnalytics } from 'features/reports/hooks/useReportsAnalytics'
import { type SegmentationSet } from 'features/segmentations/graphql/fragments'
import { useProductSetById } from 'features/segmentations/graphql/useSegmentationSetById'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import { useSetProductRules } from 'features/segmentations/views/ProductRulesView/graphql/useSetSegmentationRules'
import { uniq } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import { ExportSetModal } from './ExportSetModal'
import { isProductRelatedDimension } from './utils'

export const ExportProductSetModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  productSets: SegmentationSet[]
}> = ({ isOpen, onClose, productSets }) => {
  const { data } = useReportsAnalytics({ fetchPolicy: 'cache-only' })
  const { dimensions } = useDimensionsState()

  const productRelatedDimensions = dimensions.filter(isProductRelatedDimension)
  const [selectedDimension, setSelectedDimension] = useState(
    productRelatedDimensions[0],
  )
  const [uniqueValues, setUniqueValues] = useState<string[]>([])
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const [selectedProductSet, setSelectedProductSet] = useState(
    productSets.length > 0 ? productSets[0] : ({ id: '' } as SegmentationSet),
  )
  const [setProductRules, setProductRulesState] = useSetProductRules()
  const { productSet, query: productSetQuery } = useProductSetById(
    selectedProductSet.id,
  )
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueValues = uniq(
        data
          .map((row) => row[selectedDimension.id]?.value)
          .filter(Boolean) as string[],
      )

      setSelectedValues(uniqueValues)
      setUniqueValues(uniqueValues)
    }
  }, [data, selectedDimension.id])

  const onSubmit = () => {
    if (!productSet) return

    const newOverrideRule = {
      filters: {
        [selectedDimension.id]: [
          { comparisonId: 'isAnyOf', value: selectedValues },
        ],
      },
      description: null,
      productLabelId: null,
      expiresAt: null,
    }

    setProductRules({
      variables: {
        params: {
          rules: [
            newOverrideRule,
            ...productSet.rules.map((rule) => ({
              description: rule.description,
              filters: rule.filters,
              productLabelId: rule.productLabel?.id,
              expiresAt: rule.expiresAt,
            })),
          ],
          segmentationSetId: productSet.id,
        },
      },
      optimisticResponse: {
        setSegmentationRules: {
          ...productSet,
          rules: [
            {
              id: 'optimistic',
              __typename: 'SegmentationRule' as const,
              productLabel: null,
              ...newOverrideRule,
            },
            ...productSet.rules,
          ],
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __typename: 'SegmentationSet',
            id: productSet.id,
          }),
          fields: { rules: () => data?.setSegmentationRules.rules },
        })
      },
      onCompleted: () => {
        navigate(SEGMENTATIONS_PAGES.PRODUCT_RULES_BY_ID(productSet.id))
      },
      onError: () => {
        toast({ status: 'error', description: 'Failed to create rule' })
      },
    })
  }

  return (
    <ExportSetModal
      type="Product"
      enableDimensionSelector={true}
      filterLabel="Filter"
      filterIcon="FilterIcon"
      isLoading={productSetQuery.loading || setProductRulesState.loading}
      isDisabled={selectedValues.length === 0 || !productSet}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      uniqueValues={uniqueValues}
      sets={productSets}
      selectedSet={selectedProductSet}
      setSelectedSet={(set) => setSelectedProductSet(set as SegmentationSet)}
      dimensions={productRelatedDimensions}
      selectedDimension={selectedDimension}
      setSelectedDimension={setSelectedDimension}
    />
  )
}
