import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { type MerchantFieldsFragment } from 'generated/graphql/graphql'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { type DashboardState } from '../atoms/dashboardViewState'
import { useEditDashboard } from '../graphql/useEditDashboard'

type Props = {
  dashboard: DashboardState
  newVisibility: MerchantTeam[] | MerchantFieldsFragment[]
  oldVisibility: string[]
}

export const useEditDashboardVisibility = () => {
  const [editDashboard] = useEditDashboard()
  const toast = useToast()

  const editDashboardVisibility = async ({
    dashboard,
    newVisibility,
  }: Props) => {
    if (!dashboard.id) return

    try {
      await editDashboard({
        id: dashboard.id,
        dashboard: {
          ...dashboard,
          visibility: newVisibility,
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not update dashboard visibility, please try again',
      })

      captureException(`Update dashboard visibility: ${error}`)
    }
  }

  return { editDashboardVisibility }
}
