import { Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type ButtonProps } from 'components/buttons/Button'
import { type AddWidgetToDashboardOptions } from 'features/dashboards/hooks/useAddWidgetToDashboard'
import { atom, useAtom } from 'jotai'
import { type ReactElement, useState } from 'react'
import { AddWidgetFromReportModal } from './AddWidgetFromReportModal'
import { AddWidgetFromReportPicker } from './AddWidgetFromReportPicker'
import { AddWidgetMenuItems } from './AddWidgetMenuItems'

interface AddWidgetButtonProps {
  customMenuButton?: ReactElement
  buttonProps?: ButtonProps
  options?: AddWidgetToDashboardOptions
  onMenuClose?: () => void
}

export const addWidgetMenuOpenAtom = atom(false)

export const AddWidgetButton = ({
  customMenuButton,
  options,
  onMenuClose: onMenuCloseProp,
}: AddWidgetButtonProps) => {
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null)
  const [isAddWidgetMenuOpen, setIsAddWidgetMenuOpen] = useAtom(
    addWidgetMenuOpenAtom,
  )

  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure({
    defaultIsOpen: isAddWidgetMenuOpen,
    onOpen: () => setIsAddWidgetMenuOpen(true),
    onClose: () => {
      setIsAddWidgetMenuOpen(false)
      onMenuCloseProp?.()
    },
  })
  const {
    isOpen: isReportPickerOpen,
    onOpen: onReportPickerOpen,
    onClose: onReportPickerClose,
  } = useDisclosure()
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()

  const handleOpenReportPicker = () => {
    onReportPickerOpen()
    onMenuClose()
  }

  if (isReportPickerOpen) {
    return (
      <AddWidgetFromReportPicker
        isOpen={true}
        onClose={onReportPickerClose}
        onOpen={onReportPickerOpen}
        onModalOpen={onModalOpen}
        setSelectedReportId={setSelectedReportId}
        customMenuButton={
          customMenuButton || (
            <MenuButton as={Button} size="sm" w="fit-content">
              Add widget
            </MenuButton>
          )
        }
      />
    )
  }

  return (
    <>
      <Menu
        isOpen={isMenuOpen}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        isLazy
        size="sm"
      >
        {customMenuButton || (
          <MenuButton
            as={Button}
            onClick={onMenuOpen}
            size="sm"
            w="fit-content"
          >
            Add widget
          </MenuButton>
        )}
        <MenuList>
          <AddWidgetMenuItems
            onReportPickerOpen={handleOpenReportPicker}
            options={options}
          />
        </MenuList>
      </Menu>
      <AddWidgetFromReportModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        selectedReportId={selectedReportId}
      />
    </>
  )
}
