import { type InferenceSummaryFieldsFragment } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'

export enum EXPERIMENT_METRIC {
  GROSS_SALES = 'order:total:dema',
  NET_SALES = 'order:netSales:dema',
  NET_GROSS_PROFIT_2 = 'order:netGrossProfit2:dema',
  NEW_CUSTOMER_COUNT = 'customer:newCustomerCount:dema',
}

export const experimentViewMetrics = [
  {
    id: EXPERIMENT_METRIC.GROSS_SALES,
    name: 'Gross sales',
    returnName: 'ROAS',
    format: METRIC_FORMAT.CURRENCY,
    returnFormat: METRIC_FORMAT.PERCENT,
    getValue: (summary: InferenceSummaryFieldsFragment) =>
      summary.totalSpend * summary.incrementalFactor,
  },
  {
    id: EXPERIMENT_METRIC.NET_SALES,
    name: 'Net sales',
    returnName: 'eROAS',
    format: METRIC_FORMAT.CURRENCY,
    returnFormat: METRIC_FORMAT.PERCENT,
    getValue: (summary: InferenceSummaryFieldsFragment) =>
      summary.totalSpend * summary.incrementalFactor,
  },
  {
    id: EXPERIMENT_METRIC.NET_GROSS_PROFIT_2,
    name: 'Net gross profit 2',
    returnName: 'epROAS',
    format: METRIC_FORMAT.CURRENCY,
    returnFormat: METRIC_FORMAT.PERCENT,
    getValue: (summary: InferenceSummaryFieldsFragment) =>
      summary.totalSpend * summary.incrementalFactor,
  },
  {
    id: EXPERIMENT_METRIC.NEW_CUSTOMER_COUNT,
    name: 'New customer count',
    returnName: 'CAC',
    format: METRIC_FORMAT.INTEGER,
    returnFormat: METRIC_FORMAT.CURRENCY,
    getValue: (summary: InferenceSummaryFieldsFragment) =>
      summary.totalSpend / summary.incrementalFactor,
  },
]

export enum SETUP_VARIANT {
  COST = 'cost',
  STATISTICAL_SIGNIFICANCE = 'statisticalSignificance',
}
