import {
  type DashboardWidgetFieldsFragment,
  type DashboardWithOwnerFieldsFragment,
} from 'generated/graphql/graphql'
import { type DashboardWidgetResult, type DashboardResult } from '../types'

type InputDashboard = DashboardWithOwnerFieldsFragment[]

export const transformDashboard = (
  dashboard: InputDashboard[number],
): DashboardResult => {
  return dashboard as unknown as DashboardResult
}

export const transformDashboards = (dashboards: InputDashboard) => {
  return dashboards as unknown as DashboardResult[]
}

export const transformDashboardWidget = (
  widget: DashboardWidgetFieldsFragment,
): DashboardWidgetResult => {
  return widget as unknown as DashboardWidgetResult
}
