import { Box } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { SegmentationHeaderWrapper } from 'features/segmentations/shared/SegmentationHeaderWrapper'
import type React from 'react'
import { AddCustomerLabelModal } from './components/AddCustomerLabelModal'
import { CustomerLabelsList } from './components/CustomerLabelsList'

export const CustomerLabelsView: React.FC = () => {
  return (
    <Box>
      <SegmentationHeaderWrapper>
        <SegmentationHeaderWrapper.Title>
          Customer labels
        </SegmentationHeaderWrapper.Title>

        <AddCustomerLabelModal
          modalButton={
            <Button
              size="sm"
              leadingIcon={{ name: 'PlusSmallIcon' }}
              colorScheme="gray"
            >
              New customer label
            </Button>
          }
        />
      </SegmentationHeaderWrapper>

      <CustomerLabelsList />
    </Box>
  )
}
