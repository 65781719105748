import { type IconName } from 'components/Icon/Icon'
import { type AttributionModel as GraphAttributionModel } from 'generated/graphql/graphql'
import { type FeatureFlags } from 'graphql/useFeatureFlags'
import { atom, useAtomValue } from 'jotai'

export type AttributionId = 'dema' | 'linear' | 'lastClick' | 'ad'

type AttributionModel = Omit<GraphAttributionModel, 'id'> & {
  id: AttributionId
  iconName: IconName
  featureFlag?: keyof FeatureFlags
}

export type NormalizedAttributionModels = Record<
  AttributionId,
  AttributionModel
>

export const DEFAULT_ATTRIBUTION_ID: AttributionId = 'dema'

export const attributionModelsQueryAtom = atom<GraphAttributionModel[] | null>(
  null,
)

export const normalizedAttributionModelsAtom =
  atom<NormalizedAttributionModels>((get) => {
    const data = get(attributionModelsQueryAtom)

    return (data ?? []).reduce<NormalizedAttributionModels>((acc, model) => {
      const enhancedModel = {
        ...model,
        id: model.id as AttributionId,
        iconName: model.iconName as IconName,
        featureFlag:
          model.id === 'mmm' ? ('enableOptimizations' as const) : undefined,
      }

      acc[enhancedModel.id] = enhancedModel

      return acc
    }, {} as NormalizedAttributionModels)
  })

export const useNormalizedAttributionModels =
  (): NormalizedAttributionModels => {
    return useAtomValue(normalizedAttributionModelsAtom)
  }
