import { Divider, Grid } from '@chakra-ui/react'
import {
  getFilteredOptions,
  isGroupOption,
  sortOptions,
} from 'components/Dropdown/ComboBox/utils'
import {
  useDraftDimensions,
  useDraftMetrics,
} from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import { ORGANIZATION_ID } from 'graphql/shared/utils'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import {
  getMetricKeyWithoutAttribution,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { useUserTeams } from 'graphql/teams/useUserTeams'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { useMetricGroups } from 'hooks/useMetricGroups'
import { groupBy } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useMetricsState } from 'shared/hooks/useMetricsState'
import { GroupsList } from './GroupsList'
import { ItemsList } from './ItemsList'
import { SearchInput } from './SearchInput'

const ALL_METRICS_GROUP = 'All metrics'

export const MetricSelectorSection = ({
  allowInvalidMetricCombinations,
}: {
  allowInvalidMetricCombinations?: boolean
}) => {
  const [selectedTeam, setSelectedTeam] = useState(ORGANIZATION_ID)
  const [isLargerThanSm] = useBreakpoint('sm')
  const [selectedGroup, setSelectedGroup] = useState(ALL_METRICS_GROUP)
  const [inputValue, setInputValue] = useState('')
  const [draftDimensions] = useDraftDimensions()
  const normalizedDimensions = useNormalizedDimensions()
  const dimensions = draftDimensions.map((dim) => normalizedDimensions[dim])
  const normalizedMetrics = useNormalizedMetrics()
  const { userTeams } = useUserTeams()
  const { uniqueMetrics } = useMetricsState()
  const uniqueMetricKeys = useMemo(
    () => uniqueMetrics.map(getMetricKeyWithoutAttribution),
    [uniqueMetrics],
  )
  const [draftSelected, setDraftSelected] = useDraftMetrics(
    allowInvalidMetricCombinations,
  )
  const teamsWithPreferredData = useMemo(
    () => userTeams.filter((team) => team.preferredMetrics.length > 0),
    [userTeams],
  )
  const hasTeamsWithPreferredData = teamsWithPreferredData.length > 0

  const filteredMetrics = useMemo(() => {
    const allMetrics = Object.values(normalizedMetrics)

    if (selectedTeam === ORGANIZATION_ID || !hasTeamsWithPreferredData) {
      return allMetrics
    }

    const preferredMetrics = teamsWithPreferredData.find(
      (team) => team.id === selectedTeam,
    )?.preferredMetrics

    return allMetrics.filter((metric) => {
      return preferredMetrics?.includes(metric.groupKey)
    })
  }, [
    normalizedMetrics,
    selectedTeam,
    teamsWithPreferredData,
    hasTeamsWithPreferredData,
  ])

  const groupedMetrics = useMetricGroups({
    dimensions,
    filteredMetrics,
    allowInvalidMetricCombinations,
  })

  const groupedSelectedMetrics = useMemo(
    () =>
      groupBy(
        draftSelected.map((id) => normalizedMetrics[id]),
        'groupLabel',
      ),
    [draftSelected, normalizedMetrics],
  )

  const metricGroups = useMemo(
    () => [
      {
        id: ALL_METRICS_GROUP,
        name: `${ALL_METRICS_GROUP} ${
          draftSelected.length ? `(${draftSelected.length})` : ''
        }`,
      },
      ...groupedMetrics.map((group) => ({
        id: group.name,
        name: `${group.name} ${
          groupedSelectedMetrics[group.name]?.length
            ? `(${groupedSelectedMetrics[group.name]?.length})`
            : ''
        }`,
      })),
    ],
    [groupedMetrics, groupedSelectedMetrics, draftSelected.length],
  )

  const groupedMetricsOnGroup = useMemo(() => {
    if (selectedGroup === ALL_METRICS_GROUP) {
      return groupedMetrics
    }

    return groupedMetrics.filter((group) => {
      return group.name === selectedGroup
    })
  }, [groupedMetrics, selectedGroup])

  const sortedOptions = useMemo(
    () =>
      sortOptions(
        groupedMetricsOnGroup,
        uniqueMetricKeys,
        selectedGroup === ALL_METRICS_GROUP,
      ),
    [groupedMetricsOnGroup, uniqueMetricKeys, selectedGroup],
  )
  const filteredOptions = useMemo(() => {
    return getFilteredOptions(sortedOptions, inputValue).filter(isGroupOption)
  }, [sortedOptions, inputValue])

  return (
    <Grid gridTemplateRows="auto 1fr">
      <SearchInput
        teams={teamsWithPreferredData}
        selectedTeam={selectedTeam}
        onTeamChange={(teamId) => {
          setSelectedTeam(teamId)
          setSelectedGroup(ALL_METRICS_GROUP)
          setInputValue('')
        }}
        inputValue={inputValue}
        setInputValue={setInputValue}
        shouldShowTeamToggle={hasTeamsWithPreferredData && isLargerThanSm}
        placeholder="Search metrics"
      />
      <Grid gridTemplateColumns={['1fr', '240px auto 1fr']}>
        {isLargerThanSm && (
          <>
            <GroupsList
              groups={metricGroups}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
            <Divider orientation="vertical" />
          </>
        )}
        <ItemsList
          filteredOptions={filteredOptions}
          draftSelected={draftSelected}
          setDraftSelected={setDraftSelected}
        />
      </Grid>
    </Grid>
  )
}
