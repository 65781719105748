import { useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import {
  dashboardStateAtom,
  newDashboardAtom,
  focusDashboardStatusAtom,
} from '../atoms/dashboardViewState'

export const useDashboardState = () => useAtomValue(dashboardStateAtom)
export const useSetDashboardState = () => useSetAtom(dashboardStateAtom)
export const useNewDashboardState = () => useAtomValue(newDashboardAtom)

export const dashboardNameAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('name'),
)
export const dashboardIconNameAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('iconName'),
)
export const dashboardIconColorAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('iconColor'),
)
export const dashboardLayoutAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('layout'),
)
export const dashboardIdAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('id'),
)
export const dashboardOwnerAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('owner'),
)
export const dashboardVisibilityAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('visibility'),
)
export const dashboardCreatedAtAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('createdAt'),
)
export const dashboardUpdatedAtAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('updatedAt'),
)

export const useDashboardStatus = () => useAtomValue(focusDashboardStatusAtom)
export const useSetDashboardStatus = () => useSetAtom(focusDashboardStatusAtom)
