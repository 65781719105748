import { getDatePreset, type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { LegendRow } from 'features/optimizations/components/LegendRow/LegendRow'
import { PieChartSkeleton } from 'features/segmentations/shared/RulesContainer/PieChartSkeleton'
import { useSegmentationCustomerLabels } from 'features/segmentations/views/CustomerLabelsView/graphql/useCustomerLabels'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { type FieldArrayWithId } from 'react-hook-form'
import { colorTheme } from 'ui/theme/colors'
import { PieChart } from '../../../../shared/RulesContainer/PieChart'
import { type CustomerRuleForm } from '../../../../shared/RulesContainer/types'
import { useCustomerChartMetrics } from '../../graphql/useCustomerChartMetrics'

interface SegmentationPieChartsProps {
  selectedDatePresetId: DYNAMIC_DATE_ID
  rules: FieldArrayWithId<
    { rules: CustomerRuleForm[] },
    'rules',
    'internal_id'
  >[]
}

const METRICS = {
  CUSTOMERS: 'customer:uniqueCustomers:dema',
  ORDER_COUNT: 'order:count:dema',
  GROSS_SALES: 'order:total:dema',
} as const

type MetricKey = (typeof METRICS)[keyof typeof METRICS]

const metrics = Object.values(METRICS) satisfies MetricKey[]

export const SegmentationPieCharts: React.FC<SegmentationPieChartsProps> = ({
  selectedDatePresetId,
  rules,
}) => {
  const selectedDatePreset = getDatePreset(selectedDatePresetId)
  const { segmentationCustomerLabels } = useSegmentationCustomerLabels()
  const { frontendIds = [] } = useMerchantInfo()
  const { segmentationCustomerChartMetrics, loading } = useCustomerChartMetrics(
    {
      params: {
        rules:
          rules
            ?.filter((rule) => !!rule.label)
            .map((rule) => ({
              id: rule.id,
              labelId: rule.label?.id ?? '',
              labelName: rule.label?.name ?? '',
              filters: rule.filters,
            })) || [],
        dynamicDate: selectedDatePreset.id,
        statisticsInput: {
          frontendIds,
          startDate: selectedDatePreset.value[0]?.toISOString() ?? '',
          endDate: selectedDatePreset.value[1]?.toISOString() ?? '',
          dimensions: ['customerId'],
          metrics,
        },
      },
    },
  )

  const normalizedMetrics = useNormalizedMetrics()

  if (loading) return <PieChartSkeleton charts={3} />

  const { uncategorized, ...aggregatedMetrics } =
    segmentationCustomerChartMetrics

  const pieCharts = [
    {
      metricKey: METRICS.CUSTOMERS,
      label: 'Customers',
    },
    {
      metricKey: METRICS.ORDER_COUNT,
      label: 'Order Count',
      htmlLabel: 'Order Count',
    },
    {
      metricKey: METRICS.GROSS_SALES,
      label: 'Gross Sales',
      htmlLabel: 'Gross Sales',
    },
  ] satisfies { metricKey: MetricKey; label: string; htmlLabel?: string }[]

  return (
    <Flex
      p={4}
      shadow="base"
      bg="white"
      gap={6}
      w="max-content"
      shrink={0}
      h="max-content"
      position="sticky"
      top={4}
    >
      <Box px={6}>
        {/* overflow visible as the charts are hacked to go outside of container size, so this targets them to make them visible */}
        <Stack gap={8} sx={{ '*': { overflow: 'visible !important' } }}>
          {pieCharts.map(({ metricKey, htmlLabel, label }) => (
            <PieChart
              key={metricKey}
              metricKey={metricKey}
              label={label}
              htmlLabel={htmlLabel}
              format={normalizedMetrics[metricKey]?.format}
              selectedDatePreset={selectedDatePreset}
              aggregatedMetrics={aggregatedMetrics}
              uncategorized={uncategorized}
              labels={segmentationCustomerLabels}
            />
          ))}
        </Stack>
      </Box>
      <Box>
        {segmentationCustomerLabels
          ?.filter((label) => rules.find((rule) => rule.label?.id === label.id))
          .map((label) => (
            <LegendRow
              key={label.id}
              color={label.iconColor}
              label={label.name}
              type="column"
            />
          ))}

        {Object.values(uncategorized).some((value) => value > 0) && (
          <LegendRow
            color={colorTheme.gray[300]}
            label="Uncategorized"
            type="column"
          />
        )}
      </Box>
    </Flex>
  )
}
