import { type Atom, useStore } from 'jotai'
import { type AtomFamily } from 'jotai/vanilla/utils/atomFamily'
import { useCallback } from 'react'

/**
 * Returns a callback for getting the value of an atom from the store
 * Useful for getting the value of an atom without causing unnecessary re-renders
 */
export const useGetAtomValue = <T>(atom: Atom<T>) => {
  const store = useStore()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(() => store.get(atom), [store])
}

export const useGetAtomFamilyValue = <T, P>(
  atomFamily: AtomFamily<P, Atom<T>>,
) => {
  const store = useStore()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((key: P) => store.get(atomFamily(key)), [store])
}
