import { Box, Flex } from '@chakra-ui/react'
import { DashboardHeader } from 'features/dashboards/components/DashboardHeader/DashboardHeader'
import { DimensionMetricSelectorDrawer } from 'features/reports/components/DimensionMetricSelectorDrawer/DimensionMetricSelectorDrawer'
import { useAtomValue } from 'jotai'
import { LoaderPage } from 'shared'
import { NotFound } from 'shared/NotFound'
import { DashboardViewStatus } from '../atoms/dashboardViewState'
import {
  useWidgetPanelOpenAtom,
  useWidgetPanelSetAtom,
} from '../atoms/widgetPanel'
import { DashboardFilters } from '../components/DashboardFilters/DashboardFilters'
import { DashboardLayout } from '../components/DashboardLayout/DashboardLayout'
import { DashboardStateSync } from '../components/DashboardStateSync'
import { WidgetPanel } from '../components/WidgetPanel/WidgetPanel'
import { WidgetPanelOverlay } from '../components/WidgetPanel/WidgetPanelOverlay'
import { widgetConfig } from '../consts'
import { useDashboardStatus } from '../hooks/useDashboardState'
import { selectedWidgetTypeAtom } from '../hooks/useWidgetsState'

export const DashboardView = () => {
  return (
    <>
      <DashboardStateSync />
      <DashboardViewContent />
    </>
  )
}

const DashboardViewContent = () => {
  const dashboardStatus = useDashboardStatus()
  const widgetPanelOpen = useWidgetPanelOpenAtom()
  const selectedWidgetType = useAtomValue(selectedWidgetTypeAtom)
  const setWidgetPanelOpen = useWidgetPanelSetAtom()

  const { requiresDimensions } = selectedWidgetType
    ? widgetConfig[selectedWidgetType]
    : {}

  if (dashboardStatus === DashboardViewStatus.Loading) return <LoaderPage />

  if (dashboardStatus === DashboardViewStatus.NotFound) return <NotFound />

  return (
    <>
      <Flex direction="column" flex={1} h="full" overflowY="hidden">
        <DashboardHeader />
        <Flex flex={1} h="full" w="full" overflowY="hidden" maxW="full">
          <Flex
            direction="column"
            w="100%"
            flexBasis={0}
            flexShrink={1}
            flexGrow={1}
            h="full"
            overflowY="auto"
            position="relative"
          >
            <Box position="sticky" top={0} zIndex={1} bg="white">
              <DashboardFilters />
            </Box>
            <WidgetPanelOverlay
              isOpen={widgetPanelOpen}
              onClick={() => setWidgetPanelOpen(false)}
            />
            <DashboardLayout />
          </Flex>
          <WidgetPanel />
        </Flex>
      </Flex>
      <DimensionMetricSelectorDrawer
        isToggleDisabled={!requiresDimensions}
        allowInvalidMetricCombinations={!requiresDimensions}
      />
    </>
  )
}
