import { Box } from '@chakra-ui/react'
import { addDays } from 'date-fns'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { TooltipSectionLabel } from 'features/dashboard/components/ChartTooltip/shared/TooltipSectionLabel'
import { getGridLineOptions } from 'features/reports/utils/chart/common'
import { type InferenceGraphPointFieldsFragment } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import type React from 'react'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { yAxisFormatter } from 'utils/chart/common'
import { CHART_PRIMARY_COLOR, staticChartOptions } from 'utils/chart/constants'
import { formatMetric } from 'utils/numberFormats'
import { type EXPERIMENT_METRIC } from '../consts'

import { MARGIN_DAYS } from './KPIChart'
interface PercentLiftChartProps {
  pctLift: InferenceGraphPointFieldsFragment[]
  selectedMetric: EXPERIMENT_METRIC
  startDate: string
  endDate: string
  treatmentPeriod: number
  postTreatmentPeriod: number
}

export const PercentLiftChart: React.FC<PercentLiftChartProps> = ({
  pctLift,
  startDate,
  endDate,
  postTreatmentPeriod,
}) => {
  const postTreatmentEndDate = addDays(new Date(endDate), postTreatmentPeriod)

  const treatmentData = pctLift.map((point) => ({
    x: new Date(point.date).getTime(),
    y: point.value,
    lowerBound: point.lowerBound === null ? undefined : point.lowerBound,
    upperBound: point.upperBound === null ? undefined : point.upperBound,
  }))

  const gridLineOptions = getGridLineOptions(CHART_TYPE_ID.LINE)

  const options: Highcharts.Options = {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      zooming: { type: 'xy' },
      type: CHART_TYPE_ID.LINE,
      height: 250,
    },
    xAxis: {
      ...staticChartOptions.xAxis,
      type: 'datetime',
      max: addDays(postTreatmentEndDate, MARGIN_DAYS).getTime(),
      plotBands: [
        {
          from: new Date(startDate).getTime(),
          to: new Date(endDate).getTime(),
          color: colorTheme.gray[100],
        },
        {
          from: new Date(endDate).getTime(),
          to: postTreatmentEndDate.getTime(),
          color: colorTheme.gray[50],
        },
      ],
      plotLines: [
        {
          value: new Date(startDate).getTime(),
          color: colorTheme.gray[300],
          dashStyle: 'ShortDash',
          width: 1,
        },
        {
          value: new Date(endDate).getTime(),
          color: colorTheme.gray[300],
          dashStyle: 'ShortDash',
          width: 1,
        },
      ],
      crosshair: {
        color: colorTheme.grey[300],
        dashStyle: 'ShortDot',
      },
      ...gridLineOptions.xAxis,
    },
    yAxis: {
      ...staticChartOptions.yAxis,
      title: {
        ...staticChartOptions.yAxis?.title,
        text: `Lift percentage`,
      },
      labels: {
        ...staticChartOptions.yAxis?.labels,
        formatter: yAxisFormatter(METRIC_FORMAT.PERCENT),
      },
      plotLines: [
        {
          value: 0,
          color: colorTheme.gray[300],
          dashStyle: 'ShortDot',
          width: 1,
        },
      ],
      ...gridLineOptions.yAxis,
    },
    series: [
      {
        type: 'line',
        name: `Lift percentage`,
        data: treatmentData,
        color: CHART_PRIMARY_COLOR,
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      ...staticChartOptions.plotOptions,
      series: {
        ...staticChartOptions.plotOptions.series,
        stickyTracking: true,
      },
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      shared: true,
      useHTML: true,
      formatter: function () {
        if (!this.x) return ''

        const date = new Date(this.x)
        const formattedDate = date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })

        const [valuePoint] = this.points ?? []

        const value = valuePoint?.y
          ? formatMetric(METRIC_FORMAT.PERCENT, valuePoint.y)
          : '0'

        const element = (
          <div>
            <TooltipSectionLabel label={formattedDate} />

            <TooltipMetricRow
              iconColor={valuePoint.color?.toString()}
              metricName={`Lift percentage`}
              value={value}
            />
          </div>
        )

        return renderToString(element)
      },
    },
  }

  return (
    <Box position="relative">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  )
}
