import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Typography } from 'components/Typography'
import { startOfDay, subDays } from 'date-fns'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { type FieldError } from 'react-hook-form'
import { toISODateRange } from 'utils/dateRange'

interface FunnelDropdownProps {
  channel: string
  funnelCampaigns: string[]
  error: FieldError | undefined
  onChange: (option: string[]) => void
}

export const FunnelDropdown: React.FC<FunnelDropdownProps> = ({
  channel,
  funnelCampaigns,
  error,
  onChange,
}) => {
  const { frontendIds = [], timezone } = useMerchantInfo()

  // need to memo to avoid re-fetching when the new Date() changes on each render
  const [startDate, endDate] = useMemo(() => {
    const now = startOfDay(new Date())
    const nowMinus30Days = subDays(now, 30)

    return toISODateRange([nowMinus30Days, now], timezone)
  }, [timezone])

  const skipQuery = !frontendIds.length || !channel
  const { statistics, query } = useStatistics({
    skip: skipQuery,
    variables: {
      statisticsParams: {
        frontendIds: frontendIds,
        dimensions: ['funnelCampaign'],
        metrics: ['cost:total'],
        startDate: String(startDate),
        endDate: String(endDate),
        filter: {
          channel: [
            {
              value: [channel],
              comparisonId: 'isAnyOf',
            },
          ],
        },
      },
    },
  })

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      label="Funnel"
      options={statistics?.map(
        ({ funnelCampaign: { value, formattedValue } }) => ({
          id: String(value),
          name: formattedValue,
        }),
      )}
      selected={funnelCampaigns}
      error={error}
      searchPlaceholder="Search funnel"
      buttonText={funnelCampaigns.length ? undefined : 'Select funnel'}
      isLoading={query.loading}
      isFullHeight
      customEmptyState={
        skipQuery ? (
          <Typography px={4} py={2} textAlign="left" fontSize="xs">
            Select a channel to see available funnels.
          </Typography>
        ) : undefined
      }
      setSelected={(option) => onChange(option)}
    />
  )
}
