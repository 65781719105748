import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { transformDashboard } from './utils'

export const DASHBOARD_BY_ID_QUERY = graphql(/* GraphQL */ `
  query DashboardById($id: ID!) {
    viewer {
      id
      dashboard(id: $id) {
        ...DashboardWithOwnerFields
      }
    }
  }
`)

export const useDashboardById = (dashboardId: string | null | undefined) => {
  const query = useQuery(DASHBOARD_BY_ID_QUERY, {
    variables: {
      id: dashboardId ?? '',
    },
    skip: !dashboardId,
  })

  return {
    dashboard: query.data?.viewer?.dashboard
      ? transformDashboard(query.data.viewer.dashboard)
      : undefined,
    query,
  }
}
