import { type Filter } from 'constants/types'
import { widgetAnalyticsConfigAtom } from 'features/dashboards/atoms/dashboardViewState'
import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { normalizedMetricsAtom } from 'graphql/statistics/useMetrics'
import { atom, useAtomValue, useSetAtom, type SetStateAction } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { useMemo } from 'react'
import { getValidFilters } from 'shared/utils/filters'
import { widgetDimensionsStateAtom } from '../useWidgetDimensionsState'
import { widgetMetricsStateAtom } from '../useWidgetMetricState'
import { dashboardFiltersStateAtom } from './useDashboardFiltersState'

const mergeFilters = (
  widgetFilters: Record<string, Filter[]>,
  dashboardFilters: Record<string, Filter[]>,
) => {
  const uniqueFilters = new Set([
    ...Object.keys(widgetFilters),
    ...Object.keys(dashboardFilters),
  ])

  return uniqueFilters.keys().reduce(
    (acc, key) => {
      const widgetFiltersArray = widgetFilters[key] ?? []
      const dashboardFiltersArray = dashboardFilters[key] ?? []

      acc[key] = [...widgetFiltersArray, ...dashboardFiltersArray]

      return acc
    },
    {} as Record<string, Filter[]>,
  )
}

const focusWidgetFiltersStateAtom = atomFamily((widgetId: string | undefined) =>
  focusAtom(widgetAnalyticsConfigAtom(widgetId), (optic) =>
    optic.optional().prop('filters'),
  ),
)

export const widgetFiltersStateAtom = atomFamily(
  (widgetId: string | undefined) =>
    atom(
      (get) => {
        const widgetFilters = get(focusWidgetFiltersStateAtom(widgetId))
        const dashboardFilters = get(dashboardFiltersStateAtom)

        const { dimensions: widgetDimensions } = get(
          widgetDimensionsStateAtom(widgetId),
        )
        const { metrics: widgetMetrics } = get(widgetMetricsStateAtom(widgetId))
        const normalizedMetrics = get(normalizedMetricsAtom)
        const normalizedDimensions = get(normalizedDimensionsAtom)

        const widgetValidFilters = getValidFilters({
          filters: widgetFilters ?? {},
          dimensions: widgetDimensions,
          metrics: widgetMetrics,
          normalizedDimensions,
          normalizedMetrics,
        })

        const dashboardValidFilters = getValidFilters({
          filters: dashboardFilters?.filters ?? {},
          dimensions: dashboardFilters?.aggregatedDimensions ?? [],
          metrics: dashboardFilters?.aggregatedMetrics ?? [],
          normalizedDimensions,
          normalizedMetrics,
        })

        return {
          widgetFilters: widgetValidFilters ?? {},
          aggregatedFilters: mergeFilters(
            widgetValidFilters,
            dashboardValidFilters,
          ),
        }
      },
      (_, set, filtersState: SetStateAction<Record<string, Filter[]>>) => {
        set(focusWidgetFiltersStateAtom(widgetId), filtersState)
      },
    ),
)

export const useWidgetFiltersState = (widgetId: string | undefined) => {
  const filtersAtom = useMemo(
    () => widgetFiltersStateAtom(widgetId),
    [widgetId],
  )

  return useAtomValue(filtersAtom)
}

export const useSetWidgetFiltersState = (widgetId: string | undefined) => {
  const filtersAtom = useMemo(
    () => widgetFiltersStateAtom(widgetId),
    [widgetId],
  )

  return useSetAtom(filtersAtom)
}
