import { Flex, MenuButton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useAddWidgetFilter } from 'features/dashboards/hooks/filters/useAddWidgetFilter'
import { useRemoveWidgetFilter } from 'features/dashboards/hooks/filters/useRemoveWidgetFilter'
import { useWidgetFiltersState } from 'features/dashboards/hooks/filters/useWidgetFiltersState'
import { useWidgetDateState } from 'features/dashboards/hooks/useWidgetDateState'
import { useWidgetMetricsState } from 'features/dashboards/hooks/useWidgetMetricState'
import { selectedWidgetIdAtom } from 'features/dashboards/hooks/useWidgetsState'
import { AddFilterButton } from 'features/reports/components/Filters/AddFilterButton'
import { SelectedFilterChip } from 'features/reports/components/Filters/SelectedFilterChip/SelectedFilterChip'
import { ReportDetailsHeader } from 'features/reports/components/ReportPanel/ReportDetailsPanel/ReportDetailsRow/ReportDetailsHeader'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useAtomValue } from 'jotai'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'

export const WidgetFiltersContainer = () => {
  const selectedWidgetId = useAtomValue(selectedWidgetIdAtom)
  const { widgetFilters } = useWidgetFiltersState(selectedWidgetId)
  const { metrics } = useWidgetMetricsState(selectedWidgetId)
  const {
    resolvedDateState: { dateRange },
  } = useWidgetDateState(selectedWidgetId)
  const normalizedMetrics = useNormalizedMetrics()

  const metricGroups = useMemo(
    () => groupBy(metrics.map((metric) => metric.groupKey)),
    [metrics],
  )

  const addFilter = useAddWidgetFilter(selectedWidgetId)
  const removeFilter = useRemoveWidgetFilter(selectedWidgetId)

  return (
    <Flex flexDirection="column" gap={2}>
      <ReportDetailsHeader title="Filters" />

      {Object.entries(widgetFilters).map(([filterId, filters]) =>
        filters.map((filter, index) => (
          <SelectedFilterChip
            key={filterId + index}
            filter={filter}
            filterId={filterId}
            filterIndex={index}
            filters={widgetFilters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            metricShouldShowAttribution={
              metricGroups[normalizedMetrics[filterId]?.groupKey]?.length > 1
            }
            dateRange={dateRange}
          />
        )),
      )}

      <AddFilterButton
        filters={widgetFilters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        dateRange={dateRange}
        metrics={metrics}
        customMenuButton={
          <MenuButton
            as={Button}
            size="sm"
            colorScheme="gray"
            w="fit-content"
            leadingIcon={{ name: 'FilterIcon' }}
          >
            Add filter
          </MenuButton>
        }
      />
    </Flex>
  )
}
