import { type Filter } from 'constants/types'
import { useGetAtomValue } from 'hooks/useGetAtomValue'
import {
  dashboardFiltersStateAtom,
  useSetDashboardFiltersState,
} from './useDashboardFiltersState'

export const removeDashboardFilter = ({
  filterId,
  filterIndex,
  filters,
}: {
  filterId: string
  filterIndex: number
  filters: Record<string, Filter[]>
}) => {
  const newFilters = {
    ...filters,
  }

  const currentFilter = newFilters[filterId] || []
  const newFilter = currentFilter.filter((_, index) => index !== filterIndex)

  if (newFilter.length > 0) {
    newFilters[filterId] = newFilter
  } else {
    delete newFilters[filterId]
  }

  return newFilters
}

export const useRemoveDashboardFilter = () => {
  const setFilters = useSetDashboardFiltersState()
  const getCurrentFilters = useGetAtomValue(dashboardFiltersStateAtom)

  return ({
    filterId,
    filterIndex,
  }: {
    filterId: string
    filterIndex: number
  }) => {
    const { filters } = getCurrentFilters()

    const newFilters = removeDashboardFilter({ filterId, filterIndex, filters })

    setFilters(newFilters)
  }
}
