import {
  Box,
  MenuButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Dropdown } from 'components/Dropdown/Dropdown/Dropdown'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import {
  type SegmentationCustomerSet,
  type SegmentationSet,
} from 'features/segmentations/graphql/fragments'
import { type Dimension } from 'graphql/statistics/useDimensions'

export const ExportSetModal: React.FC<{
  type: 'Customer' | 'Product'
  enableDimensionSelector: boolean
  filterLabel: 'Customer' | 'Filter'
  filterIcon: 'CustomersIcon' | 'FilterIcon'
  isLoading: boolean
  isDisabled: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  selectedValues: string[]
  setSelectedValues: (ids: string[]) => void
  uniqueValues: string[]
  sets: SegmentationSet[] | SegmentationCustomerSet[]
  selectedSet: SegmentationSet | SegmentationCustomerSet
  setSelectedSet: (set: SegmentationSet | SegmentationCustomerSet) => void
  dimensions: Dimension[]
  selectedDimension: Dimension
  setSelectedDimension: (dimension: Dimension) => void
}> = ({
  type,
  enableDimensionSelector,
  filterLabel,
  filterIcon,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isDisabled,
  selectedValues,
  setSelectedValues,
  uniqueValues,
  sets,
  selectedSet,
  setSelectedSet,
  dimensions,
  selectedDimension,
  setSelectedDimension,
}) => {
  const typeLower = type.toLowerCase()

  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Export to ${typeLower} set`}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Typography
            fontSize="xs"
            color="gray.700"
            fontWeight={500}
            lineHeight={4}
          >
            {`Your selection will be added as a rule in the ${typeLower} set.`}
          </Typography>

          {enableDimensionSelector && (
            <Box mt={4}>
              <Typography
                fontSize="xs"
                color="gray.700"
                fontWeight={500}
                lineHeight={4}
                mb={1}
              >
                Dimension
              </Typography>
              <ComboBox
                selectionMode={SELECTION_MODE.SINGLE}
                customMenuButton={
                  <MenuButton
                    as={Button}
                    size="sm"
                    colorScheme="gray"
                    w="100%"
                    textAlign="left"
                    overflow="hidden"
                    leadingIcon={{ name: 'TableIcon' }}
                    trailingIcon={{ name: 'ChevronDownIcon' }}
                  >
                    {selectedDimension.label}
                  </MenuButton>
                }
                menuProps={{ closeOnBlur: true }}
                options={dimensions.map((dimension) => ({
                  id: dimension.id,
                  name: dimension.label,
                }))}
                selected={selectedDimension.id}
                setSelected={(selected) =>
                  setSelectedDimension(
                    dimensions.find((d) => d.id === selected) as Dimension,
                  )
                }
              />
            </Box>
          )}

          <Box mt={4}>
            <Typography
              fontSize="xs"
              color="gray.700"
              fontWeight={500}
              lineHeight={4}
              mb={1}
            >
              {filterLabel}
            </Typography>
            <ComboBox
              selectionMode={SELECTION_MODE.MULTIPLE}
              customMenuButton={
                <MenuButton
                  as={Button}
                  size="sm"
                  colorScheme="gray"
                  w="100%"
                  textAlign="left"
                  overflow="hidden"
                  leadingIcon={{ name: filterIcon }}
                  trailingIcon={{ name: 'ChevronDownIcon' }}
                >
                  {selectedValues.length === 0 ? (
                    `Select ${selectedDimension.label}s`
                  ) : (
                    <Typography overflow="hidden" textOverflow="ellipsis">
                      {selectedDimension.label} is{' '}
                      <Typography as="span" fontWeight={500}>
                        {selectedValues[0]}
                        {selectedValues.length && selectedValues.length > 1 && (
                          <> and {selectedValues.length - 1} more</>
                        )}
                      </Typography>
                    </Typography>
                  )}
                </MenuButton>
              }
              menuProps={{ closeOnBlur: true }}
              options={uniqueValues.map((value) => ({
                id: value,
                name: value,
              }))}
              selected={selectedValues}
              setSelected={(selected) => setSelectedValues(selected)}
            />
          </Box>

          <Box mt={4}>
            <Typography
              fontSize="xs"
              color="gray.700"
              fontWeight={500}
              lineHeight={4}
              mb={1}
            >
              {type} set
            </Typography>

            <Dropdown
              callback={(set) => {
                setSelectedSet(set)
              }}
              size="sm"
              customMenuButton={
                <MenuButton
                  as={Button}
                  size="sm"
                  colorScheme="gray"
                  w="100%"
                  textAlign="left"
                  leadingIcon={{
                    name: selectedSet.iconName,
                    color: selectedSet.iconColor,
                  }}
                  trailingIcon={{ name: 'ChevronDownIcon' }}
                >
                  {selectedSet.name}
                </MenuButton>
              }
              options={sets.map((set) => ({
                ...set,
                leftItem: (
                  <Icon
                    name={set.iconName}
                    color={set.iconColor}
                    size="small"
                  />
                ),
              }))}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>

          <Button
            type="submit"
            onClick={onSubmit}
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            Export
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
