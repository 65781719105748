import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { FilterOperator } from 'components/Filters/types'
import { ChartSortOrder, type ReportResult } from 'graphql/reports/types'

export const topCampaignsReportId = '0191982a-e752-7220-b2c4-555a92f77637'
export const topCategoriesReportId = '0191982b-e472-7447-924e-aecbe990b993'
export const topProductsReportId = '0191982c-d098-7aa5-b436-5fc97bef9be6'
export const topVouchersReportId = '01919828-660a-7552-98aa-4d2ad8282da5'

export const demaReports: ReportResult[] = [
  {
    id: '01919802-d355-7225-9bda-e306362ffb97',
    name: 'Starting view',
    description:
      'Consider changing Dates, Chart Settings, Filters, Dimensions, Metrics or switch to another report to get the insights you are looking for',
    analyticsConfig: {
      id: '',
      __typename: 'AnalyticsConfig',
      chart: {
        xAxis: 'day',
        color: 'compare',
        series: [
          {
            key: 'order:netGrossProfit3:dema',
            type: 'line',
          },
        ],
        chartSorting: {
          key: 'order:netGrossProfit3:dema',
          order: ChartSortOrder.DESC,
        },
      },
      dimensions: ['channel', 'channelGroup'],
      metrics: [
        'session:count',
        'session:conversionRate:dema',
        'order:count:dema',
        'order:total:dema',
        'order:netGrossProfit3:dema',
        'order:netGrossMargin3:dema',
        'order:avgOrderValue:dema',
        'cost:total',
        'cost:epRoas:dema',
        'cost:roas:dema',
      ],
      dynamicDate: 'last-28-days',
      startDate: '2023-07-17T00:00:00+02:00',
      endDate: '2023-08-13T23:59:59+02:00',
      compareDynamicDate: 'preceding-period-matching',
      compareStartDate: '2023-06-19T00:00:00+02:00',
      compareEndDate: '2023-07-16T23:59:59+02:00',
      compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
      filters: {},
      tableState: [],
    },
    visibility: [{ __typename: 'Merchant', id: '', name: '' }],
    updatedAt: '2024-08-29T00:00:00.000Z',
    owner: { id: '', externalId: '' },
    createdAt: '2024-08-29T00:00:00.000Z',
    favorite: false,
    labels: [],
  },
  {
    id: '0191b82b-f73e-711e-af53-6f8a308214e8',
    name: 'Weekly report',
    description:
      'Understand how your business is performing per market on a weekly basis',
    analyticsConfig: {
      id: '',
      __typename: 'AnalyticsConfig',
      chart: {
        xAxis: 'market',
        color: 'compare',
        series: [
          {
            key: 'order:netGrossProfit3:dema',
            type: 'line',
          },
        ],
        chartSorting: {
          key: 'order:netGrossProfit3:dema',
          order: ChartSortOrder.DESC,
        },
      },
      dimensions: ['market'],
      metrics: [
        'session:count',
        'session:conversionRate:dema',
        'order:count:dema',
        'order:total:dema',
        'order:netGrossProfit1:dema',
        'order:netGrossProfit2:dema',
        'order:netGrossProfit3:dema',
        'order:netGrossMargin1:dema',
        'order:netGrossMargin2:dema',
        'order:netGrossMargin3:dema',
        'order:avgOrderValue:dema',
        'cost:total',
        'cost:roas:dema',
        'cost:epRoas:dema',
        'cost:costOfSales:dema',
      ],
      dynamicDate: 'last-week',
      startDate: '2023-07-17T00:00:00+02:00',
      endDate: '2023-08-13T23:59:59+02:00',
      compareDynamicDate: 'preceding-period-matching',
      compareStartDate: null,
      compareEndDate: null,
      compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
      filters: {},
      tableState: [],
    },
    visibility: [{ __typename: 'Merchant', id: '', name: '' }],
    updatedAt: '2024-08-29T00:00:00.000Z',
    owner: { id: '', externalId: '' },
    createdAt: '2024-08-29T00:00:00.000Z',
    favorite: false,
    labels: [],
  },
  {
    id: topVouchersReportId,
    name: 'Top vouchers',
    description: 'Best performing vouchers',
    analyticsConfig: {
      id: '',
      __typename: 'AnalyticsConfig',
      chart: {
        xAxis: 'voucher',
        color: null,
        series: [
          {
            key: 'order:total:dema',
            type: 'column',
          },
        ],
        chartSorting: {
          key: 'order:total:dema',
          order: ChartSortOrder.DESC,
        },
      },
      dimensions: ['voucher'],
      metrics: [
        'order:count:dema',
        'order:total:dema',
        'order:netGrossProfit2:dema',
        'order:netGrossMargin1:dema',
        'order:netGrossMargin2:dema',
        'order:avgOrderValue:dema',
      ],
      dynamicDate: 'last-7-days',
      compareDynamicDate: 'preceding-period-matching',
      compareStartDate: null,
      compareEndDate: null,
      compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
      filters: {
        voucher: [
          {
            comparisonId: FilterOperator.isNoneOf,
            value: [''],
          },
        ],
      },
      endDate: '2023-08-16T23:59:59+02:00',
      startDate: '2023-07-20T00:00:00+02:00',
      tableState: [],
    },
    visibility: [{ __typename: 'Merchant', id: '', name: '' }],
    updatedAt: '2024-08-29T00:00:00.000Z',
    owner: { id: '', externalId: '' },
    createdAt: '2024-08-29T00:00:00.000Z',
    favorite: false,
    labels: [],
  },
  {
    id: topCampaignsReportId,
    name: 'Top campaigns',
    description: 'Best performing campaigns',
    analyticsConfig: {
      id: '',
      __typename: 'AnalyticsConfig',
      chart: {
        xAxis: 'day',
        color: 'compare',
        series: [
          {
            key: 'order:netGrossProfit2',
            type: 'line',
          },
        ],
        chartSorting: {
          key: 'order:netGrossProfit2',
          order: ChartSortOrder.DESC,
        },
      },
      dimensions: ['channel', 'channelGroup', 'campaign'],
      metrics: [
        'order:netGrossProfit2:dema',
        'order:netGrossMargin2:dema',
        'order:netGrossProfit3:dema',
        'order:total:dema',
        'order:avgOrderValue:dema',
        'session:conversionRate:dema',
        'product:views',
        'product:productConversionRate:dema',
      ],
      dynamicDate: 'last-7-days',
      compareDynamicDate: 'preceding-period-matching',
      compareStartDate: null,
      compareEndDate: null,
      compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
      filters: {
        campaign: [{ comparisonId: FilterOperator.isNoneOf, value: [''] }],
      },
      endDate: '2023-08-16T23:59:59+02:00',
      startDate: '2023-07-20T00:00:00+02:00',
      tableState: [],
    },
    visibility: [{ __typename: 'Merchant', id: '', name: '' }],
    updatedAt: '2024-08-29T00:00:00.000Z',
    owner: { id: '', externalId: '' },
    createdAt: '2024-08-29T00:00:00.000Z',
    favorite: false,
    labels: [],
  },
  {
    id: topCategoriesReportId,
    name: 'Top categories',
    description: 'Best performing categories',
    analyticsConfig: {
      id: '',
      __typename: 'AnalyticsConfig',
      chart: {
        xAxis: 'day',
        color: 'compare',
        series: [
          {
            key: 'order:netGrossProfit2:dema',
            type: 'line',
          },
        ],
        chartSorting: {
          key: 'order:netGrossProfit2:dema',
          order: ChartSortOrder.DESC,
        },
      },
      dimensions: ['category'],
      metrics: [
        'product:views',
        'order:total:dema',
        'order:netGrossProfit2:dema',
        'order:netGrossMargin1:dema',
        'order:netGrossMargin2:dema',
        'order:avgOrderValue:dema',
        'product:productConversionRate:dema',
      ],
      dynamicDate: 'last-7-days',
      compareDynamicDate: 'preceding-period-matching',
      compareStartDate: null,
      compareEndDate: null,
      compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
      filters: {},
      endDate: '2023-08-16T23:59:59+02:00',
      startDate: '2023-07-20T00:00:00+02:00',
      tableState: [],
    },
    visibility: [{ __typename: 'Merchant', id: '', name: '' }],
    updatedAt: '2024-08-29T00:00:00.000Z',
    owner: { id: '', externalId: '' },
    createdAt: '2024-08-29T00:00:00.000Z',
    favorite: false,
    labels: [],
  },
  {
    id: topProductsReportId,
    name: 'Top products',
    description: 'Best performing products',
    analyticsConfig: {
      id: '',
      __typename: 'AnalyticsConfig',
      chart: {
        xAxis: 'day',
        color: 'compare',
        series: [
          {
            key: 'order:netGrossProfit2:dema',
            type: 'line',
          },
        ],
        chartSorting: {
          key: 'order:netGrossProfit2:dema',
          order: ChartSortOrder.DESC,
        },
      },
      dimensions: ['productName'],
      metrics: [
        'product:views',
        'order:total:dema',
        'order:netGrossProfit2:dema',
        'order:grossMargin1:dema',
        'order:netGrossMargin2:dema',
        'order:avgOrderValue:dema',
        'product:productConversionRate:dema',
      ],
      dynamicDate: 'last-7-days',
      compareDynamicDate: 'preceding-period-matching',
      compareStartDate: null,
      compareEndDate: null,
      compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
      filters: {},
      endDate: '2023-08-16T23:59:59+02:00',
      startDate: '2023-07-20T00:00:00+02:00',
      tableState: [],
    },
    visibility: [{ __typename: 'Merchant', id: '', name: '' }],
    updatedAt: '2024-08-29T00:00:00.000Z',
    owner: { id: '', externalId: '' },
    createdAt: '2024-08-29T00:00:00.000Z',
    favorite: false,
    labels: [],
  },
]
