import { Box, type BoxProps } from '@chakra-ui/react'
import { type UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { RowDivider } from './DashboardRow/RowDivider'

type DroppableContainerProps = BoxProps & {
  id: UniqueIdentifier
  items: UniqueIdentifier[]
}

export const DroppableContainer = ({
  id,
  items,
  ...props
}: DroppableContainerProps) => {
  const { isOver, setNodeRef, isSorting } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
  })

  return (
    <Box
      ref={setNodeRef}
      boxSizing="border-box"
      // Only show the droppable indicator when sorting
      // This is because when not sorting, the resize handle is visible in the same place
      display={isSorting ? 'block' : 'none'}
      {...props}
    >
      {isOver && <RowDivider isActive />}
    </Box>
  )
}
