import { Typography } from 'components/Typography'
import { parseISO } from 'date-fns'
import { dashboardUpdatedAtAtom } from 'features/dashboards/hooks/useDashboardState'
import { useAtomValue } from 'jotai'
import { formatDate } from 'utils/formatDate'

export const LastUpdateSection = () => {
  const updatedAt = useAtomValue(dashboardUpdatedAtAtom)
  const formattedUpdatedAt = updatedAt ? formatDate(parseISO(updatedAt)) : null

  return (
    <>
      {formattedUpdatedAt && (
        <Typography fontSize="xs" color="grey.600" lineHeight={4}>
          Last updated{' '}
          <Typography
            as="time"
            lineHeight={4}
            fontSize="xs"
            color="gray.800"
            dateTime={formattedUpdatedAt}
          >
            {formattedUpdatedAt}
          </Typography>
        </Typography>
      )}
    </>
  )
}
