import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type GetSegmentationProductChartMetricsInput } from 'generated/graphql/graphql'

const PRODUCT_CHART_METRICS_QUERY = graphql(/* GraphQL */ `
  query ProductChartMetrics($params: GetSegmentationProductChartMetricsInput!) {
    viewer {
      id
      merchant {
        id
        segmentationProductChartMetrics(params: $params)
      }
    }
  }
`)

export const useProductChartMetrics = ({
  params,
}: {
  params: GetSegmentationProductChartMetricsInput
}) => {
  const { data, ...query } = useQuery(PRODUCT_CHART_METRICS_QUERY, {
    variables: { params },
  })

  return {
    segmentationProductChartMetrics: data?.viewer?.merchant
      .segmentationProductChartMetrics as Record<
      string,
      Record<string, number>
    >,
    ...query,
  }
}
