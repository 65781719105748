import { Box, Flex, FocusLock } from '@chakra-ui/react'
import { useRef, type FC } from 'react'
import { isFilterOperatorPercent } from 'shared/utils/analyticsConfig'
import { FilterGroup } from '../shared/FilterGroup/FilterGroup'
import { FilterPopover } from '../shared/FilterPopover'
import { FreeTextInputField } from '../shared/FreeTextInputField'
import { GroupHeader } from '../shared/GroupHeader'
import {
  FilterOperator,
  type BaseFilterProps,
  type AddFilterArgsBase,
  type FilterTuple,
} from '../types'
import { BetweenFilterInputField } from './BetweenFilterInputField'
import { useNumberFilter } from './useNumberFilter'

export type AddNumberFilterArgs = AddFilterArgsBase & {
  value: string | FilterTuple
}

export type NumberFilterProps = Omit<
  BaseFilterProps,
  'options' | 'selectedOptions' | 'isLoading'
> & {
  values: string[] | string
}

const numberFilterOperatorOptions = [
  FilterOperator.greaterThan,
  FilterOperator.lessThan,
  FilterOperator.between,
  FilterOperator.topPercent,
  FilterOperator.bottomPercent,
]

export const NumberFilter: FC<NumberFilterProps> = ({
  filterId,
  filterIndex,
  filterLabel,
  defaultSelectedFilter,
  isOpen,
  onOpen,
  onClose,
  filters,
  isOpenByDefault = false,
  values,
  addFilter,
  removeFilter,
  ...rest
}) => {
  const {
    showIsBetweenSection,
    selectedFilterGroup,
    draftValuesFilterTuple,
    draftValueNumber,
    setSelectedFilterGroup,
    updateNumberFilter,
    setDraftValueNumber,
    setDraftValuesFilterTuple,
  } = useNumberFilter({
    values,
    filters,
    defaultSelectedFilter,
    filterId,
    filterIndex,
    addFilter,
    removeFilter,
  })

  const focusedElementRef = useRef<HTMLInputElement>(null)
  const closeCallback = () => {
    onClose?.()
    updateNumberFilter()
  }

  return (
    <FilterPopover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={closeCallback}
      isOpenByDefault={isOpenByDefault}
      focusedElementRef={focusedElementRef}
      {...rest}
    >
      <FocusLock>
        <Flex flexDir="column">
          {filterLabel && <GroupHeader label={filterLabel} />}
          <FilterGroup
            options={numberFilterOperatorOptions}
            defaultValue={selectedFilterGroup}
            setSelectedFilter={setSelectedFilterGroup}
          />
          <Box py={2} px={4} mb={4}>
            {showIsBetweenSection ? (
              <BetweenFilterInputField
                defaultValue={draftValuesFilterTuple}
                onChange={setDraftValuesFilterTuple}
                onSubmit={closeCallback}
              />
            ) : (
              <FreeTextInputField
                type="number"
                value={draftValueNumber ?? null}
                onChange={(val) =>
                  setDraftValueNumber(
                    val && !isNaN(Number(val)) ? Number(val) : null,
                  )
                }
                onSubmit={closeCallback}
                ref={focusedElementRef}
                helperText={
                  isFilterOperatorPercent(selectedFilterGroup) ? '%' : undefined
                }
              />
            )}
          </Box>
        </Flex>
      </FocusLock>
    </FilterPopover>
  )
}
