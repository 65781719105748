import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { useCallback } from 'react'

const DELETE_DASHBOARD_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteDashboardMutation($id: ID!) {
    deleteDashboard(id: $id) {
      id
      __typename
    }
  }
`)

type DeleteDashboardParams = {
  id: string
}

export const useDeleteDashboard = (): [
  typeof deleteDashboard,
  typeof state,
] => {
  const [mutation, state] = useMutation(DELETE_DASHBOARD_MUTATION)

  const deleteDashboard = useCallback(
    ({ id }: DeleteDashboardParams) => {
      return mutation({
        variables: {
          id,
        },
        optimisticResponse: {
          deleteDashboard: {
            id,
            __typename: 'Dashboard',
          },
        },
        update: (cache, { data: deleteDashboard }) => {
          if (!deleteDashboard?.deleteDashboard) return

          cache.evict({
            id: cache.identify(deleteDashboard?.deleteDashboard),
          })
          cache.gc()
        },
      })
    },
    [mutation],
  )

  return [deleteDashboard, state]
}
