import { type BoxProps, Flex, Box } from '@chakra-ui/react'
import { forwardRef } from 'react'

interface RowDividerProps extends BoxProps {
  isActive: boolean
}

export const RowDivider = forwardRef<HTMLDivElement, RowDividerProps>(
  ({ isActive, ...props }, ref) => {
    return (
      <Flex
        h="100%"
        w="100%"
        alignItems="center"
        justifyContent="center"
        opacity={isActive ? 1 : 0}
        transition="opacity 0.1s ease-in-out"
        {...props}
        ref={ref}
      >
        <Box h="2px" w="100%" bg="blue.500" />
      </Flex>
    )
  },
)

RowDivider.displayName = 'RowDivider'
