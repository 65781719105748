import { type Filter } from 'constants/types'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { normalizedMetricsAtom } from 'graphql/statistics/useMetrics'
import { useGetAtomValue } from 'hooks/useGetAtomValue'
import { type SetStateAction, atom, useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { analyticsConfigAtom } from 'shared/atoms/analyticsConfigState'
import { getValidFilters } from 'shared/utils/filters'
import { dimensionsStateAtom } from '../useDimensionsState'
import { useIsNewAnalyticsConfigFlow } from '../useIsNewAnalyticsConfigFlow'
import { metricsStateAtom } from '../useMetricsState'

const focusFiltersStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('filters'),
)

export const filtersStateAtom = atom(
  (get) => {
    const filters = get(focusFiltersStateAtom)
    const { dimensions } = get(dimensionsStateAtom)
    const { metrics } = get(metricsStateAtom(false))
    const normalizedMetrics = get(normalizedMetricsAtom)
    const normalizedDimensions = get(normalizedDimensionsAtom)

    return getValidFilters({
      filters,
      dimensions,
      metrics,
      normalizedDimensions,
      normalizedMetrics,
    })
  },
  (_, set, filtersState: SetStateAction<Record<string, Filter[]>>) => {
    set(focusFiltersStateAtom, filtersState)
  },
)

export const useFiltersState = () => useAtomValue(filtersStateAtom)

export const useSetFiltersState = () => {
  const setFiltersStateAtom = useSetAtom(filtersStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewAnalyticsConfigFlow = useIsNewAnalyticsConfigFlow()
  const getAnalyticsConfigFromStore = useGetAtomValue(analyticsConfigAtom)

  const setFiltersState = useCallback(
    async (payload: SetStateAction<Record<string, Filter[]>>) => {
      setFiltersStateAtom(payload)
      if (!isNewAnalyticsConfigFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        if (typeof payload === 'function') {
          newAnalyticsConfig.filters = payload(newAnalyticsConfig.filters)
        } else {
          newAnalyticsConfig.filters = payload
        }

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [
      createAnalyticsConfig,
      getAnalyticsConfigFromStore,
      isNewAnalyticsConfigFlow,
      setFiltersStateAtom,
    ],
  )

  return setFiltersState
}
