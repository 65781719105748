import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type DashboardResult } from '../types'

const emptyDashboards: DashboardResult[] = []

export const USER_DASHBOARDS_QUERY = graphql(/* GraphQL */ `
  query UserDashboards {
    viewer {
      id
      dashboards {
        ...DashboardWithOwnerFields
      }
    }
  }
`)

export const useUserDashboards = () => {
  const { data, loading } = useQuery(USER_DASHBOARDS_QUERY)

  const dashboards = (data?.viewer?.dashboards ??
    emptyDashboards) as DashboardResult[]

  return { dashboards, loading }
}
