import type { OrganizationResource } from '@clerk/types'
import { type DashboardVisibility } from 'features/dashboards/types'
import { type MerchantFieldsFragment } from 'generated/graphql/graphql'
import { type ReportVisibility } from 'graphql/reports/types'

export const ORGANIZATION_ID = '*'

export type ResourceVisibility = DashboardVisibility | ReportVisibility

type ResourceVisibilityType = 'private' | 'team' | 'organisation'

export const RESOURCE_VISIBILITY = {
  PRIVATE: 'private',
  TEAM: 'team',
  ORGANISATION: 'organisation',
} satisfies Record<string, ResourceVisibilityType>

export const RESOURCE_VISIBILITY_SORT_ORDER = {
  [RESOURCE_VISIBILITY.PRIVATE]: 0,
  [RESOURCE_VISIBILITY.ORGANISATION]: 1,
  [RESOURCE_VISIBILITY.TEAM]: 2,
} satisfies Record<ResourceVisibilityType, number>

export const getResourceVisibilityType = <T extends ResourceVisibility>(
  visibility?: T[],
): ResourceVisibilityType => {
  if (!visibility || visibility.length === 0) return RESOURCE_VISIBILITY.PRIVATE
  if (getIsMerchantVisibility(visibility[0]))
    return RESOURCE_VISIBILITY.ORGANISATION

  return RESOURCE_VISIBILITY.TEAM
}

export const getIsMerchantVisibility = (
  visibility: ResourceVisibility | undefined,
): visibility is MerchantFieldsFragment => visibility?.__typename === 'Merchant'

export const mapToVisibility = <T extends ResourceVisibility>(
  visibility?: T[],
): string[] =>
  visibility?.map((visibilityItem) =>
    getIsMerchantVisibility(visibilityItem)
      ? ORGANIZATION_ID
      : visibilityItem.id,
  ) || []

export const getMerchantVisibilityFromOrganization = (
  organization: OrganizationResource | null | undefined,
): MerchantFieldsFragment[] => {
  return [
    {
      id: String(organization?.publicMetadata.dema_id),
      name: String(organization?.name),
      __typename: 'Merchant',
    },
  ]
}
