import { Box } from '@chakra-ui/react'
import { useWidgetPanelOpenAtom } from 'features/dashboards/atoms/widgetPanel'
import { selectedWidgetTypeAtom } from 'features/dashboards/hooks/useWidgetsState'
import { WidgetType } from 'features/dashboards/types'
import { useAtomValue } from 'jotai'
import { ChartWidgetPanelContent } from './ChartWidgetPanelContent'
import { KpiWidgetPanelContent } from './KpiWidgetPanelContent'
import { TableWidgetPanelContent } from './TableWidgetPanelContent'
import { WidgetStateSync } from './WidgetStateSync'

const WidgetPanelContent = () => {
  const widgetType = useAtomValue(selectedWidgetTypeAtom)
  const widgetPanelOpen = useWidgetPanelOpenAtom()

  if (!widgetPanelOpen) return null

  return (
    <Box>
      {widgetType === WidgetType.CHART && <ChartWidgetPanelContent />}
      {widgetType === WidgetType.KPI && <KpiWidgetPanelContent />}
      {widgetType === WidgetType.TABLE && <TableWidgetPanelContent />}
    </Box>
  )
}

export const WidgetPanel = () => {
  return (
    <>
      <WidgetPanelContent />
      <WidgetStateSync />
    </>
  )
}
