import { useMultiSelectLabel } from 'components/Filters/useMultiSelectLabel'
import {
  DimensionMetricSelectorDrawerOpenState,
  useDimensionMetricSelectorDrawerSetAtom,
} from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import { ReportDetailRow } from './ReportDetailsRow/ReportDetailsRow'

const infoDetails = {
  tooltipText:
    'A dimension is a property of your data. Its usually text as opposed to a numerical value. An example of a dimension is Channel, which specifies the channel that the traffic to your web site comes from.',
  link: 'https://dema.ai',
}

export const DimensionsSection = () => {
  const normalizedDimensions = useNormalizedDimensions()
  const { dimensionKeys } = useDimensionsState()
  const setDrawerOpenState = useDimensionMetricSelectorDrawerSetAtom()

  const label = useMultiSelectLabel({
    selectedIds: dimensionKeys,
    normalizedOptions: normalizedDimensions,
    defaultEmptyLabel: 'Select dimensions',
  })

  return (
    <ReportDetailRow
      title="Dimensions"
      label={label}
      iconName="TableIcon"
      infoDetails={infoDetails}
      onClick={() =>
        setDrawerOpenState(DimensionMetricSelectorDrawerOpenState.DIMENSION)
      }
    />
  )
}
