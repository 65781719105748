import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import type { OrganizationMembershipResource } from '@clerk/types'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'

export interface EditResourceOwnerModalProps {
  title?: string
  description?: string
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  selectedOwner?: OrganizationMembershipResource
  onSubmit: () => void
}

export const EditResourceOwnerModal: React.FC<EditResourceOwnerModalProps> = ({
  title = 'Transfer ownership',
  description,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Typography>{description}</Typography>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button onClick={onClose} colorScheme="gray" variant="outline">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            isLoading={isLoading}
            colorScheme="blue"
            type="submit"
          >
            Transfer ownership
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
