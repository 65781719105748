import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { DashboardLayoutManager } from '../utils'
import { dashboardLayoutRowStateAtom } from './useDashboardLayoutState'
import { dashboardLayoutAtom } from './useDashboardState'

export const useDashboardLayoutManager = () => {
  const layout = useAtomValue(dashboardLayoutAtom)
  const getRowState = useGetAtomFamilyValue(dashboardLayoutRowStateAtom)

  const layoutManager = useMemo(() => {
    return new DashboardLayoutManager(layout, { getRowState })
  }, [layout, getRowState])

  return layoutManager
}
