import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import type { SegmentationSet, SegmentationRule } from './fragments'

export const SEGMENTATION_SET_BY_ID_QUERY = graphql(/* GraphQL */ `
  query SegmentationSetById($id: ID!) {
    viewer {
      id
      merchant {
        id
        segmentationSet(id: $id) {
          rules {
            ...SegmentationRuleFields
          }
          ...SegmentationSetFields
        }
      }
    }
  }
`)

export const useProductSetById = (productSetId: string) => {
  const query = useQuery(SEGMENTATION_SET_BY_ID_QUERY, {
    variables: {
      id: productSetId,
    },
    skip: !productSetId,
  })

  return {
    productSet: query.data?.viewer?.merchant.segmentationSet as
      | (SegmentationSet & { rules: SegmentationRule[] })
      | null,
    query,
  }
}

export type SegmentationSetByIdQueryResult = NonNullable<
  ReturnType<typeof useProductSetById>['productSet']
>
