import { Divider, MenuItem } from '@chakra-ui/react'
import { Icon, type IconName } from 'components/Icon/Icon'
import { newWidgetAtom } from 'features/dashboards/atoms/dashboardViewState'
import { widgetConfig } from 'features/dashboards/consts'
import {
  type AddWidgetToDashboardOptions,
  useAddWidgetToDashboard,
} from 'features/dashboards/hooks/useAddWidgetToDashboard'
import { type WidgetType } from 'features/dashboards/types'
import { generateWidgetId } from 'features/dashboards/utils'
import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'

export const AddWidgetMenuItems = ({
  onReportPickerOpen,
  options,
}: {
  onReportPickerOpen: () => void
  options?: AddWidgetToDashboardOptions
}) => {
  const { addWidgetToDashboard } = useAddWidgetToDashboard()
  const getNewWidgetState = useGetAtomFamilyValue(newWidgetAtom)

  const handleAddWidget = (widgetType: WidgetType) => {
    const newWidgetState = getNewWidgetState(widgetType)

    addWidgetToDashboard(
      {
        ...newWidgetState,
        id: generateWidgetId(),
        type: widgetType,
      },
      options,
    )
  }

  return (
    <>
      {Object.entries(widgetConfig).map(([widgetType, { icon, name }]) => (
        <MenuItem
          fontSize="sm"
          key={widgetType}
          icon={<Icon name={icon as IconName} size="small" />}
          onClick={() => handleAddWidget(widgetType as WidgetType)}
        >
          {name}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem
        fontSize="sm"
        icon={<Icon name="ReportIcon" size="small" />}
        onClick={onReportPickerOpen}
      >
        Widget from report
      </MenuItem>
    </>
  )
}
