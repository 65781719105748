import { ChartSortOrder, type ChartSorting } from 'graphql/reports/types'
import { atom, useAtomValue } from 'jotai'
import { atomFamily } from 'jotai/utils'
import {
  getDefaultGColor,
  getDefaultSeries,
  getDefaultXAxis,
} from 'shared/utils/analyticsConfig'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { widgetAnalyticsConfigAtom } from '../atoms/dashboardViewState'
import { widgetDateStateAtom } from './useWidgetDateState'
import { widgetDimensionsStateAtom } from './useWidgetDimensionsState'
import { widgetMetricsStateAtom } from './useWidgetMetricState'

export const widgetChartStateAtom = atomFamily((widgetId?: string) =>
  atom((get) => {
    const widgetAnalyticsConfig = get(widgetAnalyticsConfigAtom(widgetId))
    const { xAxis, color, series, chartSorting } =
      widgetAnalyticsConfig?.chart ?? {}
    const { metricKeys } = get(widgetMetricsStateAtom(widgetId))
    const { dimensionKeys } = get(widgetDimensionsStateAtom(widgetId))
    const {
      resolvedDateState: { isCompare },
    } = get(widgetDateStateAtom(widgetId))

    const validSeries = getDefaultSeries(series, metricKeys)

    const isScatterPlot =
      validSeries.length === 1 && validSeries[0].type === CHART_TYPE_ID.SCATTER

    const validXAxis = getDefaultXAxis(
      xAxis,
      isScatterPlot,
      dimensionKeys,
      metricKeys,
    )

    const validGroup = getDefaultGColor(
      color,
      dimensionKeys,
      validXAxis,
      isCompare,
      validSeries.length > 1,
    )

    const isValidChartSorting =
      chartSorting &&
      (series?.find((item) => item.key === chartSorting.key) ||
        xAxis === chartSorting.key)

    const validChartSorting: ChartSorting = isValidChartSorting
      ? chartSorting
      : { key: validSeries[0]?.key, order: ChartSortOrder.DESC }

    return {
      xAxis: validXAxis,
      color: validGroup,
      series: validSeries,
      chartSorting: validChartSorting,
    }
  }),
)

export const useWidgetChartState = (widgetId?: string) =>
  useAtomValue(widgetChartStateAtom(widgetId))
