import { Flex, Image, type ImageProps } from '@chakra-ui/react'
import { Typography } from 'components/Typography'

interface ErrorWidgetContentProps {
  title?: string
  description?: string | null
  imageProps?: ImageProps
}

export const ErrorWidgetContent = ({
  title = 'No available data',
  description = 'Try changing the parameters or remove filters.',
  imageProps,
}: ErrorWidgetContentProps) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={4}
      h="100%"
    >
      <Image
        src="/images/EmptyDashboardWidget.svg"
        alt="Empty widget"
        w="160px"
        {...imageProps}
      />
      <Flex direction="column" alignItems="center" gap={0.5}>
        <Typography
          textAlign="center"
          fontSize="md"
          fontWeight={500}
          color="gray.700"
        >
          {title}
        </Typography>
        {description && (
          <Typography textAlign="center" color="gray.600">
            {description}
          </Typography>
        )}
      </Flex>
    </Flex>
  )
}
