import { getRelativeCellWidth } from 'features/dashboards/utils'
import { Panel } from 'react-resizable-panels'
import WidgetCard from '../WidgetCard/WidgetCard'
import { SortableItem } from './SortableItem'

interface SortableWidgetProps {
  widgetId: string
  minWidth: number
  width: number
  height: number
  index: number
  rowId: string
}

export const SortableWidget = ({
  widgetId,
  width,
  minWidth,
  height,
  index,
  rowId,
}: SortableWidgetProps) => {
  return (
    <Panel
      id={widgetId}
      order={index}
      defaultSize={getRelativeCellWidth(width)}
      minSize={getRelativeCellWidth(minWidth)}
      style={{ boxSizing: 'border-box', overflow: 'initial', minWidth: 0 }}
    >
      <SortableItem id={widgetId}>
        <WidgetCard widgetId={widgetId} height={height} rowId={rowId} />
      </SortableItem>
    </Panel>
  )
}
