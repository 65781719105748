import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { type IconName } from 'components/Icon/Icon'
import { ColorPicker } from 'components/Pickers/ColorPicker'
import { IconPicker } from 'components/Pickers/IconPicker'
import { Typography } from 'components/Typography'
import { useState } from 'react'

interface IconOption {
  name: IconName
  color: string
}
interface PickIconModalProps {
  isOpen: boolean
  onClose: () => void
  onApply: ({ name, color }: IconOption) => void
  currentIcon: IconOption
  iconColors: string[]
  iconNames: IconName[]
  title: string
  description?: string
}

export const PickIconModal: React.FC<PickIconModalProps> = ({
  isOpen,
  onClose,
  onApply,
  title,
  description,
  currentIcon,
  iconColors,
  iconNames,
}) => {
  const [icon, setIcon] = useState(currentIcon)

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIcon(currentIcon)
        onClose()
      }}
      size="sm"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {description && (
            <Typography mb={4} color="gray.600">
              {description}
            </Typography>
          )}

          <Box mb={6}>
            <ColorPicker
              colors={iconColors}
              selectedColor={icon.color}
              setSelectedColor={(color) =>
                setIcon((icon) => ({ ...icon, color }))
              }
            />
          </Box>

          <IconPicker
            iconColor={icon.color}
            icons={iconNames}
            selectedIcon={icon.name}
            setSelectedIcon={(name) => setIcon((icon) => ({ ...icon, name }))}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="solid"
            type="submit"
            colorScheme="primary"
            onClick={() => {
              onApply(icon)
              onClose()
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
