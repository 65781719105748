import { Flex, MenuButton, useDisclosure } from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import type { OrganizationMembershipResource } from '@clerk/types'
import { Button } from 'components/buttons'
import { type DropdownOption } from 'components/Dropdown'
import { ComboBox } from 'components/Dropdown/ComboBox/ComboBox'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import {
  useCombobox,
  type UseComboboxState,
  type UseComboboxStateChangeOptions,
} from 'downshift'
import { ClerkImage } from 'features/reports/components/ClerkImage'
import { getIsTemplateReport, getReportOwnerName } from 'graphql/reports/utils'
import { useOrganisationMembers } from 'graphql/useOrganisationMembers'
import { useCallback, useMemo, useState } from 'react'
import {
  EditResourceOwnerModal,
  type EditResourceOwnerModalProps,
} from './EditResourceOwnerModal'

const handleStateChanges = (
  state: UseComboboxState<DropdownOption>,
  actionAndChanges: UseComboboxStateChangeOptions<DropdownOption>,
) => {
  const { changes, type } = actionAndChanges
  const { InputKeyDownEnter, ItemClick, InputChange, ToggleButtonClick } =
    useCombobox.stateChangeTypes

  switch (type) {
    case ToggleButtonClick:
      return {
        ...changes,
        selectedItem: null,
      }
    case ItemClick:
    case InputKeyDownEnter:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        inputValue: state.inputValue,
      }

    case InputChange: {
      return {
        ...changes,
        inputValue: state.inputValue,
      }
    }
    default:
      return {
        ...changes,
        inputValue: state.inputValue,
      }
  }
}

interface ResourceOwnerProps {
  owner: { id: string; externalId: string } | undefined
  membershipsData: OrganizationMembershipResource[] | undefined
  onOwnerChanged?: (userId: string) => void
  disabled?: boolean
  getModalProps?: (
    owner?: OrganizationMembershipResource,
  ) => Pick<EditResourceOwnerModalProps, 'isLoading' | 'description'>
}

export const ResourceOwner = ({
  owner,
  onOwnerChanged,
  membershipsData,
  disabled,
  getModalProps,
}: ResourceOwnerProps) => {
  const { user } = useUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedOwner, setSelectedOwner] =
    useState<OrganizationMembershipResource>()

  const isTemplateReport = getIsTemplateReport(owner)

  const ownerName = getReportOwnerName({
    owner,
    memberships: membershipsData,
  })

  const { members } = useOrganisationMembers()

  const ownerDetails = membershipsData?.find(
    (member) => member.publicUserData.userId === owner?.externalId,
  )

  const { imageUrl } = ownerDetails?.publicUserData ?? {}

  const options = useMemo(
    () =>
      members?.map((member) => {
        const { firstName, lastName, userId, imageUrl } = member.publicUserData
        const isCurrentUser = userId === user?.id

        return {
          name: `${firstName} ${lastName}${isCurrentUser ? ' (You)' : ''}`,
          id: userId ?? '',
          leftItem: <ClerkImage imageUrl={imageUrl} type="user" />,
        }
      }) ?? [],
    [members, user?.id],
  )

  const handleOwnerSelect = useCallback(
    (userId: string) => {
      const memberData = members?.find(
        (member) => member.publicUserData.userId === userId,
      )

      setSelectedOwner(memberData)
      onOpen()
    },
    [members, onOpen],
  )

  const handleSubmit = () => {
    onOwnerChanged?.(selectedOwner?.publicUserData.userId ?? '')
    onClose()
  }

  const modalProps = getModalProps?.(selectedOwner)

  if (!ownerName) return null

  return isTemplateReport ? (
    <Flex gap={2} alignItems="center">
      <Icon size="small" borderRadius={1} name="DemaDIcon" />
      <Typography>{ownerName}</Typography>
    </Flex>
  ) : (
    <>
      <ComboBox
        options={options}
        selected={owner?.externalId}
        setSelected={handleOwnerSelect}
        selectionMode="single"
        handleStateChanges={handleStateChanges}
        customMenuButton={
          <MenuButton
            as={Button}
            role="group"
            isDisabled={disabled}
            leadingComponent={<ClerkImage imageUrl={imageUrl} type="user" />}
            colorScheme="grey"
            variant="ghost"
            size="sm"
          >
            {ownerName}
          </MenuButton>
        }
        containerProps={{
          display: 'block',
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
          },
        }}
      />
      <EditResourceOwnerModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        selectedOwner={selectedOwner}
        {...modalProps}
      />
    </>
  )
}

ResourceOwner.displayName = 'ResourceOwner'
