import { Box } from '@chakra-ui/react'
import { addDays } from 'date-fns'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { TooltipSectionLabel } from 'features/dashboard/components/ChartTooltip/shared/TooltipSectionLabel'
import { getGridLineOptions } from 'features/reports/utils/chart/common'
import { type ExperimentInferenceMetricFieldsFragment } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import type React from 'react'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import {
  CHART_PRIMARY_COLOR,
  CHART_SECONDARY_COLOR,
  staticChartOptions,
} from 'utils/chart/constants'
import { formatMetric } from 'utils/numberFormats'
import { experimentViewMetrics, type EXPERIMENT_METRIC } from '../consts'

interface KPIChartProps {
  inference: ExperimentInferenceMetricFieldsFragment
  selectedMetric: EXPERIMENT_METRIC
  startDate: string
  endDate: string
  treatmentPeriod: number
  postTreatmentPeriod: number
}
// Add always some extra days to the end of the chart to make it look better
export const MARGIN_DAYS = 5

export const KPIChart: React.FC<KPIChartProps> = ({
  inference,
  selectedMetric,
  startDate,
  endDate,
  postTreatmentPeriod,
}) => {
  const { currency } = useMerchantInfo()

  const metric =
    experimentViewMetrics.find((metric) => metric.id === selectedMetric) ??
    experimentViewMetrics[0]

  const postTreatmentEndDate = addDays(new Date(endDate), postTreatmentPeriod)

  const treatmentData = inference.treatmentObservations.map((point) => ({
    x: new Date(point.date).getTime(),
    y: point.value,
  }))

  const controlData = inference.controlObservations.map((point) => ({
    x: new Date(point.date).getTime(),
    y: point.value,
  }))

  const gridLineOptions = getGridLineOptions(CHART_TYPE_ID.LINE)

  const options: Highcharts.Options = {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      zooming: { type: 'xy' },
      type: CHART_TYPE_ID.LINE,
      height: 250,
    },
    xAxis: {
      ...staticChartOptions.xAxis,
      type: 'datetime',
      max: addDays(postTreatmentEndDate, MARGIN_DAYS).getTime(),
      plotBands: [
        {
          from: new Date(startDate).getTime(),
          to: new Date(endDate).getTime(),
          color: colorTheme.gray[100],
        },
        {
          from: new Date(endDate).getTime(),
          to: postTreatmentEndDate.getTime(),
          color: colorTheme.gray[50],
        },
      ],
      plotLines: [
        {
          value: new Date(startDate).getTime(),
          color: colorTheme.gray[300],
          dashStyle: 'ShortDash',
          width: 1,
        },
        {
          value: new Date(endDate).getTime(),
          color: colorTheme.gray[300],
          dashStyle: 'ShortDash',
          width: 1,
        },
      ],
      crosshair: {
        color: colorTheme.grey[300],
        dashStyle: 'ShortDot',
      },
      ...gridLineOptions.xAxis,
    },
    yAxis: {
      ...staticChartOptions.yAxis,
      title: {
        ...staticChartOptions.yAxis?.title,
        text: `${metric.name} ${
          metric.format === METRIC_FORMAT.CURRENCY ? `(${currency})` : ''
        }`,
      },
      ...gridLineOptions.yAxis,
    },
    series: [
      {
        type: 'line',
        name: 'Treatment group',
        data: treatmentData,
        color: CHART_PRIMARY_COLOR,
        zIndex: 1, // overlap the control group
        marker: {
          enabled: false,
        },
      },
      {
        type: 'line',
        name: 'Control group',
        data: controlData,
        color: CHART_SECONDARY_COLOR,
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      ...staticChartOptions.plotOptions,
      series: {
        ...staticChartOptions.plotOptions.series,
        stickyTracking: true,
      },
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      shared: true,
      useHTML: true,
      formatter: function () {
        if (!this.x) return ''

        const date = new Date(this.x)
        const formattedDate = date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })

        const element = (
          <div>
            <TooltipSectionLabel label={formattedDate} />

            {this.points?.map((point) => {
              const value = point.y
                ? formatMetric(metric.format, point.y, currency)
                : 'N/A'

              return (
                <TooltipMetricRow
                  key={point.series.name}
                  iconColor={point.color?.toString()}
                  metricName={point.series.name}
                  value={value}
                />
              )
            })}
          </div>
        )

        return renderToString(element)
      },
    },
  }

  return (
    <Box position="relative">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  )
}
