import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { dashboardWidgetsAtom } from 'features/dashboards/atoms/dashboardViewState'
import { useEditDashboard } from 'features/dashboards/graphql/useEditDashboard'
import { useDashboardLayoutManager } from 'features/dashboards/hooks/useDashboardLayoutManager'
import {
  dashboardIdAtom,
  dashboardLayoutAtom,
} from 'features/dashboards/hooks/useDashboardState'
import { useWidgetById } from 'features/dashboards/hooks/useWidgetsState'

import { useGetAtomValue } from 'hooks/useGetAtomValue'
import { useAtomValue, useSetAtom } from 'jotai'

interface DeleteWidgetModalProps {
  isOpen: boolean
  onClose: () => void
  widgetId: string | undefined
}

export const DeleteWidgetModal = ({
  isOpen,
  onClose,
  widgetId,
}: DeleteWidgetModalProps) => {
  const dashboardId = useAtomValue(dashboardIdAtom)
  const widget = useWidgetById(widgetId)
  const toast = useToast()

  const [editDashboardMutation, editDashboardState] = useEditDashboard()

  const layoutManager = useDashboardLayoutManager()
  const setDashboardLayoutAtom = useSetAtom(dashboardLayoutAtom)
  const setDashboardWidgetsAtom = useSetAtom(dashboardWidgetsAtom)

  const getDashboardWidgets = useGetAtomValue(dashboardWidgetsAtom)

  const onDeleteWidget = async () => {
    if (!widgetId || !dashboardId) return

    try {
      const currentWidgets = getDashboardWidgets()
      const updatedWidgets = currentWidgets.filter((w) => w.id !== widgetId)

      const updatedLayout = layoutManager.removeWidgetFromLayout(widget)

      setDashboardWidgetsAtom(updatedWidgets)
      setDashboardLayoutAtom(updatedLayout)

      await editDashboardMutation({
        id: dashboardId,
        dashboard: {
          widgets: updatedWidgets,
          layout: updatedLayout,
        },
      })
    } catch (err) {
      toast({ status: 'error', description: 'Could not delete widget' })
      captureException(`Delete widget: ${err}`)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete widget</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Typography>The widget will be permanently deleted.</Typography>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="grey" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={onDeleteWidget}
            isLoading={editDashboardState.loading}
          >
            Delete widget
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
