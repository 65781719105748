import { Flex, type InputProps, useDisclosure } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { type IconName } from 'components/Icon/Icon'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import {
  dashboardIconNames,
  dashboardIconColors,
} from 'features/dashboards/consts'
import {
  dashboardIconColorAtom,
  dashboardIconNameAtom,
  dashboardNameAtom,
} from 'features/dashboards/hooks/useDashboardState'
import { useAtom } from 'jotai'
import { PickIconModal } from 'shared/PickIconModal'

const AutoResizableInput = (props: InputProps) => {
  const fontProps = {
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    lineHeight: props.lineHeight,
  }

  return (
    <Flex
      position="relative"
      overflow="hidden"
      minW="122px"
      maxW="100%"
      textOverflow="ellipsis"
    >
      <Typography
        {...fontProps}
        position="relative"
        display="inline-block"
        opacity="0"
        whiteSpace="pre"
        textOverflow="ellipsis"
      >
        {props.value || props.placeholder}
      </Typography>
      <Input
        {...props}
        wrapperProps={{
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </Flex>
  )
}

export const DashboardNameIconForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [dashboardName, setDashboardName] = useAtom(dashboardNameAtom)
  const [dashboardIconName, setDashboardIconName] = useAtom(
    dashboardIconNameAtom,
  )
  const [dashboardIconColor, setDashboardIconColor] = useAtom(
    dashboardIconColorAtom,
  )

  return (
    <Flex alignItems="center" gap={1.5}>
      <Flex gap={2}>
        <Typography fontSize="md" fontWeight={600}>
          All dashboards
        </Typography>
        <Typography color="gray.600" fontSize="md">
          /
        </Typography>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <ButtonIcon
          name={dashboardIconName as IconName}
          iconProps={{ hexColor: dashboardIconColor }}
          colorScheme="gray"
          onClick={onOpen}
          title="Change icon"
          size="xs"
        />
        <PickIconModal
          isOpen={isOpen}
          onClose={onClose}
          currentIcon={{
            name: dashboardIconName as IconName,
            color: dashboardIconColor,
          }}
          onApply={({ name, color }) => {
            setDashboardIconColor(color)
            setDashboardIconName(name)
          }}
          iconColors={dashboardIconColors}
          iconNames={dashboardIconNames}
          title="Choose an icon for the dashboard."
        />
        <AutoResizableInput
          id="dashboardName"
          type="string"
          variant="flushed"
          borderBottom="none"
          fontSize="md"
          fontWeight={600}
          mb={1}
          lineHeight={7}
          h={9}
          pb={1}
          placeholder="New dashboard"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={dashboardName === ''}
          value={dashboardName}
          onChange={(e) => setDashboardName(e.target.value)}
        />
      </Flex>
    </Flex>
  )
}
