import { Box } from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import { useCanEditReportOwner } from 'features/reports/hooks/useCanEditReport'
import { useIsNewReportFlow } from 'features/reports/hooks/useIsNewReportFlow'
import {
  useReportStateAtom,
  useSetReportStateAtom,
} from 'features/reports/hooks/useReportState'

import { useEditReportOwner } from 'features/reports/views/shared/ReportOwner/useEditReportOwner'
import { ResourceOwner } from 'shared/ResourceOwner/ResourceOwner'
import { ReportDetailsHeader } from './ReportDetailsRow/ReportDetailsHeader'

export const OwnerSection = () => {
  const isNewReportFlow = useIsNewReportFlow()
  const selectedReport = useReportStateAtom()
  const setReportState = useSetReportStateAtom()
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })

  const canEditReportOwner = useCanEditReportOwner({
    visibility: selectedReport?.visibility,
    owner: selectedReport?.owner,
  })

  const { editReportOwner, loading: editReportOwnerLoading } =
    useEditReportOwner()

  return (
    <Box>
      <ReportDetailsHeader title="Owner" />

      <ResourceOwner
        owner={selectedReport?.owner}
        membershipsData={memberships?.data}
        onOwnerChanged={(userId) => {
          editReportOwner({
            selectedReport,
            newOwnerExternalId: userId,
            oldOwnerExternalId: selectedReport?.owner?.externalId,
          })
          setReportState((prev) =>
            prev
              ? { ...prev, owner: { externalId: userId, id: 'optimistic' } }
              : prev,
          )
        }}
        getModalProps={(owner) => {
          const newOwnerName =
            owner?.publicUserData.firstName +
            ' ' +
            owner?.publicUserData.lastName

          return {
            isLoading: editReportOwnerLoading,
            description: `The report's ownership will be transferred to ${newOwnerName}`,
          }
        }}
        disabled={!canEditReportOwner || isNewReportFlow}
      />
    </Box>
  )
}
