import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import {
  getMetricKeyWithoutAttribution,
  normalizedMetricsAtom,
} from 'graphql/statistics/useMetrics'
import { useGetAtomValue } from 'hooks/useGetAtomValue'
import { atom, useAtomValue, useSetAtom, type SetStateAction } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { useCallback, useMemo } from 'react'
import { analyticsConfigAtom } from 'shared/atoms/analyticsConfigState'
import { getUniqueMetrics, getValidMetrics } from 'shared/utils/analyticsConfig'
import { dimensionsStateAtom } from './useDimensionsState'
import { useIsNewAnalyticsConfigFlow } from './useIsNewAnalyticsConfigFlow'

const focusMetricsStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('metrics'),
)

export const metricsStateAtom = atomFamily(
  (allowInvalidMetricCombinations?: boolean) =>
    atom(
      (get) => {
        const metrics = get(focusMetricsStateAtom)
        const normalizedMetrics = get(normalizedMetricsAtom)
        const { dimensions } = get(dimensionsStateAtom)

        const validMetrics = getValidMetrics(
          metrics,
          normalizedMetrics,
          dimensions,
          allowInvalidMetricCombinations,
        )
        const uniqueMetrics = getUniqueMetrics(validMetrics)
        const metricKeys = validMetrics.map((m) => m.key)

        return {
          metricKeys,
          metrics: validMetrics,
          uniqueMetrics,
          uniqueMetricKeys: uniqueMetrics.map(getMetricKeyWithoutAttribution),
          unsafe_allMetricsKeys: metrics, // could involve invalid metrics
        }
      },
      (_, set, metricsState: SetStateAction<string[]>) => {
        set(focusMetricsStateAtom, metricsState)
      },
    ),
)

export const useMetricsState = (allowInvalidMetricCombinations?: boolean) => {
  const metricStateAtom = useMemo(
    () => metricsStateAtom(allowInvalidMetricCombinations),
    [allowInvalidMetricCombinations],
  )

  return useAtomValue(metricStateAtom)
}

export const useSetMetricsState = (
  allowInvalidMetricCombinations?: boolean,
) => {
  const metricStateAtom = useMemo(
    () => metricsStateAtom(allowInvalidMetricCombinations),
    [allowInvalidMetricCombinations],
  )
  const setMetricsStateAtom = useSetAtom(metricStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewAnalyticsConfigFlow = useIsNewAnalyticsConfigFlow()
  const getAnalyticsConfigFromStore = useGetAtomValue(analyticsConfigAtom)

  const setMetricsState = useCallback(
    (payload: SetStateAction<string[]>) => {
      setMetricsStateAtom(payload)
      if (!isNewAnalyticsConfigFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        if (typeof payload === 'function') {
          newAnalyticsConfig.metrics = payload(newAnalyticsConfig.metrics)
        } else {
          newAnalyticsConfig.metrics = payload
        }
        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [
      createAnalyticsConfig,
      getAnalyticsConfigFromStore,
      isNewAnalyticsConfigFlow,
      setMetricsStateAtom,
    ],
  )

  return setMetricsState
}
