import { useApolloClient } from '@apollo/client'
import {
  updateUserDashboardsQuery,
  updateMerchantDashboardsQuery,
  useCreateDashboard,
} from 'features/dashboards/graphql/useCreateDashboard'
import { useUserDashboards } from 'features/dashboards/graphql/useUserDashboards'
import { useNewDashboardState } from 'features/dashboards/hooks/useDashboardState'
import { DASHBOARD_PAGES } from 'features/dashboards/routePages'
import { type DashboardWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { useNavigate } from 'react-router'
import { DashboardListTable } from './components/DashboardListTable'

export const AllDashboardsView = () => {
  const { dashboards, loading } = useUserDashboards()
  const [createDashboard, { loading: isCreatingDashboard }] =
    useCreateDashboard({
      skipUpdateUserDashboardsQuery: true,
    })
  const client = useApolloClient()
  const newDashboardState = useNewDashboardState()
  const navigate = useNavigate()

  const handleCreateDashboard = async () => {
    const createdDashboard = await createDashboard({
      dashboard: {
        ...newDashboardState,
        visibility: newDashboardState.visibility ?? [],
        widgets: [],
      },
    })

    navigate(DASHBOARD_PAGES.DASHBOARD_BY_ID(createdDashboard.id))

    updateUserDashboardsQuery({
      cache: client.cache,
      dashboard: createdDashboard as DashboardWithOwnerFieldsFragment,
    })

    updateMerchantDashboardsQuery({
      cache: client.cache,
      dashboard: createdDashboard as DashboardWithOwnerFieldsFragment,
    })
  }

  return (
    <DashboardListTable
      pageTitle="All dashboards"
      dashboards={dashboards}
      isLoading={loading}
      addDashboardButtonProps={{
        onClick: handleCreateDashboard,
        isLoading: isCreatingDashboard,
      }}
    />
  )
}
