import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { Box, Flex, GridItem, SimpleGrid, Skeleton } from '@chakra-ui/react'
import { DiffBadge } from 'components/Badge/DiffBadge'
import { Typography } from 'components/Typography'
import { WidgetStatus } from 'features/dashboards/atoms/dashboardViewState'

import { useKpiWidgetAnalytics } from 'features/dashboards/hooks/useKpiWidgetAnalytics'
import { useWidgetMetricsState } from 'features/dashboards/hooks/useWidgetMetricState'
import { useWidgetStatus } from 'features/dashboards/hooks/useWidgetsState'
import { type Statistic } from 'graphql/statistics/types'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { calcDifference, type calcDifferenceProps } from 'utils/calcDifference'
import { ErrorWidgetContent } from './ErrorWidgetContent'

interface KpiWidgetContentProps {
  widgetId: string
}

const KpiWidgetMetric = ({
  data,
  currency,
  label,
  hasMultipleMetrics,
  isLoading,
}: {
  data?: Statistic
  currency?: string
  label: string
  hasMultipleMetrics: boolean
  isLoading?: boolean
}) => {
  const calcDiffProps: calcDifferenceProps = {
    value: Number(data?.value),
    compareValue: Number(data?.comparisonValue),
    format: 'integer',
    currency,
    displayFormat: DISPLAY_FORMAT.PERCENTAGE_DIFF,
  }

  const { formattedValue, formattedDifference } = calcDifference(calcDiffProps)

  return (
    <GridItem
      p={hasMultipleMetrics ? 4 : 0}
      position="relative"
      h="100%"
      _before={{
        content: '""',
        position: 'absolute',
        backgroundColor: 'gray.200',
        zIndex: 1,
        inlineSize: '1px',
        blockSize: '100%',
        insetBlockStart: 0,
        insetInlineStart: '-1px',
      }}
      _after={{
        content: '""',
        position: 'absolute',
        backgroundColor: 'gray.200',
        zIndex: 1,
        inlineSize: '100%',
        blockSize: '1px',
        insetInlineStart: 0,
        insetBlockStart: '-1px',
      }}
    >
      {isLoading ? (
        <LoadingKpiWidgetMetric showLabel={hasMultipleMetrics} />
      ) : (
        <Flex flexDirection="column" justifyContent="center" h="100%" gap={2}>
          {hasMultipleMetrics && (
            <Typography
              color="gray.800"
              fontWeight={500}
              w="100%"
              textOverflow="ellipsis"
              noOfLines={1}
            >
              {label}
            </Typography>
          )}
          <Flex flexDirection="column" alignItems="flex-start" gap={1}>
            <Typography
              fontSize="2xl"
              lineHeight={8}
              color="gray.900"
              fontWeight={600}
            >
              {formattedValue}
            </Typography>
            {formattedDifference && <DiffBadge {...calcDiffProps} />}
          </Flex>
        </Flex>
      )}
    </GridItem>
  )
}

const KpiWidgetContainer = ({ widgetId }: KpiWidgetContentProps) => {
  const widgetStatus = useWidgetStatus(widgetId)
  const { currency } = useMerchantInfo()
  const { statistics, loading } = useKpiWidgetAnalytics(widgetId)
  const normalizedMetrics = useNormalizedMetrics()
  const { metrics } = useWidgetMetricsState(widgetId)

  const hasMultipleMetrics = metrics.length > 1

  if (widgetStatus === WidgetStatus.Empty) {
    return (
      <Box p={4}>
        <Typography size="text-xs" color="gray.600">
          No metrics selected
        </Typography>
      </Box>
    )
  }

  if (widgetStatus === WidgetStatus.Error) {
    return <ErrorWidgetContent imageProps={{ w: '80px' }} description={null} />
  }

  return (
    <SimpleGrid columns={6} minChildWidth="165px" h="100%" overflow="hidden">
      {metrics?.map((metric) => (
        <KpiWidgetMetric
          key={metric.key}
          data={statistics?.[0]?.[metric.key]}
          currency={currency}
          label={normalizedMetrics[metric.key]?.label}
          hasMultipleMetrics={hasMultipleMetrics}
          isLoading={loading}
        />
      ))}
    </SimpleGrid>
  )
}

export const KpiWidgetContent = ({ widgetId }: KpiWidgetContentProps) => {
  const { metrics } = useWidgetMetricsState(widgetId)
  const hasMultipleMetrics = metrics.length > 1

  return (
    <Box h="100%" px={4} pb={4} pt={hasMultipleMetrics ? 2 : 4} overflow="auto">
      <KpiWidgetContainer widgetId={widgetId} />
    </Box>
  )
}

const LoadingKpiWidgetMetric = ({ showLabel }: { showLabel: boolean }) => {
  return (
    <Box>
      {showLabel && <Skeleton h={4} w="120px" mb={4} />}
      <Skeleton h={7} w="160px" mb={2} />
      <Skeleton h={4} w="60px" />
    </Box>
  )
}
