import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const EDIT_SEGMENTATION_CUSTOMER_SET_MUTATION = graphql(/* GraphQL */ `
  mutation EditSegmentationCustomerSet(
    $id: ID!
    $params: EditSegmentationCustomerSetInput!
  ) {
    editSegmentationCustomerSet(id: $id, params: $params) {
      ...SegmentationCustomerSetFields
    }
  }
`)

export const useEditSegmentationCustomerSet = () => {
  const [editSegmentationCustomerSet] = useMutation(
    EDIT_SEGMENTATION_CUSTOMER_SET_MUTATION,
  )

  return editSegmentationCustomerSet
}
