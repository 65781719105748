import {
  type COMPARE_DYNAMIC_DATE_ID,
  type DYNAMIC_DATE_ID,
  getDatePreset,
} from 'constants/getDatePresets'
import { DatePreview } from 'components/Datepicker/DatePreview'
import { RangeDatepicker } from 'components/Datepicker/RangeDatepicker/RangeDatepicker'
import { type DatepickerApplyStateProps } from 'components/Datepicker/RangeDatepicker/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { isEqual } from 'lodash-es'
import {
  compareDynamicDates,
  type DateState,
  dynamicDates,
} from 'shared/hooks/useDateState'
import { toUTCDateRange } from 'utils/dateRange'

interface DateRangePickerProps {
  dateState: DateState
  onDateChange: (dateState: DateState) => void
  triggerButton?: React.ReactElement
}

export const DateRangePicker = ({
  dateState,
  triggerButton,
  onDateChange,
}: DateRangePickerProps) => {
  const { dynamicDate, dateRange, compareDateRange, compareDynamicDate } =
    dateState || {}
  const { timezone } = useMerchantInfo()
  const dateLabel = dynamicDate ? getDatePreset(dynamicDate)?.name : undefined

  const handleApply = ({
    dateRange: newDateRange,
    dynamicDate: newDynamicDate,
    compareDateRange: newCompareDateRange,
    compareDynamicDate: newCompareDynamicDate,
  }: DatepickerApplyStateProps) => {
    const newUTCDateRange = toUTCDateRange(newDateRange, timezone)
    const newUTCCompareDateRange = toUTCDateRange(newCompareDateRange, timezone)

    const newDateValues = {
      dynamicDate: newDynamicDate as DYNAMIC_DATE_ID,
      dateRange: newUTCDateRange,
      compareDateRange: newUTCCompareDateRange,
      compareDynamicDate: newCompareDynamicDate as COMPARE_DYNAMIC_DATE_ID,
      isCompare: !!newUTCCompareDateRange[0] && !!newUTCCompareDateRange[1],
    } satisfies DateState

    // Update only if there has been some change
    if (
      !isEqual(newDateValues, {
        dynamicDate,
        dateRange,
        compareDateRange,
        compareDynamicDate,
      })
    ) {
      onDateChange(newDateValues)
    }
  }

  const hasCompareDate = getIsPopulatedDateRange(compareDateRange)

  return (
    <RangeDatepicker
      popupButton={
        triggerButton || (
          <DatePreview
            actualDate={dateRange}
            compareDate={hasCompareDate ? compareDateRange : undefined}
            badgeLabel={dateLabel}
          />
        )
      }
      dateRange={dateRange}
      dynamicDate={dynamicDate}
      dynamicDateOptions={dynamicDates}
      compareDynamicDateOptions={compareDynamicDates}
      compareDateRange={compareDateRange}
      compareDynamicDate={compareDynamicDate}
      maxDate={null}
      key={`${dateRange.join(',')}:${compareDateRange.join(',')}:${dynamicDate}:${compareDynamicDate}`}
      onApply={handleApply}
    />
  )
}
