import { Box } from '@chakra-ui/react'

export const WidgetPanelOverlay = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean
  onClick: () => void
}) => {
  if (!isOpen) return null

  return (
    <Box
      position="absolute"
      zIndex={1}
      left={0}
      top={0}
      w="100%"
      h="100%"
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    />
  )
}
