import { Box, type BoxProps, Flex, useDisclosure, Fade } from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { type IconName } from 'components/Icon/Icon'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { PickIconModal } from '../../../../../shared/PickIconModal'
import { teamIconNames, teamIconColors } from '../consts'

interface TeamIcon {
  name: IconName
  color: string
}
interface IconAndNameFormProps {
  teamName: string
  setTeamName: React.Dispatch<string>
  icon: TeamIcon
  setIcon: React.Dispatch<TeamIcon>
  actionButton?: {
    text: string
    onClick: () => void
    isShown: boolean
  }
  boxProps?: BoxProps
}

export const IconAndNameForm: React.FC<IconAndNameFormProps> = ({
  teamName,
  setTeamName,
  icon,
  setIcon,
  actionButton,
  boxProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box {...boxProps}>
      <Box mb={4}>
        <Typography fontSize="md" lineHeight={6} mb={2} color="gray.900">
          Icon and name
        </Typography>
        <Typography fontSize="sm" lineHeight={5} color="gray.800">
          Choose a name and set the icon for the team
        </Typography>
      </Box>

      <Flex gap={2}>
        <ButtonIcon
          name={icon.name}
          iconProps={{ hexColor: icon.color }}
          colorScheme="gray"
          onClick={onOpen}
          title="Change icon"
        />
        <PickIconModal
          isOpen={isOpen}
          onClose={onClose}
          currentIcon={icon}
          onApply={setIcon}
          iconColors={teamIconColors}
          iconNames={teamIconNames}
          title="Team icon"
          description="Choose a color and an icon for the team"
        />

        <Input
          placeholder="E.g Marketing"
          value={teamName}
          maxLength={50}
          onChange={(e) => setTeamName(e.target.value)}
        />

        {actionButton && (
          <Fade in={actionButton.isShown}>
            <Button
              onClick={actionButton.onClick}
              variant="solid"
              colorScheme="primary"
            >
              {actionButton.text}
            </Button>
          </Fade>
        )}
      </Flex>
    </Box>
  )
}
