import { Text, type TextProps, forwardRef, type As } from '@chakra-ui/react'

export const Typography = forwardRef<TextProps, As>(
  (
    {
      children,
      color = 'grey.800',
      fontSize = 'sm',
      lineHeight = '5',
      fontWeight = '400',
      ...rest
    },
    ref,
  ) => (
    <Text
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      userSelect="text"
      ref={ref}
      {...rest}
    >
      {children}
    </Text>
  ),
)
