import { Stack, Box, Skeleton, Flex } from '@chakra-ui/react'

export const PieChartSkeleton = ({ charts }: { charts: number }) => {
  return (
    <Flex
      bg="white"
      shadow="base"
      p={4}
      gap={6}
      top={4}
      shrink={0}
      h="max-content"
      w="max-content"
      position="sticky"
    >
      <Box px={6}>
        <Stack gap={8} sx={{ '*': { overflow: 'visible !important' } }}>
          {Array.from({ length: charts }).map((_, index) => (
            <Box key={index} position="relative" height={180} width={180}>
              <Skeleton height="100%" width="100%" borderRadius="full" />
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                height="55%"
                width="55%"
                borderRadius="full"
                bg="white"
                zIndex={1}
              />
            </Box>
          ))}
        </Stack>
      </Box>
      <Stack>
        <Skeleton height={18} width="100px" />
        <Skeleton height={18} width="100px" />
        <Skeleton height={18} width="100px" />
      </Stack>
    </Flex>
  )
}
