import { Navigate, type RouteObject } from 'react-router-dom'
import {
  SEGMENTATIONS_PAGES,
  SEGMENTATIONS_ROUTE_FRAGMENTS,
} from './routePages'
import { CustomerLabelsView } from './views/CustomerLabelsView/CustomerLabelsView'
import { CustomerRulesView } from './views/CustomerRulesView/CustomerRulesView'
import { CustomerSetsView } from './views/CustomerSetsView/CustomerSetsView'
import { ProductLabelsView } from './views/ProductLabelsView/ProductLabelsView'
import { ProductRulesView } from './views/ProductRulesView/ProductRulesView'
import { ProductSetsView } from './views/ProductSetsView/ProductSetsView'

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={SEGMENTATIONS_PAGES.PRODUCT_SETS} />,
  },
  {
    path: SEGMENTATIONS_ROUTE_FRAGMENTS.PRODUCT_LABELS,
    element: <ProductLabelsView />,
  },
  {
    path: SEGMENTATIONS_ROUTE_FRAGMENTS.PRODUCT_SETS,
    children: [
      {
        index: true,
        element: <ProductSetsView />,
      },
      {
        path: ':id',
        children: [
          {
            path: SEGMENTATIONS_ROUTE_FRAGMENTS.RULES,
            element: <ProductRulesView />,
          },
        ],
      },
    ],
  },
  {
    path: SEGMENTATIONS_ROUTE_FRAGMENTS.CUSTOMER_LABELS,
    element: <CustomerLabelsView />,
  },
  {
    path: SEGMENTATIONS_ROUTE_FRAGMENTS.CUSTOMER_SETS,
    children: [
      {
        index: true,
        element: <CustomerSetsView />,
      },
      {
        path: ':id',
        children: [
          {
            path: SEGMENTATIONS_ROUTE_FRAGMENTS.RULES,
            element: <CustomerRulesView />,
          },
        ],
      },
    ],
  },
]
