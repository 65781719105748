import { MenuItem, useDisclosure } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { DeleteWidgetModal } from './DeleteWidgetModal'

interface DeleteWidgetMenuItemProps {
  widgetId: string | undefined
}
export const DeleteWidgetMenuItem = ({
  widgetId,
}: DeleteWidgetMenuItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <MenuItem
        icon={<Icon name="TrashIcon" size="small" />}
        onClick={() => {
          onOpen()
        }}
        closeOnSelect={false}
      >
        Delete
      </MenuItem>
      <DeleteWidgetModal
        isOpen={isOpen}
        widgetId={widgetId}
        onClose={onClose}
      />
    </>
  )
}
