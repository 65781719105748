import { Input } from 'components/Input'
import { widgetNameByWidgetIdAtom } from 'features/dashboards/hooks/useWidgetsState'
import { useAtom } from 'jotai'
import { useMemo } from 'react'

export const WidgetCardTitle = ({ widgetId }: { widgetId: string }) => {
  const widgetNameAtom = useMemo(
    () => widgetNameByWidgetIdAtom(widgetId),
    [widgetId],
  )
  const [widgetName, setWidgetName] = useAtom(widgetNameAtom)

  return (
    <Input
      id={`widget-${widgetId}-name`}
      type="string"
      variant="flushed"
      py={0}
      h="fit-content"
      borderBottom="none"
      fontSize="sm"
      fontWeight="medium"
      placeholder="Untitled"
      value={widgetName}
      noOfLines={1}
      onChange={(e) => setWidgetName(e.target.value)}
    />
  )
}
