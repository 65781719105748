import { Box, Flex } from '@chakra-ui/react'
import { useOrganization, useUser } from '@clerk/clerk-react'
import { createColumnHelper } from '@tanstack/react-table'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography/Typography'
import { differenceInYears, format, formatDistanceToNow } from 'date-fns'
import { ReportFavoriteStar } from 'features/reports/components/ReportFavoriteStar'
import { ReportContextualMenu } from 'features/reports/components/ReportSidebar/ReportContextualMenu/ReportContextualMenu'
import { type ReportResult } from 'graphql/reports/types'
import { getReportOwnerName } from 'graphql/reports/utils'

import {
  RESOURCE_VISIBILITY,
  getResourceVisibilityType,
  RESOURCE_VISIBILITY_SORT_ORDER,
} from 'graphql/shared/utils'
import { useMerchantTeams } from 'graphql/teams/useMerchantTeams'
import { useIsAdminUser } from 'hooks/useIsAdminUser'
import { useIsGuestUser } from 'hooks/useIsGuestUser'
import { useMemo } from 'react'
import {
  getCanEditResource,
  getCanEditResourceOwner,
} from 'shared/hooks/useCanEditResource'
import { ResourceOwner } from 'shared/ResourceOwner/ResourceOwner'
import { ResourceVisibility } from 'shared/ResourceVisibility/ResourceVisibility'
import { chartTypes } from 'utils/chart/chartTypes'
import { ReportListLabelsCell } from './ReportListLabelsCell'
import { useEditReportOwner } from './ReportOwner/useEditReportOwner'
import { useEditReportVisibility } from './ReportVisibility/useEditReportVisibility'

const columnHelper = createColumnHelper<ReportResult>()

export const useReportListColumns = () => {
  const { editReportVisibility } = useEditReportVisibility()
  const { editReportOwner, loading: editReportOwnerLoading } =
    useEditReportOwner()

  const isGuestUser = useIsGuestUser()
  const isAdminUser = useIsAdminUser()

  const { merchantTeams, query: merchantTeamsQuery } = useMerchantTeams()
  const { user } = useUser()
  const userId = String(user?.publicMetadata.dema_id)
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: ({ row }) => {
          const report = row.original
          const { name, description } = report
          const icon =
            chartTypes[report.analyticsConfig.chart.series[0].type]
              .graphIconName

          return (
            <Flex gap={2}>
              <Icon name={icon} size="large" />
              <Box>
                <Flex alignItems="center" gap={2}>
                  <Typography
                    fontSize="sm"
                    fontWeight={600}
                    lineHeight={5}
                    noOfLines={1}
                    title={name}
                  >
                    {name}
                  </Typography>
                  <ReportFavoriteStar report={report} />
                </Flex>
                {description && (
                  <Typography
                    fontSize="xs"
                    lineHeight={4}
                    color="grey.700"
                    noOfLines={1}
                    title={description}
                  >
                    {description}
                  </Typography>
                )}
              </Box>
            </Flex>
          )
        },
        header: 'Name',
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
      }),
      columnHelper.accessor('owner', {
        cell: ({ row, getValue }) => {
          const owner = getValue()
          const { visibility } = row.original
          const canEditReportOwner = getCanEditResourceOwner({
            visibility,
            owner,
            isAdminUser,
            userId,
            isGuestUser,
          })

          return (
            <ResourceOwner
              owner={owner}
              membershipsData={memberships?.data}
              onOwnerChanged={(userId) =>
                editReportOwner({
                  selectedReport: row.original,
                  newOwnerExternalId: userId,
                })
              }
              getModalProps={(owner) => {
                const newOwnerName =
                  owner?.publicUserData.firstName +
                  ' ' +
                  owner?.publicUserData.lastName

                return {
                  isLoading: editReportOwnerLoading,
                  description: `The report's ownership will be transferred to ${newOwnerName}`,
                }
              }}
              disabled={!canEditReportOwner}
            />
          )
        },
        header: 'Owner',
        sortingFn: (a, b) => {
          // This is not performant at all since it's recalculating the name for each row and calculating the name has to traverse the whole memberships array.
          const aName = getReportOwnerName({
            owner: a.original.owner,
            memberships: memberships?.data,
          })
          const bName = getReportOwnerName({
            owner: b.original.owner,
            memberships: memberships?.data,
          })

          return aName.localeCompare(bName)
        },
      }),
      columnHelper.accessor('visibility', {
        cell: ({ row }) => {
          const { visibility, owner } = row.original

          const canEditReport = getCanEditResource({
            visibility,
            owner,
            isGuestUser,
            userId,
          })

          return (
            <Box position="relative" left={-2}>
              <ResourceVisibility
                key={getResourceVisibilityType(row.original.visibility)} // This is to reset the state when the visibility changes
                visibility={row.original.visibility}
                owner={row.original.owner}
                teamOptions={merchantTeams}
                isLoading={merchantTeamsQuery.loading}
                isDisabled={!canEditReport}
                onVisibilityChanged={(newVisibility) =>
                  editReportVisibility({
                    selectedReport: row.original,
                    newVisibility,
                    oldVisibility:
                      row.original.visibility?.map((v) => v.id) ?? [],
                  })
                }
              />
            </Box>
          )
        },
        sortingFn: (rowA, rowB) => {
          const rowAVisibilityType = getResourceVisibilityType(
            rowA.original.visibility,
          )
          const rowBVisibilityType = getResourceVisibilityType(
            rowB.original.visibility,
          )
          const rowAOrder = RESOURCE_VISIBILITY_SORT_ORDER[rowAVisibilityType]
          const rowBOrder = RESOURCE_VISIBILITY_SORT_ORDER[rowBVisibilityType]

          // Check if they have different visibility
          if (rowAOrder < rowBOrder) {
            return -1
          }
          if (rowAOrder > rowBOrder) {
            return 1
          }

          // If both are teams, compare by first team name
          if (
            rowAVisibilityType === RESOURCE_VISIBILITY.TEAM &&
            rowBVisibilityType === RESOURCE_VISIBILITY.TEAM
          ) {
            const rowAName = rowA.original.visibility?.[0]?.name ?? ''
            const rowBName = rowB.original.visibility?.[0]?.name ?? ''

            return rowAName.localeCompare(rowBName)
          }

          // Same visibility, and not team
          return 0
        },
        header: 'Visibility',
      }),
      columnHelper.accessor('updatedAt', {
        cell: ({ getValue }) => {
          const updatedAt = getValue()

          if (!updatedAt) return null

          const now = new Date(updatedAt)
          const formattedTooltip = format(now, 'yyyy-MM-dd')
          const diffYears = differenceInYears(now, new Date())
          const isWithinOneYear = diffYears < 1
          const lastUpdated = isWithinOneYear
            ? formatDistanceToNow(now, { addSuffix: true })
            : formattedTooltip

          return (
            <Typography title={isWithinOneYear ? formattedTooltip : undefined}>
              {lastUpdated}
            </Typography>
          )
        },
        header: 'Last updated',
        sortingFn: 'alphanumeric',
      }),
      columnHelper.display({
        id: 'labels',
        cell: ({ row }) => <ReportListLabelsCell report={row.original} />,
      }),
      columnHelper.display({
        id: 'settings',
        cell: ({ row }) => {
          const report = row.original

          if (!report) return null

          return <ReportContextualMenu report={report} userId={userId} />
        },
      }),
    ],
    [
      isGuestUser,
      userId,
      isAdminUser,
      memberships?.data,
      editReportOwnerLoading,
      editReportOwner,
      merchantTeams,
      merchantTeamsQuery.loading,
      editReportVisibility,
    ],
  )

  return columns
}
