import { Box } from '@chakra-ui/react'
import { useDashboardLayoutRearrange } from 'features/dashboards/hooks/useDashboardLayoutRearrange'
import { useDashboardLayoutRowState } from 'features/dashboards/hooks/useDashboardLayoutState'
import { useState } from 'react'
import { NewRowContainer } from './NewRowContainer'
import { ResizableRow } from './ResizableRow'
import { ResizableRowWidgetCells } from './ResizableRowWidgetCells'
import { RowGridLines } from './RowGridLines'

interface DashboardRowProps {
  id: string
  rowIndex: number
  newRowId: string
}

export const DashboardRow = ({ id, newRowId, rowIndex }: DashboardRowProps) => {
  const [isNewRowActive, setIsNewRowActive] = useState(false)
  const [isResizingRow, setIsResizingRow] = useState(false)
  const [activeCellIndex, setActiveCellIndex] = useState<number>()

  const rowState = useDashboardLayoutRowState(id)
  const { onRowHeightChange, onCellWidthsChange } =
    useDashboardLayoutRearrange()

  const handleResizeStart = () => {
    setIsResizingRow(true)
  }

  const handleResizeStop = (newHeight: number) => {
    onRowHeightChange(id, newHeight)
    setIsResizingRow(false)
  }

  const handleNewRowActiveChange = (isActive: boolean) => {
    setIsNewRowActive(isActive)
  }

  if (!rowState) return null

  const { minHeight, height, cells } = rowState
  const showGridLines = activeCellIndex !== undefined

  return (
    <Box position="relative">
      <RowGridLines isVisible={showGridLines} top={-4} />
      <ResizableRow
        minHeight={minHeight}
        height={height}
        isNewRowActive={isNewRowActive}
        isResizingRow={isResizingRow}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
        onNewRowActiveChange={handleNewRowActiveChange}
      >
        <ResizableRowWidgetCells
          cells={cells}
          rowId={id}
          height={height}
          onCellWidthsChange={onCellWidthsChange}
          activeCellIndex={activeCellIndex}
          setActiveCellIndex={setActiveCellIndex}
        />
      </ResizableRow>
      <RowGridLines isVisible={showGridLines} />
      <NewRowContainer
        id={newRowId}
        index={rowIndex + 1} // +1 because the new row is added after the current row
        isVisible={isNewRowActive || isResizingRow}
        setIsVisible={setIsNewRowActive}
      />
    </Box>
  )
}
