import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type DashboardWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { transformDashboards } from './utils'

export const MERCHANT_DASHBOARDS_QUERY = graphql(/* GraphQL */ `
  query MerchantDashboards {
    viewer {
      id
      merchant {
        id
        dashboards {
          ...DashboardWithOwnerFields
        }
      }
    }
  }
`)

const emptyDashboards: DashboardWithOwnerFieldsFragment[] = []

export const useMerchantDashboards = () => {
  const query = useQuery(MERCHANT_DASHBOARDS_QUERY)

  const dashboardsData =
    query.data?.viewer?.merchant?.dashboards ?? emptyDashboards

  const dashboards = transformDashboards(dashboardsData)

  return { dashboards, query }
}
