import { Flex, type FlexProps } from '@chakra-ui/react'

export const DashboardHeaderContainer = ({
  children,
  ...props
}: {
  children: React.ReactNode
} & FlexProps) => {
  return (
    <Flex
      px={4}
      py={3}
      justifyContent="space-between"
      bg="white"
      borderBottom="solid 1px"
      borderBottomColor="gray.200"
      alignItems="baseline"
      gap={{ base: 10, sm: 20 }}
      {...props}
    >
      {children}
    </Flex>
  )
}
