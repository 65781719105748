import { Flex, Grid } from '@chakra-ui/react'
import { useExperiment } from 'features/geoLift/graphql/useExperiment'
import { type ExperimentSetupFieldsFragment } from 'generated/graphql/graphql'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { useState } from 'react'
import { useParams } from 'react-router'
import { ExperimentConfigPanel } from './components/ExperimentConfigPanel/ExperimentConfigPanel'
import { ExperimentViewContent } from './components/ExperimentViewContent'
import { ExperimentViewHeader } from './components/ExperimentViewHeader'
import { EXPERIMENT_METRIC, SETUP_VARIANT } from './consts'

export const ExperimentView: React.FC = () => {
  const [isLargerThanMd] = useBreakpoint('md')
  const { id } = useParams()
  const { query, experiment } = useExperiment(String(id))
  const [selectedMetric, setSelectedMetric] = useState<EXPERIMENT_METRIC>(
    EXPERIMENT_METRIC.GROSS_SALES,
  )
  const [setupVariant, setSetupVariant] = useState<SETUP_VARIANT>(
    SETUP_VARIANT.COST,
  )
  const selectedSetup =
    experiment?.setup ||
    experiment?.suggestions?.reduce<ExperimentSetupFieldsFragment | undefined>(
      (min, suggestion) => {
        const suggestionValue =
          setupVariant === SETUP_VARIANT.COST
            ? Math.abs(suggestion.channelMetrics.estimatedTotalSpend)
            : suggestion.absLiftInZero

        const minValue =
          setupVariant === SETUP_VARIANT.COST
            ? Math.abs(min?.channelMetrics?.estimatedTotalSpend ?? Infinity)
            : min?.absLiftInZero

        return suggestionValue < (minValue ?? Infinity) ? suggestion : min
      },
      undefined,
    )

  // useExperiment has fetchPolicy 'cache-and-network' so it will return the cached data while loading. This checks for initial loading state.
  const isLoading = query.loading && !experiment

  return (
    <Flex direction="column" h="full" overflowY="auto">
      <ExperimentViewHeader
        isLoading={isLoading}
        experiment={experiment}
        selectedSetup={selectedSetup}
      />

      <Grid templateColumns={['1fr', '1fr auto']} h="full">
        <ExperimentViewContent
          experiment={experiment}
          isLoading={isLoading}
          isError={!!query.error}
          selectedSetup={selectedSetup}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          setupVariant={setupVariant}
          setSetupVariant={setSetupVariant}
        />
        {isLargerThanMd && (
          <ExperimentConfigPanel
            config={experiment?.config}
            experimentStatus={experiment?.status}
            loading={isLoading}
          />
        )}
      </Grid>
    </Flex>
  )
}
