import { MenuItem, useDisclosure, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationSet } from 'features/segmentations/graphql/fragments'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type FC } from 'react'
import { useEditSegmentationSet } from '../../graphql/useEditSegmentationSet'
import {
  SegmentationSetPicker,
  type UpdatedSegmentationSet,
} from '../SegmentationSetPicker'

interface Props {
  currentSegmentationSet: SegmentationSet | undefined
}

export const EditSegmentationSetModal: FC<Props> = ({
  currentSegmentationSet,
}) => {
  const editSegmentationSet = useEditSegmentationSet()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onEditSet = (segmentationSet: UpdatedSegmentationSet) => {
    if (!segmentationSet.id) return

    editSegmentationSet({
      variables: {
        id: segmentationSet.id,
        params: {
          name: segmentationSet.name,
          iconColor: segmentationSet.iconColor,
          iconName: segmentationSet.iconName,
          labelColumnName: segmentationSet.labelColumnName,
        },
      },
      optimisticResponse: {
        editSegmentationSet: {
          id: segmentationSet.id,
          name: segmentationSet.name,
          dynamicDate: segmentationSet.dynamicDate,
          iconColor: segmentationSet.iconColor,
          iconName: segmentationSet.iconName,
          labelColumnName: segmentationSet.labelColumnName,
          updatedAt: new Date().toISOString(),
          __typename: 'SegmentationSet',
        },
      },
      onCompleted: ({ editSegmentationSet }) => {
        trackEvent({
          eventName: 'Segmentations Product Set Edited',
          eventProperties: {
            setId: editSegmentationSet.id,
            setName: editSegmentationSet.name,
            iconName: editSegmentationSet.iconName,
            iconColor: editSegmentationSet.iconColor,
            labelColumnName: editSegmentationSet.labelColumnName,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not edit segmentation set. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <SegmentationSetPicker
      disclosure={{ isOpen, onOpen, onClose }}
      currentSegmentationSet={currentSegmentationSet}
      modalButton={
        <MenuItem
          icon={<Icon name="EditIcon" size="small" />}
          closeOnSelect={true}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          Edit product set
        </MenuItem>
      }
      isEditMode
      onApply={onEditSet}
    />
  )
}
