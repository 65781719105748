export const DASHBOARD_PAGE_TITLES = {
  ALL: 'All dashboards',
  MY: 'My dashboards',
} as const

export const DASHBOARD_ROUTE_FRAGMENTS = {
  DASHBOARDS: 'dashboards',
  ALL: 'all',
  MY: 'my',
} as const

const BASE = `/${DASHBOARD_ROUTE_FRAGMENTS.DASHBOARDS}` as const

export const DASHBOARD_PAGES = {
  BASE,
  ALL_DASHBOARDS: `${BASE}/${DASHBOARD_ROUTE_FRAGMENTS.ALL}`,
  MY_DASHBOARDS: `${BASE}/${DASHBOARD_ROUTE_FRAGMENTS.MY}`,
  DASHBOARD_BY_ID: (id: string) => `${BASE}/${id}`,
} as const
