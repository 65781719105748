import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { atom, useAtomValue } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { getActualDimensions } from 'shared/utils/analyticsConfig'
import { widgetAnalyticsConfigAtom } from '../atoms/dashboardViewState'

export const widgetDimensionsStateAtom = atomFamily((widgetId?: string) =>
  atom((get) => {
    const analyticsConfig = get(widgetAnalyticsConfigAtom(widgetId))
    const dimensions = analyticsConfig?.dimensions ?? []

    const normalizedDimensions = get(normalizedDimensionsAtom)

    const actualDimensions = getActualDimensions(
      dimensions,
      normalizedDimensions,
    )

    return {
      dimensionKeys: actualDimensions.map((d) => d.id),
      dimensions: actualDimensions,
      unsafe_allDimensionKeys: dimensions, // could involve invalid dimensions
    }
  }),
)

export const useWidgetDimensionsState = (widgetId?: string) =>
  useAtomValue(widgetDimensionsStateAtom(widgetId))
