import { MenuItem, useDisclosure } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { DeleteDashboardModal } from './DeleteDashboardModal'

interface DeleteDashboardMenuItemProps {
  dashboardId: string | undefined
}

export const DeleteDashboardMenuItem = ({
  dashboardId,
}: DeleteDashboardMenuItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <MenuItem
        icon={<Icon name="TrashIcon" size="small" />}
        onClick={onOpen}
        closeOnSelect={false}
      >
        Delete
      </MenuItem>
      <DeleteDashboardModal
        isOpen={isOpen}
        onClose={onClose}
        dashboardId={dashboardId}
      />
    </>
  )
}
