import { useAtomValue } from 'jotai'
import {
  useCanEditResource,
  useCanEditResourceOwner,
} from 'shared/hooks/useCanEditResource'
import {
  dashboardOwnerAtom,
  dashboardVisibilityAtom,
} from './useDashboardState'

export const useCanEditDashboard = () => {
  const visibility = useAtomValue(dashboardVisibilityAtom)
  const owner = useAtomValue(dashboardOwnerAtom)

  return useCanEditResource({ visibility, owner })
}

export const useCanEditDashboardOwner = () => {
  const visibility = useAtomValue(dashboardVisibilityAtom)
  const owner = useAtomValue(dashboardOwnerAtom)

  return useCanEditResourceOwner({ visibility, owner })
}
