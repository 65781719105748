import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useCustomerSetDownloadUrl } from '../graphql/useCustomerSetDownloadUrl'

interface UseCustomerSetDownloadProps {
  setId: string
  setName: string
  from: 'sets' | 'rules'
}

export const useCustomerSetDownload = ({
  setId,
  setName,
  from,
}: UseCustomerSetDownloadProps) => {
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const { fetchDownloadUrl, loading, error } = useCustomerSetDownloadUrl(setId)

  const handleError = (error?: unknown) => {
    toast({
      status: 'error',
      description: 'Could not download Customer set, please try again',
    })
    if (error) {
      captureException(`Download segmentation customer set: ${error}`)
    }
  }

  const downloadSet = async (e?: React.MouseEvent) => {
    e?.stopPropagation()

    try {
      const result = await fetchDownloadUrl()
      const url =
        result.data?.viewer?.merchant?.segmentationCustomerSet?.downloadUrl

      if (error || !url) {
        handleError(error)

        return
      }

      window.open(url, '_blank')
      trackEvent({
        eventName: 'Segmentations Customer Set CSV Downloaded',
        eventProperties: { setId, setName, from },
      })
    } catch (error) {
      handleError(error)
    }
  }

  return {
    downloadSet,
    loading,
  }
}
