import { Flex, Skeleton } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { type Experiment } from 'features/geoLift/graphql/fragments'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import { StatusTag } from 'features/geoLift/shared/StatusTag'
import { type ExperimentSetupFieldsFragment } from 'generated/graphql/graphql'
import { Link } from 'react-router-dom'
import { FinalizeExperimentButton } from './FinalizeExperimentButton'
import { UpdateResultsButton } from './UpdateResultsButton'

export const ExperimentViewHeader = ({
  isLoading,
  experiment,
  selectedSetup,
}: {
  isLoading: boolean
  experiment: Experiment | null
  selectedSetup: ExperimentSetupFieldsFragment | undefined
}) => {
  const hasSetup = !!experiment?.setup

  return (
    <Flex
      py={2}
      px={6}
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      <Flex alignItems="center">
        <Link to={GEO_LIFT_PAGES.EXPERIMENTS}>
          <Typography
            color="gray.900"
            fontSize="medium"
            fontWeight={500}
            lineHeight={6}
          >
            Experiments
          </Typography>
        </Link>

        <Typography color="gray.600" fontSize="sm" ml={2} mr={1}>
          /
        </Typography>

        {isLoading ? (
          <Skeleton h={5} w={20} />
        ) : experiment?.status ? (
          <StatusTag
            size="large"
            status={experiment?.status}
            name={experiment?.config.name}
          />
        ) : null}
      </Flex>

      {experiment?.id && selectedSetup && experiment ? (
        !hasSetup ? (
          <FinalizeExperimentButton
            experimentId={experiment.id}
            selectedSetupId={selectedSetup.id}
          />
        ) : (
          (experiment.status === 'running' ||
            experiment.status === 'completed' ||
            experiment.status === 'finalized') && (
            <UpdateResultsButton experimentId={experiment.id} />
          )
        )
      ) : null}
    </Flex>
  )
}
