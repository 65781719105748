import { useToast } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons/ButtonIcon'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type FC } from 'react'
import { type SegmentationCustomerLabel } from '../graphql/fragments'
import { useEditCustomerLabel } from '../graphql/useEditCustomerLabel'
import {
  CustomerLabelPicker,
  type UpdatedSegmentationCustomerLabel,
} from './CustomerLabelPicker'

interface Props {
  currentLabel: SegmentationCustomerLabel | undefined
}

export const EditCustomerLabelModal: FC<Props> = ({ currentLabel }) => {
  const editCustomerLabel = useEditCustomerLabel()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const onEditLabel = (label: UpdatedSegmentationCustomerLabel) => {
    if (!label.id) return

    editCustomerLabel({
      variables: {
        id: label.id,
        params: {
          name: label.name,
          iconColor: label.iconColor,
          iconName: label.iconName,
        },
      },
      optimisticResponse: {
        editSegmentationCustomerLabel: {
          id: label.id,
          name: label.name,
          iconColor: label.iconColor,
          iconName: label.iconName,
          __typename: 'SegmentationCustomerLabel',
        },
      },
      onCompleted: (data) => {
        trackEvent({
          eventName: 'Segmentations Customer Label Edited',
          eventProperties: {
            name: data.editSegmentationCustomerLabel.name,
            iconName: data.editSegmentationCustomerLabel.iconName,
            iconColor: data.editSegmentationCustomerLabel.iconColor,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not edit customer label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <CustomerLabelPicker
      currentLabel={currentLabel}
      modalButton={
        <ButtonIcon size="sm" variant="ghost" title="Edit" name="EditIcon" />
      }
      isEditMode
      onApply={onEditLabel}
    />
  )
}
