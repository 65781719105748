import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_SEGMENTATION_CUSTOMER_LABEL_MUTATION = graphql(/* GraphQL */ `
  mutation CreateCustomerLabel($params: CreateSegmentationCustomerLabelInput!) {
    createSegmentationCustomerLabel(params: $params) {
      ...SegmentationCustomerLabelFields
    }
  }
`)

export const useCreateCustomerLabel = () => {
  const [createCustomerLabel] = useMutation(
    CREATE_SEGMENTATION_CUSTOMER_LABEL_MUTATION,
  )

  return createCustomerLabel
}
