import { Box } from '@chakra-ui/react'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { getGridLineOptions } from 'features/reports/utils/chart/common'
import { type ExperimentInferenceMetricFieldsFragment } from 'generated/graphql/graphql'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { omit } from 'lodash-es'
import type React from 'react'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { yAxisFormatter } from 'utils/chart/common'
import { CHART_PRIMARY_COLOR, staticChartOptions } from 'utils/chart/constants'
import { formatMetric } from 'utils/numberFormats'
import { experimentViewMetrics, type EXPERIMENT_METRIC } from '../consts'

interface ROASComparisonChartProps {
  inference: ExperimentInferenceMetricFieldsFragment
  selectedMetric: EXPERIMENT_METRIC
}

type ReturnOnSpendId = keyof Omit<
  ExperimentInferenceMetricFieldsFragment['returnOnSpend'],
  '__typename'
>

type ReturnOnSpendType = {
  id: ReturnOnSpendId
  getName: (name: string) => string
}

const returnOnSpendTypes: ReturnOnSpendType[] = [
  {
    id: 'geolift',
    getName: (name: string) => `Incremental ${name}`,
  },
  {
    id: 'mmm',
    getName: (name: string) => `MMM ${name}`,
  },
  {
    id: 'mta',
    getName: (name: string) => `Dema MTA ${name}`,
  },
  {
    id: 'ad',
    getName: (name: string) => `Ad platform ${name}`,
  },
]

export const ROASComparisonChart: React.FC<ROASComparisonChartProps> = ({
  inference,
  selectedMetric,
}) => {
  const { currency } = useMerchantInfo()
  const metric =
    experimentViewMetrics.find((metric) => metric.id === selectedMetric) ??
    experimentViewMetrics[0]

  const data = Object.entries(
    omit(inference.returnOnSpend, '__typename'),
  ).filter(([, value]) => value !== null)

  const gridLineOptions = getGridLineOptions(CHART_TYPE_ID.BAR)

  const options: Highcharts.Options = {
    ...staticChartOptions,
    title: {
      text: `${metric.returnName} comparison`,
      align: 'left',
      style: {
        fontWeight: '500',
        color: colorTheme.grey[800],
        fontSize: '14',
      },
    },
    chart: {
      ...staticChartOptions.chart,
      zooming: { type: 'xy' },
      type: CHART_TYPE_ID.BAR,
      height: 300,
    },
    xAxis: {
      ...staticChartOptions.xAxis,
      type: 'category',
      categories: data.map(([id]) => id),
      labels: {
        ...staticChartOptions.xAxis.labels,
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject,
        ) {
          return (
            returnOnSpendTypes
              .find((type) => type.id === this.value)
              ?.getName(metric.returnName) ?? String(this.value)
          )
        },
      },
      crosshair: {
        color: colorTheme.grey[100],
        dashStyle: 'Solid',
      },
      ...gridLineOptions.xAxis,
    },
    yAxis: {
      ...staticChartOptions.yAxis,
      title: undefined,
      labels: {
        ...staticChartOptions.yAxis.labels,
        formatter: yAxisFormatter(metric.returnFormat),
      },
      ...gridLineOptions.yAxis,
    },
    series: [
      {
        type: CHART_TYPE_ID.BAR,
        name: `${metric.returnName} comparison`,
        data,
        color: CHART_PRIMARY_COLOR,
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      ...staticChartOptions.plotOptions,
      series: {
        ...staticChartOptions.plotOptions.series,
        stickyTracking: true,
      },
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      shared: true,
      useHTML: true,
      formatter: function () {
        if (!this.x) return ''

        const element = (
          <div>
            {this.points?.map((point) => {
              const value = point.y
                ? formatMetric(metric.returnFormat, point.y, currency)
                : 'N/A'

              const metricName =
                returnOnSpendTypes
                  .find((type) => type.id === point.x)
                  ?.getName(metric.returnName) ?? String(point.x)

              return (
                <TooltipMetricRow
                  key={point.series.name}
                  iconColor={point.color?.toString()}
                  metricName={metricName}
                  value={value}
                />
              )
            })}
          </div>
        )

        return renderToString(element)
      },
    },
  }

  return (
    <Box px={6} py={4} position="relative">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  )
}
