import { type IconName } from 'components/Icon/Icon'
import { colorTheme } from 'ui/theme/colors'
import { WidgetType } from './types'

interface WidgetConfig {
  name: string
  icon: IconName
  minHeight: number
  minWidth: number
  requiresDimensions: boolean
}

export const widgetConfig: Record<WidgetType, WidgetConfig> = {
  [WidgetType.CHART]: {
    name: 'Chart',
    icon: 'ChartLineIcon',
    minHeight: 392,
    minWidth: 3,
    requiresDimensions: true,
  },
  [WidgetType.KPI]: {
    name: 'Big Number',
    icon: 'NumberWidgetIcon',
    minHeight: 180,
    minWidth: 2,
    requiresDimensions: false,
  },
  [WidgetType.TABLE]: {
    name: 'Table',
    icon: 'TableIcon',
    minHeight: 392,
    minWidth: 3,
    requiresDimensions: true,
  },
}

export const DASHBOARD_LAYOUT_GRID_COLUMNS = 12

export const NEW_ROW_ID = 'new-row'

export const NEW_ROW_ID_DELIMITER = '.'

export const dashboardIconNames: IconName[] = [
  'Dashboard1Icon',
  'LayoutGridIcon',
  'LayersIcon',
  'CubeIcon',
  'AttributionDemaIcon',
  'BarChartIcon',
  'ChartPieIcon',
  'ChartDonutIcon',
  'ThreeDScaleIcon',
  'LightbulbIcon',
  'RulerIcon',
  'AISparklesIcon',
  'BeakerIcon',
  'NumberWidgetIcon',
  'CalendarBlankIcon',
  'DatabaseIcon',
  'GaugeHighIcon',
  'GiftIcon',
  'LikeIcon',
  'MapPinIcon',
  'CustomerSupportIcon',
  'UserCircleIcon',
  'PlatformLogoGoogleIcon',
  'PlatformLogoMetaIcon',
  'PlatformLogoTikTokIcon',
  'PlatformLogoSnapchatIcon',
  'OfficeIcon',
  'HomeIcon',
  'BikeIcon',
  'MotorcycleIcon',
  'CarIcon',
  'ShippingTruckIcon',
  'SailboatIcon',
  'HelicopterIcon',
  'RocketIcon',
  'RobotIcon',
  'ExclamationCircleIcon',
  'ExclamationTriangleIcon',
  'ParkingIcon',
  'DiamondIcon',
  'MedalIcon',
  'HeartIcon',
  'StarIcon',
  'BadgeIcon',
  'DollarCoinIcon',
  'PiggyBankIcon',
  'CoinsStackIcon',
  'WalletIcon',
  'SignIcon',
  'MegaphoneIcon',
  'ShirtIcon',
  'SuitcaseIcon',
  'ShoppingBagIcon',
  'ShoppingCartIcon',
  'Percent1Icon',
  'BadgeDiscountIcon',
  'TagIcon',
  'WarehouseIcon',
  'EarthIcon',
  'Flower1Icon',
  'Flower2Icon',
  'TreeIcon',
  'LeafIcon',
  'SummitIcon',
  'TargetIcon',
]

export const dashboardIconColors: string[] = [
  colorTheme.gray[500],
  colorTheme.purple[500],
  colorTheme.magenta[500],
  colorTheme.red[500],
  colorTheme.orange[500],
  colorTheme.yellow[600],
  colorTheme.green[500],
  colorTheme.dema[600],
  colorTheme.cyan[600],
  colorTheme.blue[500],
  colorTheme.gray[950],
]
