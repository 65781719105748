import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import type {
  SegmentationCustomerRule,
  SegmentationCustomerSet,
} from './fragments'

export const CUSTOMER_SET_BY_ID_QUERY = graphql(/* GraphQL */ `
  query CustomerSetById($id: ID!) {
    viewer {
      id
      merchant {
        id
        segmentationCustomerSet(id: $id) {
          rules {
            ...SegmentationCustomerRuleFields
          }
          ...SegmentationCustomerSetFields
        }
      }
    }
  }
`)

export const useCustomerSetById = (customerSetId: string) => {
  const query = useQuery(CUSTOMER_SET_BY_ID_QUERY, {
    variables: {
      id: customerSetId,
    },
    skip: !customerSetId,
  })

  return {
    customerSet: query.data?.viewer?.merchant.segmentationCustomerSet as
      | (SegmentationCustomerSet & { rules: SegmentationCustomerRule[] })
      | null,
    query,
  }
}

export type CustomerSetByIdQueryResult = NonNullable<
  ReturnType<typeof useCustomerSetById>['customerSet']
>
