import type { OrganizationMembershipResource } from '@clerk/types'
import {
  type OwnerFieldsFragment,
  type ReportWithOwnerFieldsFragment,
} from 'generated/graphql/graphql'
import { type ReportResult } from './types'

export const getIsTemplateReport = (owner?: OwnerFieldsFragment) => {
  return !owner || owner.id === ''
}

type Props = {
  owner: OwnerFieldsFragment | undefined
  memberships: OrganizationMembershipResource[] | undefined
}

export const getReportOwnerName = ({ owner, memberships }: Props) => {
  const ownerDetails = memberships?.find(
    (member) => member.publicUserData.userId === owner?.externalId,
  )

  if (getIsTemplateReport(owner)) return 'Dema'

  const { firstName, lastName } = ownerDetails?.publicUserData ?? {}

  return `${firstName} ${lastName}`
}

type InputReport = ReportWithOwnerFieldsFragment[]

export const transformReport = (report: InputReport[number]): ReportResult => {
  return report as unknown as ReportResult
}

export const transformReports = (reports: InputReport) => {
  return reports as unknown as ReportResult[]
}
