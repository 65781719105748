import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const DELETE_CUSTOMER_LABEL_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteCustomerLabel($id: ID!) {
    deleteSegmentationCustomerLabel(id: $id) {
      id
      __typename
    }
  }
`)

export const useDeleteCustomerLabel = () => {
  const [deleteCustomerLabel] = useMutation(DELETE_CUSTOMER_LABEL_MUTATION)

  return deleteCustomerLabel
}
