import { Box, type BoxProps } from '@chakra-ui/react'
import {
  DASHBOARD_LAYOUT_GRID_COLUMNS,
  widgetConfig,
} from 'features/dashboards/consts'
import { getRelativeCellWidth } from 'features/dashboards/utils'

const minimumWidgetWidth = Math.min(
  ...Object.values(widgetConfig).map((config) => config.minWidth),
)

interface RowGridLinesProps extends BoxProps {
  isVisible: boolean
}

export const RowGridLines = ({ isVisible, ...props }: RowGridLinesProps) => {
  return (
    <Box
      w="100%"
      gap={4}
      position="absolute"
      display={isVisible ? 'flex' : 'none'}
      {...props}
    >
      {Array.from({ length: DASHBOARD_LAYOUT_GRID_COLUMNS }).map((_, index) => {
        const showGridLine =
          index > minimumWidgetWidth &&
          index < DASHBOARD_LAYOUT_GRID_COLUMNS - minimumWidgetWidth

        return (
          <Box
            flex={getRelativeCellWidth(1)}
            key={index}
            h={4}
            position="relative"
            _after={{
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '-8px',
              transform: 'translate(-50%, -50%)',
              height: '2px',
              width: '2px',
              bg: 'gray.500',
            }}
            visibility={showGridLine ? 'visible' : 'hidden'}
          />
        )
      })}
    </Box>
  )
}
