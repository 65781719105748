import { type DYNAMIC_DATE_ID, getDatePreset } from 'constants/getDatePresets'
import { Box, Flex, MenuButton } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { DatePreview } from 'components/Datepicker/DatePreview'
import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import {
  type SegmentationCustomerSet,
  type SegmentationSet,
} from 'features/segmentations/graphql/fragments'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import { SegmentationHeaderWrapper } from 'features/segmentations/shared/SegmentationHeaderWrapper'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CUSTOMER_SET_DATE_RANGES,
  PRODUCT_SET_DATE_RANGES,
  SEGMENTATION_TYPE,
} from '../consts'
import { useCustomerSetDownload } from '../graphql/useCustomerSetDownload'
import { CustomerSetContextMenu } from '../views/CustomerSetsView/components/CustomerSetContextMenu'
import { SegmentationSetContextMenu } from '../views/ProductSetsView/components/SegmentationSetContextMenu/SegmentationSetContextMenu'

interface SegmentRuleHeaderProps {
  type: SEGMENTATION_TYPE
  segmentationSet: SegmentationSet | SegmentationCustomerSet
  selectedDatePresetId: DYNAMIC_DATE_ID
  setSelectedDatePresetId: (datePresetId: DYNAMIC_DATE_ID) => void
}

export const SegmentRuleHeader: React.FC<SegmentRuleHeaderProps> = ({
  type,
  segmentationSet,
  selectedDatePresetId,
  setSelectedDatePresetId,
}) => {
  const selectedDatePreset = getDatePreset(selectedDatePresetId)
  const [trackEvent] = useTrackEvent()
  const navigate = useNavigate()
  const { downloadSet, loading } = useCustomerSetDownload({
    setId: segmentationSet.id,
    setName: segmentationSet.name,
    from: 'rules',
  })
  const DATE_RANGES =
    type === SEGMENTATION_TYPE.PRODUCT
      ? PRODUCT_SET_DATE_RANGES
      : CUSTOMER_SET_DATE_RANGES

  return (
    <SegmentationHeaderWrapper>
      <Flex alignItems="center">
        <Box
          as={Link}
          to={
            type === SEGMENTATION_TYPE.PRODUCT
              ? SEGMENTATIONS_PAGES.PRODUCT_SETS
              : SEGMENTATIONS_PAGES.CUSTOMER_SETS
          }
          _hover={{ textDecoration: 'underline' }}
        >
          <SegmentationHeaderWrapper.Title>
            {`${type === SEGMENTATION_TYPE.PRODUCT ? 'Product' : 'Customer'} sets`}
          </SegmentationHeaderWrapper.Title>
        </Box>

        <Typography color="gray.600" fontSize="sm" ml={2} mr={1}>
          /
        </Typography>

        <Flex alignItems="center" gap={2}>
          <Icon
            name={segmentationSet.iconName}
            color={segmentationSet.iconColor}
            size="small"
          />
          <Typography fontSize="medium" fontWeight={500} color="gray.900">
            {segmentationSet.name}
          </Typography>

          {/* // TODO: FIX */}
          {type === SEGMENTATION_TYPE.PRODUCT && (
            <SegmentationSetContextMenu
              segmentationSet={segmentationSet as SegmentationSet}
              placement="bottom-start"
              onDelete={() => {
                navigate(SEGMENTATIONS_PAGES.PRODUCT_SETS)
              }}
            />
          )}
          {type === SEGMENTATION_TYPE.CUSTOMER && (
            <Flex gap={2}>
              <ButtonIcon
                name="DownloadIcon"
                size="sm"
                colorScheme="gray"
                variant="ghost"
                title="Download"
                onClick={downloadSet}
                isLoading={loading}
              />
              <CustomerSetContextMenu
                set={segmentationSet as SegmentationCustomerSet}
                placement="bottom-start"
                onDelete={() => {
                  navigate(SEGMENTATIONS_PAGES.CUSTOMER_SETS)
                }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>

      <Box>
        <Dropdown
          customMenuButton={
            <MenuButton
              as={DatePreview}
              actualDate={selectedDatePreset.value}
              badgeLabel={selectedDatePreset.name}
            />
          }
          size="sm"
          matchWidth={false}
          placement="bottom-end"
          options={DATE_RANGES.map((dateRange) => ({
            id: dateRange,
            name: getDatePreset(dateRange).name,
          }))}
          value={selectedDatePresetId}
          callback={(value) => {
            setSelectedDatePresetId(value.id)
            trackEvent({
              eventName: `Segmentations ${
                type === SEGMENTATION_TYPE.PRODUCT ? 'Product' : 'Customer'
              } Set Date Preset Changes`,
              eventProperties: {
                setId: segmentationSet.id,
                setName: segmentationSet.name,
                datePresetId: value.id,
              },
            })
          }}
          renderInPortal={false}
        />
      </Box>
    </SegmentationHeaderWrapper>
  )
}
