import { MenuItem, useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Icon } from 'components/Icon/Icon'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { REPORT_PAGES } from 'features/reports/routePages'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { useCreateReport } from 'graphql/reports/useCreateReport'
import { useGetAtomValue } from 'hooks/useGetAtomValue'
import type React from 'react'
import { useMatch, useNavigate } from 'react-router'
import { analyticsConfigAtom } from 'shared/atoms/analyticsConfigState'
import { useSelectReport } from '../hooks/useSelectReport'

interface DuplicateReportMenuItemProps {
  report: ReportState
}
export const DuplicateReportMenuItem: React.FC<
  DuplicateReportMenuItemProps
> = ({ report }) => {
  const navigate = useNavigate()
  const selectReport = useSelectReport()
  const [createReport, createReportState] = useCreateReport()
  const [createAnalyticsConfig] = useCreateAnalyticsConfig({
    shouldSetUrl: false,
  })
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const isReportDetailsPage = useMatch(REPORT_PAGES.REPORT_BY_ID(report.id))
  const isBasePage = useMatch(REPORT_PAGES.BASE)
  const getCurrentAnalyticsConfig = useGetAtomValue(analyticsConfigAtom)

  const createDuplicatedReport = async () => {
    if (!report.id) return

    try {
      const analyticsConfig = await createAnalyticsConfig(
        getCurrentAnalyticsConfig(),
      )

      const response = await createReport({
        report: {
          ...report,
          analyticsConfig,
          name: `Copy of ${report.name}`,
          visibility: report.visibility ?? [],
          labels: report.labels ?? [],
        },
      })

      toast({
        status: 'success',
        description: 'Successfully duplicated report',
      })
      selectReport(response, analyticsConfig)

      if (isReportDetailsPage || isBasePage) {
        navigate(REPORT_PAGES.REPORT_BY_ID(response.id))
      }
      trackEvent({
        eventName: 'Report Duplicated',
        eventProperties: {
          reportId: report.id,
          newReportId: response.id,
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not create report',
      })
      captureException(`Duplicate report: ${error}`)
    }
  }

  return (
    <MenuItem
      onClick={createDuplicatedReport}
      disabled={createReportState.loading}
      icon={<Icon name="Copy2Icon" size="small" />}
    >
      Duplicate report
    </MenuItem>
  )
}
