import { Flex } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { type FC } from 'react'
import { type TeamIcon } from './ResourceVisibility'

// Gets set in the table row hover
export const VISIBILITY_ICON_BG_VARIABLE = '--visibility-icon-overlap-bg'

export const OverlappingIcons: FC<{ icons?: TeamIcon[] }> = ({
  icons = [],
}) => (
  <Flex overflow="hidden">
    {icons.map((icon, index) => (
      <Flex key={index} maxW={2.5} _last={{ maxW: 'full' }}>
        <Icon
          size="small"
          bg={`var(${VISIBILITY_ICON_BG_VARIABLE}, white)`}
          _groupHover={{ bg: 'grey.100' }}
          _groupActive={{ bg: 'grey.300' }}
          // we dont want the hover/active state if ifs disabled as it causes inconstant css issues
          sx={{
            '[role=group]:disabled &': {
              color: 'grey.300',
            },
            '[role=group]:disabled:hover &': {
              bg: 'unset',
            },
            '[role=group]:disabled:focus &': {
              bg: 'unset',
            },
          }}
          name={icon.name}
          hexColor={icon.color}
        />
      </Flex>
    ))}
  </Flex>
)
