import {
  getMetricKeyWithoutAttribution,
  normalizedMetricsAtom,
} from 'graphql/statistics/useMetrics'
import { atom, useAtomValue } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { getUniqueMetrics, getValidMetrics } from 'shared/utils/analyticsConfig'
import { widgetAnalyticsConfigAtom } from '../atoms/dashboardViewState'
import { widgetConfig } from '../consts'
import { widgetDimensionsStateAtom } from './useWidgetDimensionsState'
import { widgetTypeByWidgetIdAtom } from './useWidgetsState'

export const widgetMetricsStateAtom = atomFamily((widgetId?: string) =>
  atom((get) => {
    const widgetType = get(widgetTypeByWidgetIdAtom(widgetId))
    const analyticsConfig = get(widgetAnalyticsConfigAtom(widgetId))
    const metrics = analyticsConfig?.metrics ?? []
    const { dimensions } = get(widgetDimensionsStateAtom(widgetId))
    const normalizedMetrics = get(normalizedMetricsAtom)

    const { requiresDimensions } = widgetType ? widgetConfig[widgetType] : {}

    const validMetrics = getValidMetrics(
      metrics,
      normalizedMetrics,
      dimensions,
      !requiresDimensions,
    )
    const uniqueMetrics = getUniqueMetrics(validMetrics)
    const metricKeys = validMetrics.map((m) => m.key)

    return {
      metricKeys,
      metrics: validMetrics,
      uniqueMetrics,
      uniqueMetricKeys: uniqueMetrics.map(getMetricKeyWithoutAttribution),
      unsafe_allMetricsKeys: metrics, // could involve invalid metrics
    }
  }),
)

export const useWidgetMetricsState = (widgetId?: string) =>
  useAtomValue(widgetMetricsStateAtom(widgetId))
