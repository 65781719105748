import {
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Switch,
} from '@chakra-ui/react'

import { Badge } from 'components/Badge'
import { Button } from 'components/buttons'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { useNormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import {
  type AttributedMetric,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useDimensionsState } from 'shared/hooks/useDimensionsState'
import {
  useMetricsState,
  useSetMetricsState,
} from 'shared/hooks/useMetricsState'
import {
  getValidMetricProcessors,
  isValidMetric,
} from 'shared/utils/analyticsConfig'

export const AttributionBadge = ({
  metric,
  attributionModalOpen,
  isOpen,
  onClose,
  onOpen,
}: {
  metric: AttributedMetric
  attributionModalOpen: () => void
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const flags = useFeatureFlags()
  const normalizedAttributionModels = useNormalizedAttributionModels()
  const attributions = Object.values(normalizedAttributionModels)
  const { metrics } = useMetricsState()
  const { dimensions } = useDimensionsState()
  const normalizedMetrics = useNormalizedMetrics()
  const validMetricProcessors = getValidMetricProcessors(dimensions)
  const setMetrics = useSetMetricsState()
  const attributionModel = normalizedAttributionModels[metric.attributionId]
  const metricKeys = metrics.map((metric) => metric.key)
  const [draftMetricKeys, setDraftMetricKeys] = useState<string[]>(metricKeys)
  const isDraftChanged = !isEqual(new Set(draftMetricKeys), new Set(metricKeys))

  function handleAttributionToggle(metricKey: string) {
    const isCurrentlySelected = draftMetricKeys.includes(metricKey)
    const newMetricKeys = isCurrentlySelected
      ? draftMetricKeys.filter((key) => key !== metricKey)
      : [...draftMetricKeys, metricKey]

    setDraftMetricKeys(newMetricKeys)
  }

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} size="small" placement="left" isLazy>
      <MenuButton mt={2} mb={4}>
        <Badge
          bg={attributionModel.color}
          color={attributionModel.iconColor}
          display="flex"
          alignItems="center"
          borderRadius={0.5}
          fontSize="xs"
          lineHeight="18px"
          fontWeight={400}
          width="fit-content"
          cursor="pointer"
        >
          {attributionModel.label}
        </Badge>
      </MenuButton>

      <Portal>
        <MenuOverlay
          isOpen={isOpen}
          onClick={() => {
            onClose()
            if (isDraftChanged) {
              setMetrics(draftMetricKeys)
            }
          }}
        />
        <MenuList>
          <MenuGroup>
            <Flex align="center" px={4} py={1} gap={1} cursor="default">
              <Typography fontSize="12px" fontWeight={600}>
                Select attribution model(s)
              </Typography>

              <Menu>
                <MenuButton>
                  <Flex align="center">
                    <Icon name="InformationCircleIcon" size="small" />
                  </Flex>
                </MenuButton>
                <MenuList width="156px">
                  <MenuGroup>
                    <Flex px={4} py={2}>
                      <Typography fontSize="12px" lineHeight="16px">
                        The attribution model defines how credit is assigned to
                        different touchpoints such as channels, campaigns and
                        ads in the user&apos;s path to placing an order.
                      </Typography>
                    </Flex>
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup>
                    <Flex px={4} py={0}>
                      <Button
                        variant="link"
                        color="blue.500"
                        onClick={() => {
                          window.open(
                            'https://docs.dema.ai/guides/fundamentals/attribution',
                            '_blank',
                          )
                        }}
                      >
                        Read more
                        <Icon name="LinkExternalIcon" size="small" ml={2} />
                      </Button>
                    </Flex>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Flex>
            {attributions.map((attribution) => {
              const metricItem =
                normalizedMetrics[`${metric.groupKey}:${attribution.id}`]
              const isFlagEnabled = attribution.featureFlag
                ? flags[attribution.featureFlag]
                : true
              const isDisabled =
                !isFlagEnabled ||
                !metricItem ||
                !isValidMetric(metricItem, validMetricProcessors)

              return (
                <MenuItem
                  key={attribution.id}
                  px={4}
                  py="7px"
                  onClick={(e) => {
                    e.preventDefault()
                    handleAttributionToggle(metricItem.key)
                  }}
                  isDisabled={isDisabled}
                >
                  <Flex align="center" gap={4}>
                    <Switch
                      size="sm"
                      isChecked={draftMetricKeys
                        .filter(
                          (draftMetricKey) =>
                            draftMetricKey.split(':').slice(0, 2).join(':') ===
                            metric.groupKey,
                        )
                        .map((item) => item.split(':')[2])
                        .includes(attribution.id)}
                    />
                    <Badge
                      bg={attribution.color}
                      color={attribution.iconColor}
                      display="flex"
                      alignItems="center"
                      borderRadius={0.5}
                      fontWeight={400}
                      width="fit-content"
                    >
                      {attribution.label}
                    </Badge>
                  </Flex>
                </MenuItem>
              )
            })}
          </MenuGroup>

          <MenuDivider />

          <MenuGroup>
            <MenuItem
              icon={<Icon name="ChartPieIcon" size="small" />}
              onClick={() => {
                attributionModalOpen()
                onClose()
              }}
              px={4}
            >
              <Typography>All attribution settings</Typography>
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}
