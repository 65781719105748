import { useDisclosure, useToast } from '@chakra-ui/react'
import { type CreateSegmentationCustomerSetInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { set } from 'lodash-es'
import cloneDeep from 'lodash-es/cloneDeep'
import { type ReactElement } from 'react'
import { useCreateCustomerSet } from '../graphql/useCreateCustomerSegmentationSet'
import { SEGMENTATION_CUSTOMER_SETS_QUERY } from '../graphql/useSegmentationCustomerSets'
import { SegmentationCustomerSetPicker } from './CustomerSetPicker'

interface AddCustomerSetModalProps {
  modalButton: ReactElement
}

export const AddCustomerSetModal: React.FC<AddCustomerSetModalProps> = ({
  modalButton,
}) => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const [createCustomerSet, createCustomerSetMutation] = useCreateCustomerSet()
  const modalDisclosure = useDisclosure()

  const onApply = (customerSet: CreateSegmentationCustomerSetInput) => {
    if (!customerSet.name) return

    createCustomerSet({
      variables: {
        params: {
          name: customerSet.name,
          iconColor: customerSet.iconColor,
          iconName: customerSet.iconName,
          dynamicDate: customerSet.dynamicDate,
        },
      },
      update: (cache, { data }) => {
        if (!data) return
        cache.updateQuery(
          { query: SEGMENTATION_CUSTOMER_SETS_QUERY },
          (queryData) => {
            if (!queryData?.viewer?.merchant.segmentationCustomerSets)
              return queryData

            const queryDataClone = cloneDeep(queryData)

            set(queryDataClone, 'viewer.merchant.segmentationCustomerSets', [
              ...queryData.viewer.merchant.segmentationCustomerSets,
              data.createSegmentationCustomerSet,
            ])

            return queryDataClone
          },
        )
      },
      onCompleted: ({ createSegmentationCustomerSet }) => {
        trackEvent({
          eventName: 'Segmentations Customer Set Created',
          eventProperties: {
            id: createSegmentationCustomerSet.id,
            name: createSegmentationCustomerSet.name,
            iconName: createSegmentationCustomerSet.iconName,
            iconColor: createSegmentationCustomerSet.iconColor,
          },
        })

        modalDisclosure.onClose()
      },
      onError: () => {
        toast({
          description: 'Could not create customer set. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <SegmentationCustomerSetPicker
      currentCustomerSet={undefined}
      modalButton={modalButton}
      onApply={onApply}
      closeOnApply={false}
      disclosure={modalDisclosure}
      isLoading={createCustomerSetMutation.loading}
    />
  )
}
