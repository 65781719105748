import { type Filter } from 'constants/types'
import {
  dashboardStateAtom,
  dashboardWidgetsAtom,
} from 'features/dashboards/atoms/dashboardViewState'
import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { normalizedMetricsAtom } from 'graphql/statistics/useMetrics'
import { atom, useAtomValue, useSetAtom, type SetStateAction } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { getUniqueMetrics } from 'shared/utils/analyticsConfig'
import { getValidFilters } from 'shared/utils/filters'
import { widgetDimensionsStateAtom } from '../useWidgetDimensionsState'
import { widgetMetricsStateAtom } from '../useWidgetMetricState'

const focusFiltersStateAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('filters'),
)

export const dashboardFiltersStateAtom = atom(
  (get) => {
    const filters = get(focusFiltersStateAtom)
    const normalizedMetrics = get(normalizedMetricsAtom)
    const normalizedDimensions = get(normalizedDimensionsAtom)

    const dashboardWidgets = get(dashboardWidgetsAtom)

    const allWidgetMetrics = dashboardWidgets.flatMap(
      (widget) => get(widgetMetricsStateAtom(widget.id)).uniqueMetrics,
    )
    const aggregatedMetrics = getUniqueMetrics(allWidgetMetrics)

    const aggregatedDimensions = dashboardWidgets.flatMap(
      (widget) => get(widgetDimensionsStateAtom(widget.id)).dimensions,
    )

    const validFilters = getValidFilters({
      filters,
      metrics: aggregatedMetrics,
      dimensions: aggregatedDimensions,
      normalizedMetrics,
      normalizedDimensions,
    })

    return {
      filters: validFilters,
      aggregatedMetrics,
      aggregatedDimensions,
    }
  },
  (_, set, filtersState: SetStateAction<Record<string, Filter[]>>) => {
    set(focusFiltersStateAtom, filtersState)
  },
)

export const useDashboardFiltersState = () =>
  useAtomValue(dashboardFiltersStateAtom)

export const useSetDashboardFiltersState = () =>
  useSetAtom(dashboardFiltersStateAtom)
