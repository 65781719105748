import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_CUSTOMER_SET_MUTATION = graphql(/* GraphQL */ `
  mutation CreateSegmentationCustomerSet(
    $params: CreateSegmentationCustomerSetInput!
  ) {
    createSegmentationCustomerSet(params: $params) {
      ...SegmentationCustomerSetFields
    }
  }
`)

export const useCreateCustomerSet = () => {
  return useMutation(CREATE_CUSTOMER_SET_MUTATION)
}
