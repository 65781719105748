import { Box, Flex, Skeleton, Stack, useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import {
  type ExperimentConfig,
  type ExperimentStatus,
} from 'features/geoLift/graphql/fragments'
import { StatusTag } from 'features/geoLift/shared/StatusTag'
import {
  formatTestPeriodFromDays,
  stringIdToLabel,
} from 'features/geoLift/utils'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useChannels } from 'graphql/useChannels'
import { useCountries } from 'graphql/useCountries'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { formatMetric } from 'utils/numberFormats'
import { CampaignDetails } from './CampaignDetails'
import { DetailRow } from './DetailRow'
import { EditExperimentDrawer } from './EditExperimentDrawer'

export interface ExperimentConfigPanelProps {
  config: ExperimentConfig | undefined
  experimentStatus: ExperimentStatus | undefined
  loading: boolean
}

export const ExperimentConfigPanel: React.FC<ExperimentConfigPanelProps> = ({
  config,
  experimentStatus,
  loading,
}) => {
  const { sites = [] } = useMerchantInfo()
  const editDrawerDisclosure = useDisclosure()
  const { countries } = useCountries()
  const { channels } = useChannels()

  if (loading)
    return (
      <Box
        py={4}
        px={6}
        w="300px"
        h="full"
        borderLeft="1px solid"
        borderColor="gray.200"
      >
        <Typography
          fontSize="sm"
          fontWeight={500}
          lineHeight={5}
          color="gray.800"
        >
          Experiment details
        </Typography>

        <Stack spacing={2} mt={2}>
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
        </Stack>
      </Box>
    )

  if (!config || !experimentStatus) return null

  return (
    <Box
      flexShrink={0}
      py={4}
      px={6}
      w="300px"
      h="full"
      borderLeft="1px solid"
      borderColor="gray.200"
    >
      <Typography
        fontSize="sm"
        fontWeight={500}
        lineHeight={5}
        color="gray.800"
      >
        Experiment details
      </Typography>

      <Stack gap={0}>
        <DetailRow
          label="Status"
          value={
            <Flex justifyContent="flex-end">
              <StatusTag status={experimentStatus} size="medium" />
            </Flex>
          }
        />
        <DetailRow label="Type" value={stringIdToLabel(config.purpose)} />
        <DetailRow
          label="Country"
          value={
            countries.find((country) => country.id === config.country)
              ?.name ?? <Skeleton h={4} w={24} />
          }
        />
        <DetailRow
          label="Storefront"
          value={
            sites.find((site) => site.frontendId === config.frontendId)?.name ??
            config.frontendId
          }
        />
        <DetailRow
          label="Channel"
          value={
            channels.find((channel) => channel.id === config.channel)?.name ?? (
              <Skeleton h={4} w={24} />
            )
          }
        />

        <DetailRow
          label="Funnel"
          value={
            <Box minW={0}>
              {config.funnelCampaigns.map((campaign) => (
                <Tooltip
                  key={campaign}
                  label={campaign}
                  placement="left"
                  size="sm"
                >
                  <Typography
                    fontSize="sm"
                    fontWeight={400}
                    lineHeight={5}
                    color="gray.800"
                    textAlign="right"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {campaign}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          }
        />
        <DetailRow
          label="Campaigns"
          value={<CampaignDetails config={config} />}
        />
        <DetailRow
          label="Expected ROAS"
          value={formatMetric(
            METRIC_FORMAT.PERCENT_INT,
            config.expectedRoas / 100,
          )}
        />
        <DetailRow label="Start date" value={config.startDate} />
        <DetailRow
          label="Treatment period"
          value={formatTestPeriodFromDays(config.treatmentPeriod)}
        />
        {config.endDate ? (
          <DetailRow label="End date" value={config.endDate} />
        ) : null}
        <DetailRow
          label="Post treatment period"
          value={formatTestPeriodFromDays(config.postTreatmentPeriod)}
        />
      </Stack>

      {experimentStatus === 'draft' || experimentStatus === 'failed' ? (
        <>
          <Button
            variant="outline"
            size="sm"
            mt={6}
            onClick={editDrawerDisclosure.onOpen}
          >
            Edit details
          </Button>

          <EditExperimentDrawer
            config={config}
            experimentId={config.id}
            isOpen={editDrawerDisclosure.isOpen}
            onClose={editDrawerDisclosure.onClose}
          />
        </>
      ) : null}
    </Box>
  )
}
