import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { useCanEditDashboard } from 'features/dashboards/hooks/useCanEditDashboard'
import { dashboardOwnerAtom } from 'features/dashboards/hooks/useDashboardState'
import { useAtomValue } from 'jotai'
import { DeleteWidgetMenuItem } from './DeleteWidgetMenuItem'
import { DuplicateWidgetMenuItem } from './DuplicateWidgetMenuItem'

interface WidgetContextualMenuProps {
  widgetId: string
  rowId: string | undefined
  isVisible: boolean
}

export const WidgetContextualMenu = ({
  widgetId,
  rowId,
  isVisible = false,
}: WidgetContextualMenuProps) => {
  const dashboardOwner = useAtomValue(dashboardOwnerAtom)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const canEditDashboard = useCanEditDashboard()
  const { user } = useUser()

  if (!canEditDashboard) return null

  const userId = user?.publicMetadata.dema_id?.toString()
  const isOwnUser = userId === dashboardOwner?.id
  const isDeleteAllowed = canEditDashboard && isOwnUser

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy size="sm">
      <MenuButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onOpen()
        }}
        as={IconButton}
        size="xs"
        aria-label="Options"
        icon={
          <Icon
            name="HorizontalMenuIcon"
            tooltipLabel="More actions"
            size="small"
          />
        }
        variant="ghost"
        visibility={isVisible ? 'visible' : 'hidden'}
      />
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList
          onClick={(e) => e.stopPropagation()}
          zIndex="dropdown" // zIndex is set to dropdown to ensure the menu is below the delete report modal
        >
          <DuplicateWidgetMenuItem widgetId={widgetId} rowId={rowId} />
          {isDeleteAllowed && (
            <>
              <MenuDivider />
              <DeleteWidgetMenuItem widgetId={widgetId} />
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}
