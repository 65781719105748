import {
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationCustomerSet } from 'features/segmentations/graphql/fragments'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useDeleteSegmentationCustomerSet } from '../../graphql/useDeleteSegmentationSet'

interface DeleteSegmentationCustomerSetProps {
  customerSet: SegmentationCustomerSet
  onDelete?: () => void
}

export const DeleteCustomerSetModal = ({
  customerSet,
  onDelete,
}: DeleteSegmentationCustomerSetProps) => {
  const deleteSegmentationCustomerSet = useDeleteSegmentationCustomerSet()

  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onDeleteSet = () => {
    onDelete?.()

    deleteSegmentationCustomerSet({
      variables: {
        id: customerSet.id,
      },
      optimisticResponse: {
        deleteSegmentationCustomerSet: {
          id: customerSet.id,
          __typename: 'SegmentationCustomerSet',
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteSegmentationCustomerSet) return

        cache.evict({
          id: cache.identify(data?.deleteSegmentationCustomerSet),
        })
        cache.gc()
      },
      onCompleted: () => {
        trackEvent({
          eventName: 'Segmentations Customer Set Deleted',
          eventProperties: { setId: customerSet.id, setName: customerSet.name },
        })
      },
      onError: () => {
        toast({
          description: 'Could not delete customer set. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <MenuItem
        icon={<Icon name="TrashIcon" size="small" />}
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
        closeOnSelect={true}
      >
        Delete customer set
      </MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete customer set</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            The customer set will be deleted including all the rules it
            contains.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              type="submit"
              onClick={() => {
                onDeleteSet()
                onClose()
              }}
            >
              Delete customer set
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
