import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const SET_SEGMENTATION_RULES_MUTATION = graphql(/* GraphQL */ `
  mutation SetSegmentationRules($params: SetSegmentationRulesInput!) {
    setSegmentationRules(params: $params) {
      ...SegmentationSetFields
      rules {
        ...SegmentationRuleFields
      }
    }
  }
`)

export const useSetProductRules = () => {
  return useMutation(SET_SEGMENTATION_RULES_MUTATION)
}
