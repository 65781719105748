import { Box } from '@chakra-ui/react'
import { WidgetAnalyticsChart } from './WidgetAnalyticsChart'

interface ChartWidgetContentProps {
  widgetId: string
  chartContainerHeight: number
}

export const ChartWidgetContent = ({
  widgetId,
  chartContainerHeight,
}: ChartWidgetContentProps) => {
  return (
    <Box p={4} h="100%">
      <WidgetAnalyticsChart
        widgetId={widgetId}
        containerHeight={chartContainerHeight}
      />
    </Box>
  )
}
