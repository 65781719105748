import { useAtomValue, useSetAtom } from 'jotai'
import {
  analyticsConfigAtom,
  newAnalyticsConfigAtom,
} from 'shared/atoms/analyticsConfigState'

export const useAnalyticsConfigAtom = () => useAtomValue(analyticsConfigAtom)
export const useSetAnalyticsConfigAtom = () => useSetAtom(analyticsConfigAtom)
export const useNewAnalyticsConfigAtom = () =>
  useAtomValue(newAnalyticsConfigAtom)
