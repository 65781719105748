import { Box } from '@chakra-ui/react'
import { WidgetAnalyticsTable } from '../WidgetAnalyticsTable/WidgetAnalyticsTable'

interface TableWidgetContentProps {
  widgetId: string
}

export const TableWidgetContent = ({ widgetId }: TableWidgetContentProps) => {
  return (
    <Box p={4} h="100%">
      <WidgetAnalyticsTable widgetId={widgetId} />
    </Box>
  )
}
