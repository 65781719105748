/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n  fragment TextMessageItemFields on TextMessageItem {\n    id\n    text\n    __typename\n  }\n": typeof types.TextMessageItemFieldsFragmentDoc,
    "\n  fragment ReportMessageItemFields on ReportMessageItem {\n    id\n    report {\n      id\n      name\n      startDate\n      endDate\n      dynamicDate\n      compareStartDate\n      compareEndDate\n      compareDynamicDate\n      metrics\n      dimensions\n      filters\n      __typename\n    }\n    __typename\n  }\n": typeof types.ReportMessageItemFieldsFragmentDoc,
    "\n  fragment ConversationMessageFields on Message {\n    id\n    author {\n      id\n      name\n      role\n      __typename\n    }\n    items {\n      ...ReportMessageItemFields\n      ...TextMessageItemFields\n      __typename\n    }\n    createdAt\n    __typename\n  }\n": typeof types.ConversationMessageFieldsFragmentDoc,
    "\n  fragment ConversationFields on Conversation {\n    id\n    title\n    messages {\n      ...ConversationMessageFields\n    }\n    __typename\n  }\n": typeof types.ConversationFieldsFragmentDoc,
    "\n  mutation CreateConversation($payload: CreateConversationInput!) {\n    createConversation(params: $payload) {\n      ...ConversationFields\n    }\n  }\n": typeof types.CreateConversationDocument,
    "\n  mutation CreateConversationMessage(\n    $payload: CreateConversationMessageInput!\n  ) {\n    createConversationMessage(params: $payload) {\n      ...ConversationMessageFields\n    }\n  }\n": typeof types.CreateConversationMessageDocument,
    "\n  query ConversationById($id: ID!) {\n    viewer {\n      id\n      conversation(id: $id) {\n        ...ConversationFields\n      }\n    }\n  }\n": typeof types.ConversationByIdDocument,
    "\n  fragment AnalyticsConfigFields on AnalyticsConfig {\n    id\n    chart\n    compareDynamicDate\n    compareStartDate\n    compareEndDate\n    compareUnit\n    dimensions\n    metrics\n    startDate\n    endDate\n    dynamicDate\n    filters\n    tableState\n    __typename\n  }\n": typeof types.AnalyticsConfigFieldsFragmentDoc,
    "\n  fragment DashboardWidgetFields on DashboardWidget {\n    __typename\n    id\n    name\n    type\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n  }\n": typeof types.DashboardWidgetFieldsFragmentDoc,
    "\n  fragment DashboardOwnerFields on User {\n    id\n    externalId\n  }\n": typeof types.DashboardOwnerFieldsFragmentDoc,
    "\n  fragment DashboardFields on Dashboard {\n    __typename\n    id\n    name\n    createdAt\n    updatedAt\n    iconName\n    iconColor\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    widgets {\n      ...DashboardWidgetFields\n    }\n    startDate\n    endDate\n    dynamicDate\n    compareStartDate\n    compareEndDate\n    compareDynamicDate\n    filters\n    layout\n  }\n": typeof types.DashboardFieldsFragmentDoc,
    "\n  fragment DashboardWithOwnerFields on Dashboard {\n    ...DashboardFields\n    owner {\n      ...DashboardOwnerFields\n    }\n  }\n": typeof types.DashboardWithOwnerFieldsFragmentDoc,
    "\n  mutation CreateDashboardMutation($payload: CreateDashboardInput!) {\n    createDashboard(payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n": typeof types.CreateDashboardMutationDocument,
    "\n  query DashboardById($id: ID!) {\n    viewer {\n      id\n      dashboard(id: $id) {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n": typeof types.DashboardByIdDocument,
    "\n  mutation DeleteDashboardMutation($id: ID!) {\n    deleteDashboard(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteDashboardMutationDocument,
    "\n  mutation EditDashboardMutation(\n    $dashboardId: ID!\n    $payload: UpdateDashboardInput!\n  ) {\n    updateDashboard(id: $dashboardId, payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n": typeof types.EditDashboardMutationDocument,
    "\n  query MerchantDashboards {\n    viewer {\n      id\n      merchant {\n        id\n        dashboards {\n          ...DashboardWithOwnerFields\n        }\n      }\n    }\n  }\n": typeof types.MerchantDashboardsDocument,
    "\n  query UserDashboards {\n    viewer {\n      id\n      dashboards {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n": typeof types.UserDashboardsDocument,
    "\n  fragment ExperimentSummaryFields on ExperimentSummary {\n    id\n    name\n    purpose\n    frontendId\n    status\n    startDate\n    endDate\n    channel\n    country\n    __typename\n  }\n": typeof types.ExperimentSummaryFieldsFragmentDoc,
    "\n  fragment ExperimentFields on Experiment {\n    id\n    status\n    config {\n      ...ExperimentConfigFields\n    }\n    setup {\n      ...ExperimentSetupFields\n    }\n    suggestions {\n      ...ExperimentSetupFields\n    }\n    inference {\n      ...ExperimentInferenceFields\n    }\n    __typename\n  }\n": typeof types.ExperimentFieldsFragmentDoc,
    "\n  fragment ExperimentConfigFields on ExperimentConfig {\n    id\n    purpose\n    name\n    frontendId\n    country\n    zoneType\n    zipCodeLength\n    channel\n    funnelCampaigns\n    excludeCampaigns\n    statusInference\n    statusSuggestions\n    expectedRoas\n    startDate\n    endDate\n    treatmentPeriod\n    postTreatmentPeriod\n    __typename\n  }\n": typeof types.ExperimentConfigFieldsFragmentDoc,
    "\n  fragment ExperimentSetupFields on ExperimentSetup {\n    id\n    correlation\n    isValidExperiment\n    treatmentProportion\n    avgMinimumDetectableEffect\n    absLiftInZero\n    treatmentGroup {\n      ...LocationFields\n    }\n    controlGroup {\n      ...LocationFields\n    }\n    channelMetrics {\n      estimatedLift\n      estimatedTotalSpend\n      estimatedTotalSpendPastPeriod\n      estimatedTotalTargetIncremental\n      isValidChannelSpend\n      __typename\n    }\n    __typename\n  }\n": typeof types.ExperimentSetupFieldsFragmentDoc,
    "\n  fragment LocationFields on Location {\n    commutingZone\n    weight\n    zipCode\n    __typename\n  }\n": typeof types.LocationFieldsFragmentDoc,
    "\n  fragment ExperimentInferenceMetricFields on ExperimentInferenceMetric {\n    id\n    returnOnSpend {\n      geolift\n      ad\n      mmm\n      mta\n      __typename\n    }\n    syntheticControlGroup {\n      ...LocationFields\n    }\n    controlObservations {\n      ...InferenceGraphPointFields\n    }\n    treatmentObservations {\n      ...InferenceGraphPointFields\n    }\n    summary {\n      ...InferenceSummaryFields\n    }\n    cumulativeLift {\n      ...InferenceGraphPointFields\n    }\n    returnLift {\n      ...InferenceGraphPointFields\n    }\n    pctLift {\n      ...InferenceGraphPointFields\n    }\n    __typename\n  }\n": typeof types.ExperimentInferenceMetricFieldsFragmentDoc,
    "\n  fragment ExperimentInferenceFields on ExperimentInference {\n    id\n    metrics {\n      ...ExperimentInferenceMetricFields\n    }\n    __typename\n  }\n": typeof types.ExperimentInferenceFieldsFragmentDoc,
    "\n  fragment InferenceGraphPointFields on InferenceGraphPoint {\n    date\n    lowerBound\n    upperBound\n    value\n  }\n": typeof types.InferenceGraphPointFieldsFragmentDoc,
    "\n  fragment InferenceSummaryFields on InferenceSummary {\n    incrementalFactor\n    lift\n    pValue\n    totalSpend\n    __typename\n  }\n": typeof types.InferenceSummaryFieldsFragmentDoc,
    "\n  mutation CreateExperiment($payload: CreateExperimentInput!) {\n    createExperiment(params: $payload) {\n      ...ExperimentFields\n    }\n  }\n": typeof types.CreateExperimentDocument,
    "\n  mutation DeleteExperiment($id: ID!) {\n    deleteExperiment(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteExperimentDocument,
    "\n  query Experiment($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        experiment(id: $id) {\n          ...ExperimentFields\n        }\n      }\n    }\n  }\n": typeof types.ExperimentDocument,
    "\n  query ExperimentGeography($params: ExperimentGeographyInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        experimentGeography(params: $params)\n      }\n    }\n  }\n": typeof types.ExperimentGeographyDocument,
    "\n  query Experiments {\n    viewer {\n      id\n      merchant {\n        id\n        experiments {\n          ...ExperimentSummaryFields\n        }\n      }\n    }\n  }\n": typeof types.ExperimentsDocument,
    "\n  mutation UpdateActiveSetup($params: UpdateActiveSetupInput!) {\n    updateActiveSetup(params: $params) {\n      ...ExperimentFields\n    }\n  }\n": typeof types.UpdateActiveSetupDocument,
    "\n  mutation UpdateResults($id: ID!) {\n    runExperimentInference(id: $id)\n  }\n": typeof types.UpdateResultsDocument,
    "\n  query FutureAnalysisQuery($futureAnalysisInput: FutureAnalysisInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        futureAnalysis(params: $futureAnalysisInput) {\n          contribution\n          summary\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n        }\n      }\n    }\n  }\n": typeof types.FutureAnalysisQueryDocument,
    "\n  query HistoricalAnalysisQuery(\n    $historicalAnalysisInput: HistoricalAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalAnalysis(params: $historicalAnalysisInput) {\n          startDate\n          endDate\n          contribution\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n          summary\n        }\n      }\n    }\n  }\n": typeof types.HistoricalAnalysisQueryDocument,
    "\n  query OptimizationConfigQuery {\n    viewer {\n      id\n      merchant {\n        id\n        optimizationConfig {\n          markets\n          frontendIds\n          optimizableChannels {\n            channelGroup\n            channel\n            funnelCampaign\n          }\n          targets {\n            id\n            name\n          }\n          summaries {\n            id\n            name\n            description\n          }\n          startWeekday\n          guardrail\n        }\n      }\n    }\n  }\n": typeof types.OptimizationConfigQueryDocument,
    "\n  query HistoricalHistoryAnalysisQuery(\n    $historicalHistoryAnalysisInput: HistoricalHistoryAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalHistoryAnalysis(params: $historicalHistoryAnalysisInput) {\n          historicalContributions\n          historicalReport\n        }\n      }\n    }\n  }\n": typeof types.HistoricalHistoryAnalysisQueryDocument,
    "\n  query HistoricalOverviewAnalysisQuery(\n    $historicalOverviewAnalysisInput: HistoricalOverviewAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalOverviewAnalysis(params: $historicalOverviewAnalysisInput) {\n          missedPotentialMarkets\n          metricCards\n          missedPotentialAggregated {\n            actualProfit\n            missedProfit\n            optimalProfit\n          }\n        }\n      }\n    }\n  }\n": typeof types.HistoricalOverviewAnalysisQueryDocument,
    "\n  mutation FavoriteReport($params: UpdateFavoriteReportInput!) {\n    updateFavoriteReport(params: $params) {\n      id\n      favorite\n      __typename\n    }\n  }\n": typeof types.FavoriteReportDocument,
    "\n  fragment SegmentationSetFields on SegmentationSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    labelColumnName\n    updatedAt\n    __typename\n  }\n": typeof types.SegmentationSetFieldsFragmentDoc,
    "\n  fragment SegmentationCustomerSetFields on SegmentationCustomerSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    updatedAt\n    __typename\n  }\n": typeof types.SegmentationCustomerSetFieldsFragmentDoc,
    "\n  fragment ProductLabelFields on ProductLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n": typeof types.ProductLabelFieldsFragmentDoc,
    "\n  fragment SegmentationCustomerLabelFields on SegmentationCustomerLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n": typeof types.SegmentationCustomerLabelFieldsFragmentDoc,
    "\n  fragment SegmentationRuleFields on SegmentationRule {\n    id\n    description\n    filters\n    productLabel {\n      ...ProductLabelFields\n    }\n    expiresAt\n    __typename\n  }\n": typeof types.SegmentationRuleFieldsFragmentDoc,
    "\n  fragment SegmentationCustomerRuleFields on SegmentationCustomerRule {\n    id\n    description\n    filters\n    label {\n      ...SegmentationCustomerLabelFields\n    }\n    expiresAt\n    __typename\n  }\n": typeof types.SegmentationCustomerRuleFieldsFragmentDoc,
    "\n  query CustomerSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          rules {\n            ...SegmentationCustomerRuleFields\n          }\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n": typeof types.CustomerSetByIdDocument,
    "\n  query CustomerSetDownloadUrl($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          downloadUrl\n        }\n      }\n    }\n  }\n": typeof types.CustomerSetDownloadUrlDocument,
    "\n  query SegmentationSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSet(id: $id) {\n          rules {\n            ...SegmentationRuleFields\n          }\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n": typeof types.SegmentationSetByIdDocument,
    "\n  mutation CreateCustomerLabel($params: CreateSegmentationCustomerLabelInput!) {\n    createSegmentationCustomerLabel(params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n": typeof types.CreateCustomerLabelDocument,
    "\n  query SegmentationCustomerLabels {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerLabels {\n          ...SegmentationCustomerLabelFields\n        }\n      }\n    }\n  }\n": typeof types.SegmentationCustomerLabelsDocument,
    "\n  mutation DeleteCustomerLabel($id: ID!) {\n    deleteSegmentationCustomerLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteCustomerLabelDocument,
    "\n  mutation EditSegmentationCustomerLabel(\n    $id: ID!\n    $params: EditSegmentationCustomerLabelInput!\n  ) {\n    editSegmentationCustomerLabel(id: $id, params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n": typeof types.EditSegmentationCustomerLabelDocument,
    "\n  query CustomerChartMetrics(\n    $params: GetSegmentationCustomerChartMetricsInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerChartMetrics(params: $params)\n      }\n    }\n  }\n": typeof types.CustomerChartMetricsDocument,
    "\n  mutation SetSegmentationCustomerRules(\n    $params: SetSegmentationCustomerRulesInput!\n  ) {\n    setSegmentationCustomerRules(params: $params) {\n      ...SegmentationCustomerSetFields\n      rules {\n        ...SegmentationCustomerRuleFields\n      }\n    }\n  }\n": typeof types.SetSegmentationCustomerRulesDocument,
    "\n  mutation CreateSegmentationCustomerSet(\n    $params: CreateSegmentationCustomerSetInput!\n  ) {\n    createSegmentationCustomerSet(params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n": typeof types.CreateSegmentationCustomerSetDocument,
    "\n  mutation DeleteSegmentationCustomerSet($id: ID!) {\n    deleteSegmentationCustomerSet(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteSegmentationCustomerSetDocument,
    "\n  mutation EditSegmentationCustomerSet(\n    $id: ID!\n    $params: EditSegmentationCustomerSetInput!\n  ) {\n    editSegmentationCustomerSet(id: $id, params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n": typeof types.EditSegmentationCustomerSetDocument,
    "\n  query SegmentationCustomerSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSets {\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n": typeof types.SegmentationCustomerSetsDocument,
    "\n  mutation CreateProductLabel($params: CreateProductLabelInput!) {\n    createProductLabel(params: $params) {\n      ...ProductLabelFields\n    }\n  }\n": typeof types.CreateProductLabelDocument,
    "\n  mutation DeleteProductLabel($id: ID!) {\n    deleteProductLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteProductLabelDocument,
    "\n  mutation EditProductLabel($id: ID!, $params: EditProductLabelInput!) {\n    editProductLabel(id: $id, params: $params) {\n      ...ProductLabelFields\n    }\n  }\n": typeof types.EditProductLabelDocument,
    "\n  query ProductLabels {\n    viewer {\n      id\n      merchant {\n        id\n        productLabels {\n          ...ProductLabelFields\n        }\n      }\n    }\n  }\n": typeof types.ProductLabelsDocument,
    "\n  query ProductChartMetrics($params: GetSegmentationProductChartMetricsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationProductChartMetrics(params: $params)\n      }\n    }\n  }\n": typeof types.ProductChartMetricsDocument,
    "\n  query ProductLabelSegmentation($params: GetProductLabelSegmentationInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        productLabelSegmentation(params: $params)\n      }\n    }\n  }\n": typeof types.ProductLabelSegmentationDocument,
    "\n  mutation SetSegmentationRules($params: SetSegmentationRulesInput!) {\n    setSegmentationRules(params: $params) {\n      ...SegmentationSetFields\n      rules {\n        ...SegmentationRuleFields\n      }\n    }\n  }\n": typeof types.SetSegmentationRulesDocument,
    "\n  mutation CreateSegmentationSet($params: CreateSegmentationSetInput!) {\n    createSegmentationSet(params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n": typeof types.CreateSegmentationSetDocument,
    "\n  mutation DeleteSegmentationSet($id: ID!) {\n    deleteSegmentationSet(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteSegmentationSetDocument,
    "\n  mutation EditSegmentationSet($id: ID!, $params: EditSegmentationSetInput!) {\n    editSegmentationSet(id: $id, params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n": typeof types.EditSegmentationSetDocument,
    "\n  query SegmentationSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSets {\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n": typeof types.SegmentationSetsDocument,
    "\n  fragment IntegrationFields on Integration {\n    id\n    serviceId\n    connected\n    config\n    costConfig\n    __typename\n  }\n": typeof types.IntegrationFieldsFragmentDoc,
    "\n  mutation ToggleIntegration($params: ToggleIntegrationInput!) {\n    toggleIntegration(params: $params) {\n      id\n      __typename\n      connected\n    }\n  }\n": typeof types.ToggleIntegrationDocument,
    "\n  fragment Integrations_MerchantSite on MerchantSite {\n    integrations {\n      ...IntegrationFields\n    }\n  }\n": typeof types.Integrations_MerchantSiteFragmentDoc,
    "\n  query IntegrationsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...Integrations_MerchantSite\n      }\n    }\n  }\n": typeof types.IntegrationsViewQueryDocument,
    "\n  mutation SaveIntegrationAccounts($params: SaveIntegrationAccountsInput!) {\n    saveIntegrationAccounts(params: $params) {\n      ...IntegrationFields\n    }\n  }\n": typeof types.SaveIntegrationAccountsDocument,
    "\n  fragment ChannelMappings_MerchantSite on MerchantSite {\n    channelMappings {\n      channel\n      channelGroup\n      mappings\n    }\n  }\n": typeof types.ChannelMappings_MerchantSiteFragmentDoc,
    "\n  query MappingsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MappableSessions_MerchantSite\n        ...ChannelMappings_MerchantSite\n      }\n    }\n  }\n": typeof types.MappingsViewQueryDocument,
    "\n  query TotalUnmappedSessions {\n    viewer {\n      id\n      merchant {\n        id\n        totalUnmappedSessions\n      }\n    }\n  }\n": typeof types.TotalUnmappedSessionsDocument,
    "\n  fragment MappableSessions_MerchantSite on MerchantSite {\n    mappableSessions {\n      utmCampaign\n      utmMedium\n      utmSource\n      visits\n    }\n  }\n": typeof types.MappableSessions_MerchantSiteFragmentDoc,
    "\n  mutation UpdateChannelMappings($params: UpdateChannelMappingsInput!) {\n    updateChannelMappings(params: $params) {\n      mappings\n      channel\n      channelGroup\n    }\n  }\n": typeof types.UpdateChannelMappingsDocument,
    "\n    fragment MarketingCostManualInputs_MerchantSite on MerchantSite {\n      marketingCostManualInputs {\n        ...IntegrationFields\n      }\n    }\n  ": typeof types.MarketingCostManualInputs_MerchantSiteFragmentDoc,
    "\n  query MarketingCostsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MarketingCostManualInputs_MerchantSite\n      }\n    }\n  }\n": typeof types.MarketingCostsViewQueryDocument,
    "\n  mutation CreateMarketingCostManualInput(\n    $params: CreateMarketingCostManualInputParams!\n  ) {\n    createMarketingCostManualInput(params: $params) {\n      ...IntegrationFields\n    }\n  }\n": typeof types.CreateMarketingCostManualInputDocument,
    "\n  mutation CreateReportLabel($params: CreateReportLabelInput!) {\n    createReportLabel(params: $params) {\n      id\n      name\n      color\n      __typename\n      reportCount\n    }\n  }\n": typeof types.CreateReportLabelDocument,
    "\n  mutation DeleteReportLabel($id: ID!) {\n    deleteReportLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteReportLabelDocument,
    "\n  mutation EditReportLabel($id: ID!, $params: EditReportLabelInput!) {\n    editReportLabel(id: $id, params: $params) {\n      id\n      name\n      color\n      __typename\n    }\n  }\n": typeof types.EditReportLabelDocument,
    "\n  mutation CreateOrganizationInvitation(\n    $params: CreateOrganizationInvitationInput!\n  ) {\n    createOrganizationInvitation(params: $params) {\n      __typename\n      ... on OrganizationInvitation {\n        id\n        status\n      }\n      ... on CreateOrganizationInvitationError {\n        message\n      }\n    }\n  }\n": typeof types.CreateOrganizationInvitationDocument,
    "\n  mutation RevokeOrganizationInvitation(\n    $params: RevokeOrganizationInvitationInput!\n  ) {\n    revokeOrganizationInvitation(params: $params)\n  }\n": typeof types.RevokeOrganizationInvitationDocument,
    "\n  query ReturnThreshold {\n    viewer {\n      id\n      merchant {\n        __typename\n        id\n        returnThreshold\n        returnThresholdCurve\n      }\n    }\n  }\n": typeof types.ReturnThresholdDocument,
    "\n  mutation UpdateReturnThreshold($params: UpdateReturnThresholdInput!) {\n    updateReturnThreshold(params: $params) {\n      returnThreshold\n      id\n      __typename\n    }\n  }\n": typeof types.UpdateReturnThresholdDocument,
    "\n  mutation CreateTeamMutation($payload: CreateTeamInput!) {\n    createTeam(params: $payload) {\n      ...Teams_TeamFields\n    }\n  }\n": typeof types.CreateTeamMutationDocument,
    "\n  mutation DeleteMerchantTeamById($teamId: ID!) {\n    deleteTeam(id: $teamId) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteMerchantTeamByIdDocument,
    "\n  mutation EditMerchantTeamById($teamId: ID!, $payload: EditTeamInput!) {\n    editTeam(id: $teamId, params: $payload) {\n      id\n      iconName\n      iconColor\n      name\n      preferredMetrics\n      preferredDimensions\n    }\n  }\n": typeof types.EditMerchantTeamByIdDocument,
    "\n  fragment TaxonomyInputFields on TaxonomyInput {\n    id\n    type\n    rootMatcher\n  }\n": typeof types.TaxonomyInputFieldsFragmentDoc,
    "\n  mutation CreateTaxonomyInputMutation($params: CreateTaxonomyInputInput!) {\n    createTaxonomyInput(params: $params) {\n      ...TaxonomyInputFields\n    }\n  }\n": typeof types.CreateTaxonomyInputMutationDocument,
    "\n  query TaxonomyInputsQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        taxonomyInputs {\n          ...TaxonomyInputFields\n        }\n      }\n    }\n  }\n": typeof types.TaxonomyInputsQueryDocument,
    "\n  mutation TrackEventMutation($params: TrackUserEventInput!) {\n    trackUserEvent(params: $params)\n  }\n": typeof types.TrackEventMutationDocument,
    "\n  fragment TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n": typeof types.TeamFieldsFragmentDoc,
    "\n  fragment MerchantFields on Merchant {\n    id\n    name\n    __typename\n  }\n": typeof types.MerchantFieldsFragmentDoc,
    "\n  fragment OwnerFields on User {\n    id\n    externalId\n  }\n": typeof types.OwnerFieldsFragmentDoc,
    "\n  fragment ReportFields on Report {\n    id\n    createdAt\n    description\n    name\n    favorite\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    labels {\n      id\n      name\n      color\n    }\n    updatedAt\n  }\n": typeof types.ReportFieldsFragmentDoc,
    "\n  fragment ReportWithOwnerFields on Report {\n    ...ReportFields\n    owner {\n      ...OwnerFields\n    }\n  }\n": typeof types.ReportWithOwnerFieldsFragmentDoc,
    "\n  query AnalyticsConfigById($id: ID!) {\n    viewer {\n      id\n      analyticsConfig(id: $id) {\n        ...AnalyticsConfigFields\n      }\n    }\n  }\n": typeof types.AnalyticsConfigByIdDocument,
    "\n  mutation CreateAnalyticsConfig($params: AnalyticsConfigInput!) {\n    createAnalyticsConfig(params: $params) {\n      ...AnalyticsConfigFields\n    }\n  }\n": typeof types.CreateAnalyticsConfigDocument,
    "\n  mutation CreateReportMutation($payload: CreateReportInput!) {\n    createReport(payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n": typeof types.CreateReportMutationDocument,
    "\n  mutation DeleteReportMutation($id: ID!) {\n    deleteReport(id: $id) {\n      id\n      __typename\n    }\n  }\n": typeof types.DeleteReportMutationDocument,
    "\n  mutation EditReportMutation($reportId: ID!, $payload: EditReportInput!) {\n    editReport(id: $reportId, payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n": typeof types.EditReportMutationDocument,
    "\n  query FavoriteReportsQuery {\n    viewer {\n      id\n      favoriteReports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n": typeof types.FavoriteReportsQueryDocument,
    "\n  query ReportsQuery {\n    viewer {\n      id\n      merchant {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n": typeof types.ReportsQueryDocument,
    "\n  query ReportById($id: ID!) {\n    viewer {\n      id\n      report(id: $id) {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n": typeof types.ReportByIdDocument,
    "\n  query ReportLabels($withReportCount: Boolean! = false) {\n    viewer {\n      id\n      merchant {\n        id\n        reportLabels {\n          id\n          name\n          color\n          reportCount @include(if: $withReportCount)\n        }\n      }\n    }\n  }\n": typeof types.ReportLabelsDocument,
    "\n  query TeamReportsById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n": typeof types.TeamReportsByIdDocument,
    "\n  query UserReports {\n    viewer {\n      id\n      reports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n": typeof types.UserReportsDocument,
    "\n  mutation DeleteOrganizationMembership(\n    $payload: DeleteOrganizationMembershipInput!\n  ) {\n    deleteOrganizationMembership(params: $payload)\n  }\n": typeof types.DeleteOrganizationMembershipDocument,
    "\n  mutation UpdateTeamMembership($payload: UpdateTeamMembershipInput!) {\n    updateTeamMembership(params: $payload) {\n      ...Teams_TeamMemberFields\n    }\n  }\n": typeof types.UpdateTeamMembershipDocument,
    "\n  query DimensionsValueQuery($dimensionValuesParams: DimensionValuesInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        dimensionValues(params: $dimensionValuesParams)\n      }\n    }\n  }\n": typeof types.DimensionsValueQueryDocument,
    "\n  query StatisticsQuery($statisticsParams: StatisticsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        statistics(params: $statisticsParams) {\n          dimensions\n          metrics\n        }\n      }\n    }\n  }\n": typeof types.StatisticsQueryDocument,
    "\n  fragment Teams_TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    members {\n      ...Teams_TeamMemberFields\n    }\n    preferredDimensions\n    preferredMetrics\n    __typename\n  }\n": typeof types.Teams_TeamFieldsFragmentDoc,
    "\n  fragment Teams_TeamMemberFields on User {\n    id\n    externalId\n    __typename\n  }\n": typeof types.Teams_TeamMemberFieldsFragmentDoc,
    "\n  query MerchantTeams {\n    viewer {\n      id\n      merchant {\n        id\n        teams {\n          ...Teams_TeamFields\n        }\n      }\n    }\n  }\n": typeof types.MerchantTeamsDocument,
    "\n  query MerchantTeamById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        ...Teams_TeamFields\n      }\n    }\n  }\n": typeof types.MerchantTeamByIdDocument,
    "\n  query UserTeams {\n    viewer {\n      id\n      teams {\n        ...Teams_TeamFields\n      }\n    }\n  }\n": typeof types.UserTeamsDocument,
    "\n  query ChannelGroups {\n    channelGroups {\n      id\n      name\n      channels {\n        id\n        name\n      }\n    }\n  }\n": typeof types.ChannelGroupsDocument,
    "\n  query Channels {\n    channels {\n      id\n      name\n    }\n  }\n": typeof types.ChannelsDocument,
    "\n  query Countries {\n    countries {\n      id\n      name\n    }\n  }\n": typeof types.CountriesDocument,
    "\n  query FeatureFlags {\n    viewer {\n      id\n      featureFlags\n    }\n  }\n": typeof types.FeatureFlagsDocument,
    "\n  query InitializeApp {\n    viewer {\n      id\n      featureFlags\n      merchant {\n        id\n        timezone\n        currency\n        sites {\n          domain\n          name\n          frontendId\n        }\n        metrics {\n          id\n          label\n          description\n          descriptionMarkdown\n          groupId\n          groupLabel\n          format\n          dependencies\n          attributionVariants\n          isHidden\n          isAverage\n          isReversePositive\n        }\n        dimensions {\n          id\n          label\n          description\n          descriptionMarkdown\n          processors\n          groupId\n          groupLabel\n          isHidden\n        }\n        attributionModels {\n          id\n          label\n          description\n          color\n          iconName\n          iconColor\n        }\n      }\n    }\n  }\n": typeof types.InitializeAppDocument,
    "\n  query Markets {\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n          countries\n        }\n      }\n    }\n  }\n": typeof types.MarketsDocument,
    "\n  query StaticDimensionValues {\n    channelGroups {\n      id\n      name\n    }\n    channels {\n      id\n      name\n    }\n    countries {\n      id\n      name\n    }\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n        }\n      }\n    }\n  }\n": typeof types.StaticDimensionValuesDocument,
    "\n  query apiVersion {\n    apiVersion\n  }\n": typeof types.ApiVersionDocument,
};
const documents: Documents = {
    "\n  fragment TextMessageItemFields on TextMessageItem {\n    id\n    text\n    __typename\n  }\n": types.TextMessageItemFieldsFragmentDoc,
    "\n  fragment ReportMessageItemFields on ReportMessageItem {\n    id\n    report {\n      id\n      name\n      startDate\n      endDate\n      dynamicDate\n      compareStartDate\n      compareEndDate\n      compareDynamicDate\n      metrics\n      dimensions\n      filters\n      __typename\n    }\n    __typename\n  }\n": types.ReportMessageItemFieldsFragmentDoc,
    "\n  fragment ConversationMessageFields on Message {\n    id\n    author {\n      id\n      name\n      role\n      __typename\n    }\n    items {\n      ...ReportMessageItemFields\n      ...TextMessageItemFields\n      __typename\n    }\n    createdAt\n    __typename\n  }\n": types.ConversationMessageFieldsFragmentDoc,
    "\n  fragment ConversationFields on Conversation {\n    id\n    title\n    messages {\n      ...ConversationMessageFields\n    }\n    __typename\n  }\n": types.ConversationFieldsFragmentDoc,
    "\n  mutation CreateConversation($payload: CreateConversationInput!) {\n    createConversation(params: $payload) {\n      ...ConversationFields\n    }\n  }\n": types.CreateConversationDocument,
    "\n  mutation CreateConversationMessage(\n    $payload: CreateConversationMessageInput!\n  ) {\n    createConversationMessage(params: $payload) {\n      ...ConversationMessageFields\n    }\n  }\n": types.CreateConversationMessageDocument,
    "\n  query ConversationById($id: ID!) {\n    viewer {\n      id\n      conversation(id: $id) {\n        ...ConversationFields\n      }\n    }\n  }\n": types.ConversationByIdDocument,
    "\n  fragment AnalyticsConfigFields on AnalyticsConfig {\n    id\n    chart\n    compareDynamicDate\n    compareStartDate\n    compareEndDate\n    compareUnit\n    dimensions\n    metrics\n    startDate\n    endDate\n    dynamicDate\n    filters\n    tableState\n    __typename\n  }\n": types.AnalyticsConfigFieldsFragmentDoc,
    "\n  fragment DashboardWidgetFields on DashboardWidget {\n    __typename\n    id\n    name\n    type\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n  }\n": types.DashboardWidgetFieldsFragmentDoc,
    "\n  fragment DashboardOwnerFields on User {\n    id\n    externalId\n  }\n": types.DashboardOwnerFieldsFragmentDoc,
    "\n  fragment DashboardFields on Dashboard {\n    __typename\n    id\n    name\n    createdAt\n    updatedAt\n    iconName\n    iconColor\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    widgets {\n      ...DashboardWidgetFields\n    }\n    startDate\n    endDate\n    dynamicDate\n    compareStartDate\n    compareEndDate\n    compareDynamicDate\n    filters\n    layout\n  }\n": types.DashboardFieldsFragmentDoc,
    "\n  fragment DashboardWithOwnerFields on Dashboard {\n    ...DashboardFields\n    owner {\n      ...DashboardOwnerFields\n    }\n  }\n": types.DashboardWithOwnerFieldsFragmentDoc,
    "\n  mutation CreateDashboardMutation($payload: CreateDashboardInput!) {\n    createDashboard(payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n": types.CreateDashboardMutationDocument,
    "\n  query DashboardById($id: ID!) {\n    viewer {\n      id\n      dashboard(id: $id) {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n": types.DashboardByIdDocument,
    "\n  mutation DeleteDashboardMutation($id: ID!) {\n    deleteDashboard(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteDashboardMutationDocument,
    "\n  mutation EditDashboardMutation(\n    $dashboardId: ID!\n    $payload: UpdateDashboardInput!\n  ) {\n    updateDashboard(id: $dashboardId, payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n": types.EditDashboardMutationDocument,
    "\n  query MerchantDashboards {\n    viewer {\n      id\n      merchant {\n        id\n        dashboards {\n          ...DashboardWithOwnerFields\n        }\n      }\n    }\n  }\n": types.MerchantDashboardsDocument,
    "\n  query UserDashboards {\n    viewer {\n      id\n      dashboards {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n": types.UserDashboardsDocument,
    "\n  fragment ExperimentSummaryFields on ExperimentSummary {\n    id\n    name\n    purpose\n    frontendId\n    status\n    startDate\n    endDate\n    channel\n    country\n    __typename\n  }\n": types.ExperimentSummaryFieldsFragmentDoc,
    "\n  fragment ExperimentFields on Experiment {\n    id\n    status\n    config {\n      ...ExperimentConfigFields\n    }\n    setup {\n      ...ExperimentSetupFields\n    }\n    suggestions {\n      ...ExperimentSetupFields\n    }\n    inference {\n      ...ExperimentInferenceFields\n    }\n    __typename\n  }\n": types.ExperimentFieldsFragmentDoc,
    "\n  fragment ExperimentConfigFields on ExperimentConfig {\n    id\n    purpose\n    name\n    frontendId\n    country\n    zoneType\n    zipCodeLength\n    channel\n    funnelCampaigns\n    excludeCampaigns\n    statusInference\n    statusSuggestions\n    expectedRoas\n    startDate\n    endDate\n    treatmentPeriod\n    postTreatmentPeriod\n    __typename\n  }\n": types.ExperimentConfigFieldsFragmentDoc,
    "\n  fragment ExperimentSetupFields on ExperimentSetup {\n    id\n    correlation\n    isValidExperiment\n    treatmentProportion\n    avgMinimumDetectableEffect\n    absLiftInZero\n    treatmentGroup {\n      ...LocationFields\n    }\n    controlGroup {\n      ...LocationFields\n    }\n    channelMetrics {\n      estimatedLift\n      estimatedTotalSpend\n      estimatedTotalSpendPastPeriod\n      estimatedTotalTargetIncremental\n      isValidChannelSpend\n      __typename\n    }\n    __typename\n  }\n": types.ExperimentSetupFieldsFragmentDoc,
    "\n  fragment LocationFields on Location {\n    commutingZone\n    weight\n    zipCode\n    __typename\n  }\n": types.LocationFieldsFragmentDoc,
    "\n  fragment ExperimentInferenceMetricFields on ExperimentInferenceMetric {\n    id\n    returnOnSpend {\n      geolift\n      ad\n      mmm\n      mta\n      __typename\n    }\n    syntheticControlGroup {\n      ...LocationFields\n    }\n    controlObservations {\n      ...InferenceGraphPointFields\n    }\n    treatmentObservations {\n      ...InferenceGraphPointFields\n    }\n    summary {\n      ...InferenceSummaryFields\n    }\n    cumulativeLift {\n      ...InferenceGraphPointFields\n    }\n    returnLift {\n      ...InferenceGraphPointFields\n    }\n    pctLift {\n      ...InferenceGraphPointFields\n    }\n    __typename\n  }\n": types.ExperimentInferenceMetricFieldsFragmentDoc,
    "\n  fragment ExperimentInferenceFields on ExperimentInference {\n    id\n    metrics {\n      ...ExperimentInferenceMetricFields\n    }\n    __typename\n  }\n": types.ExperimentInferenceFieldsFragmentDoc,
    "\n  fragment InferenceGraphPointFields on InferenceGraphPoint {\n    date\n    lowerBound\n    upperBound\n    value\n  }\n": types.InferenceGraphPointFieldsFragmentDoc,
    "\n  fragment InferenceSummaryFields on InferenceSummary {\n    incrementalFactor\n    lift\n    pValue\n    totalSpend\n    __typename\n  }\n": types.InferenceSummaryFieldsFragmentDoc,
    "\n  mutation CreateExperiment($payload: CreateExperimentInput!) {\n    createExperiment(params: $payload) {\n      ...ExperimentFields\n    }\n  }\n": types.CreateExperimentDocument,
    "\n  mutation DeleteExperiment($id: ID!) {\n    deleteExperiment(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteExperimentDocument,
    "\n  query Experiment($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        experiment(id: $id) {\n          ...ExperimentFields\n        }\n      }\n    }\n  }\n": types.ExperimentDocument,
    "\n  query ExperimentGeography($params: ExperimentGeographyInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        experimentGeography(params: $params)\n      }\n    }\n  }\n": types.ExperimentGeographyDocument,
    "\n  query Experiments {\n    viewer {\n      id\n      merchant {\n        id\n        experiments {\n          ...ExperimentSummaryFields\n        }\n      }\n    }\n  }\n": types.ExperimentsDocument,
    "\n  mutation UpdateActiveSetup($params: UpdateActiveSetupInput!) {\n    updateActiveSetup(params: $params) {\n      ...ExperimentFields\n    }\n  }\n": types.UpdateActiveSetupDocument,
    "\n  mutation UpdateResults($id: ID!) {\n    runExperimentInference(id: $id)\n  }\n": types.UpdateResultsDocument,
    "\n  query FutureAnalysisQuery($futureAnalysisInput: FutureAnalysisInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        futureAnalysis(params: $futureAnalysisInput) {\n          contribution\n          summary\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n        }\n      }\n    }\n  }\n": types.FutureAnalysisQueryDocument,
    "\n  query HistoricalAnalysisQuery(\n    $historicalAnalysisInput: HistoricalAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalAnalysis(params: $historicalAnalysisInput) {\n          startDate\n          endDate\n          contribution\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n          summary\n        }\n      }\n    }\n  }\n": types.HistoricalAnalysisQueryDocument,
    "\n  query OptimizationConfigQuery {\n    viewer {\n      id\n      merchant {\n        id\n        optimizationConfig {\n          markets\n          frontendIds\n          optimizableChannels {\n            channelGroup\n            channel\n            funnelCampaign\n          }\n          targets {\n            id\n            name\n          }\n          summaries {\n            id\n            name\n            description\n          }\n          startWeekday\n          guardrail\n        }\n      }\n    }\n  }\n": types.OptimizationConfigQueryDocument,
    "\n  query HistoricalHistoryAnalysisQuery(\n    $historicalHistoryAnalysisInput: HistoricalHistoryAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalHistoryAnalysis(params: $historicalHistoryAnalysisInput) {\n          historicalContributions\n          historicalReport\n        }\n      }\n    }\n  }\n": types.HistoricalHistoryAnalysisQueryDocument,
    "\n  query HistoricalOverviewAnalysisQuery(\n    $historicalOverviewAnalysisInput: HistoricalOverviewAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalOverviewAnalysis(params: $historicalOverviewAnalysisInput) {\n          missedPotentialMarkets\n          metricCards\n          missedPotentialAggregated {\n            actualProfit\n            missedProfit\n            optimalProfit\n          }\n        }\n      }\n    }\n  }\n": types.HistoricalOverviewAnalysisQueryDocument,
    "\n  mutation FavoriteReport($params: UpdateFavoriteReportInput!) {\n    updateFavoriteReport(params: $params) {\n      id\n      favorite\n      __typename\n    }\n  }\n": types.FavoriteReportDocument,
    "\n  fragment SegmentationSetFields on SegmentationSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    labelColumnName\n    updatedAt\n    __typename\n  }\n": types.SegmentationSetFieldsFragmentDoc,
    "\n  fragment SegmentationCustomerSetFields on SegmentationCustomerSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    updatedAt\n    __typename\n  }\n": types.SegmentationCustomerSetFieldsFragmentDoc,
    "\n  fragment ProductLabelFields on ProductLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n": types.ProductLabelFieldsFragmentDoc,
    "\n  fragment SegmentationCustomerLabelFields on SegmentationCustomerLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n": types.SegmentationCustomerLabelFieldsFragmentDoc,
    "\n  fragment SegmentationRuleFields on SegmentationRule {\n    id\n    description\n    filters\n    productLabel {\n      ...ProductLabelFields\n    }\n    expiresAt\n    __typename\n  }\n": types.SegmentationRuleFieldsFragmentDoc,
    "\n  fragment SegmentationCustomerRuleFields on SegmentationCustomerRule {\n    id\n    description\n    filters\n    label {\n      ...SegmentationCustomerLabelFields\n    }\n    expiresAt\n    __typename\n  }\n": types.SegmentationCustomerRuleFieldsFragmentDoc,
    "\n  query CustomerSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          rules {\n            ...SegmentationCustomerRuleFields\n          }\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n": types.CustomerSetByIdDocument,
    "\n  query CustomerSetDownloadUrl($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          downloadUrl\n        }\n      }\n    }\n  }\n": types.CustomerSetDownloadUrlDocument,
    "\n  query SegmentationSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSet(id: $id) {\n          rules {\n            ...SegmentationRuleFields\n          }\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n": types.SegmentationSetByIdDocument,
    "\n  mutation CreateCustomerLabel($params: CreateSegmentationCustomerLabelInput!) {\n    createSegmentationCustomerLabel(params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n": types.CreateCustomerLabelDocument,
    "\n  query SegmentationCustomerLabels {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerLabels {\n          ...SegmentationCustomerLabelFields\n        }\n      }\n    }\n  }\n": types.SegmentationCustomerLabelsDocument,
    "\n  mutation DeleteCustomerLabel($id: ID!) {\n    deleteSegmentationCustomerLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteCustomerLabelDocument,
    "\n  mutation EditSegmentationCustomerLabel(\n    $id: ID!\n    $params: EditSegmentationCustomerLabelInput!\n  ) {\n    editSegmentationCustomerLabel(id: $id, params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n": types.EditSegmentationCustomerLabelDocument,
    "\n  query CustomerChartMetrics(\n    $params: GetSegmentationCustomerChartMetricsInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerChartMetrics(params: $params)\n      }\n    }\n  }\n": types.CustomerChartMetricsDocument,
    "\n  mutation SetSegmentationCustomerRules(\n    $params: SetSegmentationCustomerRulesInput!\n  ) {\n    setSegmentationCustomerRules(params: $params) {\n      ...SegmentationCustomerSetFields\n      rules {\n        ...SegmentationCustomerRuleFields\n      }\n    }\n  }\n": types.SetSegmentationCustomerRulesDocument,
    "\n  mutation CreateSegmentationCustomerSet(\n    $params: CreateSegmentationCustomerSetInput!\n  ) {\n    createSegmentationCustomerSet(params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n": types.CreateSegmentationCustomerSetDocument,
    "\n  mutation DeleteSegmentationCustomerSet($id: ID!) {\n    deleteSegmentationCustomerSet(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteSegmentationCustomerSetDocument,
    "\n  mutation EditSegmentationCustomerSet(\n    $id: ID!\n    $params: EditSegmentationCustomerSetInput!\n  ) {\n    editSegmentationCustomerSet(id: $id, params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n": types.EditSegmentationCustomerSetDocument,
    "\n  query SegmentationCustomerSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSets {\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n": types.SegmentationCustomerSetsDocument,
    "\n  mutation CreateProductLabel($params: CreateProductLabelInput!) {\n    createProductLabel(params: $params) {\n      ...ProductLabelFields\n    }\n  }\n": types.CreateProductLabelDocument,
    "\n  mutation DeleteProductLabel($id: ID!) {\n    deleteProductLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteProductLabelDocument,
    "\n  mutation EditProductLabel($id: ID!, $params: EditProductLabelInput!) {\n    editProductLabel(id: $id, params: $params) {\n      ...ProductLabelFields\n    }\n  }\n": types.EditProductLabelDocument,
    "\n  query ProductLabels {\n    viewer {\n      id\n      merchant {\n        id\n        productLabels {\n          ...ProductLabelFields\n        }\n      }\n    }\n  }\n": types.ProductLabelsDocument,
    "\n  query ProductChartMetrics($params: GetSegmentationProductChartMetricsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationProductChartMetrics(params: $params)\n      }\n    }\n  }\n": types.ProductChartMetricsDocument,
    "\n  query ProductLabelSegmentation($params: GetProductLabelSegmentationInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        productLabelSegmentation(params: $params)\n      }\n    }\n  }\n": types.ProductLabelSegmentationDocument,
    "\n  mutation SetSegmentationRules($params: SetSegmentationRulesInput!) {\n    setSegmentationRules(params: $params) {\n      ...SegmentationSetFields\n      rules {\n        ...SegmentationRuleFields\n      }\n    }\n  }\n": types.SetSegmentationRulesDocument,
    "\n  mutation CreateSegmentationSet($params: CreateSegmentationSetInput!) {\n    createSegmentationSet(params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n": types.CreateSegmentationSetDocument,
    "\n  mutation DeleteSegmentationSet($id: ID!) {\n    deleteSegmentationSet(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteSegmentationSetDocument,
    "\n  mutation EditSegmentationSet($id: ID!, $params: EditSegmentationSetInput!) {\n    editSegmentationSet(id: $id, params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n": types.EditSegmentationSetDocument,
    "\n  query SegmentationSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSets {\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n": types.SegmentationSetsDocument,
    "\n  fragment IntegrationFields on Integration {\n    id\n    serviceId\n    connected\n    config\n    costConfig\n    __typename\n  }\n": types.IntegrationFieldsFragmentDoc,
    "\n  mutation ToggleIntegration($params: ToggleIntegrationInput!) {\n    toggleIntegration(params: $params) {\n      id\n      __typename\n      connected\n    }\n  }\n": types.ToggleIntegrationDocument,
    "\n  fragment Integrations_MerchantSite on MerchantSite {\n    integrations {\n      ...IntegrationFields\n    }\n  }\n": types.Integrations_MerchantSiteFragmentDoc,
    "\n  query IntegrationsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...Integrations_MerchantSite\n      }\n    }\n  }\n": types.IntegrationsViewQueryDocument,
    "\n  mutation SaveIntegrationAccounts($params: SaveIntegrationAccountsInput!) {\n    saveIntegrationAccounts(params: $params) {\n      ...IntegrationFields\n    }\n  }\n": types.SaveIntegrationAccountsDocument,
    "\n  fragment ChannelMappings_MerchantSite on MerchantSite {\n    channelMappings {\n      channel\n      channelGroup\n      mappings\n    }\n  }\n": types.ChannelMappings_MerchantSiteFragmentDoc,
    "\n  query MappingsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MappableSessions_MerchantSite\n        ...ChannelMappings_MerchantSite\n      }\n    }\n  }\n": types.MappingsViewQueryDocument,
    "\n  query TotalUnmappedSessions {\n    viewer {\n      id\n      merchant {\n        id\n        totalUnmappedSessions\n      }\n    }\n  }\n": types.TotalUnmappedSessionsDocument,
    "\n  fragment MappableSessions_MerchantSite on MerchantSite {\n    mappableSessions {\n      utmCampaign\n      utmMedium\n      utmSource\n      visits\n    }\n  }\n": types.MappableSessions_MerchantSiteFragmentDoc,
    "\n  mutation UpdateChannelMappings($params: UpdateChannelMappingsInput!) {\n    updateChannelMappings(params: $params) {\n      mappings\n      channel\n      channelGroup\n    }\n  }\n": types.UpdateChannelMappingsDocument,
    "\n    fragment MarketingCostManualInputs_MerchantSite on MerchantSite {\n      marketingCostManualInputs {\n        ...IntegrationFields\n      }\n    }\n  ": types.MarketingCostManualInputs_MerchantSiteFragmentDoc,
    "\n  query MarketingCostsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MarketingCostManualInputs_MerchantSite\n      }\n    }\n  }\n": types.MarketingCostsViewQueryDocument,
    "\n  mutation CreateMarketingCostManualInput(\n    $params: CreateMarketingCostManualInputParams!\n  ) {\n    createMarketingCostManualInput(params: $params) {\n      ...IntegrationFields\n    }\n  }\n": types.CreateMarketingCostManualInputDocument,
    "\n  mutation CreateReportLabel($params: CreateReportLabelInput!) {\n    createReportLabel(params: $params) {\n      id\n      name\n      color\n      __typename\n      reportCount\n    }\n  }\n": types.CreateReportLabelDocument,
    "\n  mutation DeleteReportLabel($id: ID!) {\n    deleteReportLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteReportLabelDocument,
    "\n  mutation EditReportLabel($id: ID!, $params: EditReportLabelInput!) {\n    editReportLabel(id: $id, params: $params) {\n      id\n      name\n      color\n      __typename\n    }\n  }\n": types.EditReportLabelDocument,
    "\n  mutation CreateOrganizationInvitation(\n    $params: CreateOrganizationInvitationInput!\n  ) {\n    createOrganizationInvitation(params: $params) {\n      __typename\n      ... on OrganizationInvitation {\n        id\n        status\n      }\n      ... on CreateOrganizationInvitationError {\n        message\n      }\n    }\n  }\n": types.CreateOrganizationInvitationDocument,
    "\n  mutation RevokeOrganizationInvitation(\n    $params: RevokeOrganizationInvitationInput!\n  ) {\n    revokeOrganizationInvitation(params: $params)\n  }\n": types.RevokeOrganizationInvitationDocument,
    "\n  query ReturnThreshold {\n    viewer {\n      id\n      merchant {\n        __typename\n        id\n        returnThreshold\n        returnThresholdCurve\n      }\n    }\n  }\n": types.ReturnThresholdDocument,
    "\n  mutation UpdateReturnThreshold($params: UpdateReturnThresholdInput!) {\n    updateReturnThreshold(params: $params) {\n      returnThreshold\n      id\n      __typename\n    }\n  }\n": types.UpdateReturnThresholdDocument,
    "\n  mutation CreateTeamMutation($payload: CreateTeamInput!) {\n    createTeam(params: $payload) {\n      ...Teams_TeamFields\n    }\n  }\n": types.CreateTeamMutationDocument,
    "\n  mutation DeleteMerchantTeamById($teamId: ID!) {\n    deleteTeam(id: $teamId) {\n      id\n      __typename\n    }\n  }\n": types.DeleteMerchantTeamByIdDocument,
    "\n  mutation EditMerchantTeamById($teamId: ID!, $payload: EditTeamInput!) {\n    editTeam(id: $teamId, params: $payload) {\n      id\n      iconName\n      iconColor\n      name\n      preferredMetrics\n      preferredDimensions\n    }\n  }\n": types.EditMerchantTeamByIdDocument,
    "\n  fragment TaxonomyInputFields on TaxonomyInput {\n    id\n    type\n    rootMatcher\n  }\n": types.TaxonomyInputFieldsFragmentDoc,
    "\n  mutation CreateTaxonomyInputMutation($params: CreateTaxonomyInputInput!) {\n    createTaxonomyInput(params: $params) {\n      ...TaxonomyInputFields\n    }\n  }\n": types.CreateTaxonomyInputMutationDocument,
    "\n  query TaxonomyInputsQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        taxonomyInputs {\n          ...TaxonomyInputFields\n        }\n      }\n    }\n  }\n": types.TaxonomyInputsQueryDocument,
    "\n  mutation TrackEventMutation($params: TrackUserEventInput!) {\n    trackUserEvent(params: $params)\n  }\n": types.TrackEventMutationDocument,
    "\n  fragment TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n": types.TeamFieldsFragmentDoc,
    "\n  fragment MerchantFields on Merchant {\n    id\n    name\n    __typename\n  }\n": types.MerchantFieldsFragmentDoc,
    "\n  fragment OwnerFields on User {\n    id\n    externalId\n  }\n": types.OwnerFieldsFragmentDoc,
    "\n  fragment ReportFields on Report {\n    id\n    createdAt\n    description\n    name\n    favorite\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    labels {\n      id\n      name\n      color\n    }\n    updatedAt\n  }\n": types.ReportFieldsFragmentDoc,
    "\n  fragment ReportWithOwnerFields on Report {\n    ...ReportFields\n    owner {\n      ...OwnerFields\n    }\n  }\n": types.ReportWithOwnerFieldsFragmentDoc,
    "\n  query AnalyticsConfigById($id: ID!) {\n    viewer {\n      id\n      analyticsConfig(id: $id) {\n        ...AnalyticsConfigFields\n      }\n    }\n  }\n": types.AnalyticsConfigByIdDocument,
    "\n  mutation CreateAnalyticsConfig($params: AnalyticsConfigInput!) {\n    createAnalyticsConfig(params: $params) {\n      ...AnalyticsConfigFields\n    }\n  }\n": types.CreateAnalyticsConfigDocument,
    "\n  mutation CreateReportMutation($payload: CreateReportInput!) {\n    createReport(payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n": types.CreateReportMutationDocument,
    "\n  mutation DeleteReportMutation($id: ID!) {\n    deleteReport(id: $id) {\n      id\n      __typename\n    }\n  }\n": types.DeleteReportMutationDocument,
    "\n  mutation EditReportMutation($reportId: ID!, $payload: EditReportInput!) {\n    editReport(id: $reportId, payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n": types.EditReportMutationDocument,
    "\n  query FavoriteReportsQuery {\n    viewer {\n      id\n      favoriteReports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n": types.FavoriteReportsQueryDocument,
    "\n  query ReportsQuery {\n    viewer {\n      id\n      merchant {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n": types.ReportsQueryDocument,
    "\n  query ReportById($id: ID!) {\n    viewer {\n      id\n      report(id: $id) {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n": types.ReportByIdDocument,
    "\n  query ReportLabels($withReportCount: Boolean! = false) {\n    viewer {\n      id\n      merchant {\n        id\n        reportLabels {\n          id\n          name\n          color\n          reportCount @include(if: $withReportCount)\n        }\n      }\n    }\n  }\n": types.ReportLabelsDocument,
    "\n  query TeamReportsById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n": types.TeamReportsByIdDocument,
    "\n  query UserReports {\n    viewer {\n      id\n      reports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n": types.UserReportsDocument,
    "\n  mutation DeleteOrganizationMembership(\n    $payload: DeleteOrganizationMembershipInput!\n  ) {\n    deleteOrganizationMembership(params: $payload)\n  }\n": types.DeleteOrganizationMembershipDocument,
    "\n  mutation UpdateTeamMembership($payload: UpdateTeamMembershipInput!) {\n    updateTeamMembership(params: $payload) {\n      ...Teams_TeamMemberFields\n    }\n  }\n": types.UpdateTeamMembershipDocument,
    "\n  query DimensionsValueQuery($dimensionValuesParams: DimensionValuesInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        dimensionValues(params: $dimensionValuesParams)\n      }\n    }\n  }\n": types.DimensionsValueQueryDocument,
    "\n  query StatisticsQuery($statisticsParams: StatisticsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        statistics(params: $statisticsParams) {\n          dimensions\n          metrics\n        }\n      }\n    }\n  }\n": types.StatisticsQueryDocument,
    "\n  fragment Teams_TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    members {\n      ...Teams_TeamMemberFields\n    }\n    preferredDimensions\n    preferredMetrics\n    __typename\n  }\n": types.Teams_TeamFieldsFragmentDoc,
    "\n  fragment Teams_TeamMemberFields on User {\n    id\n    externalId\n    __typename\n  }\n": types.Teams_TeamMemberFieldsFragmentDoc,
    "\n  query MerchantTeams {\n    viewer {\n      id\n      merchant {\n        id\n        teams {\n          ...Teams_TeamFields\n        }\n      }\n    }\n  }\n": types.MerchantTeamsDocument,
    "\n  query MerchantTeamById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        ...Teams_TeamFields\n      }\n    }\n  }\n": types.MerchantTeamByIdDocument,
    "\n  query UserTeams {\n    viewer {\n      id\n      teams {\n        ...Teams_TeamFields\n      }\n    }\n  }\n": types.UserTeamsDocument,
    "\n  query ChannelGroups {\n    channelGroups {\n      id\n      name\n      channels {\n        id\n        name\n      }\n    }\n  }\n": types.ChannelGroupsDocument,
    "\n  query Channels {\n    channels {\n      id\n      name\n    }\n  }\n": types.ChannelsDocument,
    "\n  query Countries {\n    countries {\n      id\n      name\n    }\n  }\n": types.CountriesDocument,
    "\n  query FeatureFlags {\n    viewer {\n      id\n      featureFlags\n    }\n  }\n": types.FeatureFlagsDocument,
    "\n  query InitializeApp {\n    viewer {\n      id\n      featureFlags\n      merchant {\n        id\n        timezone\n        currency\n        sites {\n          domain\n          name\n          frontendId\n        }\n        metrics {\n          id\n          label\n          description\n          descriptionMarkdown\n          groupId\n          groupLabel\n          format\n          dependencies\n          attributionVariants\n          isHidden\n          isAverage\n          isReversePositive\n        }\n        dimensions {\n          id\n          label\n          description\n          descriptionMarkdown\n          processors\n          groupId\n          groupLabel\n          isHidden\n        }\n        attributionModels {\n          id\n          label\n          description\n          color\n          iconName\n          iconColor\n        }\n      }\n    }\n  }\n": types.InitializeAppDocument,
    "\n  query Markets {\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n          countries\n        }\n      }\n    }\n  }\n": types.MarketsDocument,
    "\n  query StaticDimensionValues {\n    channelGroups {\n      id\n      name\n    }\n    channels {\n      id\n      name\n    }\n    countries {\n      id\n      name\n    }\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n        }\n      }\n    }\n  }\n": types.StaticDimensionValuesDocument,
    "\n  query apiVersion {\n    apiVersion\n  }\n": types.ApiVersionDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TextMessageItemFields on TextMessageItem {\n    id\n    text\n    __typename\n  }\n"): (typeof documents)["\n  fragment TextMessageItemFields on TextMessageItem {\n    id\n    text\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReportMessageItemFields on ReportMessageItem {\n    id\n    report {\n      id\n      name\n      startDate\n      endDate\n      dynamicDate\n      compareStartDate\n      compareEndDate\n      compareDynamicDate\n      metrics\n      dimensions\n      filters\n      __typename\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment ReportMessageItemFields on ReportMessageItem {\n    id\n    report {\n      id\n      name\n      startDate\n      endDate\n      dynamicDate\n      compareStartDate\n      compareEndDate\n      compareDynamicDate\n      metrics\n      dimensions\n      filters\n      __typename\n    }\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ConversationMessageFields on Message {\n    id\n    author {\n      id\n      name\n      role\n      __typename\n    }\n    items {\n      ...ReportMessageItemFields\n      ...TextMessageItemFields\n      __typename\n    }\n    createdAt\n    __typename\n  }\n"): (typeof documents)["\n  fragment ConversationMessageFields on Message {\n    id\n    author {\n      id\n      name\n      role\n      __typename\n    }\n    items {\n      ...ReportMessageItemFields\n      ...TextMessageItemFields\n      __typename\n    }\n    createdAt\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ConversationFields on Conversation {\n    id\n    title\n    messages {\n      ...ConversationMessageFields\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment ConversationFields on Conversation {\n    id\n    title\n    messages {\n      ...ConversationMessageFields\n    }\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateConversation($payload: CreateConversationInput!) {\n    createConversation(params: $payload) {\n      ...ConversationFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateConversation($payload: CreateConversationInput!) {\n    createConversation(params: $payload) {\n      ...ConversationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateConversationMessage(\n    $payload: CreateConversationMessageInput!\n  ) {\n    createConversationMessage(params: $payload) {\n      ...ConversationMessageFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateConversationMessage(\n    $payload: CreateConversationMessageInput!\n  ) {\n    createConversationMessage(params: $payload) {\n      ...ConversationMessageFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ConversationById($id: ID!) {\n    viewer {\n      id\n      conversation(id: $id) {\n        ...ConversationFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query ConversationById($id: ID!) {\n    viewer {\n      id\n      conversation(id: $id) {\n        ...ConversationFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AnalyticsConfigFields on AnalyticsConfig {\n    id\n    chart\n    compareDynamicDate\n    compareStartDate\n    compareEndDate\n    compareUnit\n    dimensions\n    metrics\n    startDate\n    endDate\n    dynamicDate\n    filters\n    tableState\n    __typename\n  }\n"): (typeof documents)["\n  fragment AnalyticsConfigFields on AnalyticsConfig {\n    id\n    chart\n    compareDynamicDate\n    compareStartDate\n    compareEndDate\n    compareUnit\n    dimensions\n    metrics\n    startDate\n    endDate\n    dynamicDate\n    filters\n    tableState\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DashboardWidgetFields on DashboardWidget {\n    __typename\n    id\n    name\n    type\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n  }\n"): (typeof documents)["\n  fragment DashboardWidgetFields on DashboardWidget {\n    __typename\n    id\n    name\n    type\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DashboardOwnerFields on User {\n    id\n    externalId\n  }\n"): (typeof documents)["\n  fragment DashboardOwnerFields on User {\n    id\n    externalId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DashboardFields on Dashboard {\n    __typename\n    id\n    name\n    createdAt\n    updatedAt\n    iconName\n    iconColor\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    widgets {\n      ...DashboardWidgetFields\n    }\n    startDate\n    endDate\n    dynamicDate\n    compareStartDate\n    compareEndDate\n    compareDynamicDate\n    filters\n    layout\n  }\n"): (typeof documents)["\n  fragment DashboardFields on Dashboard {\n    __typename\n    id\n    name\n    createdAt\n    updatedAt\n    iconName\n    iconColor\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    widgets {\n      ...DashboardWidgetFields\n    }\n    startDate\n    endDate\n    dynamicDate\n    compareStartDate\n    compareEndDate\n    compareDynamicDate\n    filters\n    layout\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DashboardWithOwnerFields on Dashboard {\n    ...DashboardFields\n    owner {\n      ...DashboardOwnerFields\n    }\n  }\n"): (typeof documents)["\n  fragment DashboardWithOwnerFields on Dashboard {\n    ...DashboardFields\n    owner {\n      ...DashboardOwnerFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateDashboardMutation($payload: CreateDashboardInput!) {\n    createDashboard(payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateDashboardMutation($payload: CreateDashboardInput!) {\n    createDashboard(payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DashboardById($id: ID!) {\n    viewer {\n      id\n      dashboard(id: $id) {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query DashboardById($id: ID!) {\n    viewer {\n      id\n      dashboard(id: $id) {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteDashboardMutation($id: ID!) {\n    deleteDashboard(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteDashboardMutation($id: ID!) {\n    deleteDashboard(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditDashboardMutation(\n    $dashboardId: ID!\n    $payload: UpdateDashboardInput!\n  ) {\n    updateDashboard(id: $dashboardId, payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n"): (typeof documents)["\n  mutation EditDashboardMutation(\n    $dashboardId: ID!\n    $payload: UpdateDashboardInput!\n  ) {\n    updateDashboard(id: $dashboardId, payload: $payload) {\n      ...DashboardWithOwnerFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MerchantDashboards {\n    viewer {\n      id\n      merchant {\n        id\n        dashboards {\n          ...DashboardWithOwnerFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MerchantDashboards {\n    viewer {\n      id\n      merchant {\n        id\n        dashboards {\n          ...DashboardWithOwnerFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserDashboards {\n    viewer {\n      id\n      dashboards {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserDashboards {\n    viewer {\n      id\n      dashboards {\n        ...DashboardWithOwnerFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExperimentSummaryFields on ExperimentSummary {\n    id\n    name\n    purpose\n    frontendId\n    status\n    startDate\n    endDate\n    channel\n    country\n    __typename\n  }\n"): (typeof documents)["\n  fragment ExperimentSummaryFields on ExperimentSummary {\n    id\n    name\n    purpose\n    frontendId\n    status\n    startDate\n    endDate\n    channel\n    country\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExperimentFields on Experiment {\n    id\n    status\n    config {\n      ...ExperimentConfigFields\n    }\n    setup {\n      ...ExperimentSetupFields\n    }\n    suggestions {\n      ...ExperimentSetupFields\n    }\n    inference {\n      ...ExperimentInferenceFields\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment ExperimentFields on Experiment {\n    id\n    status\n    config {\n      ...ExperimentConfigFields\n    }\n    setup {\n      ...ExperimentSetupFields\n    }\n    suggestions {\n      ...ExperimentSetupFields\n    }\n    inference {\n      ...ExperimentInferenceFields\n    }\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExperimentConfigFields on ExperimentConfig {\n    id\n    purpose\n    name\n    frontendId\n    country\n    zoneType\n    zipCodeLength\n    channel\n    funnelCampaigns\n    excludeCampaigns\n    statusInference\n    statusSuggestions\n    expectedRoas\n    startDate\n    endDate\n    treatmentPeriod\n    postTreatmentPeriod\n    __typename\n  }\n"): (typeof documents)["\n  fragment ExperimentConfigFields on ExperimentConfig {\n    id\n    purpose\n    name\n    frontendId\n    country\n    zoneType\n    zipCodeLength\n    channel\n    funnelCampaigns\n    excludeCampaigns\n    statusInference\n    statusSuggestions\n    expectedRoas\n    startDate\n    endDate\n    treatmentPeriod\n    postTreatmentPeriod\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExperimentSetupFields on ExperimentSetup {\n    id\n    correlation\n    isValidExperiment\n    treatmentProportion\n    avgMinimumDetectableEffect\n    absLiftInZero\n    treatmentGroup {\n      ...LocationFields\n    }\n    controlGroup {\n      ...LocationFields\n    }\n    channelMetrics {\n      estimatedLift\n      estimatedTotalSpend\n      estimatedTotalSpendPastPeriod\n      estimatedTotalTargetIncremental\n      isValidChannelSpend\n      __typename\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment ExperimentSetupFields on ExperimentSetup {\n    id\n    correlation\n    isValidExperiment\n    treatmentProportion\n    avgMinimumDetectableEffect\n    absLiftInZero\n    treatmentGroup {\n      ...LocationFields\n    }\n    controlGroup {\n      ...LocationFields\n    }\n    channelMetrics {\n      estimatedLift\n      estimatedTotalSpend\n      estimatedTotalSpendPastPeriod\n      estimatedTotalTargetIncremental\n      isValidChannelSpend\n      __typename\n    }\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment LocationFields on Location {\n    commutingZone\n    weight\n    zipCode\n    __typename\n  }\n"): (typeof documents)["\n  fragment LocationFields on Location {\n    commutingZone\n    weight\n    zipCode\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExperimentInferenceMetricFields on ExperimentInferenceMetric {\n    id\n    returnOnSpend {\n      geolift\n      ad\n      mmm\n      mta\n      __typename\n    }\n    syntheticControlGroup {\n      ...LocationFields\n    }\n    controlObservations {\n      ...InferenceGraphPointFields\n    }\n    treatmentObservations {\n      ...InferenceGraphPointFields\n    }\n    summary {\n      ...InferenceSummaryFields\n    }\n    cumulativeLift {\n      ...InferenceGraphPointFields\n    }\n    returnLift {\n      ...InferenceGraphPointFields\n    }\n    pctLift {\n      ...InferenceGraphPointFields\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment ExperimentInferenceMetricFields on ExperimentInferenceMetric {\n    id\n    returnOnSpend {\n      geolift\n      ad\n      mmm\n      mta\n      __typename\n    }\n    syntheticControlGroup {\n      ...LocationFields\n    }\n    controlObservations {\n      ...InferenceGraphPointFields\n    }\n    treatmentObservations {\n      ...InferenceGraphPointFields\n    }\n    summary {\n      ...InferenceSummaryFields\n    }\n    cumulativeLift {\n      ...InferenceGraphPointFields\n    }\n    returnLift {\n      ...InferenceGraphPointFields\n    }\n    pctLift {\n      ...InferenceGraphPointFields\n    }\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExperimentInferenceFields on ExperimentInference {\n    id\n    metrics {\n      ...ExperimentInferenceMetricFields\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment ExperimentInferenceFields on ExperimentInference {\n    id\n    metrics {\n      ...ExperimentInferenceMetricFields\n    }\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InferenceGraphPointFields on InferenceGraphPoint {\n    date\n    lowerBound\n    upperBound\n    value\n  }\n"): (typeof documents)["\n  fragment InferenceGraphPointFields on InferenceGraphPoint {\n    date\n    lowerBound\n    upperBound\n    value\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InferenceSummaryFields on InferenceSummary {\n    incrementalFactor\n    lift\n    pValue\n    totalSpend\n    __typename\n  }\n"): (typeof documents)["\n  fragment InferenceSummaryFields on InferenceSummary {\n    incrementalFactor\n    lift\n    pValue\n    totalSpend\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateExperiment($payload: CreateExperimentInput!) {\n    createExperiment(params: $payload) {\n      ...ExperimentFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateExperiment($payload: CreateExperimentInput!) {\n    createExperiment(params: $payload) {\n      ...ExperimentFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteExperiment($id: ID!) {\n    deleteExperiment(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteExperiment($id: ID!) {\n    deleteExperiment(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Experiment($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        experiment(id: $id) {\n          ...ExperimentFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Experiment($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        experiment(id: $id) {\n          ...ExperimentFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ExperimentGeography($params: ExperimentGeographyInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        experimentGeography(params: $params)\n      }\n    }\n  }\n"): (typeof documents)["\n  query ExperimentGeography($params: ExperimentGeographyInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        experimentGeography(params: $params)\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Experiments {\n    viewer {\n      id\n      merchant {\n        id\n        experiments {\n          ...ExperimentSummaryFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Experiments {\n    viewer {\n      id\n      merchant {\n        id\n        experiments {\n          ...ExperimentSummaryFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateActiveSetup($params: UpdateActiveSetupInput!) {\n    updateActiveSetup(params: $params) {\n      ...ExperimentFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateActiveSetup($params: UpdateActiveSetupInput!) {\n    updateActiveSetup(params: $params) {\n      ...ExperimentFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateResults($id: ID!) {\n    runExperimentInference(id: $id)\n  }\n"): (typeof documents)["\n  mutation UpdateResults($id: ID!) {\n    runExperimentInference(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FutureAnalysisQuery($futureAnalysisInput: FutureAnalysisInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        futureAnalysis(params: $futureAnalysisInput) {\n          contribution\n          summary\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query FutureAnalysisQuery($futureAnalysisInput: FutureAnalysisInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        futureAnalysis(params: $futureAnalysisInput) {\n          contribution\n          summary\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HistoricalAnalysisQuery(\n    $historicalAnalysisInput: HistoricalAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalAnalysis(params: $historicalAnalysisInput) {\n          startDate\n          endDate\n          contribution\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n          summary\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query HistoricalAnalysisQuery(\n    $historicalAnalysisInput: HistoricalAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalAnalysis(params: $historicalAnalysisInput) {\n          startDate\n          endDate\n          contribution\n          curve {\n            channel {\n              channel\n              channelGroup\n              funnelCampaign\n            }\n            line\n            optimalPoint\n            actualPoint\n            saturationPoint\n          }\n          summary\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OptimizationConfigQuery {\n    viewer {\n      id\n      merchant {\n        id\n        optimizationConfig {\n          markets\n          frontendIds\n          optimizableChannels {\n            channelGroup\n            channel\n            funnelCampaign\n          }\n          targets {\n            id\n            name\n          }\n          summaries {\n            id\n            name\n            description\n          }\n          startWeekday\n          guardrail\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OptimizationConfigQuery {\n    viewer {\n      id\n      merchant {\n        id\n        optimizationConfig {\n          markets\n          frontendIds\n          optimizableChannels {\n            channelGroup\n            channel\n            funnelCampaign\n          }\n          targets {\n            id\n            name\n          }\n          summaries {\n            id\n            name\n            description\n          }\n          startWeekday\n          guardrail\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HistoricalHistoryAnalysisQuery(\n    $historicalHistoryAnalysisInput: HistoricalHistoryAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalHistoryAnalysis(params: $historicalHistoryAnalysisInput) {\n          historicalContributions\n          historicalReport\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query HistoricalHistoryAnalysisQuery(\n    $historicalHistoryAnalysisInput: HistoricalHistoryAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalHistoryAnalysis(params: $historicalHistoryAnalysisInput) {\n          historicalContributions\n          historicalReport\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HistoricalOverviewAnalysisQuery(\n    $historicalOverviewAnalysisInput: HistoricalOverviewAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalOverviewAnalysis(params: $historicalOverviewAnalysisInput) {\n          missedPotentialMarkets\n          metricCards\n          missedPotentialAggregated {\n            actualProfit\n            missedProfit\n            optimalProfit\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query HistoricalOverviewAnalysisQuery(\n    $historicalOverviewAnalysisInput: HistoricalOverviewAnalysisInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        historicalOverviewAnalysis(params: $historicalOverviewAnalysisInput) {\n          missedPotentialMarkets\n          metricCards\n          missedPotentialAggregated {\n            actualProfit\n            missedProfit\n            optimalProfit\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation FavoriteReport($params: UpdateFavoriteReportInput!) {\n    updateFavoriteReport(params: $params) {\n      id\n      favorite\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation FavoriteReport($params: UpdateFavoriteReportInput!) {\n    updateFavoriteReport(params: $params) {\n      id\n      favorite\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SegmentationSetFields on SegmentationSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    labelColumnName\n    updatedAt\n    __typename\n  }\n"): (typeof documents)["\n  fragment SegmentationSetFields on SegmentationSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    labelColumnName\n    updatedAt\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SegmentationCustomerSetFields on SegmentationCustomerSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    updatedAt\n    __typename\n  }\n"): (typeof documents)["\n  fragment SegmentationCustomerSetFields on SegmentationCustomerSet {\n    id\n    name\n    iconName\n    iconColor\n    dynamicDate\n    updatedAt\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductLabelFields on ProductLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n"): (typeof documents)["\n  fragment ProductLabelFields on ProductLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SegmentationCustomerLabelFields on SegmentationCustomerLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n"): (typeof documents)["\n  fragment SegmentationCustomerLabelFields on SegmentationCustomerLabel {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SegmentationRuleFields on SegmentationRule {\n    id\n    description\n    filters\n    productLabel {\n      ...ProductLabelFields\n    }\n    expiresAt\n    __typename\n  }\n"): (typeof documents)["\n  fragment SegmentationRuleFields on SegmentationRule {\n    id\n    description\n    filters\n    productLabel {\n      ...ProductLabelFields\n    }\n    expiresAt\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SegmentationCustomerRuleFields on SegmentationCustomerRule {\n    id\n    description\n    filters\n    label {\n      ...SegmentationCustomerLabelFields\n    }\n    expiresAt\n    __typename\n  }\n"): (typeof documents)["\n  fragment SegmentationCustomerRuleFields on SegmentationCustomerRule {\n    id\n    description\n    filters\n    label {\n      ...SegmentationCustomerLabelFields\n    }\n    expiresAt\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          rules {\n            ...SegmentationCustomerRuleFields\n          }\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomerSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          rules {\n            ...SegmentationCustomerRuleFields\n          }\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerSetDownloadUrl($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          downloadUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomerSetDownloadUrl($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSet(id: $id) {\n          downloadUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SegmentationSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSet(id: $id) {\n          rules {\n            ...SegmentationRuleFields\n          }\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SegmentationSetById($id: ID!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSet(id: $id) {\n          rules {\n            ...SegmentationRuleFields\n          }\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCustomerLabel($params: CreateSegmentationCustomerLabelInput!) {\n    createSegmentationCustomerLabel(params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCustomerLabel($params: CreateSegmentationCustomerLabelInput!) {\n    createSegmentationCustomerLabel(params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SegmentationCustomerLabels {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerLabels {\n          ...SegmentationCustomerLabelFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SegmentationCustomerLabels {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerLabels {\n          ...SegmentationCustomerLabelFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCustomerLabel($id: ID!) {\n    deleteSegmentationCustomerLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomerLabel($id: ID!) {\n    deleteSegmentationCustomerLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditSegmentationCustomerLabel(\n    $id: ID!\n    $params: EditSegmentationCustomerLabelInput!\n  ) {\n    editSegmentationCustomerLabel(id: $id, params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n"): (typeof documents)["\n  mutation EditSegmentationCustomerLabel(\n    $id: ID!\n    $params: EditSegmentationCustomerLabelInput!\n  ) {\n    editSegmentationCustomerLabel(id: $id, params: $params) {\n      ...SegmentationCustomerLabelFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerChartMetrics(\n    $params: GetSegmentationCustomerChartMetricsInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerChartMetrics(params: $params)\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomerChartMetrics(\n    $params: GetSegmentationCustomerChartMetricsInput!\n  ) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerChartMetrics(params: $params)\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetSegmentationCustomerRules(\n    $params: SetSegmentationCustomerRulesInput!\n  ) {\n    setSegmentationCustomerRules(params: $params) {\n      ...SegmentationCustomerSetFields\n      rules {\n        ...SegmentationCustomerRuleFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetSegmentationCustomerRules(\n    $params: SetSegmentationCustomerRulesInput!\n  ) {\n    setSegmentationCustomerRules(params: $params) {\n      ...SegmentationCustomerSetFields\n      rules {\n        ...SegmentationCustomerRuleFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSegmentationCustomerSet(\n    $params: CreateSegmentationCustomerSetInput!\n  ) {\n    createSegmentationCustomerSet(params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSegmentationCustomerSet(\n    $params: CreateSegmentationCustomerSetInput!\n  ) {\n    createSegmentationCustomerSet(params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSegmentationCustomerSet($id: ID!) {\n    deleteSegmentationCustomerSet(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSegmentationCustomerSet($id: ID!) {\n    deleteSegmentationCustomerSet(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditSegmentationCustomerSet(\n    $id: ID!\n    $params: EditSegmentationCustomerSetInput!\n  ) {\n    editSegmentationCustomerSet(id: $id, params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n"): (typeof documents)["\n  mutation EditSegmentationCustomerSet(\n    $id: ID!\n    $params: EditSegmentationCustomerSetInput!\n  ) {\n    editSegmentationCustomerSet(id: $id, params: $params) {\n      ...SegmentationCustomerSetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SegmentationCustomerSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSets {\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SegmentationCustomerSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationCustomerSets {\n          ...SegmentationCustomerSetFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateProductLabel($params: CreateProductLabelInput!) {\n    createProductLabel(params: $params) {\n      ...ProductLabelFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProductLabel($params: CreateProductLabelInput!) {\n    createProductLabel(params: $params) {\n      ...ProductLabelFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteProductLabel($id: ID!) {\n    deleteProductLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteProductLabel($id: ID!) {\n    deleteProductLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditProductLabel($id: ID!, $params: EditProductLabelInput!) {\n    editProductLabel(id: $id, params: $params) {\n      ...ProductLabelFields\n    }\n  }\n"): (typeof documents)["\n  mutation EditProductLabel($id: ID!, $params: EditProductLabelInput!) {\n    editProductLabel(id: $id, params: $params) {\n      ...ProductLabelFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductLabels {\n    viewer {\n      id\n      merchant {\n        id\n        productLabels {\n          ...ProductLabelFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProductLabels {\n    viewer {\n      id\n      merchant {\n        id\n        productLabels {\n          ...ProductLabelFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductChartMetrics($params: GetSegmentationProductChartMetricsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationProductChartMetrics(params: $params)\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProductChartMetrics($params: GetSegmentationProductChartMetricsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationProductChartMetrics(params: $params)\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductLabelSegmentation($params: GetProductLabelSegmentationInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        productLabelSegmentation(params: $params)\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProductLabelSegmentation($params: GetProductLabelSegmentationInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        productLabelSegmentation(params: $params)\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetSegmentationRules($params: SetSegmentationRulesInput!) {\n    setSegmentationRules(params: $params) {\n      ...SegmentationSetFields\n      rules {\n        ...SegmentationRuleFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetSegmentationRules($params: SetSegmentationRulesInput!) {\n    setSegmentationRules(params: $params) {\n      ...SegmentationSetFields\n      rules {\n        ...SegmentationRuleFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSegmentationSet($params: CreateSegmentationSetInput!) {\n    createSegmentationSet(params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSegmentationSet($params: CreateSegmentationSetInput!) {\n    createSegmentationSet(params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSegmentationSet($id: ID!) {\n    deleteSegmentationSet(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSegmentationSet($id: ID!) {\n    deleteSegmentationSet(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditSegmentationSet($id: ID!, $params: EditSegmentationSetInput!) {\n    editSegmentationSet(id: $id, params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n"): (typeof documents)["\n  mutation EditSegmentationSet($id: ID!, $params: EditSegmentationSetInput!) {\n    editSegmentationSet(id: $id, params: $params) {\n      ...SegmentationSetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SegmentationSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSets {\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SegmentationSets {\n    viewer {\n      id\n      merchant {\n        id\n        segmentationSets {\n          ...SegmentationSetFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment IntegrationFields on Integration {\n    id\n    serviceId\n    connected\n    config\n    costConfig\n    __typename\n  }\n"): (typeof documents)["\n  fragment IntegrationFields on Integration {\n    id\n    serviceId\n    connected\n    config\n    costConfig\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ToggleIntegration($params: ToggleIntegrationInput!) {\n    toggleIntegration(params: $params) {\n      id\n      __typename\n      connected\n    }\n  }\n"): (typeof documents)["\n  mutation ToggleIntegration($params: ToggleIntegrationInput!) {\n    toggleIntegration(params: $params) {\n      id\n      __typename\n      connected\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Integrations_MerchantSite on MerchantSite {\n    integrations {\n      ...IntegrationFields\n    }\n  }\n"): (typeof documents)["\n  fragment Integrations_MerchantSite on MerchantSite {\n    integrations {\n      ...IntegrationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query IntegrationsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...Integrations_MerchantSite\n      }\n    }\n  }\n"): (typeof documents)["\n  query IntegrationsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...Integrations_MerchantSite\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SaveIntegrationAccounts($params: SaveIntegrationAccountsInput!) {\n    saveIntegrationAccounts(params: $params) {\n      ...IntegrationFields\n    }\n  }\n"): (typeof documents)["\n  mutation SaveIntegrationAccounts($params: SaveIntegrationAccountsInput!) {\n    saveIntegrationAccounts(params: $params) {\n      ...IntegrationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ChannelMappings_MerchantSite on MerchantSite {\n    channelMappings {\n      channel\n      channelGroup\n      mappings\n    }\n  }\n"): (typeof documents)["\n  fragment ChannelMappings_MerchantSite on MerchantSite {\n    channelMappings {\n      channel\n      channelGroup\n      mappings\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MappingsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MappableSessions_MerchantSite\n        ...ChannelMappings_MerchantSite\n      }\n    }\n  }\n"): (typeof documents)["\n  query MappingsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MappableSessions_MerchantSite\n        ...ChannelMappings_MerchantSite\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TotalUnmappedSessions {\n    viewer {\n      id\n      merchant {\n        id\n        totalUnmappedSessions\n      }\n    }\n  }\n"): (typeof documents)["\n  query TotalUnmappedSessions {\n    viewer {\n      id\n      merchant {\n        id\n        totalUnmappedSessions\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MappableSessions_MerchantSite on MerchantSite {\n    mappableSessions {\n      utmCampaign\n      utmMedium\n      utmSource\n      visits\n    }\n  }\n"): (typeof documents)["\n  fragment MappableSessions_MerchantSite on MerchantSite {\n    mappableSessions {\n      utmCampaign\n      utmMedium\n      utmSource\n      visits\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateChannelMappings($params: UpdateChannelMappingsInput!) {\n    updateChannelMappings(params: $params) {\n      mappings\n      channel\n      channelGroup\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateChannelMappings($params: UpdateChannelMappingsInput!) {\n    updateChannelMappings(params: $params) {\n      mappings\n      channel\n      channelGroup\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment MarketingCostManualInputs_MerchantSite on MerchantSite {\n      marketingCostManualInputs {\n        ...IntegrationFields\n      }\n    }\n  "): (typeof documents)["\n    fragment MarketingCostManualInputs_MerchantSite on MerchantSite {\n      marketingCostManualInputs {\n        ...IntegrationFields\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MarketingCostsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MarketingCostManualInputs_MerchantSite\n      }\n    }\n  }\n"): (typeof documents)["\n  query MarketingCostsViewQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        ...MarketingCostManualInputs_MerchantSite\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMarketingCostManualInput(\n    $params: CreateMarketingCostManualInputParams!\n  ) {\n    createMarketingCostManualInput(params: $params) {\n      ...IntegrationFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMarketingCostManualInput(\n    $params: CreateMarketingCostManualInputParams!\n  ) {\n    createMarketingCostManualInput(params: $params) {\n      ...IntegrationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateReportLabel($params: CreateReportLabelInput!) {\n    createReportLabel(params: $params) {\n      id\n      name\n      color\n      __typename\n      reportCount\n    }\n  }\n"): (typeof documents)["\n  mutation CreateReportLabel($params: CreateReportLabelInput!) {\n    createReportLabel(params: $params) {\n      id\n      name\n      color\n      __typename\n      reportCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteReportLabel($id: ID!) {\n    deleteReportLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteReportLabel($id: ID!) {\n    deleteReportLabel(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditReportLabel($id: ID!, $params: EditReportLabelInput!) {\n    editReportLabel(id: $id, params: $params) {\n      id\n      name\n      color\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation EditReportLabel($id: ID!, $params: EditReportLabelInput!) {\n    editReportLabel(id: $id, params: $params) {\n      id\n      name\n      color\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOrganizationInvitation(\n    $params: CreateOrganizationInvitationInput!\n  ) {\n    createOrganizationInvitation(params: $params) {\n      __typename\n      ... on OrganizationInvitation {\n        id\n        status\n      }\n      ... on CreateOrganizationInvitationError {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrganizationInvitation(\n    $params: CreateOrganizationInvitationInput!\n  ) {\n    createOrganizationInvitation(params: $params) {\n      __typename\n      ... on OrganizationInvitation {\n        id\n        status\n      }\n      ... on CreateOrganizationInvitationError {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RevokeOrganizationInvitation(\n    $params: RevokeOrganizationInvitationInput!\n  ) {\n    revokeOrganizationInvitation(params: $params)\n  }\n"): (typeof documents)["\n  mutation RevokeOrganizationInvitation(\n    $params: RevokeOrganizationInvitationInput!\n  ) {\n    revokeOrganizationInvitation(params: $params)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReturnThreshold {\n    viewer {\n      id\n      merchant {\n        __typename\n        id\n        returnThreshold\n        returnThresholdCurve\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReturnThreshold {\n    viewer {\n      id\n      merchant {\n        __typename\n        id\n        returnThreshold\n        returnThresholdCurve\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateReturnThreshold($params: UpdateReturnThresholdInput!) {\n    updateReturnThreshold(params: $params) {\n      returnThreshold\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateReturnThreshold($params: UpdateReturnThresholdInput!) {\n    updateReturnThreshold(params: $params) {\n      returnThreshold\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTeamMutation($payload: CreateTeamInput!) {\n    createTeam(params: $payload) {\n      ...Teams_TeamFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTeamMutation($payload: CreateTeamInput!) {\n    createTeam(params: $payload) {\n      ...Teams_TeamFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMerchantTeamById($teamId: ID!) {\n    deleteTeam(id: $teamId) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMerchantTeamById($teamId: ID!) {\n    deleteTeam(id: $teamId) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditMerchantTeamById($teamId: ID!, $payload: EditTeamInput!) {\n    editTeam(id: $teamId, params: $payload) {\n      id\n      iconName\n      iconColor\n      name\n      preferredMetrics\n      preferredDimensions\n    }\n  }\n"): (typeof documents)["\n  mutation EditMerchantTeamById($teamId: ID!, $payload: EditTeamInput!) {\n    editTeam(id: $teamId, params: $payload) {\n      id\n      iconName\n      iconColor\n      name\n      preferredMetrics\n      preferredDimensions\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TaxonomyInputFields on TaxonomyInput {\n    id\n    type\n    rootMatcher\n  }\n"): (typeof documents)["\n  fragment TaxonomyInputFields on TaxonomyInput {\n    id\n    type\n    rootMatcher\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTaxonomyInputMutation($params: CreateTaxonomyInputInput!) {\n    createTaxonomyInput(params: $params) {\n      ...TaxonomyInputFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTaxonomyInputMutation($params: CreateTaxonomyInputInput!) {\n    createTaxonomyInput(params: $params) {\n      ...TaxonomyInputFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TaxonomyInputsQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        taxonomyInputs {\n          ...TaxonomyInputFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query TaxonomyInputsQuery($merchantSiteParams: MerchantSiteInput!) {\n    viewer {\n      id\n      merchantSite(params: $merchantSiteParams) {\n        frontendId\n        taxonomyInputs {\n          ...TaxonomyInputFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation TrackEventMutation($params: TrackUserEventInput!) {\n    trackUserEvent(params: $params)\n  }\n"): (typeof documents)["\n  mutation TrackEventMutation($params: TrackUserEventInput!) {\n    trackUserEvent(params: $params)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n"): (typeof documents)["\n  fragment TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MerchantFields on Merchant {\n    id\n    name\n    __typename\n  }\n"): (typeof documents)["\n  fragment MerchantFields on Merchant {\n    id\n    name\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnerFields on User {\n    id\n    externalId\n  }\n"): (typeof documents)["\n  fragment OwnerFields on User {\n    id\n    externalId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReportFields on Report {\n    id\n    createdAt\n    description\n    name\n    favorite\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    labels {\n      id\n      name\n      color\n    }\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment ReportFields on Report {\n    id\n    createdAt\n    description\n    name\n    favorite\n    analyticsConfig {\n      ...AnalyticsConfigFields\n    }\n    visibility {\n      __typename\n      ...TeamFields\n      ...MerchantFields\n    }\n    labels {\n      id\n      name\n      color\n    }\n    updatedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReportWithOwnerFields on Report {\n    ...ReportFields\n    owner {\n      ...OwnerFields\n    }\n  }\n"): (typeof documents)["\n  fragment ReportWithOwnerFields on Report {\n    ...ReportFields\n    owner {\n      ...OwnerFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AnalyticsConfigById($id: ID!) {\n    viewer {\n      id\n      analyticsConfig(id: $id) {\n        ...AnalyticsConfigFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query AnalyticsConfigById($id: ID!) {\n    viewer {\n      id\n      analyticsConfig(id: $id) {\n        ...AnalyticsConfigFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAnalyticsConfig($params: AnalyticsConfigInput!) {\n    createAnalyticsConfig(params: $params) {\n      ...AnalyticsConfigFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAnalyticsConfig($params: AnalyticsConfigInput!) {\n    createAnalyticsConfig(params: $params) {\n      ...AnalyticsConfigFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateReportMutation($payload: CreateReportInput!) {\n    createReport(payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n"): (typeof documents)["\n  mutation CreateReportMutation($payload: CreateReportInput!) {\n    createReport(payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteReportMutation($id: ID!) {\n    deleteReport(id: $id) {\n      id\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteReportMutation($id: ID!) {\n    deleteReport(id: $id) {\n      id\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditReportMutation($reportId: ID!, $payload: EditReportInput!) {\n    editReport(id: $reportId, payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n"): (typeof documents)["\n  mutation EditReportMutation($reportId: ID!, $payload: EditReportInput!) {\n    editReport(id: $reportId, payload: $payload) {\n      ...ReportWithOwnerFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FavoriteReportsQuery {\n    viewer {\n      id\n      favoriteReports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query FavoriteReportsQuery {\n    viewer {\n      id\n      favoriteReports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReportsQuery {\n    viewer {\n      id\n      merchant {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReportsQuery {\n    viewer {\n      id\n      merchant {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReportById($id: ID!) {\n    viewer {\n      id\n      report(id: $id) {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReportById($id: ID!) {\n    viewer {\n      id\n      report(id: $id) {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReportLabels($withReportCount: Boolean! = false) {\n    viewer {\n      id\n      merchant {\n        id\n        reportLabels {\n          id\n          name\n          color\n          reportCount @include(if: $withReportCount)\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReportLabels($withReportCount: Boolean! = false) {\n    viewer {\n      id\n      merchant {\n        id\n        reportLabels {\n          id\n          name\n          color\n          reportCount @include(if: $withReportCount)\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TeamReportsById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query TeamReportsById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        id\n        reports {\n          ...ReportWithOwnerFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserReports {\n    viewer {\n      id\n      reports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserReports {\n    viewer {\n      id\n      reports {\n        ...ReportWithOwnerFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOrganizationMembership(\n    $payload: DeleteOrganizationMembershipInput!\n  ) {\n    deleteOrganizationMembership(params: $payload)\n  }\n"): (typeof documents)["\n  mutation DeleteOrganizationMembership(\n    $payload: DeleteOrganizationMembershipInput!\n  ) {\n    deleteOrganizationMembership(params: $payload)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTeamMembership($payload: UpdateTeamMembershipInput!) {\n    updateTeamMembership(params: $payload) {\n      ...Teams_TeamMemberFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTeamMembership($payload: UpdateTeamMembershipInput!) {\n    updateTeamMembership(params: $payload) {\n      ...Teams_TeamMemberFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DimensionsValueQuery($dimensionValuesParams: DimensionValuesInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        dimensionValues(params: $dimensionValuesParams)\n      }\n    }\n  }\n"): (typeof documents)["\n  query DimensionsValueQuery($dimensionValuesParams: DimensionValuesInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        dimensionValues(params: $dimensionValuesParams)\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StatisticsQuery($statisticsParams: StatisticsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        statistics(params: $statisticsParams) {\n          dimensions\n          metrics\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query StatisticsQuery($statisticsParams: StatisticsInput!) {\n    viewer {\n      id\n      merchant {\n        id\n        statistics(params: $statisticsParams) {\n          dimensions\n          metrics\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Teams_TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    members {\n      ...Teams_TeamMemberFields\n    }\n    preferredDimensions\n    preferredMetrics\n    __typename\n  }\n"): (typeof documents)["\n  fragment Teams_TeamFields on Team {\n    id\n    name\n    iconName\n    iconColor\n    members {\n      ...Teams_TeamMemberFields\n    }\n    preferredDimensions\n    preferredMetrics\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Teams_TeamMemberFields on User {\n    id\n    externalId\n    __typename\n  }\n"): (typeof documents)["\n  fragment Teams_TeamMemberFields on User {\n    id\n    externalId\n    __typename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MerchantTeams {\n    viewer {\n      id\n      merchant {\n        id\n        teams {\n          ...Teams_TeamFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MerchantTeams {\n    viewer {\n      id\n      merchant {\n        id\n        teams {\n          ...Teams_TeamFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MerchantTeamById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        ...Teams_TeamFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query MerchantTeamById($id: ID!) {\n    viewer {\n      id\n      team(id: $id) {\n        ...Teams_TeamFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserTeams {\n    viewer {\n      id\n      teams {\n        ...Teams_TeamFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserTeams {\n    viewer {\n      id\n      teams {\n        ...Teams_TeamFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ChannelGroups {\n    channelGroups {\n      id\n      name\n      channels {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ChannelGroups {\n    channelGroups {\n      id\n      name\n      channels {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Channels {\n    channels {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query Channels {\n    channels {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Countries {\n    countries {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query Countries {\n    countries {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FeatureFlags {\n    viewer {\n      id\n      featureFlags\n    }\n  }\n"): (typeof documents)["\n  query FeatureFlags {\n    viewer {\n      id\n      featureFlags\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InitializeApp {\n    viewer {\n      id\n      featureFlags\n      merchant {\n        id\n        timezone\n        currency\n        sites {\n          domain\n          name\n          frontendId\n        }\n        metrics {\n          id\n          label\n          description\n          descriptionMarkdown\n          groupId\n          groupLabel\n          format\n          dependencies\n          attributionVariants\n          isHidden\n          isAverage\n          isReversePositive\n        }\n        dimensions {\n          id\n          label\n          description\n          descriptionMarkdown\n          processors\n          groupId\n          groupLabel\n          isHidden\n        }\n        attributionModels {\n          id\n          label\n          description\n          color\n          iconName\n          iconColor\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query InitializeApp {\n    viewer {\n      id\n      featureFlags\n      merchant {\n        id\n        timezone\n        currency\n        sites {\n          domain\n          name\n          frontendId\n        }\n        metrics {\n          id\n          label\n          description\n          descriptionMarkdown\n          groupId\n          groupLabel\n          format\n          dependencies\n          attributionVariants\n          isHidden\n          isAverage\n          isReversePositive\n        }\n        dimensions {\n          id\n          label\n          description\n          descriptionMarkdown\n          processors\n          groupId\n          groupLabel\n          isHidden\n        }\n        attributionModels {\n          id\n          label\n          description\n          color\n          iconName\n          iconColor\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Markets {\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n          countries\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Markets {\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n          countries\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StaticDimensionValues {\n    channelGroups {\n      id\n      name\n    }\n    channels {\n      id\n      name\n    }\n    countries {\n      id\n      name\n    }\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query StaticDimensionValues {\n    channelGroups {\n      id\n      name\n    }\n    channels {\n      id\n      name\n    }\n    countries {\n      id\n      name\n    }\n    viewer {\n      id\n      merchant {\n        id\n        markets {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query apiVersion {\n    apiVersion\n  }\n"): (typeof documents)["\n  query apiVersion {\n    apiVersion\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;