import { MenuItem } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import {
  type DashboardWidgetState,
  widgetAtomFamily,
} from 'features/dashboards/atoms/dashboardViewState'
import { widgetConfig } from 'features/dashboards/consts'
import { useDashboardLayoutManager } from 'features/dashboards/hooks/useDashboardLayoutManager'
import { dashboardLayoutRowStateAtom } from 'features/dashboards/hooks/useDashboardLayoutState'
import {
  dashboardIdAtom,
  dashboardLayoutAtom,
} from 'features/dashboards/hooks/useDashboardState'
import { useWidgetOperations } from 'features/dashboards/hooks/useWidgetsState'
import { type DashboardLayout } from 'features/dashboards/types'
import { generateWidgetId } from 'features/dashboards/utils'
import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import { useAtomValue, useSetAtom } from 'jotai'
import { v7 as uuidv7 } from 'uuid'

interface DuplicateWidgetMenuItemProps {
  widgetId: string | undefined
  rowId: string | undefined
}

export const DuplicateWidgetMenuItem = ({
  widgetId,
  rowId,
}: DuplicateWidgetMenuItemProps) => {
  const dashboardId = useAtomValue(dashboardIdAtom)
  const layoutManager = useDashboardLayoutManager()
  const { addWidget } = useWidgetOperations()

  const setDashboardLayout = useSetAtom(dashboardLayoutAtom)
  const getWidgetState = useGetAtomFamilyValue(widgetAtomFamily)
  const getRowState = useGetAtomFamilyValue(dashboardLayoutRowStateAtom)

  const onDuplicateWidget = () => {
    if (!dashboardId || !widgetId || !rowId) return

    const widgetState = getWidgetState(widgetId)
    const rowState = getRowState(rowId)

    if (!widgetState || !rowState) return

    const newWidget: DashboardWidgetState = {
      ...widgetState,
      id: generateWidgetId(),
      ...(widgetState.analyticsConfig && {
        analyticsConfig: {
          ...widgetState.analyticsConfig,
          id: uuidv7(),
        },
      }),
    }

    addWidget(newWidget)

    const currentWidgetIndex = rowState.cells.findIndex(
      (cell) => cell.widgetId === widgetId,
    )
    const newWidgetMinWidth = widgetConfig[widgetState.type].minWidth

    let updatedLayout: DashboardLayout | undefined

    // If the new widget is too wide for the row, move it to a new row
    if (rowState.availableWidth < newWidgetMinWidth) {
      updatedLayout = layoutManager.moveWidgetToNewRow({
        widget: newWidget,
        rowIndex: rowState.rowIndex + 1,
      }).layout
    } else {
      // Otherwise, add the new widget next to the current widget in the same row
      updatedLayout = layoutManager.moveWidgetToExistingRow({
        widget: newWidget,
        targetRowId: rowState.rowId,
        targetCellIndex: currentWidgetIndex + 1,
      })
    }

    setDashboardLayout(updatedLayout)
  }

  return (
    <>
      <MenuItem
        icon={<Icon name="CopyIcon" size="small" />}
        onClick={onDuplicateWidget}
      >
        Duplicate
      </MenuItem>
    </>
  )
}
