import { arrayMove } from '@dnd-kit/sortable'
import { memoize, omit } from 'lodash-es'
import { v7 as uuidv7 } from 'uuid'
import {
  type DashboardState,
  type DashboardWidgetState,
} from './atoms/dashboardViewState'
import {
  DASHBOARD_LAYOUT_GRID_COLUMNS,
  NEW_ROW_ID,
  NEW_ROW_ID_DELIMITER,
  widgetConfig,
} from './consts'
import {
  type DashboardLayoutCellState,
  type DashboardLayoutRowState,
} from './hooks/useDashboardLayoutState'
import {
  type DashboardLayoutCell,
  type DashboardLayout,
  type WidgetType,
  type DashboardLayoutRow,
} from './types'

interface MoveWidgetToRowParams {
  widget: DashboardWidgetState | undefined
  targetRowId: string
  sourceRowId?: string
  targetCellIndex: number
}

interface MoveWidgetInRowParams {
  rowId: string
  fromIndex: number
  toIndex: number
}

interface LayoutStateProvider {
  getRowState(rowId?: string): DashboardLayoutRowState | undefined
}

/**
 * DashboardLayoutManager is responsible for manipulating the layout of a dashboard.
 * It provides methods for manipulating rows and widgets within the layout which return
 * an updated layout object. The layout state is never mutated.
 */
export class DashboardLayoutManager {
  private layout: DashboardLayout
  private stateProvider: LayoutStateProvider

  constructor(_layout: DashboardLayout, _stateProvider: LayoutStateProvider) {
    this.layout = _layout
    this.stateProvider = _stateProvider
  }

  /**
   * Returns a row by its id.
   */
  public getRow = (id: string): DashboardLayoutRow | undefined => {
    return this.layout.rows[id]
  }

  /**
   * Returns the state of a row by its id.
   * Memoized to avoid recalculating the same row state multiple times.
   */
  private getRowState = memoize(
    (rowId: string): DashboardLayoutRowState | undefined => {
      const rowState = this.stateProvider.getRowState(rowId)

      if (!rowState) {
        return undefined
      }

      return rowState
    },
  )

  /**
   * Returns the default size (minWidth, minHeight) of a widget.
   */
  private getWidgetDefaultSize = (widgetType?: WidgetType) => {
    if (!widgetType) return { minWidth: 0, minHeight: 0 }

    const { minWidth, minHeight } = widgetConfig[widgetType]

    return {
      minWidth,
      minHeight,
    }
  }

  /**
   * Recalculates the width of the cells in a row when a new widget is added.
   * The width is distributed proportionally to the minimum width of the cells.
   */
  private recalculateRowCellsWidth = (
    cellsState: DashboardLayoutCellState[],
  ): DashboardLayoutCell[] => {
    if (cellsState.length === 0) {
      return []
    }

    const widths = cellsState.map((cell) => cell.minWidth)

    return distributeValuesWeighted(widths, DASHBOARD_LAYOUT_GRID_COLUMNS).map(
      (width, index) => ({
        widgetId: cellsState[index].widgetId,
        width,
      }),
    )
  }

  /**
   * Checks if a widget can be moved to a row.
   * The widget can be moved if the row has enough available width.
   */
  private canMoveWidgetToRow = (
    widget: DashboardWidgetState,
    rowId: string,
  ) => {
    const rowState = this.getRowState(rowId)

    const { minWidth } = this.getWidgetDefaultSize(widget.type)

    return {
      result: (rowState?.availableWidth ?? 0) >= minWidth,
      availableWidth: rowState?.availableWidth ?? 0,
      requiredSpace: minWidth,
    }
  }

  /**
   * Recalculates the height of the row based on the new widget.
   * The height is the maximum value of the current height and the minimum height of the new widget.
   */
  private recalculateRowHeight = (
    rowId: string,
    newWidget: DashboardWidgetState,
  ) => {
    const rowState = this.getRowState(rowId)

    if (!rowState) {
      return 0
    }

    const { minHeight } = this.getWidgetDefaultSize(newWidget.type)

    return Math.max(rowState.minHeight ?? 0, minHeight)
  }

  /**
   * Returns a new list of cells with the new widget added at the specified index.
   * The widths of the cells are recalculated.
   */
  private handleAddWidgetToRowCells = (
    newWidget: DashboardWidgetState,
    currentRowCells: DashboardLayoutCellState[],
    index?: number,
  ) => {
    if (!newWidget.id) {
      throw new Error('Widget id is required')
    }

    const { minWidth, minHeight } = this.getWidgetDefaultSize(newWidget.type)

    const newCellState: DashboardLayoutCellState = {
      widgetId: newWidget.id,
      width: minWidth, // Since the width needs to be recalculated, use the minimum width
      minWidth,
      minHeight,
    }

    const updatedCells = [
      ...currentRowCells.slice(0, index),
      newCellState,
      ...currentRowCells.slice(index),
    ]

    return this.recalculateRowCellsWidth(updatedCells)
  }

  /**
   * Returns an updated rows object and order array with the specified widget removed from the source row.
   * If the source row is empty, it is removed from the layout order and rows.
   */
  private handleRemoveWidgetFromRow = ({
    widget,
    rowId,
    rows,
    order,
  }: {
    widget: DashboardWidgetState
    rowId: string | undefined
    rows: DashboardLayout['rows']
    order?: DashboardLayout['order']
  }) => {
    order ??= this.layout.order

    if (!rowId) {
      return { rows, order }
    }

    const rowState = this.getRowState(rowId)
    const updatedCells = rowState?.cells.filter(
      (cell) => cell.widgetId !== widget.id,
    )

    const updatedRows = { ...rows }
    let updatedOrder = [...order]

    if (updatedCells && updatedCells.length > 0) {
      updatedRows[rowId] = {
        cells: this.recalculateRowCellsWidth(updatedCells),
        height: this.recalculateRowHeight(rowId, widget),
      }
    } else {
      delete updatedRows[rowId]
      updatedOrder = updatedOrder.filter((id) => id !== rowId)
    }

    return { rows: updatedRows, order: updatedOrder }
  }

  /**
   * Returns a new layout with a widget added to a new row.
   * If a row index is provided, the row is added at the specified index.
   * If no row index is provided, the row is added at the end of the dashboard.
   * If no row height is provided, the height of the row is the minimum height of the widget.
   */
  public moveWidgetToNewRow = ({
    widget,
    rowIndex,
    rowHeight,
    sourceRowId,
  }: {
    widget: DashboardWidgetState | undefined
    rowIndex?: number
    rowHeight?: number
    sourceRowId?: string
  }): {
    layout: DashboardLayout
    rowId: string
  } => {
    if (!widget?.id) {
      throw new Error('Widget is required')
    }

    const { minHeight } = this.getWidgetDefaultSize(widget.type)

    const rowId = generateRowId()

    const updatedOrder = [...this.layout.order]

    if (rowIndex !== undefined) {
      updatedOrder.splice(rowIndex, 0, rowId)
    } else {
      updatedOrder.push(rowId)
    }

    const updatedRows = {
      ...this.layout.rows,
      [rowId]: {
        height: rowHeight ?? minHeight,
        cells: this.handleAddWidgetToRowCells(widget, []),
      },
    }

    const { rows: finalRows, order: finalOrder } =
      this.handleRemoveWidgetFromRow({
        widget,
        rowId: sourceRowId,
        rows: updatedRows,
        order: updatedOrder,
      })

    return {
      rowId,
      layout: {
        ...this.layout,
        order: finalOrder,
        rows: finalRows,
      },
    }
  }

  /**
   * Returns a new layout with the specified widget moved to a new position within an existing row.
   * The row's height and cell widths remain unchanged.
   * If the widget is being moved from another row, the height and cell widths of the source row are recalculated.
   * If the source row is empty, it is removed from the layout order and rows.
   * If the widget cannot be moved to the target row due to insufficient available space, the layout is returned unchanged.
   */
  public moveWidgetToExistingRow = ({
    widget,
    targetRowId,
    targetCellIndex = 0,
    sourceRowId,
  }: MoveWidgetToRowParams): DashboardLayout => {
    if (!widget?.id) return this.layout

    // If the widget cannot be moved to the target row, return the layout unchanged
    const canMoveToRow = this.canMoveWidgetToRow(widget, targetRowId)

    if (!canMoveToRow.result) {
      const { availableWidth, requiredSpace } = canMoveToRow

      console.warn(
        'Widget cannot be moved to row due to insufficient available space',
        {
          availableWidth,
          requiredSpace,
        },
      )

      return this.layout
    }

    // If the widget can be moved to the target row, add the widget to the target row and remove it from the source row
    const targetRowState = this.getRowState(targetRowId)

    // Add the widget to the target row
    const updatedTargetRowCells = this.handleAddWidgetToRowCells(
      widget,
      targetRowState?.cells ?? [],
      targetCellIndex,
    )

    const updatedRows = {
      ...this.layout.rows,
      [targetRowId]: {
        cells: updatedTargetRowCells,
        height: this.recalculateRowHeight(targetRowId, widget),
      },
    }

    // If the widget is being moved from a source row, remove the widget from the source row
    // If the source row is empty, remove it from the layout order and rows
    const { rows: finalRows, order: finalOrder } =
      this.handleRemoveWidgetFromRow({
        widget,
        rowId: sourceRowId,
        rows: updatedRows,
      })

    return {
      ...this.layout,
      rows: finalRows,
      order: finalOrder,
    }
  }

  /**
   * Returns a new layout with the specified widget moved to a new position within a row.
   * The row's height and cell widths remain unchanged.
   */
  public changeWidgetPositionInRow = ({
    rowId,
    fromIndex,
    toIndex,
  }: MoveWidgetInRowParams) => {
    const row = this.getRow(rowId)

    if (!row) {
      throw new Error(`Row ${rowId} not found`)
    }

    const updatedCells = arrayMove(row.cells, fromIndex, toIndex)

    return {
      ...this.layout,
      rows: {
        ...this.layout.rows,
        [rowId]: {
          ...row,
          cells: updatedCells,
        },
      },
    }
  }

  /**
   * Returns a new layout with the specified widget removed from the layout.
   */
  public removeWidgetFromLayout = (
    widget: DashboardWidgetState | undefined,
  ) => {
    if (!widget) {
      return this.layout
    }

    const { rows, order } = this.layout

    const rowId = Object.keys(rows).find((rowId) =>
      rows[rowId]?.cells.some((cell) => cell.widgetId === widget.id),
    )

    if (!rowId) {
      return this.layout
    }

    return this.handleRemoveWidgetFromRow({ widget, rowId, rows, order })
  }

  /**
   * Returns a new layout with the specified row updated with the provided changes.
   */
  public updateRowById = (
    rowId: string,
    updates: Partial<DashboardLayoutRow>,
  ) => {
    const row = this.getRow(rowId)

    if (!row) {
      throw new Error('Row not found')
    }

    return {
      ...this.layout,
      rows: {
        ...this.layout.rows,
        [rowId]: {
          ...row,
          ...updates,
        },
      },
    }
  }
}

/**
 * Proportionally distributes a total value across an array of input values.
 * The logic is as follows:
 * - Each value receives a proportional share of the total.
 * - Any rounding errors are distributed to the highest values first.
 * - The sum of the returned values is always equal to the total.
 */
export const distributeValuesWeighted = (values: number[], total: number) => {
  if (values.length === 0) return []
  if (values.length === 1) return [total]

  const sum = values.reduce((acc, value) => acc + value, 0)

  if (sum === 0) return values.map(() => 0)

  // Calculate initial distribution
  const result = values.map((value) => Math.floor((value / sum) * total))
  let remaining = total - result.reduce((acc, value) => acc + value, 0)

  // Sort values in descending order to distribute rounding errors first to the highest values
  const sortedValueIndexes = values
    .map((w, i) => ({ weight: w, index: i }))
    .sort((a, b) => b.weight - a.weight)
    .map((item) => item.index)

  // Distribute rounding errors to the highest values first
  for (let i = 0; remaining > 0; i++) {
    // Get the next index to increment or loop back to the start
    const nextIndex = sortedValueIndexes[i % sortedValueIndexes.length]

    result[nextIndex] += 1
    remaining -= 1
  }

  return result
}

/**
 * Returns the cell width in columns given a percentage width.
 */
export const getCellWidthInColumns = (percentage: number): number => {
  const clampedPercentage = Math.max(1, Math.min(100, percentage))

  return Math.round((clampedPercentage / 100) * 12) || 1
}

/**
 * Returns the relative cell width given a column number.
 */
export const getRelativeCellWidth = (columnSpan: number): number => {
  const GAP_SIZE = 16
  const gapRatio = GAP_SIZE / DASHBOARD_LAYOUT_GRID_COLUMNS

  const relativeWidth = (columnSpan / DASHBOARD_LAYOUT_GRID_COLUMNS) * 100

  return relativeWidth - gapRatio
}

/**
 * Generates a random row id.
 */
export const generateRowId = () => {
  return Math.random().toString(36).substring(2, 15)
}

export const generateNewRowId = (rowIndex: number) => {
  return `${NEW_ROW_ID}${NEW_ROW_ID_DELIMITER}${rowIndex}`
}

export const getNewRowIndex = (rowId: string) => {
  return parseInt(rowId.split(NEW_ROW_ID_DELIMITER)[1])
}

export const isNewRowId = (rowId: string) => {
  return rowId.startsWith(NEW_ROW_ID)
}

export const generateWidgetId = () => {
  return uuidv7()
}

/**
 * Creates a copy of a dashboard.
 * The copy is a new dashboard with the same widgets and layout.
 * The widgets and layout are duplicated and the ids are regenerated.
 */
export const createDashboardCopy = (dashboard: DashboardState) => {
  const widgetDuplicatesIdMap = new Map<string, string>()
  const rowDuplicatesIdMap = new Map<string, string>()

  const duplicatedWidgets = dashboard.widgets.map((widget) => {
    const newWidgetId = generateWidgetId()

    widgetDuplicatesIdMap.set(widget.id, newWidgetId)

    return {
      ...omit(widget, ['__typename']),
      id: newWidgetId,
      analyticsConfig: {
        ...omit(widget.analyticsConfig, ['__typename']),
        id: uuidv7(),
      },
    }
  })

  const duplicatedLayoutOrder = dashboard.layout.order.map((rowId) => {
    const newRowId = generateRowId()

    rowDuplicatesIdMap.set(rowId, newRowId)

    return newRowId
  })

  const duplicatedLayoutRows = Object.entries(dashboard.layout.rows).reduce(
    (acc, [rowId, row]) => {
      if (!row) return acc

      const newRowId = rowDuplicatesIdMap.get(rowId)

      if (!newRowId) return acc

      return {
        ...acc,
        [newRowId]: {
          ...row,
          cells: row.cells
            .map((cell) => ({
              ...cell,
              widgetId: widgetDuplicatesIdMap.get(cell.widgetId),
            }))
            .filter((cell): cell is DashboardLayoutCell => !!cell.widgetId),
        },
      }
    },
    {} as Record<string, DashboardLayoutRow>,
  )

  const duplicatedLayout = {
    order: duplicatedLayoutOrder,
    rows: duplicatedLayoutRows,
  }

  return {
    ...dashboard,
    widgets: duplicatedWidgets,
    layout: duplicatedLayout,
  }
}
