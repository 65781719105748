import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
  getCompareDatePreset,
  getDatePreset,
} from 'constants/getDatePresets'
import { ChartSortOrder } from 'graphql/reports/types'
import { atom, type SetStateAction } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { userAtom } from 'shared/atoms'
import {
  analyticsConfigAtom,
  type AnalyticsConfigState,
} from 'shared/atoms/analyticsConfigState'
import { type OptionalKey } from 'types/optionalKey'
import { widgetConfig } from '../consts'
import {
  type DashboardWidgetResult,
  type DashboardResult,
  type WidgetType,
} from '../types'

export type DashboardWidgetState = Omit<
  OptionalKey<DashboardWidgetResult, '__typename'>,
  'analyticsConfig'
> & {
  analyticsConfig?: AnalyticsConfigState
}

export type DashboardState = Omit<
  OptionalKey<
    DashboardResult,
    'id' | '__typename' | 'owner' | 'createdAt' | 'updatedAt' | 'visibility'
  >,
  'widgets'
> & {
  widgets: DashboardWidgetState[]
}

export enum DashboardViewStatus {
  Loading = 'loading',
  NotFound = 'notFound',
  InSync = 'inSync',
}

type DashboardViewState = {
  dashboard?: DashboardState
  selectedWidget?: DashboardWidgetState
  status: DashboardViewStatus
}

// Status is set for the initial loading state. After that, everything is in sync by updating the atoms directly.
const initialDashboardViewState: DashboardViewState = {
  dashboard: undefined,
  selectedWidget: undefined,
  status: DashboardViewStatus.Loading,
}

const dashboardViewStateAtom = atom(initialDashboardViewState)

const focusDashboardStateAtom = focusAtom(dashboardViewStateAtom, (optic) =>
  optic.prop('dashboard'),
)

export const focusDashboardStatusAtom = focusAtom(
  dashboardViewStateAtom,
  (optic) => optic.prop('status'),
)

export const selectedWidgetStateAtom = focusAtom(
  dashboardViewStateAtom,
  (optic) => optic.prop('selectedWidget'),
)

const defaultDashboardAtom = atom<DashboardState>(() => {
  const dynamicDate = DYNAMIC_DATE_ID.LAST_28_DAYS
  const datePreset = getDatePreset(dynamicDate)
  const compareDynamicDate = COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING
  const compareDatePreset = getCompareDatePreset(
    compareDynamicDate,
    datePreset.value[0],
    datePreset.value[1],
  )

  return {
    name: '',
    iconName: 'Dashboard1Icon',
    iconColor: 'grey',
    filters: {},
    layout: {
      order: [],
      rows: {},
    },
    dynamicDate,
    startDate: datePreset.value[0]?.toISOString() ?? '',
    endDate: datePreset.value[1]?.toISOString() ?? '',
    compareDynamicDate,
    compareStartDate: compareDatePreset.value[0]?.toISOString() ?? '',
    compareEndDate: compareDatePreset.value[1]?.toISOString() ?? '',
    visibility: [],
    widgets: [],
  }
})

export const newDashboardAtom = atom<DashboardState>((get) => {
  const user = get(userAtom)

  return {
    ...get(defaultDashboardAtom),
    owner: user,
  }
})

export const dashboardStateAtom = atom(
  (get) => {
    return get(focusDashboardStateAtom) ?? get(defaultDashboardAtom)
  },
  (_, set, dashboard: SetStateAction<DashboardState | undefined>) => {
    set(focusDashboardStateAtom, dashboard)
  },
)

const defaultWidgetState: OptionalKey<DashboardWidgetState, 'type' | 'id'> = {
  name: '',
  type: undefined,
  analyticsConfig: {
    dimensions: [],
    metrics: [],
    filters: {},
    tableState: [],
    chart: {
      xAxis: '',
      color: undefined,
      series: [],
      chartSorting: {
        key: '',
        order: ChartSortOrder.ASC,
      },
    },
    dynamicDate: null,
    startDate: null,
    endDate: null,
    compareDynamicDate: null,
    compareStartDate: null,
    compareEndDate: null,
    compareUnit: 'percentageDiff',
  },
}

export const dashboardWidgetsAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('widgets'),
)

export const widgetAtomFamily = atomFamily((widgetId?: string) =>
  atom(
    (get) => {
      if (!widgetId) return undefined

      const widgets = get(dashboardWidgetsAtom)

      return widgets.find((widget) => widget.id === widgetId)
    },
    (get, set, newWidget: SetStateAction<DashboardWidgetState | undefined>) => {
      const dashboard = get(dashboardStateAtom)
      const index = dashboard.widgets.findIndex((w) => w.id === widgetId)

      if (index === -1) return

      const updatedWidget =
        typeof newWidget === 'function'
          ? newWidget(dashboard.widgets[index])
          : newWidget

      if (!updatedWidget) return

      const updatedWidgets = [...dashboard.widgets]

      updatedWidgets[index] = updatedWidget

      set(dashboardStateAtom, {
        ...dashboard,
        widgets: updatedWidgets,
      })
    },
  ),
)

export const widgetAnalyticsConfigAtom = atomFamily((widgetId?: string) =>
  atom(
    (get) => {
      const widget = get(widgetAtomFamily(widgetId))

      if (!widget) return undefined

      const selectedWidget = get(selectedWidgetStateAtom)

      if (widgetId === selectedWidget?.id) {
        return get(analyticsConfigAtom)
      }

      return widget.analyticsConfig
    },
    (get, set, config: SetStateAction<AnalyticsConfigState | undefined>) => {
      const widget = get(widgetAtomFamily(widgetId))

      if (!widget) return

      const selectedWidget = get(selectedWidgetStateAtom)

      if (widgetId === selectedWidget?.id) {
        set(analyticsConfigAtom, config)
      }

      const newConfig =
        typeof config === 'function' ? config(widget.analyticsConfig) : config

      set(widgetAtomFamily(widgetId), {
        ...widget,
        analyticsConfig: newConfig,
      })
    },
  ),
)

export const newWidgetAtom = atomFamily((widgetType: WidgetType) =>
  atom<OptionalKey<DashboardWidgetState, 'id'>>({
    ...defaultWidgetState,
    type: widgetType,
    id: undefined,
  }),
)

export enum WidgetStatus {
  Empty = 'empty',
  Error = 'error',
  Ready = 'ready',
}

interface WidgetStatusState {
  status: WidgetStatus | undefined
  widgetId: string | undefined
}

const widgetStatus = atomFamily((widgetId: string | undefined) =>
  atom<WidgetStatusState>({
    status: undefined,
    widgetId,
  }),
)

export const widgetStatusAtomFamily = atomFamily(
  (widgetId: string | undefined) =>
    atom(
      (get) => {
        if (!widgetId) return

        const { status } = get(widgetStatus(widgetId))

        if (status) {
          return status
        }

        const widgetType = get(widgetAtomFamily(widgetId))?.type
        const { requiresDimensions } = widgetType
          ? widgetConfig[widgetType]
          : {}

        const { metrics, dimensions } =
          get(widgetAnalyticsConfigAtom(widgetId)) || {}

        if (!metrics?.length || (requiresDimensions && !dimensions?.length)) {
          return WidgetStatus.Empty
        }

        return WidgetStatus.Ready
      },
      (get, set, newStatus: SetStateAction<WidgetStatus | undefined>) => {
        const { status: currentStatus } = get(widgetStatus(widgetId))
        const updatedStatus =
          typeof newStatus === 'function' ? newStatus(currentStatus) : newStatus

        set(widgetStatus(widgetId), { widgetId, status: updatedStatus })
      },
    ),
)
