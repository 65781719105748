import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Typography } from 'components/Typography'
import { startOfDay, subDays } from 'date-fns'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { toISODateRange } from 'utils/dateRange'

interface ExcludeCampaignsDropdownProps {
  frontendId: string
  channel: string
  country: string
  funnelCampaigns: string[]
  excludeCampaigns: string[]
  onChange: (option: string[]) => void
}

export const ExcludeCampaignsDropdown: React.FC<
  ExcludeCampaignsDropdownProps
> = ({
  frontendId,
  channel,
  country,
  funnelCampaigns,
  excludeCampaigns,
  onChange,
}) => {
  const { timezone } = useMerchantInfo()

  // need to memo to avoid re-fetching when the new Date() changes on each render
  const [startDate, endDate] = useMemo(() => {
    const now = startOfDay(new Date())
    const nowMinus30Days = subDays(now, 30)

    return toISODateRange([nowMinus30Days, now], timezone)
  }, [timezone])

  const skipQuery =
    !frontendId || !channel || !country || !funnelCampaigns.length

  const { statistics, query } = useStatistics({
    skip: skipQuery,
    variables: {
      statisticsParams: {
        frontendIds: [frontendId],
        dimensions: ['campaign'],
        metrics: ['cost:total'],
        startDate: String(startDate),
        endDate: String(endDate),
        filter: {
          channel: [
            {
              value: [channel],
              comparisonId: 'isAnyOf',
            },
          ],
          country: [
            {
              value: [country],
              comparisonId: 'isAnyOf',
            },
          ],
          funnelCampaign: [
            {
              value: funnelCampaigns,
              comparisonId: 'isAnyOf',
            },
          ],
        },
      },
    },
  })

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      label="Exclude campaigns"
      options={statistics?.map(({ campaign: { value, formattedValue } }) => ({
        id: String(value),
        name: formattedValue,
      }))}
      selected={excludeCampaigns}
      searchPlaceholder="Search campaign"
      buttonText={
        excludeCampaigns.length ? undefined : 'Select campaigns (optional)'
      }
      isLoading={query.loading}
      isFullHeight
      customEmptyState={
        skipQuery ? (
          <Typography px={4} py={2} textAlign="left" fontSize="xs">
            Select a country, channel and funnel to see campaigns to exclude.
          </Typography>
        ) : undefined
      }
      setSelected={(option) => onChange(option)}
    />
  )
}
