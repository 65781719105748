import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { type DashboardState } from '../atoms/dashboardViewState'
import { useEditDashboard } from '../graphql/useEditDashboard'

type Props = {
  dashboard: DashboardState
  newOwnerExternalId: string
  oldOwnerExternalId?: string
}

export const useEditDashboardOwner = () => {
  const [editDashboard, state] = useEditDashboard()
  const toast = useToast()

  const editDashboardOwner = async ({
    dashboard,
    newOwnerExternalId,
  }: Props) => {
    if (!dashboard.id) return

    try {
      await editDashboard({
        id: dashboard.id,
        dashboard: {
          ...dashboard,
          owner: {
            externalId: newOwnerExternalId,
          },
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not update dashboard owner, please try again',
      })

      captureException(`Update dashboard owner: ${error}`)
    }
  }

  return { editDashboardOwner, loading: state.loading }
}
