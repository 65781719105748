import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Portal,
  type PlacementWithLogical,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationCustomerSet } from 'features/segmentations/graphql/fragments'
import type React from 'react'
import { DeleteCustomerSetModal } from './ContextMenu/DeleteCustomerSetModal'
import { EditCustomerSetModal } from './ContextMenu/EditCustomerSetModal'

interface CustomerSetContextMenuProps {
  set: SegmentationCustomerSet
  placement?: PlacementWithLogical
  onDelete?: () => void
}

export const CustomerSetContextMenu: React.FC<CustomerSetContextMenuProps> = ({
  set,
  placement,
  onDelete,
}) => {
  return (
    <Menu
      isLazy={false}
      size="sm"
      closeOnSelect={false}
      placement={placement || 'bottom-end'}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            onClick={
              (e) => e.stopPropagation() // For example when context menu is used in report list, it will stop the report from being selected on click
            }
            as={IconButton}
            size="sm"
            aria-label="Options"
            border="none"
            icon={
              <Icon
                name="HorizontalMenuIcon"
                tooltipLabel="Actions"
                size="small"
              />
            }
            variant="ghost"
          />
          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList>
              <EditCustomerSetModal currentSegmentationCustomerSet={set} />

              <MenuDivider />

              <DeleteCustomerSetModal customerSet={set} onDelete={onDelete} />
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
