import { Box, MenuButton } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { AddWidgetButton } from '../../DashboardHeader/AddWidgetButton/AddWidgetButton'

interface NewRowButtonProps {
  rowIndex: number
  isVisible: boolean
  onWidgetMenuClose: () => void
}

export const NewRowButton = ({
  isVisible,
  rowIndex,
  onWidgetMenuClose,
  ...props
}: NewRowButtonProps) => {
  return (
    <Box
      position="absolute"
      zIndex={1}
      left={-2}
      bottom={-5}
      {...props}
      opacity={isVisible ? 1 : 0}
      transition="opacity 0.1s ease-in-out"
    >
      <AddWidgetButton
        customMenuButton={
          <MenuButton
            as={ButtonIcon}
            name="PlusIcon"
            size="xs"
            w="fit-content"
            colorScheme="grey"
          />
        }
        options={{ rowIndex }}
        onMenuClose={onWidgetMenuClose}
      />
    </Box>
  )
}
