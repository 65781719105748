import { MenuItem, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { DASHBOARD_PAGES } from 'features/dashboards/routePages'
import type React from 'react'

interface Props {
  dashboardId: string | undefined
}

export const CopyDashboardUrlMenuItem: React.FC<Props> = ({ dashboardId }) => {
  const toast = useToast()

  const copyDashboardUrl = () => {
    if (!dashboardId) {
      return
    }

    const dashboardUrl = `${window.location.origin}${DASHBOARD_PAGES.DASHBOARD_BY_ID(
      dashboardId,
    )}`

    navigator.clipboard.writeText(dashboardUrl)

    toast({
      status: 'success',
      description: 'Copied dashboard url to clipboard',
    })
  }

  return (
    <MenuItem
      onClick={copyDashboardUrl}
      icon={<Icon name="LinkIcon" size="small" />}
    >
      Copy URL
    </MenuItem>
  )
}
