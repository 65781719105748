import { Flex } from '@chakra-ui/react'
import { ClearAllFilterButton } from 'components/Filters/ClearAllFilterButton'
import { useAddDashboardFilter } from 'features/dashboards/hooks/filters/useAddDashboardFilter'
import {
  useDashboardDateState,
  useSetDashboardDateState,
} from 'features/dashboards/hooks/filters/useDashboardDateState'
import {
  useDashboardFiltersState,
  useSetDashboardFiltersState,
} from 'features/dashboards/hooks/filters/useDashboardFiltersState'
import { useRemoveDashboardFilter } from 'features/dashboards/hooks/filters/useRemoveDashboardFilter'
import { AddFilterButton } from 'features/reports/components/Filters/AddFilterButton'
import { SelectedFilterChip } from 'features/reports/components/Filters/SelectedFilterChip/SelectedFilterChip'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'
import { DateRangePicker } from 'shared/DateRangePicker'

export const DashboardFilters = () => {
  const dateState = useDashboardDateState()
  const { filters: selectedFilterValues, aggregatedMetrics } =
    useDashboardFiltersState()
  const setFilters = useSetDashboardFiltersState()
  const setDateState = useSetDashboardDateState()
  const normalizedMetrics = useNormalizedMetrics()

  const metricGroups = useMemo(
    () => groupBy(aggregatedMetrics.map((metric) => metric.groupKey)),
    [aggregatedMetrics],
  )

  const addFilter = useAddDashboardFilter()
  const removeFilter = useRemoveDashboardFilter()

  return (
    <Flex
      gap={2}
      justifyContent="space-between"
      flexDir="row-reverse"
      flexWrap="wrap"
      px={6}
      py={1.5}
      overflowX="auto"
    >
      <Flex justifyContent="end">
        <DateRangePicker dateState={dateState} onDateChange={setDateState} />
      </Flex>
      <Flex
        gap={2}
        wrap="wrap"
        justifyContent="start"
        flex={1}
        alignItems="center"
        maxW="full"
      >
        {Object.entries(selectedFilterValues).map(([filterId, filters]) =>
          filters.map((filter, index) => (
            <SelectedFilterChip
              key={filterId + index}
              filter={filter}
              filterId={filterId}
              filterIndex={index}
              filters={selectedFilterValues}
              addFilter={addFilter}
              removeFilter={removeFilter}
              metricShouldShowAttribution={
                metricGroups[normalizedMetrics[filterId]?.groupKey]?.length > 1
              }
              dateRange={dateState.dateRange}
            />
          )),
        )}

        <AddFilterButton
          filters={selectedFilterValues}
          addFilter={addFilter}
          removeFilter={removeFilter}
          dateRange={dateState.dateRange}
          metrics={aggregatedMetrics}
        />

        {Object.keys(selectedFilterValues).length > 0 && (
          <ClearAllFilterButton onClick={() => setFilters({})} />
        )}
      </Flex>
    </Flex>
  )
}
