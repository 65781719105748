import { Box, Divider, Flex, Grid } from '@chakra-ui/react'
import { Dropdown } from 'components/Dropdown'
import { Typography } from 'components/Typography'
import {
  type ExperimentSetupFieldsFragment,
  type InferenceSummaryFieldsFragment,
} from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { formatMetric } from 'utils/numberFormats'
import { experimentViewMetrics, type EXPERIMENT_METRIC } from '../consts'
import { DetailRow } from './ExperimentConfigPanel/DetailRow'
interface ExperimentStatisticsProps {
  setup: ExperimentSetupFieldsFragment
  summary: InferenceSummaryFieldsFragment | undefined
  selectedMetric: EXPERIMENT_METRIC
  setSelectedMetric: (metric: EXPERIMENT_METRIC) => void
}

export const ExperimentStatistics: React.FC<ExperimentStatisticsProps> = ({
  setup,
  summary,
  selectedMetric,
  setSelectedMetric,
}) => {
  const { currency } = useMerchantInfo()

  const hasSummary = summary !== undefined

  const metric =
    experimentViewMetrics.find((metric) => metric.id === selectedMetric) ??
    experimentViewMetrics[0]

  const metricNameLowerCase = metric.name.toLowerCase()

  return (
    <Box py={4} px={6}>
      <Flex justifyContent="space-between" mb={4}>
        <Typography
          fontSize="sm"
          fontWeight={500}
          lineHeight={5}
          color="gray.800"
        >
          Experiment statistics
        </Typography>

        {hasSummary && (
          <Flex gap={1} alignItems="center">
            <Typography
              fontSize="xs"
              fontWeight={500}
              lineHeight={5}
              color="gray.700"
            >
              Metric:
            </Typography>
            <Dropdown
              variant="solid"
              size="xs"
              options={experimentViewMetrics}
              value={selectedMetric}
              callback={(option) => {
                setSelectedMetric(option.id)
              }}
            />
          </Flex>
        )}
      </Flex>

      <Grid templateColumns="1fr auto 1fr" gap={10}>
        <Box w={0} minW="full">
          <DetailRow
            label={
              hasSummary
                ? 'Spend during experiment'
                : 'Estimated total spend in treatment group'
            }
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              hasSummary
                ? summary.totalSpend
                : setup.channelMetrics.estimatedTotalSpend,
              currency,
            )}
          />
          <DetailRow
            label="Total spend previous period"
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              setup.channelMetrics.estimatedTotalSpendPastPeriod,
              currency,
            )}
            tooltip={
              'Total spend in the period before the experiment (matching the length of the experiment treatment period) for the selected channel and funnels.'
            }
          />
          <DetailRow
            label={
              hasSummary
                ? `Incremental ${metricNameLowerCase}`
                : `Estimated incremental ${metricNameLowerCase}`
            }
            value={formatMetric(
              metric.format,
              hasSummary
                ? metric.getValue(summary)
                : setup.channelMetrics.estimatedTotalTargetIncremental,
              currency,
            )}
            tooltip={
              hasSummary
                ? 'Total difference between treatment and control during the experiment.'
                : 'Estimated total difference between treatment and control by the end of the experiment.'
            }
          />

          {hasSummary && (
            <DetailRow
              label={`Incremental ${metric.returnName}`}
              value={formatMetric(
                metric.returnFormat,
                summary.incrementalFactor,
                currency,
              )}
            />
          )}
        </Box>

        <Divider orientation="vertical" />

        <Box w={0} minW="full">
          <DetailRow
            label={`Treatment group proportion of ${metricNameLowerCase}`}
            value={formatMetric(
              METRIC_FORMAT.PERCENT_INT,
              setup.treatmentProportion,
            )}
          />
          <DetailRow
            label={
              hasSummary
                ? 'Lift detected due to marketing'
                : 'Estimated lift due to marketing'
            }
            value={formatMetric(
              METRIC_FORMAT.PERCENT,
              hasSummary ? summary.lift : setup.channelMetrics.estimatedLift,
            )}
          />
          {!hasSummary && (
            <DetailRow
              label="Minimum detectable effect"
              value={formatMetric(
                METRIC_FORMAT.PERCENT,
                setup.avgMinimumDetectableEffect,
              )}
              tooltip={
                'The smallest change in the treatment group’s performance that can be reliably detected by the test given its design.'
              }
            />
          )}
          <DetailRow
            label="Treatment group correlation"
            value={formatMetric(METRIC_FORMAT.PERCENT, setup.correlation)}
            tooltip={
              'How well the treatment and control groups match historically. Higher correlation (>90%) indicates well-matched groups for reliable comparison.'
            }
          />

          {hasSummary && (
            <DetailRow
              label="P-value"
              value={formatMetric(METRIC_FORMAT.PERCENT, summary.pValue)}
              tooltip={
                'The probability of observing the data if the null hypothesis is true. The null hypothesis is that there is no difference between the treatment and control groups.'
              }
            />
          )}
        </Box>
      </Grid>
    </Box>
  )
}
