import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const EDIT_SEGMENTATION_CUSTOMER_LABEL_MUTATION = graphql(/* GraphQL */ `
  mutation EditSegmentationCustomerLabel(
    $id: ID!
    $params: EditSegmentationCustomerLabelInput!
  ) {
    editSegmentationCustomerLabel(id: $id, params: $params) {
      ...SegmentationCustomerLabelFields
    }
  }
`)

export const useEditCustomerLabel = () => {
  const [editCustomerLabel] = useMutation(
    EDIT_SEGMENTATION_CUSTOMER_LABEL_MUTATION,
  )

  return editCustomerLabel
}
