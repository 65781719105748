import { widgetAtomFamily } from 'features/dashboards/atoms/dashboardViewState'
import {
  selectedWidgetIdAtom,
  useSetWidgetById,
} from 'features/dashboards/hooks/useWidgetsState'
import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import { useAtomValue } from 'jotai'
import { isEqual } from 'lodash-es'
import { useEffect } from 'react'
import { analyticsConfigAtom } from 'shared/atoms/analyticsConfigState'

// This component is used to sync the the global analytics config state with the widget state
export const WidgetStateSync = () => {
  const selectedWidgetId = useAtomValue(selectedWidgetIdAtom)
  const analyticsConfigState = useAtomValue(analyticsConfigAtom)
  const setWidgetById = useSetWidgetById(selectedWidgetId)
  const getWidgetStateById = useGetAtomFamilyValue(widgetAtomFamily)

  useEffect(() => {
    if (!selectedWidgetId) return
    const widgetState = getWidgetStateById(selectedWidgetId)

    if (!widgetState) return

    const analyticsConfigHasChanged = !isEqual(
      widgetState.analyticsConfig,
      analyticsConfigState,
    )

    if (!analyticsConfigHasChanged) return

    setWidgetById({
      ...widgetState,
      analyticsConfig: analyticsConfigState,
    })
  }, [
    setWidgetById,
    selectedWidgetId,
    getWidgetStateById,
    analyticsConfigState,
  ])

  return null
}
