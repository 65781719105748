import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { type ExtendedSortDirection } from 'graphql/reports/types'
import { isAttributedMetric } from 'graphql/statistics/useMetrics'
import { forwardRef, useCallback } from 'react'
import {
  useAnalyticsTableState,
  useSetTableState,
} from 'shared/hooks/useAnalyticsTableState'
import { AttributionModal } from '../../ReportPanel/ReportDetailsPanel/AttributionSection/AttributionModal'
import { CellWrapper } from '../cell/CellWrapper'
import { type AnalyticsTableHeaderProps } from '../types'
import { AttributionBadge } from './AttributionBadge'
import { HeaderMenuWrapper } from './HeaderMenuWrapper'
import { HeaderText } from './HeaderText'
import { HeaderTotalSection } from './HeaderTotalSection'

export const AnalyticsTableHeader = forwardRef(
  (
    {
      aggregatedData,
      column,
      api,
      metric,
      dimension,
      displayName,
      isCompare,
      isLoading,
      compareUnit,
      progressSort,
    }: AnalyticsTableHeaderProps,
    ref,
  ) => {
    const columnId = column?.getColId()
    const state = useAnalyticsTableState()
    const sortValue =
      state.find(({ id, sort }) => !!sort && id === columnId)?.sort ?? null
    const { setTableSorting, setTableColumnPinning } = useSetTableState()
    const { onOpen, onClose, isOpen } = useDisclosure()
    const {
      onOpen: attributionModalOpen,
      onClose: attributionModalClose,
      isOpen: attributionModalIsOpen,
    } = useDisclosure()

    const setSortOrder = useCallback(
      (sort: ExtendedSortDirection) => {
        setTableSorting(columnId, sort)
      },
      [columnId, setTableSorting],
    )

    const onHeaderClick = () => {
      switch (sortValue) {
        case 'ascCompare': {
          setSortOrder(null)
          break
        }

        case 'descCompare': {
          setSortOrder('ascCompare')
          break
        }

        default: {
          progressSort()
          const sort = column.getSort() ?? null

          setSortOrder(sort)
        }
      }
    }

    const toggleFreezeColumn = () => {
      const isPinned = column.isPinned()

      api.setColumnsPinned([columnId], isPinned ? null : 'left')

      setTableColumnPinning(columnId, !isPinned)
    }

    return (
      <CellWrapper gap={2} justifyContent="space-between" py={3} ref={ref}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexDir={metric ? 'row-reverse' : 'row'}
          _hover={{
            '& .icon': {
              visibility: 'visible',
            },
          }}
        >
          <Box onClick={onHeaderClick} as="button">
            <HeaderText
              isMetric={!!metric}
              showIcon
              sortDirection={sortValue}
              description={dimension?.description || metric?.description}
            >
              {displayName}
            </HeaderText>
          </Box>
          <HeaderMenuWrapper
            setSortOrder={setSortOrder}
            columnId={columnId}
            displayName={displayName}
            isMetric={!!metric}
            isFrozen={column.isPinned()}
            toggleFreezeState={toggleFreezeColumn}
          />
        </Flex>
        <Flex flexDir="column" alignItems="flex-end" gap={1}>
          {metric && isAttributedMetric(metric) ? (
            <AttributionBadge
              metric={metric}
              attributionModalOpen={attributionModalOpen}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />
          ) : null}
          <AttributionModal
            isOpen={attributionModalIsOpen}
            onClose={attributionModalClose}
          />
          <HeaderTotalSection
            isLoading={isLoading}
            metric={metric}
            isCompare={isCompare}
            aggregatedData={aggregatedData}
            compareUnit={compareUnit}
          />
        </Flex>
      </CellWrapper>
    )
  },
)

AnalyticsTableHeader.displayName = 'AnalyticsTableHeader'
