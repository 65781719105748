import { Navigate, type RouteObject } from 'react-router-dom'
import { DASHBOARD_ROUTE_FRAGMENTS } from '../dashboards/routePages'
import { AllDashboardsView } from './views/DashboardsList/AllDashboardsView'
import { UserDashboardsView } from './views/DashboardsList/UserDashboardsView'
import { DashboardView } from './views/DashboardView'

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={DASHBOARD_ROUTE_FRAGMENTS.MY} replace />,
  },
  {
    path: DASHBOARD_ROUTE_FRAGMENTS.MY,
    element: <UserDashboardsView />,
  },
  {
    path: DASHBOARD_ROUTE_FRAGMENTS.ALL,
    element: <AllDashboardsView />,
  },
  {
    path: ':id',
    element: <DashboardView />,
  },
]
