import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'

import { type DashboardState } from 'features/dashboards/atoms/dashboardViewState'
import { DASHBOARD_PAGES } from 'features/dashboards/routePages'
import { CopyDashboardUrlMenuItem } from './CopyDashboardUrlMenuItem'
import { DeleteDashboardMenuItem } from './DeleteDashboardMenuItem'
import { DuplicateDashboardMenuItem } from './DuplicateDashboardMenuItem'

interface DashboardContextualMenuProps {
  dashboard: DashboardState
}

export const DashboardContextualMenu = ({
  dashboard,
}: DashboardContextualMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user } = useUser()

  if (!dashboard.id) return null

  const isOwnUser =
    user?.publicMetadata.dema_id?.toString() === dashboard.owner?.id
  const isDeleteAllowed = dashboard.id && isOwnUser

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy size="sm">
      <MenuButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onOpen()
        }}
        as={IconButton}
        size="sm"
        aria-label="Options"
        icon={
          <Icon
            name="HorizontalMenuIcon"
            tooltipLabel="More actions"
            size="small"
          />
        }
        variant="ghost"
      />
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList onClick={(e) => e.stopPropagation()} zIndex="dropdown">
          <DuplicateDashboardMenuItem dashboard={dashboard} />
          <MenuDivider />
          <CopyDashboardUrlMenuItem dashboardId={dashboard.id} />
          <MenuItem
            icon={<Icon name="LinkExternalIcon" size="small" />}
            onClick={() => {
              if (!dashboard.id) return

              window.open(
                DASHBOARD_PAGES.DASHBOARD_BY_ID(dashboard.id),
                '_blank',
              )
            }}
          >
            Open in new tab
          </MenuItem>
          {isDeleteAllowed && (
            <>
              <MenuDivider />
              <DeleteDashboardMenuItem dashboardId={dashboard.id} />
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}
