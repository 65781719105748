import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { SEGMENTATION_TYPE } from 'features/segmentations/consts'
import {
  type CustomerSetByIdQueryResult,
  useCustomerSetById,
} from 'features/segmentations/graphql/useCustomerSetById'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import { EmptyState } from 'features/segmentations/shared/EmptyState'
import { type CustomerRuleForm } from 'features/segmentations/shared/RulesContainer/types'
import { SegmentationHeaderWrapper } from 'features/segmentations/shared/SegmentationHeaderWrapper'
import { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { getEmptyCustomerRule } from '../../shared/RulesContainer/utils'
import { SegmentRuleHeader } from '../../shared/SegmentRuleHeader'
import { RulesContainer } from './components/RulesContainer/RulesContainer'
import { SegmentationPieCharts } from './components/RulesContainer/SegmentationPieCharts'

export const CustomerRulesView: React.FC = () => {
  const { id } = useParams()
  const { customerSet, query } = useCustomerSetById(id ?? '')

  if (query.loading)
    return (
      <Box>
        <SegmentationHeaderWrapper>
          {/* minH needed for the dropdown */}
          <Flex alignItems="center" minH={8}>
            <SegmentationHeaderWrapper.Title>
              Customer set
            </SegmentationHeaderWrapper.Title>

            <Typography color="gray.600" fontSize="sm" ml={2} mr={1}>
              /
            </Typography>

            <Skeleton h={5} w={32} />
          </Flex>
        </SegmentationHeaderWrapper>

        <Box p={4}>
          <Skeleton h={52} w="full" mb={4} />
          <Skeleton h={52} w="full" />
        </Box>
      </Box>
    )

  if (query.error || !customerSet)
    return (
      <EmptyState
        imageSrc="/images/Layers.svg"
        title="Customer set not found"
        description="The customer set you are looking for does not exist."
        modalButton={
          <Link to={SEGMENTATIONS_PAGES.CUSTOMER_SETS}>
            <Button
              mt={4}
              variant="solid"
              size="sm"
              leadingIcon={{ name: 'ArrowLeftIcon' }}
            >
              Go back to customer sets
            </Button>
          </Link>
        }
      />
    )

  return <SegmentationContent segmentationCustomerSet={customerSet} />
}

const SegmentationContent: React.FC<{
  segmentationCustomerSet: CustomerSetByIdQueryResult
}> = ({ segmentationCustomerSet }) => {
  const [selectedDatePresetId, setSelectedDatePresetId] = useState(
    segmentationCustomerSet.dynamicDate,
  )

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<{ rules: CustomerRuleForm[] }>({
    defaultValues: {
      rules:
        segmentationCustomerSet.rules?.length > 0
          ? segmentationCustomerSet.rules
          : [getEmptyCustomerRule()],
    },
  })

  const methods = useFieldArray({
    control,
    name: 'rules',
    // the id generated here changes on every change. Which makes the state inside of the cards unstable
    // so we use our own generated id
    keyName: 'internal_id',
  })

  return (
    <Box>
      <SegmentRuleHeader
        type={SEGMENTATION_TYPE.CUSTOMER}
        segmentationSet={segmentationCustomerSet}
        selectedDatePresetId={selectedDatePresetId}
        setSelectedDatePresetId={setSelectedDatePresetId}
      />

      <Flex p={4} gap={4}>
        <Box w="full">
          <RulesContainer
            segmentationCustomerSet={segmentationCustomerSet}
            selectedDatePresetId={selectedDatePresetId}
            methods={methods}
            handleSubmit={handleSubmit}
            isDirty={isDirty}
          />
        </Box>

        <SegmentationPieCharts
          selectedDatePresetId={selectedDatePresetId}
          rules={methods.fields}
        />
      </Flex>
    </Box>
  )
}
