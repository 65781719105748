import { Box } from '@chakra-ui/react'
import { type UniqueIdentifier } from '@dnd-kit/core'
import { useAtomValue } from 'jotai'
import { addWidgetMenuOpenAtom } from '../../DashboardHeader/AddWidgetButton/AddWidgetButton'
import { DroppableContainer } from '../DroppableContainer'
import { NewRowButton } from './NewRowButton'

interface NewRowContainerProps {
  id: string
  index: number
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

const emptyRow: UniqueIdentifier[] = []

export const NewRowContainer = ({
  id,
  index,
  isVisible,
  setIsVisible,
}: NewRowContainerProps) => {
  const isAddWidgetMenuOpen = useAtomValue(addWidgetMenuOpenAtom)

  return (
    <>
      <Box
        position="absolute"
        onMouseEnter={() => {
          if (!isAddWidgetMenuOpen) {
            setIsVisible(true)
          }
        }}
        onMouseLeave={() => {
          if (!isAddWidgetMenuOpen) {
            setIsVisible(false)
          }
        }}
      >
        <NewRowButton
          rowIndex={index}
          isVisible={isVisible}
          onWidgetMenuClose={() => {
            if (isVisible) setIsVisible(false)
          }}
        />
      </Box>

      <DroppableContainer
        id={id}
        items={emptyRow}
        position="absolute"
        h={4}
        w="100%"
      />
    </>
  )
}
