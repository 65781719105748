import { type Filter } from 'constants/types'
import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import {
  useSetWidgetFiltersState,
  widgetFiltersStateAtom,
} from './useWidgetFiltersState'

export const removeWidgetFilter = ({
  filterId,
  filterIndex,
  filters,
}: {
  filterId: string
  filterIndex: number
  filters: Record<string, Filter[]>
}) => {
  const newFilters = {
    ...filters,
  }

  const currentFilter = newFilters[filterId] || []
  const newFilter = currentFilter.filter((_, index) => index !== filterIndex)

  if (newFilter.length > 0) {
    newFilters[filterId] = newFilter
  } else {
    delete newFilters[filterId]
  }

  return newFilters
}

export const useRemoveWidgetFilter = (widgetId: string | undefined) => {
  const setFilters = useSetWidgetFiltersState(widgetId)
  const getCurrentFilters = useGetAtomFamilyValue(widgetFiltersStateAtom)

  return ({
    filterId,
    filterIndex,
  }: {
    filterId: string
    filterIndex: number
  }) => {
    const { widgetFilters: filters } = getCurrentFilters(widgetId)

    const newFilters = removeWidgetFilter({ filterId, filterIndex, filters })

    setFilters(newFilters)
  }
}
