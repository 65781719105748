import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { Button } from 'components/buttons/Button'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type SegmentationCustomerLabel } from '../graphql/fragments'
import { useDeleteCustomerLabel } from '../graphql/useDeleteCustomerLabel'

interface DeleteCustomerLabelProps {
  label: SegmentationCustomerLabel
}

export const DeleteCustomerLabelModal = ({
  label,
}: DeleteCustomerLabelProps) => {
  const deleteCustomerLabel = useDeleteCustomerLabel()

  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onDeleteLabel = () => {
    deleteCustomerLabel({
      variables: { id: label.id },
      optimisticResponse: {
        deleteSegmentationCustomerLabel: {
          id: label.id,
          __typename: 'SegmentationCustomerLabel',
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteSegmentationCustomerLabel) return

        cache.evict({
          id: cache.identify(data?.deleteSegmentationCustomerLabel),
        })
        cache.gc()
      },
      onCompleted: () => {
        trackEvent({
          eventName: 'Segmentations Customer Label Deleted',
          eventProperties: { name: label.name },
        })
      },
      onError: () => {
        toast({
          description: 'Could not delete customer label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <ButtonIcon
        onClick={onOpen}
        size="sm"
        variant="ghost"
        title="Delete customer label"
        name="TrashIcon"
      />

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete customer label</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            The customer label will be deleted and removed from all instances
            where it is used.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              type="submit"
              onClick={() => {
                onDeleteLabel()
                onClose()
              }}
            >
              Delete customer label
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
