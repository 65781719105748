import { type Filter } from 'constants/types'
import {
  type OwnerFieldsFragment,
  type DashboardWithOwnerFieldsFragment,
  type TeamFieldsFragment,
  type MerchantFieldsFragment,
  type DashboardWidgetFieldsFragment,
} from 'generated/graphql/graphql'
import { type AnalyticsConfig } from 'graphql/reports/types'

export type DashboardVisibility = TeamFieldsFragment | MerchantFieldsFragment

type DashboardRowId = string

export type DashboardLayoutCell = {
  widgetId: string
  width: number
}

export type DashboardLayoutRow = {
  height: number
  cells: DashboardLayoutCell[]
}

export type DashboardLayout = {
  rows: Partial<Record<DashboardRowId, DashboardLayoutRow>>
  order: DashboardRowId[]
}

export type DashboardResult = Omit<
  DashboardWithOwnerFieldsFragment,
  | 'visibility'
  | 'owner'
  | 'layout'
  | 'widgets'
  | 'filters'
  | 'tableState'
  | 'chart'
  | 'compareUnit'
> & {
  owner: OwnerFieldsFragment
  visibility: DashboardVisibility[]
  layout: DashboardLayout
  widgets: DashboardWidgetResult[]
  filters: Record<string, Filter[]>
}

export enum WidgetType {
  CHART = 'chart',
  KPI = 'kpi',
  TABLE = 'table',
}

export type DashboardWidgetResult = Omit<
  DashboardWidgetFieldsFragment,
  'analyticsConfig' | 'type'
> & {
  analyticsConfig?: AnalyticsConfig
  type: WidgetType
}
