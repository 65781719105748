import { MenuItem, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { getSegmentationCSVLink } from 'features/segmentations/shared/CopyCSVLinkModal'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'

interface CopyCSVItemProps {
  setId: string
  setName: string
}

export const CopyCSVItem: React.FC<CopyCSVItemProps> = ({ setId, setName }) => {
  const { merchantId } = useMerchantInfo()
  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  return (
    <>
      <MenuItem
        icon={<Icon name="LinkIcon" size="small" />}
        onClick={(e) => {
          e.stopPropagation()
          const csvLink = getSegmentationCSVLink({ merchantId, setId })

          navigator.clipboard.writeText(csvLink).then(() => {
            toast({
              status: 'success',
              description: 'CSV link copied to clipboard',
              position: 'bottom-right',
            })
          })

          trackEvent({
            eventName: `Segmentations Product Set CSV Link Copied`,
            eventProperties: { setId, setName, fromModal: false },
          })
        }}
        closeOnSelect={true}
      >
        Copy link to CSV file
      </MenuItem>
    </>
  )
}
