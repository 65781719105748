import { getDateString } from 'components/Datepicker/utils'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { statusLabelMapping } from 'features/geoLift/shared/StatusTag'
import { stringIdToLabel } from 'features/geoLift/utils'
import { type NormalizedChannels } from 'graphql/useChannels'
import { type NormalizedCountries } from 'graphql/useCountries'
import { type NormalizedSites } from 'graphql/useMerchantInfo'

export type ExperimentFilterValue = {
  id: string
  name: string
}

export type ExperimentFilters = Record<ExperimentOptionFields, string[]>
export type ExperimentOptionFields = Extract<
  keyof ExperimentSummary,
  | 'status'
  | 'channel'
  | 'country'
  | 'purpose'
  | 'startDate'
  | 'endDate'
  | 'frontendId'
>

export const FILTER_OPTION = {
  STATUS: 'status',
  CHANNEL: 'channel',
  COUNTRY: 'country',
  PURPOSE: 'purpose',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  FRONTEND_ID: 'frontendId',
} as const

export const getExperimentOptionDisplayName = (
  option: ExperimentOptionFields,
  experiment: ExperimentSummary,
  normalizedSites: NormalizedSites,
  normalizedCountries: NormalizedCountries,
  normalizedChannels: NormalizedChannels,
) => {
  switch (option) {
    case FILTER_OPTION.STATUS:
      return statusLabelMapping[experiment.status]
    case FILTER_OPTION.START_DATE:
      return getDateString(new Date(experiment.startDate))
    case FILTER_OPTION.END_DATE:
      return getDateString(new Date(experiment.endDate))
    case FILTER_OPTION.FRONTEND_ID:
      return (
        normalizedSites[experiment.frontendId].name ?? experiment.frontendId
      )
    case FILTER_OPTION.COUNTRY:
      return normalizedCountries[experiment.country].name ?? experiment.country
    case FILTER_OPTION.CHANNEL:
      return normalizedChannels[experiment.channel].name ?? experiment.channel
    case FILTER_OPTION.PURPOSE:
      return stringIdToLabel(experiment.purpose)
  }
}

export const getExperimentOptionSortingFn = (
  option: ExperimentOptionFields,
) => {
  return (a: ExperimentFilterValue, b: ExperimentFilterValue) => {
    switch (option) {
      case FILTER_OPTION.START_DATE:
      case FILTER_OPTION.END_DATE:
        return new Date(a.id).getTime() - new Date(b.id).getTime()
      default:
        return a.name.localeCompare(b.name)
    }
  }
}

export const filterOptions: {
  id: ExperimentOptionFields
  name: string
}[] = [
  {
    id: FILTER_OPTION.STATUS,
    name: 'Status',
  },
  {
    id: FILTER_OPTION.START_DATE,
    name: 'Start date',
  },
  {
    id: FILTER_OPTION.END_DATE,
    name: 'End date',
  },
  {
    id: FILTER_OPTION.FRONTEND_ID,
    name: 'Storefront',
  },
  {
    id: FILTER_OPTION.COUNTRY,
    name: 'Country',
  },
  {
    id: FILTER_OPTION.CHANNEL,
    name: 'Channel',
  },
  {
    id: FILTER_OPTION.PURPOSE,
    name: 'Type',
  },
] as const

export const initialFilters = filterOptions.reduce(
  (acc, { id }) => {
    acc[id] = []

    return acc
  },
  {} as Record<ExperimentOptionFields, string[]>,
)
