import {
  type DragStartEvent,
  type DragEndEvent,
  type UniqueIdentifier,
  type CollisionDetection,
  pointerWithin,
  rectIntersection,
  getFirstCollision,
  type DragMoveEvent,
  type CollisionDescriptor,
  type Collision,
} from '@dnd-kit/core'
import { useGetAtomFamilyValue } from 'hooks/useGetAtomValue'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef } from 'react'
import { widgetAtomFamily } from '../atoms/dashboardViewState'
import { getNewRowIndex, isNewRowId } from '../utils'
import { useDashboardLayoutManager } from './useDashboardLayoutManager'
import {
  WidgetDropPosition,
  overItemStateAtom,
  activeItemStateAtom,
  dashboardLayoutRowStateAtom,
} from './useDashboardLayoutState'
import { dashboardIdAtom, dashboardLayoutAtom } from './useDashboardState'

export const useDashboardLayoutRearrange = () => {
  const dashboardId = useAtomValue(dashboardIdAtom)
  const [layout, setLayout] = useAtom(dashboardLayoutAtom)

  const setActiveItemState = useSetAtom(activeItemStateAtom)
  const setOverItemState = useSetAtom(overItemStateAtom)

  const getRowState = useGetAtomFamilyValue(dashboardLayoutRowStateAtom)

  const layoutManager = useDashboardLayoutManager()

  const lastOverId = useRef<UniqueIdentifier | null>(null)
  const recentlyMovedToNewContainer = useRef(false)

  const getWidgetById = useGetAtomFamilyValue(widgetAtomFamily)

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [layout.rows])

  const findContainer = useCallback(
    (id: UniqueIdentifier) => {
      if (id in layout.rows) {
        return id
      }

      return layout.order.find((rowId) => {
        return layout.rows[rowId]?.cells.some((cell) => cell.widgetId === id)
      })
    },
    [layout.rows, layout.order],
  )

  const onDragMove = ({ over, active, collisions }: DragMoveEvent) => {
    if (!over) {
      return
    }

    const overContainer = findContainer(over.id)

    if (active.id === over.id) {
      setOverItemState({
        id: over.id as string,
        containerId: overContainer as string,
        dropPosition: undefined,
      })

      return
    }

    const dropPosition = getDropPosition(collisions, over.id)

    setOverItemState({
      id: over.id as string,
      containerId: overContainer as string,
      dropPosition,
    })
  }

  const onDragStart = ({ active }: DragStartEvent) => {
    const container = findContainer(active.id)

    if (!container) {
      return
    }

    setActiveItemState({
      id: active.id as string,
      containerId: container as string,
    })
  }

  const onDragCancel = () => {
    setActiveItemState(null)
    setOverItemState(null)
  }

  /**
   * This function handles the logic for when the widget is dragged from an `active` container (row) to an `over` container (row):
   * - If the widget is dropped into a new row, a new row is created and the widget is moved to the new row.
   * - If the widget is dropped into the same row, its position/index within the row is updated.
   * - If the widget is dropped into a new row, the widget is moved to the new row.
   * The layout manager handles the logic for moving the widget and recalculating the layout.
   */
  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (!dashboardId) {
        return
      }

      const { active, over } = event

      const activeContainer = findContainer(active.id)

      if (!activeContainer) {
        setActiveItemState(null)
        setOverItemState(null)

        return
      }

      const overId = over?.id

      if (overId == null) {
        setActiveItemState(null)
        setOverItemState(null)

        return
      }

      // If the widget is dropped into a new row, it is moved to the new row.
      if (isNewRowId(overId as string)) {
        const newRowIndex = getNewRowIndex(overId as string)
        const widget = getWidgetById(active.id as string)
        const rowState = getRowState(activeContainer as string)

        const { layout: updatedLayout } = layoutManager.moveWidgetToNewRow({
          widget,
          rowIndex: newRowIndex,
          rowHeight: rowState?.height,
          sourceRowId: activeContainer as string,
        })

        setLayout(updatedLayout)
        setActiveItemState(null)
        setOverItemState(null)

        return
      }

      const overContainer = findContainer(overId)

      // If the widget is dropped into the same row, its position/index within the row is updated.
      if (overContainer === activeContainer) {
        const activeIndex = layout.rows[activeContainer]?.cells.findIndex(
          (cell) => cell.widgetId === active.id,
        )
        const overIndex = layout.rows[overContainer]?.cells.findIndex(
          (cell) => cell.widgetId === overId,
        )

        if (activeIndex === undefined || overIndex === undefined) {
          return
        }

        if (activeIndex !== overIndex) {
          const updatedLayout = layoutManager.changeWidgetPositionInRow({
            rowId: overContainer as string,
            fromIndex: activeIndex,
            toIndex: overIndex,
          })

          setLayout(updatedLayout)
        }

        setActiveItemState(null)
        setOverItemState(null)

        return
      }

      // If the widget is dropped into an existing row, it is moved to that row.
      if (overContainer && activeContainer !== overContainer) {
        const overItems = layout.rows[overContainer]

        if (!overItems) {
          return
        }

        const overIndex = overItems.cells.findIndex(
          (cell) => cell.widgetId === overId,
        )

        const dropPosition = getDropPosition(event.collisions, overId)
        const indexModifier = dropPosition === WidgetDropPosition.After ? 1 : 0
        const newIndex =
          overIndex >= 0
            ? overIndex + indexModifier
            : overItems.cells.length + 1

        const widget = getWidgetById(active.id as string)

        const updatedLayout = layoutManager.moveWidgetToExistingRow({
          widget,
          sourceRowId: activeContainer as string,
          targetRowId: overContainer as string,
          targetCellIndex: newIndex,
        })

        setLayout(updatedLayout)
        setActiveItemState(null)
        setOverItemState(null)

        return
      }

      setActiveItemState(null)
      setOverItemState(null)
    },
    [
      dashboardId,
      findContainer,
      setActiveItemState,
      setOverItemState,
      getWidgetById,
      layoutManager,
      getRowState,
      setLayout,
      layout.rows,
    ],
  )

  const onCellWidthsChange = (rowId: string, cellWidths: number[]) => {
    if (!dashboardId) {
      return
    }

    const row = layoutManager.getRow(rowId)

    const currentWidths = row?.cells.map((cell) => cell.width)

    // If the widths are the same, do nothing
    if (
      currentWidths?.length === cellWidths.length &&
      currentWidths.every((width, index) => width === cellWidths[index])
    ) {
      return
    }

    const updatedCells = row?.cells.map((cell, index) => {
      return {
        ...cell,
        width: cellWidths[index],
      }
    })

    const updatedLayout = layoutManager.updateRowById(rowId, {
      cells: updatedCells,
    })

    setLayout(updatedLayout)
  }

  const onRowHeightChange = useCallback(
    (rowId: string, height: number) => {
      if (!dashboardId) {
        return
      }

      const updatedLayout = layoutManager.updateRowById(rowId, { height })

      setLayout(updatedLayout)
    },
    [dashboardId, setLayout, layoutManager],
  )

  /**
   * Custom collision detection strategy optimized for a layout with multiple containers (rows).
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args)

      const overId = getFirstCollision(intersections, 'id')

      if (overId != null) {
        if (isNewRowId(overId as string)) {
          return intersections
        }

        const overContainer = findContainer(overId)

        if (overContainer && overContainer in layout.rows) {
          const cells = layout.rows[overContainer]?.cells.map(
            (cell) => cell.widgetId,
          )

          if (cells && cells.length > 0) {
            const collisions = closestCenterToPointer({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => cells.includes(container.id as string),
              ),
            })

            if (collisions.length > 0) {
              lastOverId.current = collisions[0]?.id

              return collisions
            }
          }
        }

        lastOverId.current = overId

        return [{ id: overId }]
      }

      // If a widget is dragged to a different row, layout shifts can cause `overId` to be `null`.
      // To prevent incorrect positioning, we set `lastOverId` to the dragged widget's ID.
      // Without this, the previous `overId` would be used, potentially causing widgets to move incorrectly.
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = args.active.id as UniqueIdentifier
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [findContainer, layout.rows],
  )

  return {
    onDragMove,
    onDragStart,
    onDragCancel,
    onDragEnd,
    onCellWidthsChange,
    onRowHeightChange,
    collisionDetectionStrategy,
  }
}

const closestCenterToPointer: CollisionDetection = ({
  droppableContainers,
  pointerCoordinates,
  droppableRects,
}) => {
  if (!pointerCoordinates) {
    return []
  }

  const collisions: CollisionDescriptor[] = []

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer
    const rect = droppableRects.get(id)

    if (rect) {
      const centerX = rect.left + rect.width * 0.5
      const relativeDistance = pointerCoordinates.x - centerX
      const distance = Math.abs(relativeDistance)

      collisions.push({
        id,
        data: {
          value: distance,
          relativeDistance,
          droppableContainer,
        },
      })
    }
  }

  return collisions.toSorted((a, b) => a.data.value - b.data.value)
}

const getDropPosition = (
  collisions: Collision[] | null,
  overId: UniqueIdentifier,
) => {
  const firstCollision = getFirstCollision(collisions)

  if (firstCollision?.id !== overId) return

  const { relativeDistance } = firstCollision?.data || {}

  return relativeDistance > 0
    ? WidgetDropPosition.After
    : WidgetDropPosition.Before
}
