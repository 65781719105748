import { Flex, Td, Tr } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils/formatDate'
import { type SegmentationCustomerSet } from '../../../graphql/fragments'
import { useCustomerSetDownload } from '../../../graphql/useCustomerSetDownload'
import { CustomerSetContextMenu } from './CustomerSetContextMenu'

interface CustomerSetRowProps {
  set: SegmentationCustomerSet
}

export const CustomerSetRow: React.FC<CustomerSetRowProps> = ({ set }) => {
  const navigate = useNavigate()
  const { downloadSet, loading } = useCustomerSetDownload({
    setId: set.id,
    setName: set.name,
    from: 'sets',
  })

  return (
    <Tr
      onClick={() => navigate(SEGMENTATIONS_PAGES.CUSTOMER_RULES_BY_ID(set.id))}
      cursor="pointer"
      _hover={{ backgroundColor: 'gray.50' }}
    >
      <Td>
        <Flex alignItems="center" gap={2}>
          <Icon size="small" name={set.iconName} color={set.iconColor} />

          {set.name}
        </Flex>
      </Td>
      <Td>
        <Typography>{formatDate(new Date(set.updatedAt))}</Typography>
      </Td>
      <Td>
        <Flex gap={2}>
          <ButtonIcon
            name="DownloadIcon"
            size="sm"
            colorScheme="gray"
            variant="ghost"
            title="Download"
            onClick={downloadSet}
            isLoading={loading}
          />
          <CustomerSetContextMenu set={set} />
        </Flex>
      </Td>
    </Tr>
  )
}
