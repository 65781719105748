import { Box } from '@chakra-ui/react'
import { Icon, type IconProps } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import type React from 'react'
import { colorTheme } from 'ui/theme/colors'

interface RadioCardProps {
  label: string
  description?: string
  icon?: IconProps
  selected: boolean
  onChange: (selected: boolean) => void
}

// Once we move to chakra ui v3 we can use the Radio component instead
export const RadioCard: React.FC<RadioCardProps> = ({
  label,
  description,
  icon,
  selected,
  onChange,
}) => {
  return (
    <Box
      as="label"
      display="flex"
      cursor="pointer"
      p={4}
      boxShadow={
        // use inset shadow instead of border to prevent content inside from moving when toggling
        selected
          ? `0 0 0 2px inset ${colorTheme.gray[900]}`
          : `0 0 0 1px inset ${colorTheme.gray[200]}`
      }
      _hover={{
        bg: 'grey.50',
      }}
      gap={2}
    >
      <Box flex={1}>
        {icon && <Icon name={icon.name} size={icon.size} />}
        <Box flex={1}>
          <Typography
            fontSize="sm"
            fontWeight={500}
            lineHeight={5}
            color="gray.900"
            mb={0.5}
          >
            {label}
          </Typography>
          {description && (
            <Typography
              fontSize="sm"
              lineHeight={5}
              fontWeight={400}
              color="gray.600"
            >
              {description}
            </Typography>
          )}
        </Box>
      </Box>

      <Box flexShrink={0}>
        <Box
          as="input"
          type="radio"
          checked={selected}
          onChange={() => onChange(!selected)}
          w={4}
          h={4}
          cursor="pointer"
          sx={{
            accentColor: colorTheme.gray[900],
          }}
        />
      </Box>
    </Box>
  )
}
