import { getDatePreset, type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type CustomerSetByIdQueryResult } from 'features/segmentations/graphql/useCustomerSetById'
import { useSegmentationCustomerLabels } from 'features/segmentations/views/CustomerLabelsView/graphql/useCustomerLabels'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useRef } from 'react'
import {
  type UseFieldArrayReturn,
  type UseFormHandleSubmit,
} from 'react-hook-form'
import { Form } from 'react-router-dom'
import { type CustomerRuleForm } from '../../../../shared/RulesContainer/types'
import { getEmptyCustomerRule } from '../../../../shared/RulesContainer/utils'
import { useSetCustomerRules } from '../../graphql/useSetSegmentationCustomerRules'
import { RuleCard } from './RuleCard'

interface RulesContainerProps {
  segmentationCustomerSet: CustomerSetByIdQueryResult
  selectedDatePresetId: DYNAMIC_DATE_ID
  methods: UseFieldArrayReturn<
    { rules: CustomerRuleForm[] },
    'rules',
    'internal_id'
  >
  handleSubmit: UseFormHandleSubmit<{ rules: CustomerRuleForm[] }, undefined>
  isDirty: boolean
}

export const RulesContainer: React.FC<RulesContainerProps> = ({
  segmentationCustomerSet,
  selectedDatePresetId,
  methods,
  handleSubmit,
  isDirty,
}) => {
  const { segmentationCustomerLabels } = useSegmentationCustomerLabels()

  const { fields, append } = methods

  const [setSegmentationRules, setSegmentationRulesState] =
    useSetCustomerRules()

  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  const isSaveButtonDisabled =
    !isDirty && segmentationCustomerSet.dynamicDate === selectedDatePresetId

  const onSubmit = ({ rules }: { rules: CustomerRuleForm[] }) => {
    trackEvent({
      eventName: 'Segmentations Customer Set Saved',
      eventProperties: {
        setId: segmentationCustomerSet.id,
        setName: segmentationCustomerSet.name,
        dynamicDate: selectedDatePresetId,
        rules: rules.map((rule) => ({
          description: rule.description,
          labelId: rule.label?.id,
          labelName: rule.label?.name,
          expiryDate: rule.expiresAt,
        })),
      },
    })

    setSegmentationRules({
      variables: {
        params: {
          rules: rules.map((rule) => {
            return {
              description: rule.description,
              filters: rule.filters,
              labelId: rule.label?.id,
              expiresAt: rule.expiresAt,
            }
          }),
          dynamicDate: selectedDatePresetId,
          setId: segmentationCustomerSet.id,
        },
      },
      optimisticResponse: {
        setSegmentationCustomerRules: {
          ...segmentationCustomerSet,
          dynamicDate: selectedDatePresetId,
          rules: rules.map((rule) => ({
            __typename: 'SegmentationCustomerRule',
            id: rule.id,
            description: rule.description,
            filters: rule.filters,
            label: rule.label
              ? { ...rule.label, __typename: 'SegmentationCustomerLabel' }
              : null,
            expiresAt: rule.expiresAt,
          })),
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __typename: 'SegmentationCustomerSet',
            id: segmentationCustomerSet.id,
          }),
          fields: {
            rules: () => data?.setSegmentationCustomerRules.rules,
            dynamicDate: () => data?.setSegmentationCustomerRules.dynamicDate,
          },
        })
      },
      onCompleted: () => {
        toast({
          status: 'success',
          description: 'Successfully saved rules',
        })
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Failed to save rules',
        })
      },
    })
  }

  const confirmationModalDisclosure = useDisclosure()
  const bottomRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const buttons = (
    <Flex
      px={4}
      py={2}
      borderBottom="solid 1px"
      borderBottomColor="gray.200"
      gap={2}
    >
      <Button
        type="button"
        size="sm"
        variant="outline"
        onClick={() => {
          append(getEmptyCustomerRule())
          trackEvent({
            eventName: 'Segmentations Customer Rule Added',
            eventProperties: {
              setId: segmentationCustomerSet.id,
              setName: segmentationCustomerSet.name,
            },
          })

          // Wait for next render to account for the new rule
          window.requestAnimationFrame(scrollToBottom)
        }}
      >
        Add rule
      </Button>

      <>
        <Button
          onClick={confirmationModalDisclosure.onOpen}
          size="sm"
          isDisabled={isSaveButtonDisabled}
          isLoading={setSegmentationRulesState.loading}
        >
          Save
        </Button>
      </>
    </Flex>
  )

  const datePreset = getDatePreset(selectedDatePresetId)

  return (
    <>
      <Form>
        <Box shadow="base">
          {buttons}

          <Box>
            {fields.map((field, index) => (
              <RuleCard
                key={field.id}
                field={field}
                index={index}
                customerLabels={segmentationCustomerLabels}
                methods={methods}
                datePreset={datePreset}
                segmentationCustomerSet={segmentationCustomerSet}
              />
            ))}
            <div ref={bottomRef} />
          </Box>

          {buttons}
        </Box>
      </Form>

      <Modal
        isOpen={confirmationModalDisclosure.isOpen}
        onClose={confirmationModalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save customer set</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            When saving the customer set all changes will be stored in the
            database and exported to your ad platforms.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={3}
              onClick={confirmationModalDisclosure.onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                handleSubmit(onSubmit)()
                confirmationModalDisclosure.onClose()
              }}
            >
              Save customer set
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
