import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { Box, Flex, Switch } from '@chakra-ui/react'
import {
  ToggleWithIcons,
  type ToggleWithIconsItem,
} from 'components/ToggleWithIcons/ToggleWithIcons'
import { Typography } from 'components/Typography'
import { useDashboardDateState } from 'features/dashboards/hooks/filters/useDashboardDateState'
import {
  useSetWidgetCompareUnitState,
  useWidgetCompareUnitState,
} from 'features/dashboards/hooks/useWidgetCompareUnitState'
import {
  useSetWidgetDateState,
  useWidgetDateState,
} from 'features/dashboards/hooks/useWidgetDateState'
import { selectedWidgetIdAtom } from 'features/dashboards/hooks/useWidgetsState'
import { ReportDetailsHeader } from 'features/reports/components/ReportPanel/ReportDetailsPanel/ReportDetailsRow/ReportDetailsHeader'
import { type CompareUnit } from 'graphql/reports/types'
import { useAtomValue } from 'jotai'

const items: ToggleWithIconsItem<CompareUnit>[] = [
  {
    id: DISPLAY_FORMAT.PERCENTAGE_DIFF,
    title: 'Percentage difference',
    iconName: 'Percent1Icon',
  },
  {
    id: DISPLAY_FORMAT.ABSOLUTE_DIFF,
    title: 'Absolute difference',
    iconName: 'HashtagIcon',
  },
]

export const WidgetComparisonSettings = () => {
  const selectedWidgetId = useAtomValue(selectedWidgetIdAtom)
  const { widgetDateState } = useWidgetDateState(selectedWidgetId)
  const {
    compareDateRange: dashboardCompareDateRange,
    isCompare: isDashboardCompare,
  } = useDashboardDateState()

  const compareUnit = useWidgetCompareUnitState(selectedWidgetId)
  const setCompareUnit = useSetWidgetCompareUnitState(selectedWidgetId)
  const setWidgetDateState = useSetWidgetDateState(selectedWidgetId)

  const toggleCompareDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked

    setWidgetDateState({
      dateRange: widgetDateState.dateRange,
      dynamicDate: widgetDateState.dynamicDate,
      compareDateRange: isChecked
        ? !widgetDateState.isCompare
          ? dashboardCompareDateRange
          : widgetDateState.compareDateRange
        : [null, null],
      compareDynamicDate: widgetDateState.compareDynamicDate,
    })
  }

  return (
    <Flex gap={4} direction="column">
      <Flex gap={2} alignItems="center">
        <Typography fontSize="xs" color="grey.900">
          Show comparison
        </Typography>
        <Switch
          size="sm"
          colorScheme="primary"
          isChecked={widgetDateState.isCompare}
          onChange={toggleCompareDate}
          isDisabled={!isDashboardCompare && !widgetDateState.isCompare}
        />
      </Flex>
      {widgetDateState.isCompare && compareUnit && (
        <Box>
          <ReportDetailsHeader title="Comparison unit" />

          <ToggleWithIcons
            items={items}
            selected={compareUnit}
            setSelected={setCompareUnit}
            isDisabled={!widgetDateState.isCompare}
          />
        </Box>
      )}
    </Flex>
  )
}
