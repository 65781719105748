import { Box, Flex } from '@chakra-ui/react'
import { useOrganization, useUser } from '@clerk/clerk-react'
import { createColumnHelper } from '@tanstack/react-table'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import {
  differenceInYears,
  format,
  formatDistanceToNow,
  parseISO,
} from 'date-fns'
import { DashboardContextualMenu } from 'features/dashboards/components/DashboardHeader/DashboardContextualMenu/DashboardContextualMenu'
import { useEditDashboardOwner } from 'features/dashboards/hooks/useEditDashboardOwner'
import { useEditDashboardVisibility } from 'features/dashboards/hooks/useEditDashboardVisibility'
import { type DashboardResult } from 'features/dashboards/types'
import { getResourceVisibilityType } from 'graphql/shared/utils'
import { useMerchantTeams } from 'graphql/teams/useMerchantTeams'
import { useIsAdminUser } from 'hooks/useIsAdminUser'
import { useIsGuestUser } from 'hooks/useIsGuestUser'
import { useMemo } from 'react'
import {
  getCanEditResource,
  getCanEditResourceOwner,
} from 'shared/hooks/useCanEditResource'
import { ResourceOwner } from 'shared/ResourceOwner/ResourceOwner'
import { ResourceVisibility } from 'shared/ResourceVisibility/ResourceVisibility'

const columnHelper = createColumnHelper<DashboardResult>()

export const useDashboardListColumns = () => {
  const { editDashboardOwner, loading: editDashboardOwnerLoading } =
    useEditDashboardOwner()
  const { editDashboardVisibility } = useEditDashboardVisibility()
  const { merchantTeams, query: merchantTeamsQuery } = useMerchantTeams()
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })

  const { user } = useUser()
  const isGuestUser = useIsGuestUser()
  const isAdminUser = useIsAdminUser()
  const userId = String(user?.publicMetadata.dema_id)

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: ({ row }) => {
          const dashboard = row.original
          const { name, iconName, iconColor } = dashboard

          return (
            <Flex alignItems="center" gap={2}>
              <Icon
                name={iconName as IconName}
                color={iconColor}
                width={4}
                height={4}
              />
              <Typography fontWeight={600}>{name || 'Untitled'}</Typography>
            </Flex>
          )
        },
        header: 'Name',
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
      }),
      columnHelper.accessor('owner', {
        cell: ({ row, getValue }) => {
          const owner = getValue()
          const { visibility } = row.original
          const canEditDashboardOwner = getCanEditResourceOwner({
            visibility,
            owner,
            isAdminUser,
            userId,
            isGuestUser,
          })

          return (
            <ResourceOwner
              owner={owner}
              membershipsData={memberships?.data}
              disabled={!canEditDashboardOwner}
              onOwnerChanged={(newOwner) => {
                editDashboardOwner({
                  dashboard: row.original,
                  newOwnerExternalId: newOwner,
                  oldOwnerExternalId: owner.externalId,
                })
              }}
              getModalProps={(owner) => {
                const newOwnerName =
                  owner?.publicUserData.firstName +
                  ' ' +
                  owner?.publicUserData.lastName

                return {
                  isLoading: editDashboardOwnerLoading,
                  description: `The dashboard's ownership will be transferred to ${newOwnerName}`,
                }
              }}
            />
          )
        },
        header: 'Owner',
      }),
      columnHelper.accessor('visibility', {
        cell: ({ row }) => {
          const { visibility, owner } = row.original

          const canEditDashboard = getCanEditResource({
            visibility,
            owner,
            isGuestUser,
            userId,
          })

          return (
            <Box position="relative" left={-2}>
              <ResourceVisibility
                key={getResourceVisibilityType(row.original.visibility)}
                visibility={visibility}
                owner={owner}
                teamOptions={merchantTeams}
                isLoading={merchantTeamsQuery.loading}
                isDisabled={!canEditDashboard}
                onVisibilityChanged={(newVisibility) => {
                  editDashboardVisibility({
                    dashboard: row.original,
                    newVisibility,
                    oldVisibility:
                      row.original.visibility?.map((v) => v.id) ?? [],
                  })
                }}
              />
            </Box>
          )
        },
        header: 'Visibility',
      }),
      columnHelper.accessor('updatedAt', {
        cell: ({ getValue }) => {
          const updatedAt = getValue()

          if (!updatedAt) return null

          const date = parseISO(updatedAt)
          const formattedTooltip = format(date, 'yyyy-MM-dd')
          const diffYears = differenceInYears(date, new Date())
          const isWithinOneYear = diffYears < 1
          const lastUpdated = isWithinOneYear
            ? formatDistanceToNow(date, { addSuffix: true })
            : formattedTooltip

          return (
            <Typography title={isWithinOneYear ? formattedTooltip : undefined}>
              {lastUpdated}
            </Typography>
          )
        },
        header: 'Last updated',
        sortingFn: 'alphanumeric',
      }),
      columnHelper.display({
        id: 'settings',
        cell: ({ row }) => {
          const dashboard = row.original

          if (!dashboard) return null

          return <DashboardContextualMenu dashboard={dashboard} />
        },
      }),
    ],
    [
      editDashboardOwner,
      editDashboardOwnerLoading,
      editDashboardVisibility,
      isAdminUser,
      isGuestUser,
      memberships?.data,
      merchantTeams,
      merchantTeamsQuery.loading,
      userId,
    ],
  )

  return columns
}
