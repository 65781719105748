import { Flex, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { useWidgetType } from 'features/dashboards/hooks/useWidgetsState'
import { WidgetType } from 'features/dashboards/types'

const imageConfig: Partial<Record<WidgetType, string>> = {
  [WidgetType.CHART]: '/images/EmptyDashboardWidgets.svg',
  [WidgetType.TABLE]: '/images/EmptyTableWidget.svg',
}

export const EmptyWidgetContent = ({ widgetId }: { widgetId: string }) => {
  const widgetType = useWidgetType(widgetId)
  const image = widgetType ? imageConfig[widgetType] : null

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      gap={6}
      h="100%"
      justifyContent="center"
    >
      {image && <Image src={image} alt="Empty widget" boxSize={120} />}
      <Typography>You haven’t added any data yet.</Typography>
    </Flex>
  )
}
