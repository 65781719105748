import { getDatePreset } from 'constants/getDatePresets'
import { Badge } from 'components/Badge'
import { useWidgetDateState } from 'features/dashboards/hooks/useWidgetDateState'

export const WidgetCardDateLabel = ({ widgetId }: { widgetId: string }) => {
  const { widgetDateState } = useWidgetDateState(widgetId)

  const dateLabel = widgetDateState.dynamicDate
    ? getDatePreset(widgetDateState.dynamicDate)?.name
    : widgetDateState.dateRange[0]?.toLocaleDateString()

  if (!dateLabel) return null

  return <Badge>{dateLabel}</Badge>
}
