import { Flex, useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { dashboardStateAtom } from 'features/dashboards/atoms/dashboardViewState'
import { useCreateDashboard } from 'features/dashboards/graphql/useCreateDashboard'
import { useEditDashboard } from 'features/dashboards/graphql/useEditDashboard'
import {
  dashboardIdAtom,
  dashboardOwnerAtom,
  dashboardVisibilityAtom,
} from 'features/dashboards/hooks/useDashboardState'
import { DASHBOARD_PAGES } from 'features/dashboards/routePages'
import { createDashboardCopy } from 'features/dashboards/utils'
import { useGetAtomValue } from 'hooks/useGetAtomValue'
import { useAtomValue } from 'jotai'
import { useNavigate } from 'react-router'
import { useCanEditResource } from 'shared/hooks/useCanEditResource'

export const SaveDashboardButton = () => {
  const dashboardId = useAtomValue(dashboardIdAtom)
  const visibility = useAtomValue(dashboardVisibilityAtom)
  const owner = useAtomValue(dashboardOwnerAtom)

  const toast = useToast()
  const navigate = useNavigate()

  const getDashboardState = useGetAtomValue(dashboardStateAtom)

  const canEditDashboard = useCanEditResource({
    visibility,
    owner,
  })

  const [editDashboard, { loading: isEditingDashboard }] = useEditDashboard()
  const [createDashboard, { loading: isCreatingDashboard }] =
    useCreateDashboard()

  const isLoading = isEditingDashboard || isCreatingDashboard

  const saveDashboard = async () => {
    if (!dashboardId) {
      toast({
        status: 'error',
        description: 'Could not find selected dashboard, please try again',
      })

      return
    }

    try {
      const dashboardState = getDashboardState()

      await editDashboard({
        id: dashboardId,
        dashboard: dashboardState,
      })

      toast({
        status: 'success',
        description: 'Successfully updated dashboard',
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save dashboard, please try again',
      })

      captureException(`Save dashboard: ${error}`)
    }
  }

  const saveDashboardAsCopy = async () => {
    try {
      const dashboardState = getDashboardState()
      const { layout, widgets } = createDashboardCopy(dashboardState)

      const newDashboard = await createDashboard({
        dashboard: {
          ...dashboardState,
          name: `Copy of ${dashboardState.name}`,
          visibility: dashboardState.visibility ?? [],
          layout,
          widgets,
        },
      })

      navigate(DASHBOARD_PAGES.DASHBOARD_BY_ID(newDashboard.id))
      toast({
        status: 'success',
        description: 'Successfully created dashboard!',
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save dashboard, please try again',
      })

      captureException(`Save dashboard copy: ${error}`)
    }
  }

  return (
    <Flex>
      <Button
        size="sm"
        w="fit-content"
        isLoading={isLoading}
        onClick={canEditDashboard ? saveDashboard : saveDashboardAsCopy}
      >
        {canEditDashboard ? 'Save' : 'Save as copy'}
      </Button>
    </Flex>
  )
}
