import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { useUserReports } from 'graphql/reports/useUserReports'
import { type ReactElement } from 'react'

interface AddWidgetFromReportPickerProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onModalOpen: () => void
  setSelectedReportId: (reportId: string) => void
  customMenuButton: ReactElement
}

export const AddWidgetFromReportPicker = ({
  isOpen,
  onClose,
  onOpen,
  onModalOpen,
  setSelectedReportId,
  customMenuButton,
}: AddWidgetFromReportPickerProps) => {
  const { userReports, query } = useUserReports()

  return (
    <ComboBox
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      selectionMode={SELECTION_MODE.SINGLE}
      isLoading={query.loading}
      customMenuButton={customMenuButton}
      setSelected={(reportId) => {
        setSelectedReportId(reportId)
        onClose()
        onModalOpen()
      }}
      options={userReports}
      listProps={{ mt: 1 }}
      menuProps={{
        placement: 'bottom-end',
        matchWidth: false,
      }}
    />
  )
}
