import { getDatePreset, type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { LegendRow } from 'features/optimizations/components/LegendRow/LegendRow'
import { PieChartSkeleton } from 'features/segmentations/shared/RulesContainer/PieChartSkeleton'
import { useProductLabels } from 'features/segmentations/views/ProductLabelsView/graphql/useProductLabels'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { type FieldArrayWithId } from 'react-hook-form'
import { colorTheme } from 'ui/theme/colors'
import { PieChart } from '../../../../shared/RulesContainer/PieChart'
import { type RuleForm } from '../../../../shared/RulesContainer/types'
import { useProductChartMetrics } from '../../graphql/useProductChartMetrics'

interface SegmentationPieChartsProps {
  selectedDatePresetId: DYNAMIC_DATE_ID
  rules: FieldArrayWithId<{ rules: RuleForm[] }, 'rules', 'internal_id'>[]
}

const METRICS = {
  PRODUCTS: 'product:uniqueProducts',
  MARKETING_SPEND: 'feed:feedTotal',
  GROSS_SALES: 'order:total:dema',
  INVENTORY_VALUE: 'product:inventoryValue',
} as const

type MetricKey = (typeof METRICS)[keyof typeof METRICS]

const metrics = Object.values(METRICS) satisfies MetricKey[]

export const SegmentationPieCharts: React.FC<SegmentationPieChartsProps> = ({
  selectedDatePresetId,
  rules,
}) => {
  const selectedDatePreset = getDatePreset(selectedDatePresetId)
  const { productLabels } = useProductLabels()
  const { frontendIds = [] } = useMerchantInfo()
  const { segmentationProductChartMetrics, loading } = useProductChartMetrics({
    params: {
      rules:
        rules
          ?.filter((rule) => !!rule.productLabel)
          .map((rule) => ({
            id: rule.id,
            filters: rule.filters,
            productLabelId: rule.productLabel?.id ?? '',
            productLabelName: rule.productLabel?.name ?? '',
          })) || [],
      dynamicDate: selectedDatePreset.id,
      statisticsInput: {
        frontendIds,
        startDate: selectedDatePreset.value[0]?.toISOString() ?? '',
        endDate: selectedDatePreset.value[1]?.toISOString() ?? '',
        dimensions: ['product'],
        metrics,
        filter: {
          product: [
            { comparisonId: 'isNoneOf', value: ['Unknown', 'UNKNOWN'] },
          ],
        },
      },
    },
  })

  const normalizedMetrics = useNormalizedMetrics()

  if (loading) return <PieChartSkeleton charts={4} />

  const { uncategorized, ...aggregatedMetrics } =
    segmentationProductChartMetrics

  const pieCharts = [
    {
      metricKey: METRICS.PRODUCTS,
      label: 'Products',
    },
    {
      metricKey: METRICS.MARKETING_SPEND,
      label: 'Marketing spend',
      htmlLabel: 'Marketing <br /> spend', // We use this to display the label in the pie chart to add a line break
    },
    {
      metricKey: METRICS.GROSS_SALES,
      label: 'Gross Sales',
    },
    {
      metricKey: METRICS.INVENTORY_VALUE,
      label: 'Inventory value',
      htmlLabel: 'Inventory <br /> value',
    },
  ] satisfies { metricKey: MetricKey; label: string; htmlLabel?: string }[]

  return (
    <Flex
      p={4}
      shadow="base"
      bg="white"
      gap={6}
      w="max-content"
      shrink={0}
      h="max-content"
      position="sticky"
      top={4}
    >
      <Box px={6}>
        {/* overflow visible as the charts are hacked to go outside of container size, so this targets them to make them visible */}
        <Stack gap={8} sx={{ '*': { overflow: 'visible !important' } }}>
          {pieCharts.map(({ metricKey, htmlLabel, label }) => (
            <PieChart
              key={metricKey}
              metricKey={metricKey}
              label={label}
              htmlLabel={htmlLabel}
              format={normalizedMetrics[metricKey]?.format}
              selectedDatePreset={selectedDatePreset}
              aggregatedMetrics={aggregatedMetrics}
              uncategorized={uncategorized}
              labels={productLabels}
            />
          ))}
        </Stack>
      </Box>
      <Box>
        {productLabels
          ?.filter((label) =>
            rules.find((rule) => rule.productLabel?.id === label.id),
          )
          .map((label) => (
            <LegendRow
              key={label.id}
              color={label.iconColor}
              label={label.name}
              type="column"
            />
          ))}

        {Object.values(uncategorized).some((value) => value > 0) && (
          <LegendRow
            color={colorTheme.gray[300]}
            label="Uncategorized"
            type="column"
          />
        )}
      </Box>
    </Flex>
  )
}
